import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconMinus } from "../../../ui/Icon/Line/Minus";
import { Event } from "../../event/eventsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import "./AdminNotificationRuleEventTemplate.css";
import { notificationDefaultTemplateSelectors } from "./notificationDefaultTemplateSlice";
import {
  getNotificationMetadataAsync,
  NotificationMetadata,
  notificationMetadataSelectors,
} from "./notificationMetadataSlice";
import { NotificationTemplateDTO } from "./notificationRuleAggregationSlice";

interface AdminNotificationRuleEventTemplateProps {
  events: Event[];
  sms: boolean;
  canModify: boolean;
  notificationRuleAggregationId?: number;
  open: boolean;
  responseTemplatesMap: (e: any) => any;
}

interface TemplateElement {
  name: string;
  text: string;
}

export const AdminNotificationRuleEventTemplate: React.FC<AdminNotificationRuleEventTemplateProps> =
  ({
    events,
    sms,
    canModify,
    notificationRuleAggregationId,
    open,
    responseTemplatesMap,
  }) => {
    const [selectedEventForTemplate, setEventManageTextSelected] =
      useState<Event>({} as Event);
    const [personalizedEventTemplates, setTextsPersonalized] = useState<
      TemplateElement[]
    >([]);
    const location = useLocation();
    const { pathname } = location;
    const isAddRule = pathname.includes("/add-rule");
    const [isFocused, setIsFocused] = useState(false);
    const [output, setOutput] = useState<Map<String, String>>();
    const templates: NotificationTemplateDTO[] = useAppSelector(
      notificationDefaultTemplateSelectors.selectAll
    );

    let editableDivRef = useRef<HTMLDivElement | null>(null);

    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const metadataList: NotificationMetadata[] = useAppSelector(
      notificationMetadataSelectors.selectAll
    ).filter((e) =>
      e.eventTypes?.find((el) => el === selectedEventForTemplate?.name)
    );

    //#region USEEFFECT

    /**
     * Initializes what?
     */
    useEffect(() => {
      store.dispatch(
        getNotificationMetadataAsync({
          queryParams: "?language=" + preferencesContext.language,
        })
      );
      events && setEventManageTextSelected(events[0]);
      let timeoutId: NodeJS.Timeout;
      const handleResize = () => {
        clearTimeout(timeoutId); // Clear previous timeout on resize-event

        timeoutId = setTimeout(() => {
          const element = document.getElementById(
            "admin-notification-forms-text-manager-components"
          );
          const elementToResize = document.getElementById(
            "admin-notification-event-template-text-area"
          );

          if (elementToResize && element) {
            const larghezzaIniziale = element.offsetWidth - 185 + "px";
            // Imposta la larghezza massima
            elementToResize.style.maxWidth = larghezzaIniziale;
          }
        }, 50);
      };

      window.addEventListener("resize", handleResize);

      // Cleanup: it removes the event listener and the timeout on component unmount
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    /**
     * Each time a template is begin modified updates the state of custom templates to be associated to the current rule.
     */
    useEffect(() => {
      //creating map element for updating/creating notificaiton rule
      let textsPersonalizedResponse: Map<string, string> = new Map<
        string,
        string
      >();
      let textsPersonalizedFilteredAndSubstituted: any;

      textsPersonalizedFilteredAndSubstituted =
        personalizedEventTemplates.filter((textEvent) => {
          return (
            textEvent.name !== undefined &&
            textEvent.text !== "" &&
            textEvent.text !== undefined &&
            (templates.find(
              (defaultTemplate) =>
                defaultTemplate.event === textEvent.name &&
                defaultTemplate.type === "TEXT" &&
                (defaultTemplate.notificationRuleAggregationId !== null ||
                  defaultTemplate.notificationRuleAggregationId !== undefined)
            )
              ? true
              : textEvent.text !==
                templates.find(
                  (defaultTemplate) =>
                    defaultTemplate.event === textEvent.name &&
                    defaultTemplate.type === "TEXT" &&
                    (defaultTemplate.notificationRuleAggregationId === null ||
                      defaultTemplate.notificationRuleAggregationId ===
                        undefined)
                )?.template) &&
            textEvent.text !==
              templates.find(
                (defaultTemplate) =>
                  defaultTemplate.event === textEvent.name &&
                  defaultTemplate.type === "TEXT" &&
                  (defaultTemplate.notificationRuleAggregationId !== null ||
                    defaultTemplate.notificationRuleAggregationId !== undefined)
              )?.template &&
            events?.some((event) => event.name === textEvent.name)
          );
        });

      textsPersonalizedFilteredAndSubstituted =
        textsPersonalizedFilteredAndSubstituted.map(
          (textPersonalized: TemplateElement) => {
            const newText = metadataList.reduce(
              (updatedText, currentMetadata) => {
                const metadataLabel = currentMetadata.metadata;
                // Replace the translation with the original text
                return updatedText.replace(
                  metadataLabel,
                  " " +
                    `${currentMetadata.originType}.${currentMetadata.name}` +
                    " "
                );
              },
              textPersonalized.text
            );
            return {
              ...textPersonalized,
              text: newText,
            };
          }
        );

      if (isAddRule) {
        let eventsToAdd = events.filter(
          (textPersonalized: any) =>
            !textsPersonalizedFilteredAndSubstituted.some(
              (event: any) => event.name === textPersonalized.name
            )
        );

        eventsToAdd.map((event: any) =>
          textsPersonalizedFilteredAndSubstituted.push({
            name: event.name,
            text: "",
          } as any)
        );
      }

      textsPersonalizedResponse = new Map<string, string>(
        textsPersonalizedFilteredAndSubstituted.map((element: any) => [
          element.name,
          element.text
            .replace(/<[^>]*>([^<]*)<\/[^>]*>/g, " $1 ")
            .replace(/\s+/g, " ")
            .trim(),
        ])
      );

      if (!_.isEqual(output, textsPersonalizedResponse)) {
        setOutput(textsPersonalizedResponse);
        responseTemplatesMap(textsPersonalizedResponse);
      }
    }, [personalizedEventTemplates, selectedEventForTemplate, events]);

    /**
     * Each time an event is selected it loads the default/custom templates into a state to be shown in the correct section.
     */
    useEffect(() => {
      if (
        !personalizedEventTemplates?.find(
          (e) => e.name === selectedEventForTemplate?.name
        )
      ) {
        let renderedTemplate =
          templates?.find(
            (e) =>
              e.event === selectedEventForTemplate?.name &&
              e.template !== "" &&
              e.type == "TEXT" &&
              e.notificationRuleAggregationId === notificationRuleAggregationId
          )?.template ?? "";

        if (renderedTemplate === "") {
          renderedTemplate =
            templates.find(
              (defaultTemplate) =>
                defaultTemplate.event === selectedEventForTemplate?.name &&
                defaultTemplate.type === "TEXT" &&
                (defaultTemplate.notificationRuleAggregationId === null ||
                  defaultTemplate.notificationRuleAggregationId === undefined)
            )?.template ?? "";
        }

        if (renderedTemplate.length > 0) {
          metadataList.map((currentMetadata) => {
            renderedTemplate = renderedTemplate.replace(
              currentMetadata.originType + "." + currentMetadata.name,
              renderToString(
                <span className="metadata-tag" contentEditable={false}>
                  {currentMetadata.metadata}
                </span>
              )
            );
          });
          const newTemplateElement: TemplateElement = {
            name: selectedEventForTemplate?.name,
            text: renderedTemplate,
          };
          personalizedEventTemplates.push(newTemplateElement);
        }
      }
      if (editableDivRef.current) {
        editableDivRef.current.innerHTML =
          personalizedEventTemplates?.find(
            (e) => e.name === selectedEventForTemplate?.name
          )?.text ?? "";
      }
    }, [selectedEventForTemplate, templates]);

    //#region FUNCTION

    function smsNumberCalculator(text: string) {
      let textWithoutVariables = text.replace(/\b\S*@\S*\b/g, "").trim();

      if (
        (textWithoutVariables && textWithoutVariables.length <= 160) ||
        textWithoutVariables == ""
      ) {
        return 1;
      } else {
        if (textWithoutVariables)
          return Math.ceil(textWithoutVariables.length / 153);
      }
    }

    function textTagInsert(tagText: string, elemID: string) {
      let div = document.getElementById(elemID);
      if (div !== null && div.isContentEditable) {
        const selection = window.getSelection();

        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);

          let currentNode: Node | null = selection?.rangeCount
            ? selection.getRangeAt(0).startContainer
            : null;

          // Risali l'albero dei nodi fino a trovare un div, o null se non viene trovato
          const parentDiv = currentNode
            ? (() => {
                while (currentNode && currentNode.nodeName !== "DIV") {
                  currentNode = currentNode.parentNode;
                }
                return currentNode;
              })()
            : (null as any);

          if (parentDiv?.className === elemID) {
            // Rimuove il contenuto selezionato
            range.deleteContents();

            // Crea un elemento span e imposta il suo contenuto HTML
            const tagNode = document.createElement("span");
            tagNode.className = "metadata-tag";
            tagNode.contentEditable = "false";
            tagNode.innerHTML = tagText;

            // Inserisci il nodo nel range corrente
            range.insertNode(tagNode);

            // Sposta il cursore dopo il tag inserito
            range.setStartAfter(tagNode);
            selection.removeAllRanges();
            selection.addRange(range);

            // Aggiorna il testo personalizzato
            let newContent = div.innerHTML;

            let element = personalizedEventTemplates?.find(
              (e) => e.name === selectedEventForTemplate?.name
            );

            if (element) {
              element.text = newContent;
            }

            setTextsPersonalized(
              personalizedEventTemplates.map((e) =>
                e.name === selectedEventForTemplate?.name
                  ? { ...e, text: newContent }
                  : e
              )
            );
          }
        }
      }
    }

    function textTagRemove(tagText: string, elemID: string) {
      let div = document.getElementById(elemID);
      if (div !== null) {
        // Seleziona tutti gli elementi span con la classe "metadata-tag"
        const tags = div.querySelectorAll(`span.metadata-tag`);

        tags.forEach((tag) => {
          // Controlla se l'elemento corrente ha il testo specificato
          if (tag.textContent === tagText) {
            // Rimuove l'elemento dal DOM
            tag.parentNode?.removeChild(tag);
          }
        });

        // Aggiorna il testo personalizzato
        let newContent = div.innerHTML;

        let element = personalizedEventTemplates?.find(
          (e) => e.name === selectedEventForTemplate?.name
        );

        if (element) {
          element.text = newContent;
        }

        setTextsPersonalized(
          personalizedEventTemplates.map((e) =>
            e.name === selectedEventForTemplate?.name
              ? { ...e, text: newContent }
              : e
          )
        );
      }
    }

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    //#endregion FUNCTION

    useEffect(() => {
      events.length > 0 &&
        _.isEmpty(selectedEventForTemplate) &&
        setEventManageTextSelected(events[0]);
    }, [events]);

    //#endregion USEEFFECT

    return open ? (
      <div className="admin-notification-forms-manage-text">
        <div className="admin-notification-forms-event-selection">
          {events?.map((event: Event) => {
            return (
              <div
                className={
                  _.isEqual(event, selectedEventForTemplate)
                    ? "admin-notification-forms-event-selected"
                    : "admin-notification-forms-event"
                }
                onClick={() => setEventManageTextSelected(event)}
              >
                {t("eventType." + event.name) !== "eventType." + event.name
                  ? t("eventType." + event.name)
                  : event.name
                  ? event.name.split("_").join(" ").toLowerCase()
                  : ""}
              </div>
            );
          })}
        </div>
        <div className="admin-notification-divider" />
        {!_.isEmpty(selectedEventForTemplate) && (
          <div
            id="admin-notification-forms-text-manager-components"
            className="admin-notification-forms-text-manager-components"
          >
            <div
              className="admin-notification-forms-text-manager-component"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="admin-notification-forms-text-manager-description">
                <span style={{ fontWeight: 500 }}>
                  {t("admin.notifications.actions.manageText.description")
                    .split(" ")
                    .slice(0, 3)
                    .join(" ")}
                </span>
                {" " +
                  t("admin.notifications.actions.manageText.description")
                    .split(" ")
                    .slice(3)
                    .join(" ")}
              </p>
              <span style={{ marginBottom: "40px", marginRight: "77px" }}>
                <Button
                  size={"small"}
                  label={undefined}
                  aspect="outline"
                  onClick={() => {
                    let tempText = templates?.find(
                      (e) =>
                        e.event === selectedEventForTemplate?.name &&
                        (e.notificationRuleAggregationId === null ||
                          e.notificationRuleAggregationId === undefined)
                    )?.template;
                    metadataList.map((currentMetadata) => {
                      tempText = tempText?.replace(
                        currentMetadata.originType + "." + currentMetadata.name,
                        renderToString(
                          <span
                            className="metadata-tag"
                            contentEditable={false}
                          >
                            {currentMetadata.metadata}
                          </span>
                        )
                      );
                    });

                    const newTemplateElement: TemplateElement = {
                      name: selectedEventForTemplate?.name,
                      text: tempText ?? "",
                    };
                    setTextsPersonalized([
                      ...personalizedEventTemplates.filter(
                        (e) => e.name !== selectedEventForTemplate?.name
                      ),
                      newTemplateElement,
                    ]);
                    if (editableDivRef.current !== null) {
                      editableDivRef.current.innerHTML = tempText ?? "";
                    }
                  }}
                  disabled={
                    !!!templates.find(
                      (defaultTemplate) =>
                        defaultTemplate.event ===
                          selectedEventForTemplate?.name &&
                        (defaultTemplate.notificationRuleAggregationId ===
                          null ||
                          defaultTemplate.notificationRuleAggregationId ===
                            undefined) &&
                        defaultTemplate.type === "TEXT"
                    ) ||
                    templates.find(
                      (defaultTemplate) =>
                        defaultTemplate.event ===
                          selectedEventForTemplate?.name &&
                        defaultTemplate.type === "TEXT" &&
                        (defaultTemplate.notificationRuleAggregationId ===
                          null ||
                          defaultTemplate.notificationRuleAggregationId ===
                            undefined)
                    )?.template ===
                      personalizedEventTemplates?.find(
                        (e) => e.name === selectedEventForTemplate?.name
                      )?.text ||
                    !canModify
                  }
                >
                  {t("admin.notifications.actions.manageText.restoreDefault")}
                </Button>
              </span>
            </div>
            <div
              className="admin-notification-forms-text-manager-component"
              style={{ marginBottom: "40px" }}
            >
              <p className="admin-notification-forms-text-manager-text">
                {t("admin.notifications.actions.manageText.text")}
              </p>
              <div
                style={{
                  width: "100%",
                  marginRight: "77px",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                {sms && (
                  <p
                    style={{
                      marginLeft: "calc(100% - 55px)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {"MIN " +
                      smsNumberCalculator(
                        personalizedEventTemplates?.find(
                          (e) => e.name === selectedEventForTemplate?.name
                        )?.text ?? ""
                      ) +
                      " SMS"}
                  </p>
                )}
                <div
                  className="admin-notification-event-template-text-area"
                  ref={editableDivRef}
                  contentEditable={canModify}
                  id="admin-notification-event-template-text-area"
                  suppressContentEditableWarning={true}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onInput={(event) => {
                    selectedEventForTemplate?.name !== undefined &&
                      setTextsPersonalized([
                        ...personalizedEventTemplates.filter(
                          (e) => e.name !== selectedEventForTemplate?.name
                        ),
                        {
                          name: selectedEventForTemplate?.name,
                          text:
                            document.getElementById(
                              "admin-notification-event-template-text-area"
                            )?.innerHTML ?? "",
                        },
                      ]);
                  }}
                  style={{
                    fontSize: "var(--global-font-size-sm)",
                    fontFamily: "inherit",
                    padding: "16px",
                    paddingBottom: "16px",
                    paddingTop: "8px",
                    width: "100%",
                    minHeight: "80px",
                    outline: "none",
                    boxShadow: isFocused
                      ? "1px 1px 1px 1px rgba(0.1, 0.1, 0.1, 0.1)"
                      : "none",
                    border: isFocused
                      ? "1px solid #0d6efd"
                      : "1px solid var(--global-colors-sky-dark)",
                    borderRadius: "4px",
                  }}
                />
                <div
                  style={{
                    width: "fit-content",
                    position: "absolute",
                    right: 0,
                    marginRight: "10px",
                    bottom: 5,
                    color: "#687484",
                    display: "flex",
                  }}
                >
                  {"Min"}
                  <div style={{ marginRight: "2px", marginLeft: "2px" }}>
                    {" " +
                      personalizedEventTemplates
                        ?.find((e) => e.name === selectedEventForTemplate?.name)
                        ?.text.replace(/\b\S*@\S*\b/g, "")
                        .trim().length !==
                    undefined
                      ? personalizedEventTemplates
                          ?.find(
                            (e) => e.name === selectedEventForTemplate?.name
                          )
                          ?.text.replace(/\b\S*@\S*\b/g, "")
                          .trim().length
                      : 0}
                  </div>
                  {t("validation.characters")}
                </div>
              </div>
            </div>
            <div
              className="admin-notification-forms-text-manager-component"
              style={{ alignItems: "center", gap: "60px" }}
            >
              <p className="admin-notification-forms-text-manager-text">
                {t("admin.notifications.actions.manageText.variables")}
              </p>
              <div className="admin-notification-tag-variable-container">
                {metadataList.length > 0 &&
                  metadataList.map((e) => {
                    return (
                      <div
                        className="mn-tag"
                        style={{
                          borderRadius: "4px",
                          alignItems: "center",
                          margin: "0",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                        }}
                        onClick={() => {
                          personalizedEventTemplates
                            ?.find(
                              (e) => e.name === selectedEventForTemplate?.name
                            )
                            ?.text?.includes(e.metadata)
                            ? textTagRemove(
                                e.metadata,
                                "admin-notification-event-template-text-area"
                              )
                            : textTagInsert(
                                e.metadata,
                                "admin-notification-event-template-text-area"
                              );
                        }}
                      >
                        <div
                          className="mn-tag--neutro"
                          style={{
                            borderRadius: "4px",
                            padding: "4px",
                            cursor: "pointer",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          {e.metadata}
                          {personalizedEventTemplates.length &&
                          personalizedEventTemplates
                            ?.find(
                              (e) => e.name === selectedEventForTemplate?.name
                            )
                            ?.text?.includes(e.metadata) ? (
                            <IconMinus size={12} color="--white" />
                          ) : (
                            <IconAdd size={12} color="--white" />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <></>
    );
  };
