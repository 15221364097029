import PropTypes from "prop-types";
import { Tag } from "../Tags/Tag";
export const ModalBody = ({
  children,
  isScrollable,
  tagList,
  title,
  desc,
  stylePadding = null,
  ...props
}) => {
  return (
    <>
      <header className="mn-modal__dialog-header">
        {title && (
          <div className="mn-modal__dialog-title">
            <div>{title}</div>
            {desc && (
              <>
                <div>
                  <p className="mn-modal__dialog-text">{desc}</p>
                  <div className="mn-modal__dialog-tag">
                    {tagList &&
                      tagList.map((tag, index) => {
                        return (
                          <Tag
                            key={index}
                            mode="outline"
                            size="small"
                            text={tag}
                            type="basic"
                          />
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </header>
      {children && (
        <div
          className="mn-modal__dialog-body"
          style={
            stylePadding
              ? {
                  paddingLeft: stylePadding,
                  paddingRight: stylePadding,
                  paddingBottom: stylePadding,
                  paddingTop: stylePadding,
                }
              : null
          }
          data-scrollable={isScrollable}
          {...props}
        >
          {isScrollable ? (
            <div className="mn-modal__dialog-body--scrollable">{children}</div>
          ) : (
            children
          )}
        </div>
      )}
    </>
  );
};

ModalBody.propTypes = {
  isScrollable: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  desc: PropTypes.string,
};
