import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import NotificationsRepository from "../NotificationRepository";

export type NotificationMetadata = {
  id: number;
  name: string;
  metadata: string;
  originType: string;
  originDb: string;
  eventTypes: string[];
};

const notificationMetadataAdapter = createEntityAdapter<NotificationMetadata>({
  selectId: (notificationMetadata) => notificationMetadata.id,
});

export const getNotificationMetadataAsync = createAsyncThunk(
  "notificationMetadata/getNotificationMetadata",
  async (data: { queryParams?: string }) => {
    const notificationsRepository = new NotificationsRepository();
    const response = await notificationsRepository.getNotificationMetadata(
      data.queryParams
    );
    return response.data;
  }
);

export const notificationMetadataSlice = createSlice({
  name: "notificationMetadata",
  initialState: notificationMetadataAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    notificationMetadataEmptyState: (state) => {
      notificationMetadataAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getNotificationMetadataAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notificationMetadataAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getNotificationMetadataAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getNotificationMetadataAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const notificationMetadataSelectors =
  notificationMetadataAdapter.getSelectors<RootState>(
    (state) => state.notificationMetadata
  );

export const selectNotificationMetadataSliceStatus = (state: any) =>
  state.notificationMetadata.status;
export const selectNotificationMetadataSliceReasonCode = (state: any) =>
  state.notificationMetadata.reasonCode;

export default notificationMetadataSlice.reducer;
