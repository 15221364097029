import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import { DropdownButton, ListItem } from "../../ui/Button/DropdownButton";
import { IconAttach } from "../../ui/Icon/Line/Attach";
import { IconEuro } from "../../ui/Icon/Line/Euro";
import { IconGasStation } from "../../ui/Icon/Line/GasStation";
import { IconMore } from "../../ui/Icon/Line/More";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../ui/Tags/Tag";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { Driver } from "../driver/driversSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserInfo, userInfoSelectors } from "../users/usersInfoSlice";
import { Vehicle } from "../vehicle/vehiclesSlice";
import {
  Activity,
  ActivityStatusType,
  ActivityUpdateRequest,
  updateActivityAsync,
} from "./activitiesSlice";

export class DashboardActivitiesTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      activity?: Activity;
      vehicle?: Vehicle;
      driver?: Driver;
      openAttachmentModal: (e: any) => any;
      openDetailsModal: (e: any) => any;
      openDeleteActivityModal: (e: any) => any;
      selectedActivity: (e: any) => any;
    },
    preferencesContext: Preferences,
    navigate: NavigateFunction
  ) => {
    function getTagTypeFromActivityStatus(status: ActivityStatusType) {
      switch (status) {
        case "ARCHIVED":
        case "DRAFT":
          return "neutro";
        case "COMPLETED":
        case "MANAGED":
          return "success";
        default:
          return "neutro2";
      }
    }
    function getTagModeFromActivityStatus(status: ActivityStatusType) {
      switch (status) {
        case "ARCHIVED":
        case "MANAGED":
          return "fill";
        case "COMPLETED":
        case "DRAFT":
          return "outline";
      }
    }

    function getUpdateTypeFromAction(action: string, activity: Activity) {
      switch (action) {
        case "confirm":
          let confirmedActivity = mapActivityToUpdateRequest(activity, user);
          confirmedActivity = {
            ...confirmedActivity,
            status: "MANAGED" as ActivityStatusType,
          };
          store.dispatch(
            updateActivityAsync({
              activity: confirmedActivity,
              id: activity.id,
            })
          );
          break;
        case "archive":
          let archivedActivity = mapActivityToUpdateRequest(activity, user);
          archivedActivity = {
            ...archivedActivity,
            status: "ARCHIVED" as ActivityStatusType,
          };
          store.dispatch(
            updateActivityAsync({
              activity: archivedActivity,
              id: activity.id,
            })
          );
          break;
      }
    }

    const mapActivityToUpdateRequest = (
      activity: Activity,
      userInfo: UserInfo
    ): ActivityUpdateRequest => {
      return {
        type: activity.type,
        status: activity.status,
        user: userInfo,
        latitude: activity.latitude,
        longitude: activity.longitude,
        date: activity.date,
      };
    };

    let row: Row = { render: {} as Row };
    let activity: Activity = entities.activity ?? ({} as Activity);
    let driver: Driver = entities.driver ?? ({} as Driver);
    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);
    let user =
      userInfoSelectors.selectById(store.getState(), activity.user) ??
      ({} as UserInfo);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "name":
            row.name = activity.name ?? t("common.na");
            row.render.name = activity.name ?? t("common.na");
            break;
          case "driver":
            row.driver = driver.id ?? t("common.na");
            row.render.driver = (
              <LabelText
                label={driver.id ? driver.id + "" : t("common.na")}
                text={
                  driver.firstName
                    ? driver.firstName + " " + driver.lastName
                    : t("common.na")
                }
              />
            );
            break;
          case "vehicle":
            row.vehicle = vehicle.id ?? t("common.na");
            row.render.vehicle = (
              <LabelText
                label={vehicle.id ? vehicle.id + "" : t("common.na")}
                text={
                  vehicle.alias
                    ? vehicle.alias + " " + vehicle.plate
                    : t("common.na")
                }
              />
            );
            break;
          case "dateTime":
            let nonConvertedStartTime = undefined;
            nonConvertedStartTime = activity?.date;
            if (nonConvertedStartTime !== undefined) {
              const startTime = ConvertTimeZone(
                nonConvertedStartTime,
                preferencesContext.timeZone ?? undefined,
                preferencesContext.localeFormat ?? undefined
              ).split(" ");
              row.render.date = (
                <>
                  <p className="reportMarked">{startTime[0]}</p>
                  <p className="reportNormal">{startTime[1]}</p>
                </>
              );
            } else {
              row.render.date = (
                <>
                  <p className="reportMarked">-</p>
                  <p className="reportNormal">-</p>
                </>
              );
            }
            break;
          case "type":
            row.render.type = (
              <Table.TextIcon
                text={t("activity.type." + activity.type) ?? t("common.na")}
                icon={
                  activity.type && activity.type == "REFUELING" ? (
                    <IconGasStation
                      size={14}
                      color="--global-colors-ink-light"
                      class="classTravelDriver"
                    />
                  ) : (
                    <IconMore
                      size={14}
                      color="--global-colors-ink-light"
                      class="classTravelDriver"
                    />
                  )
                }
              />
            );
            break;
          case "cost":
            row.cost = activity.dynamicFields.cost ?? t("common.na");
            row.render.cost = (
              <Table.TextIcon
                text={t(activity.dynamicFields.cost) ?? t("common.na")}
                icon={
                  <IconEuro
                    size={14}
                    color="--global-colors-ink-light"
                    class="classTravelDriver"
                  />
                }
              />
            );
            break;
          case "activityStatus":
            row.status = activity.status ?? t("common.na");
            row.render.status = activity.status && (
              <Tag
                text={t("activity.status." + activity.status)}
                type={getTagTypeFromActivityStatus(activity.status)}
                mode={getTagModeFromActivityStatus(activity.status)}
              />
            );
            break;
          case "attachment":
            row.attachment =
              activity?.dynamicFields?.attached ?? t("common.na");
            row.render.attachment = activity?.dynamicFields != null && (
              <>
                <Button
                  size={"small"}
                  label={undefined}
                  aspect="outline"
                  disabled={!activity?.dynamicFields?.attached}
                  onClick={() => {
                    entities.openAttachmentModal(true);
                    entities.selectedActivity(activity);
                  }}
                >
                  <IconAttach size={18} color="black " />
                </Button>
              </>
            );

            break;
          default:
            break;
        }
      });

    const activityDropDownList: ListItem[] = [
      {
        id: 0,
        title: t("common.confirm"),
        disabled: activity.status == "ARCHIVED" || activity.status == "MANAGED",
        onClick: () => {
          getUpdateTypeFromAction("confirm", activity);
        },
      },
      {
        id: 1,
        title: t("common.viewDetails"),
        disabled: false,
        onClick: () => {
          entities.openDetailsModal(true);
          entities.selectedActivity(activity);
        },
      },
      {
        id: 2,
        title: t("common.sendMessageToDriver"),
        disabled: true, //  TODO handle based on driver number
        onClick: () => {
          navigate("/dashboard/messages", {
            state: { driver: driver },
          });
        },
      },
      {
        id: 3,
        title: t("common.actionOption.delete"),
        disabled: false,
        onClick: () => {
          entities.openDeleteActivityModal(true);
          entities.selectedActivity(activity);
        },
      },
      {
        id: 4,
        title: t("common.archive"),
        disabled: activity.status == "ARCHIVED",
        onClick: () => {
          getUpdateTypeFromAction("archive", activity);
        },
      },
    ];
    row.render.action = (
      <DropdownButton aspect="ghost" size="small" list={activityDropDownList} />
    );
    return row;
  };
}
