import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconList } from "../../../ui/Icon/Line/List";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconVehicle } from "../../../ui/Icon/Line/Vehicle";
import { CustomizeColumnsModal } from "../../../ui/Modal/CustomModals/CustomizeColumnsModal";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../../utils/Utils";
import { getAddressBooksAsync } from "../../address/addressBookSlice";
import { fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import { schedulesSelectors } from "../../schedules/schedulesSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  addPreset,
  deletePresetAsync,
  getPresetsAsync,
  newPresetAsync,
  removePreset,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
  updatePresetAsync,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import { getReportsMetadataAsync } from "../reportsMetadataSlice";
import { SchedulationManageModal } from "../schedulation/SchedulationManageModal";
import { SchedulationModal } from "../schedulation/SchedulationModal";
import "./VehicleReportFilterBar.css";
import { vehicleStopsSummaryEmptyState } from "./vehicleStopsSummarySlice";
import {
  VehicleTravelsMacroSummary,
  getVehicleTravelsMacroSummaryAsync,
  selectVehicleTravelsMacroSummarySliceReasonCode,
  selectVehicleTravelsMacroSummarySliceStatus,
  vehicleTravelsMacroSummaryEmptyState,
  vehicleTravelsMacroSummarySelectors,
} from "./vehicleTravelsMacroSummarySlice";
import {
  getVehicleTravelsSummaryAsync,
  selectVehicleTravelsSummarySliceReasonCode,
  selectVehicleTravelsSummarySliceStatus,
  vehicleTravelsSummaryEmptyState,
} from "./vehicleTravelsSummarySlice";

interface RouteType {
  key: "STOP" | "TRACK";
  label: string;
}

const routeTypeValues: RouteType[] = [
  {
    key: "TRACK",
    label: t("locationHistory.tracksStopDropdown.tracks"),
  },
  {
    key: "STOP",
    label: t("locationHistory.tracksStopDropdown.stops"),
  },
];
interface QueryParams {
  [paramName: string]: any;
}

interface VehicleFilterBarReportProps {
  callback: (
    vehicleId: number,
    buildQueryParam: string,
    routeType?: string,
    dateRange?: string[]
  ) => any;
  presets: any[];
  disableButton?: boolean;
}

interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
}

const reportsRepository = new ReportsRepository();

/**
 * TODO : remove map.set of firstPointDate when locationHistory API will be removed
 */
export const VehicleFilterBarReport: React.FC<VehicleFilterBarReportProps> = ({
  callback,
  presets,
  disableButton,
}) => {
  const navigate = useNavigate();
  const pathMatchAddingSchedulation = useMatch(
    "/reports/vehicle-travel-report/addSchedulation"
  );
  const pathMatchEditingSchedulation = useMatch(
    "/reports/vehicle-travel-report/editSchedulation/:schedulationId"
  );
  const [manageModalOpened, setManageModalOpened] = useState(false);
  const schedules = useAppSelector(schedulesSelectors.selectAll);
  const filterSchedulesByType = (type: string, type2: string) => {
    return schedules.filter(
      (schedule: any) => schedule.type === type || schedule.type === type2
    );
  };
  const [justReloaded, setJustReloaded] = useState(false);
  const [alias, setAlias] = useState("");
  const [vehicleId, setVehicleId] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const searchbarRef = useRef<HTMLHeadingElement>(null);
  const parentRef = useRef<HTMLHeadingElement>(null);
  const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
  const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
  const [dateRange, setDateRange] = useState<string[]>(
    initialDateValue.map((value) => value.toString())
  );
  const [oldQueryParams, setOldQueryParams] = useState<string>();
  const [oldVehicleId, setOldVehicleId] = useState<number>();
  const [newPreset, setNewPreset] = useState<boolean>(true);
  const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
  const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
  const [routeTypeOptions, setrouteTypeOptions] = useState(routeTypeValues[0]);
  const [currentRouteTypeValue, setCurrentRouteTypeValue] = useState(
    routeTypeValues[0].key
  );
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);

  const vehicleTravelsMacroSummarySliceStatus = useAppSelector(
    selectVehicleTravelsMacroSummarySliceStatus
  );
  const vehicleTravelsMacroSummarySliceReasonCode = useAppSelector(
    selectVehicleTravelsMacroSummarySliceReasonCode
  );

  const vehicleTravelsSummarySliceStatus = useAppSelector(
    selectVehicleTravelsSummarySliceStatus
  );
  const vehicleTravelsSummarySliceReasonCode = useAppSelector(
    selectVehicleTravelsSummarySliceReasonCode
  );

  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);

  const [schedulationModal, setSchedulationModal] = useState(
    !!pathMatchAddingSchedulation || !!pathMatchEditingSchedulation
  );

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const [queryString, setQueryString] = useState("");

  let defaultColumns: Preset = {} as Preset;
  let customColumns: TableColumnEntry[] = [];

  if (!!presets && presets.length > 0) {
    defaultColumns = presets.filter((x) => x.name === "Default")[0];
    if (!!defaultColumns) {
      defaultColumns?.columns.forEach((column, index) =>
        customColumns.push({
          id: index,
          name: t("table.columns." + column),
          value: column,
        })
      );
    }
  }

  let vehiclesGroupByFleet = vehicles.reduce((group: any, vehicle: Vehicle) => {
    const { fleet } = vehicle;
    const fleetName = fleetViewsSelectors.selectById(
      store.getState(),
      fleet
    )?.name;
    if (fleetName) {
      group[fleetName] = group[fleetName] ?? [];
      group[fleetName].push(vehicle);
    }
    return group;
  }, {});

  let fleetNames = Object.keys(vehiclesGroupByFleet);

  useEffect(() => {
    store.dispatch(getFilteredVehiclesDetailsAsync(""));
    store.dispatch(getAddressBooksAsync(""));
    return function cleanUp() {
      store.dispatch(vehiclesEmptyState());
      store.dispatch(vehicleTravelsSummaryEmptyState());
      store.dispatch(vehicleStopsSummaryEmptyState());
      store.dispatch(vehicleTravelsMacroSummaryEmptyState());
    };
  }, []);

  const getPresets = (routeType: "STOP" | "TRACK") => {
    store.dispatch(
      getPresetsAsync(
        getQueryString({
          context:
            routeType === "TRACK" ? "vehicleTravelReport" : "vehicleStopReport",
        })
      )
    );
  };

  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

  useEffect(() => {
    let presetNotification: {
      status: string | undefined;
      description: string | undefined;
    } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
    if (
      presetNotification.status !== undefined &&
      presetNotification.description !== undefined
    ) {
      ToastNotification({
        toastId: t(presetNotification.description),
        status: presetNotification.status as TypeOptions,
        description: t(presetNotification.description),
      });
      store.dispatch(restoreState());
    }
  }, [presetsSliceStatus, presetsSliceReasonCode]);

  const updatePreset = (newPreset: Preset) => {
    let context =
      currentRouteTypeValue === "TRACK"
        ? "vehicleTravelReport"
        : "vehicleStopReport";
    setNewPreset(true);
    if (newPreset?.columns?.length > 0) {
      let temporaryPreset = presets.find((preset) => preset.name === undefined);
      if (temporaryPreset) {
        store.dispatch(removePreset(temporaryPreset.id));
      }
      if (newPreset.name) {
        if (newPreset.name !== "Default" && newPreset.context === undefined) {
          newPreset.context = context;
          newPreset.lastSelected = true;
          store.dispatch(newPresetAsync(newPreset));
        } else {
          store.dispatch(
            updatePresetAsync({
              id: newPreset.id,
              preset: {
                lastSelected: true,
              },
              context: context,
            })
          );
        }
        return true;
      } else {
        newPreset.id = Math.max(...presets.map((preset) => preset.id)) + 1;
        store.dispatch(addPreset(newPreset));
        return false;
      }
    }
  };

  const handleChanges = (
    params: Map<string, string[] | string>,
    vehicleId?: number,
    generateMacroSummary?: boolean,
    generateSummary?: boolean
  ): void => {
    if (generateSummary && vehicleId && vehicleId !== -1) {
      let queryParamsFixed: QueryParams = {};
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParamsFixed[key] = value;
        }
      });
      const stringifiedQueryParams = getQueryString(queryParamsFixed);
      generateResultWithParams(vehicleId, stringifiedQueryParams, false);
      return;
    }
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      const stringifiedQueryParams = getQueryString(queryParams);
      setQueryString(stringifiedQueryParams);
      if (vehicleId && vehicleId !== -1) {
        pathMatchAddingSchedulation === null &&
          navigate(
            `/reports/vehicle-travel-report/${vehicleId}${getQueryString(
              queryParams
            )}`
          );
        pathMatchAddingSchedulation &&
          navigate(
            `/reports/vehicle-travel-report/addSchedulation${vehicleId}${getQueryString(
              queryParams
            )}`
          );
        pathMatchEditingSchedulation &&
          navigate(
            `/reports/vehicle-travel-report/editSchedulation/${
              pathMatchEditingSchedulation.params.schedulationId
            }/${vehicleId}${getQueryString(queryParams)}`
          );
        if (generateMacroSummary) {
          generateResultWithParams(vehicleId, stringifiedQueryParams);
        }
      }
    }
  };

  const filterVehicle = (vehicle: Vehicle) => {
    const filterVehicle =
      alias == "" ||
      (vehicle?.alias &&
        vehicle?.alias?.toLowerCase().includes(alias.toLowerCase())) ||
      (vehicle?.plate &&
        vehicle?.plate?.toLowerCase().includes(alias.toLowerCase())) ||
      (vehicle?.id &&
        vehicle?.id?.toString()?.toLowerCase().includes(alias.toLowerCase()));
    return filterVehicle;
  };

  /**
   * When another vehicle is selected, the URL should be changed
   */
  useEffect(() => {
    vehicleId !== -1 &&
      navigate(
        `/reports/vehicle-travel-report/${vehicleId}${getQueryString(
          queryParams
        )}`
      );
  }, [vehicleId]);

  /**
   * When another vehicle is selected and there are vehicles, the related alias should be saved.
   */
  useEffect(() => {
    if (vehicleId !== -1 && vehicles.length && justReloaded) {
      const aliasVehicle = vehicles.find(
        (vehicle) => vehicle.id === Number(vehicleId)
      );
      setAlias(aliasVehicle?.alias ?? "");
      setJustReloaded(false);
    }
  }, [vehicleId, vehicles, justReloaded]);

  const generateResult = () => {
    if (
      (vehicleId !== -1 && queryString !== "") ||
      initialDateValue ||
      isSelectedPeriod
    ) {
      setOldVehicleId(vehicleId);
      setOldQueryParams(queryString);
      generateResultWithParams(vehicleId, queryString);
    }
  };

  useEffect(() => {
    if (!_.isEqual(getQueryString(queryParams), oldQueryParams)) {
      setOldQueryParams(getQueryString(queryParams));
    }
    if (
      !_.isEqual(
        oldVehicleId,
        Number(vehicleTravelReportRoute?.params.vehicleId)
      )
    ) {
      setOldVehicleId(Number(vehicleTravelReportRoute?.params.vehicleId));
    }
  }, [queryParams, vehicleId]);

  const generateResultWithParams = (
    vehicleId: number,
    queryString: string,
    macro: boolean = true
  ) => {
    if (vehicleId !== -1 && queryString !== "" && macro) {
      const urlParams = new URLSearchParams(queryString);
      const routeStateTypeValue =
        urlParams.get("routeStateTypeEnum") ?? "TRACK";

      const macroSummaryQuery = queryString;
      store.dispatch(
        getVehicleTravelsMacroSummaryAsync({
          queryParams: macroSummaryQuery,
          vehicleId: vehicleId,
        })
      );
      callback(vehicleId, queryString, routeStateTypeValue);
    } else if (vehicleId !== -1 && queryString !== "" && !macro) {
      store.dispatch(
        getVehicleTravelsSummaryAsync({
          queryParams: queryString,
          vehicleId: vehicleId,
          page: 0,
        })
      );
      callback(vehicleId, queryString, undefined, dateRange);
    }
  };

  const downloadResult = (pdf: boolean) => {
    if (
      (vehicleId !== -1 && queryString !== "") ||
      initialDateValue ||
      isSelectedPeriod
    ) {
      const params = queryString + (pdf ? "&isPdf=true" : "&isPdf=false");
      pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
      reportsRepository
        .getVehicleTravelReportDownload(params, vehicleId)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          const fileName = data.split("/").pop();
          const attachmentDownloadLink = document.createElement("a");
          attachmentDownloadLink.href = process.env.REACT_APP_BUCKET_URL + data;
          attachmentDownloadLink.download = fileName;
          attachmentDownloadLink.target = "_blank";
          attachmentDownloadLink.style.display = "none";
          document.body.appendChild(attachmentDownloadLink);
          attachmentDownloadLink.click();
          document.body.removeChild(attachmentDownloadLink);
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
        })
        .catch((error) => {
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          if (
            error.response &&
            error.response.data.message ==
              GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
          ) {
            console.log(error?.message || "Report Timeout Exception");
            ToastNotification({
              toastId: "reportTimeoutError",
              status: "default",
              title: t("common.reportTimeoutErrorTitle"),
              description: t("common.reportTimeoutErrorDesc"),
            });
          } else {
            console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          }
        });
    }
  };

  const sendReportEmail = () => {
    if (
      (vehicleId !== -1 && queryString !== "") ||
      initialDateValue ||
      isSelectedPeriod
    ) {
      reportsRepository
        .getVehicleTravelReportEmail(queryString, vehicleId)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          ToastNotification({
            toastId: "requestSendingMailSuccess",
            status: "success",
            description: t(
              "report.toastNotification.requestSendingMailSuccess"
            ),
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error?.message || "Unknown Error");
          else console.log(error.response?.data?.error || "Unknown Error");
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        });
    }
  };

  useEffect(() => {
    if (
      vehicleTravelsMacroSummarySliceStatus === "idle" &&
      vehicleTravelsMacroSummarySliceReasonCode === "S1" &&
      initialDateValue.length > 0 &&
      currentRouteTypeValue
    ) {
      const vehicleTravelsMacroSummary: VehicleTravelsMacroSummary[] =
        vehicleTravelsMacroSummarySelectors.selectAll(store.getState());
      let map = new Map();
      map.set(
        "startPeriod",
        dateRange[0] ?? dayjs(initialDateValue[0]).format("YYYY-MM-DDTHH:mm")
      );
      map.set(
        "endPeriod",
        dateRange[1] ?? dayjs(initialDateValue[1]).format("YYYY-MM-DDTHH:mm")
      );
      if (vehicleTravelsMacroSummary[0]) {
        let valueDayStart = dayjs(vehicleTravelsMacroSummary[0].firstTimestamp);
        let valueDayEnd = dayjs(vehicleTravelsMacroSummary[0].lastTimestamp);
        if (
          valueDayStart.isValid() &&
          map.get("startPeriod") &&
          valueDayStart.isBefore(map.get("startPeriod"))
        ) {
          map.set("startPeriod", valueDayStart.format("YYYY-MM-DDTHH:mm"));
        }
        if (
          valueDayEnd.isValid() &&
          map.get("endPeriod") &&
          valueDayEnd.isBefore(map.get("endPeriod"))
        ) {
          map.set("endPeriod", valueDayEnd.format("YYYY-MM-DDTHH:mm"));
        }
        setDateRange([map.get("startPeriod"), map.get("endPeriod")]);
      }
      map.set("routeStateTypeEnum", currentRouteTypeValue);
      handleChanges(map, vehicleId, false, true);
    }
  }, [
    vehicleTravelsMacroSummarySliceStatus,
    vehicleTravelsMacroSummarySliceReasonCode,
    initialDateValue,
  ]);

  const isReportsIdle = vehicleTravelsMacroSummarySliceStatus === "idle";
  const isReportsFailed = vehicleTravelsMacroSummarySliceStatus === "failed";
  if (
    vehicleTravelsSummarySliceStatus === "failed" &&
    vehicleTravelsSummarySliceReasonCode === ""
  ) {
    ToastNotification({
      toastId: "networkError",
      status: "error",
      description: t("common.networkError"),
    });
  }

  if (
    vehiclesSliceStatus === "failed" &&
    vehiclesSliceReasonCode === GTFleetErrorCodes.USER_UNAUTHORIZED
  ) {
    ToastNotification({
      toastId: "unauthorizedVehicleList",
      status: "error",
      description: t("common.unauthorizedError"),
    });
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        searchbarRef.current &&
        !searchbarRef.current.contains(event.target) &&
        parentRef.current &&
        !parentRef.current.contains(event.target)
      )
        setIsOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchbarRef]);

  //#region " Handle url parameters "
  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    pathMatchEditingSchedulation && setSchedulationModal(true);
  }, [pathMatchEditingSchedulation]);

  const vehicleTravelReportRoute = useMatch(
    `/reports/vehicle-travel-report/:vehicleId`
  );
  let isVehicleTravelReportRoute = vehicleTravelReportRoute !== null;

  useEffect(() => {
    schedulationModal && setManageModalOpened(false);
  }, [schedulationModal]);
  useEffect(() => {
    manageModalOpened && setSchedulationModal(false);
  }, [manageModalOpened]);

  useEffect(() => {
    const map = new Map();
    let vehicleIdUrl = -1;
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    // Retrieving vehicle id from URL
    vehicleIdUrl =
      isVehicleTravelReportRoute && vehicleTravelReportRoute?.params?.vehicleId
        ? Number(vehicleTravelReportRoute?.params?.vehicleId)
        : -1;

    if (vehicleIdUrl !== -1 && vehicleId === -1) {
      setVehicleId(Number(vehicleIdUrl));
      setJustReloaded(true);
    }

    // Retrieving query params from URL
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const startPeriod = currentSearchParams.get("startPeriod");
      const endPeriod = currentSearchParams.get("endPeriod");
      const firstPointDate = currentSearchParams.getAll("firstPointDate");
      if (startPeriod) {
        map.set("startPeriod", startPeriod);
        setIsSelectedPeriod(true);
        setInitialDateValue((prev) => {
          let prevValue = prev;

          prevValue[0] = new Date(startPeriod);

          return prevValue;
        });
      }
      if (endPeriod) {
        map.set("endPeriod", endPeriod);
        setInitialDateValue((prev) => {
          let prevValue = prev;

          prevValue[1] = new Date(endPeriod);

          return prevValue;
        });
      }
      if (firstPointDate) {
        map.set("firstPointDate", firstPointDate);
      } else {
        const period: string[] = [];
        startPeriod && period.push(startPeriod);
        endPeriod && period.push(endPeriod);
        period.length === 2 && map.set("firstPointDate", period);
      }

      // Retrieving the route state type
      const routeStateType = currentSearchParams?.get("routeStateTypeEnum");
      let routeTypeValue: RouteType = {
        key: "TRACK",
        label: t("locationHistory.tracksStopDropdown.tracks"),
      };
      if (!!routeStateType) {
        routeTypeValue =
          routeTypeValues.find(
            (routeTypeValue) => routeTypeValue.key === routeStateType
          ) ?? routeTypeValue;
      }
      if (routeTypeValue) {
        map.set("routeStateTypeEnum", routeTypeValue.key);
        setrouteTypeOptions(routeTypeValue);
        setCurrentRouteTypeValue(routeTypeValue.key);
        getPresets(routeTypeValue.key);
      }
    }

    // If there are no query params, default values are set
    if (map.size === 0 && _.isEmpty(queryParams)) {
      const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
      const startPeriodDate = new Date(endPeriod);
      startPeriodDate.setHours(0, 0, 0, 0);
      const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
      map.set("startPeriod", startPeriod);
      map.set("endPeriod", endPeriod);
      map.set("firstPointDate", [
        dayjs(startPeriod).format("YYYY/MM/DD HH:mm"),
        dayjs(endPeriod).format("YYYY/MM/DD HH:mm"),
      ]);
      setIsSelectedPeriod(true);
      setInitialDateValue((prev) => {
        let prevValue = prev;
        prevValue[0] = new Date(startPeriod);
        prevValue[1] = new Date(endPeriod);
        return prevValue;
      });

      let routeTypeValue: RouteType = {
        key: "TRACK",
        label: t("locationHistory.tracksStopDropdown.tracks"),
      };
      map.set("routeStateTypeEnum", routeTypeValue.key);
      setrouteTypeOptions(routeTypeValue);
      setCurrentRouteTypeValue(routeTypeValue.key);
      getPresets(routeTypeValue.key);
    }

    if (map.size > 0) {
      handleChanges(map, vehicleIdUrl, true);
    }
  }, [location]);

  //#endregion
  const list = [
    {
      id: 0,
      title: t("report.filterBar.downloadExcel"),
      icon: isExcelGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(false);
      },
    },
    {
      id: 1,
      title: t("report.filterBar.downloadPDF"),
      icon: isPdfGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(true);
      },
    },
    {
      id: 2,
      title: t("report.filterBar.sendReportEmail"),
      icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
      onClick: () => {
        sendReportEmail();
      },
    },
  ];

  return (
    <div className="report-vehicle-filterbar-row">
      <div className="report-vehicle-filterbar-search">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          ref={parentRef}
        >
          <Form>
            <SearchField
              name="search"
              id="search-field"
              size="small"
              placeholder={t(
                "locationHistory.searchDropdown.placeholderVehicle"
              )}
              value={alias}
              onChange={(val: string) => {
                setAlias(val);
              }}
            />
          </Form>
        </div>
        {isOpen && vehicles.length > 0 && (
          <div className="report-vehicle-search-results" ref={searchbarRef}>
            {fleetNames?.map((element: string, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  <div className="report-vehicle-fleet-label">
                    {element}
                    {` (${
                      vehiclesGroupByFleet[element].filter(filterVehicle).length
                    })`}
                  </div>
                  {vehiclesGroupByFleet[element]
                    .filter(filterVehicle)
                    .map((vehicle: Vehicle, index: number) => (
                      <div
                        key={index}
                        className={
                          alias === vehicle.alias
                            ? "report-vehicle-alias-selected"
                            : "report-vehicle-alias"
                        }
                        onClick={() => {
                          setIsOpen(false);
                          setVehicleId(vehicle.id);
                          setAlias(vehicle.alias);
                        }}
                      >
                        {vehicle.alias} <span className="report-vehicle-dot" />
                        {vehicle.plate}
                      </div>
                    ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>

      <div className="report-vehicle-date-picker">
        <Form>
          <DatePickerDropdown
            setDate={(val) => {
              const map = new Map();
              let startPeriod = "";
              let endPeriod = "";
              if (_.isArray(val)) {
                if (val.length === 1) {
                  endPeriod =
                    typeof val[0] === "string"
                      ? val[0]
                      : val[0].format("YYYY-MM-DDTHH:mm");
                  const startPeriodDate = new Date(endPeriod);
                  startPeriodDate.setHours(0, 0, 0, 0);
                  startPeriod =
                    dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
                } else {
                  startPeriod =
                    typeof val[0] === "string"
                      ? val[0]
                      : val[0].format("YYYY-MM-DDTHH:mm");
                  endPeriod = val[1]?.format("YYYY-MM-DDTHH:mm") ?? "";
                }
              }
              map.set("startPeriod", startPeriod);
              map.set("endPeriod", endPeriod);
              map.set("firstPointDate", [
                dayjs(startPeriod).format("YYYY/MM/DD HH:mm"),
                dayjs(endPeriod).format("YYYY/MM/DD HH:mm"),
              ]);
              setIsSelectedPeriod(true);
              setDateRange([startPeriod, endPeriod]);
              handleChanges(map, vehicleId, false);
            }}
            hasTime={true}
            localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
            language={preferencesContext.language ?? "it"}
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            initialValue={initialDateValue.length ? initialDateValue : []}
            defaultValue={initialDateValue[1]}
            dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
            limitDaysRange={31}
          />
        </Form>
      </div>

      <div className="report-vehicle-dropdown-tracks-stops">
        <Dropdown
          placeholder={""}
          size={"small"}
          onChange={(values: RouteType[]) => {
            const map = new Map();
            const routeTypeValue = routeTypeValues.find(
              (routeTypeValue) => routeTypeValue.key === values[0].key
            )?.key;
            map.set("routeStateTypeEnum", routeTypeValue);
            handleChanges(map, vehicleId, false);
            if (routeTypeValue) {
              setCurrentRouteTypeValue(routeTypeValue);
              getPresets(routeTypeValue);
            }
          }}
          value={routeTypeOptions}
          itemAttribute="label"
          options={routeTypeValues}
        />
      </div>

      <div className="report-vehicle-dropdown-customize">
        <CustomizeColumnsModal
          columns={customColumns}
          presets={presets ?? []}
          onClose={(newPreset) => updatePreset(newPreset)}
          handleDelete={(e: any) => store.dispatch(deletePresetAsync(e))}
        />
      </div>

      <div className="report-vehicle-filterbar-btn-generate">
        <Button
          aspect="primary"
          size="small"
          label={t("report.filterBar.buttonGenerate")}
          onlyIcon={false}
          onClick={() => generateResult()}
          isLoading={isReportsIdle || isReportsFailed ? false : true}
          disabled={
            (initialDateValue === undefined ||
              alias === "" ||
              isOpen ||
              (oldQueryParams === getQueryString(queryParams) &&
                oldVehicleId === vehicleId)) &&
            !newPreset
          }
        />
      </div>
      <div className="report-vehicle-filterbar-btn ">
        <div className="ior-filterbar-btn-schedule">
          <DropdownButton
            aspect="secondary"
            size="small"
            label={t("report.filterBar.schedule")}
            onClick={() =>
              store.dispatch(
                getReportsMetadataAsync("?lang=" + preferencesContext.language)
              )
            }
            disabled={
              (initialDateValue === undefined ||
                alias === "" ||
                isOpen ||
                (oldQueryParams === getQueryString(queryParams) &&
                  oldVehicleId === vehicleId)) &&
              !newPreset
            }
            list={
              [
                {
                  id: 0,
                  title: t("common.schedulate"),
                  icon: <IconAdd size={14} color={"#687484"} />,
                  onClick: () => {
                    setSchedulationModal(!schedulationModal);
                  },
                },
                {
                  id: 0,
                  title: t("common.schedulationList"),
                  icon: <IconList size={14} color={"#687484"} />,
                  onClick: () => {
                    setManageModalOpened(true);
                  },
                  disabled:
                    filterSchedulesByType(
                      "VEHICLE_STOP_REPORT",
                      "VEHICLE_TRAVEL_REPORT"
                    ).length === 0,
                },
              ] as any
            }
          >
            <IconCalendar size={14} color="--global-colors-ink-ink" />
          </DropdownButton>
        </div>
        <DropdownButton
          aspect="secondary"
          disabled={disableButton}
          size="small"
          label={t("common.export")}
          list={list}
        >
          <IconDown size={14} color="--global-colors-ink-ink" />
        </DropdownButton>
      </div>
      {schedulationModal && (
        <SchedulationModal
          reportType="VEHICLE_TRAVEL_REPORT"
          onClose={() => {
            setSchedulationModal(false);
          }}
          isOpen={schedulationModal}
          customEntities={vehicles.map((vehicle) => {
            return {
              title: vehicle.alias,
              id: vehicle.id,
            };
          })}
          customEntitiesParamName="vehicleId"
          customTextLabel={t("common.selectVehicles")}
          customIcon={<IconVehicle size={14} />}
          paramsComponents={[
            <div className="report-vehicle-dropdown-tracks-stops">
              <Dropdown
                placeholder={""}
                size={"small"}
                onChange={(values: RouteType[]) => {
                  const map = new Map();
                  const routeTypeValue = routeTypeValues.find(
                    (routeTypeValue) => routeTypeValue.key === values[0].key
                  )?.key;
                  map.set("routeStateTypeEnum", routeTypeValue);
                  handleChanges(map, vehicleId, false);
                  if (routeTypeValue) {
                    setCurrentRouteTypeValue(routeTypeValue);
                    getPresets(routeTypeValue);
                  }
                }}
                value={routeTypeOptions}
                itemAttribute="label"
                options={routeTypeValues}
              />
            </div>,
          ]}
          params={queryParams}
          schedulationId={pathMatchEditingSchedulation?.params?.schedulationId}
          paramsToSchedulate={["routeStateTypeEnum"]}
        />
      )}
      <SchedulationManageModal
        schedulationAddModal={(e) => setSchedulationModal(e)}
        reportType={"VEHICLE_TRAVEL_REPORT"}
        isOpen={manageModalOpened}
        onClose={() => {
          setManageModalOpened(false);
        }}
        schedulations={filterSchedulesByType(
          "VEHICLE_STOP_REPORT",
          "VEHICLE_TRAVEL_REPORT"
        )}
      />
    </div>
  );
};
