import HttpClient from "../../utils/HttpClient";

export default class ReportsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getReports(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/reports" + (queryParams ?? "")
    );
  }

  getReportsIO(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" + tenantId + "/reportsIO" + (queryParams ?? "")
    );
  }

  getReportsUtilization(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/vehicles/utilizations/report" +
        (queryParams ?? "")
    );
  }

  getUtilizationReportEmail(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/vehicles/utilizations/report/sendEmail" +
        (queryParams ?? "")
    );
  }

  getUtilizationReportDownload(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/vehicles/utilizations/report/download" +
        (queryParams ?? "")
    );
  }

  getReportsIOSummary(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" + tenantId + "/reportsIO/summary" + (queryParams ?? "")
    );
  }

  getReportsSummary(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/reports/summary" + (queryParams ?? "")
    );
  }

  getVehicleTravelReportDownload(queryParams?: string, vehicleId?: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/report/download" +
        (queryParams ?? "")
    );
  }

  getVehicleTravelReportEmail(queryParams?: string, vehicleId?: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/report/sendEmail" +
        (queryParams ?? "")
    );
  }

  getDriverTravelReportDownload(queryParams?: string, driverId?: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/drivers/" +
        driverId +
        "/travels/report/download" +
        (queryParams ?? "")
    );
  }

  getDriverTravelReportEmail(queryParams?: string, driverId?: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/drivers/" +
        driverId +
        "/travels/report/sendEmail" +
        (queryParams ?? "")
    );
  }

  getGeofencesReportDownload(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/geofences/report/download" +
        (queryParams ?? "")
    );
  }

  getGeofencesReportEmail(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/geofences/report/sendEmail" +
        (queryParams ?? "")
    );
  }

  getGpsDataReportDownload(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" + tenantId + "/gpsdata/report/download" + (queryParams ?? "")
    );
  }

  getGpsDataReportEmail(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" + tenantId + "/gpsdata/report/sendEmail" + (queryParams ?? "")
    );
  }

  getRefuellingReportDownload(queryParams?: string, vehicleId?: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/refuel/report/download/" +
        (queryParams ?? "")
    );
  }
  getRefuellingReportEmail(queryParams?: string, vehicleId?: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/refuel/report/sendEmail/" +
        (queryParams ?? "")
    );
  }

  getEventsIOReportDownload(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/reportsIO/report/download" +
        (queryParams ?? "")
    );
  }

  getEventsIOReportEmail(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/reportsIO/report/sendEmail" +
        (queryParams ?? "")
    );
  }

  getEventLogsReportEmail(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/reportsLogs/report/sendEmail" +
        (queryParams ?? "")
    );
  }

  getEventLogsDownload(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/reportsLogs/report/download" +
        (queryParams ?? "")
    );
  }

  getDriverActivityDownload(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/activities/report/download" +
        (queryParams ?? "")
    );
  }

  getDriverActivityReportEmail(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/activities/report/sendEmail" +
        (queryParams ?? "")
    );
  }
}
