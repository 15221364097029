import PropTypes from "prop-types";

export const IllustrationHumanBeing = ({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <mask
        id="mask"
        x="0"
        width="23.99"
        height="24"
        maskUnits="userSpaceOnUse"
      />
    </defs>
    <g id="_-_02_:-Image-and-Icons">
      <g id="_05.-Icon-Library">
        <g id="solid_User">
          <path
            id="path-1"
            d="M12,12c3.37,0,6.11-2.69,6.11-6S15.37,0,12,0,5.89,2.69,5.89,6s2.74,6,6.11,6Zm11.97,10.15c-1.14-6.1-5.13-9.15-11.97-9.15-6.83,0-10.82,3.05-11.97,9.15-.16,.85,.4,1.67,1.25,1.83,.09,.02,.19,.03,.29,.03H22.43c.86,0,1.56-.7,1.56-1.57,0-.1,0-.19-.03-.29Z"
            fill="#687484"
          />
          <g mask="url(#mask)">
            <rect id="Color" x="0" width={size} height={size} fill="#687484" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationHumanBeing.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationHumanBeing.defaultProps = {
  size: 70,
};

export { IllustrationHumanBeing as default } from "./HumanBeing";
