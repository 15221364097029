import HttpClient from "../../utils/HttpClient";
import { Preferences } from "./preference/preferencesSlice";
import { UserInfo } from "./usersInfoSlice";

export default class UsersRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getUsers() {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/users";
    return this.instance.get(path + "?size=" + Number.MAX_SAFE_INTEGER);
  }

  getFilteredUsers(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/users";
    let queryParams = "";
    if (customQueryParams) {
      if (customQueryParams.includes("lastName")) {
        customQueryParams = customQueryParams.replace("lastName", "secondName");
      }
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }

  getUser(customerId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get("tenants/" + tenantId + "/users/" + customerId);
  }

  partiallyUpdateUser(customerId: number, userInfo: UserInfo) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.patch(
      "tenants/" + tenantId + "/users/" + customerId,
      userInfo
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    const id = localStorage.getItem("customerId");
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post("/tenants/"+tenantId+"/users/" + id + "/changepassword", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  createUser(userInfo: UserInfo, preferences: Partial<Preferences>) {
    const tenantId = localStorage.getItem("tenantId");

    const userInfoWithTenant: any = {
      ...userInfo,
      tenant: { id: tenantId },
      active: true,
      preferences: preferences,
    };
    return this.instance.post(
      "tenants/" + tenantId + "/users/",
      userInfoWithTenant
    );
  }
}
