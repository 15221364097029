import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import "./DatePicker.css";
import { DatePickerCalendar } from "./DatePickerCalendar";
import { DatePickerContext } from "./DatePickerContext";
import { DatePickerPreset } from "./DatePickerPreset";
import { DatePickerTime } from "./DatePickerTime";
import { Switch } from "./Switch";
import buildCalendar from "./utils/buildCalendar";

export const DatePicker = ({
  isDouble,
  hasTime,
  hasDatePresets,
  weekPresets,
  hasComparison,
  isSingleDate,
  oldDate,
  localeFormat,
  setInputValue,
  handleSubmit,
  resetCallback,
  notExpirationDateLabel,
  getSelectedPeriodOrDate,
}) => {
  const picker = useContext(DatePickerContext);
  const { t } = useTranslation();
  const [calendar, setCalendar] = useState([]);
  const [secondCalendar, setSecondCalendar] = useState([]);

  useEffect(() => {
    const period = [picker.startDate];

    if (!!picker.endDate) {
      period.push(picker.endDate);
    }

    getSelectedPeriodOrDate && getSelectedPeriodOrDate(period);
  }, [picker?.startDate, picker?.endDate]);

  useEffect(() => {
    if (isDouble) {
      setSecondCalendar(
        picker.activeComparison[0] === t("common.datePicker.previousYear") &&
          picker.toggleComparison
          ? buildCalendar(picker.value.subtract(1, "year"))
          : buildCalendar(picker.value.subtract(1, "month"))
      );
    }

    setInputValue &&
      setInputValue(
        picker.startDate?.format(localeFormat || "DD / MM / YYYY") ?? ""
      );

    setCalendar(buildCalendar(picker.value));
  }, [
    isDouble,

    picker.value,

    picker.activeComparison,

    picker.toggleComparison,
  ]);

  const className = () => {
    const classes = ["date-picker"];

    if (isDouble) {
      classes.push("is-double");
    }

    if (hasDatePresets) {
      classes.push("has-date-presets");
    }

    if (hasDatePresets && weekPresets) {
      classes.push("has-week-presets");
    }

    if (isSingleDate) {
      classes.push("has-single-date");
    }

    return classes.join(" ");
  };

  return (
    <div className={className()}>
      <div className="left">
        {hasComparison && isDouble && (
          <div className="comparison">
            <Switch
              checked={picker.toggleComparison}
              onChange={() => picker.setToggleComparison((prev) => !prev)}
              label={t("common.datePicker.compare")}
            />

            {picker.toggleComparison && (
              <div className="comparison-controls">
                <div className="firstDate">
                  <div className="datePreset">
                    <span className="firstDateIndicator">{picker.preset}</span>
                  </div>

                  <div className="dates">
                    <div className="firstDateStartDate">
                      <input
                        type="text"
                        value={
                          picker.startDate
                            ? picker.startDate.format("DD MMM YYYY")
                            : ""
                        }
                        readOnly
                      />
                    </div>

                    <span className="date-separator">-</span>

                    <div className="firstDateEndDate">
                      <input
                        type="text"
                        value={
                          picker.endDate
                            ? picker.endDate.format("DD MMM YYYY")
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="secondDate">
                  <div className="datePreset">
                    <span className="secondDateIndicator"></span>

                    <Dropdown
                      value={{ label: picker.activeComparison[0] }}
                      onChange={(val) => {
                        picker.setActiveComparison([val[0]?.label ?? ""]);
                      }}
                      options={[
                        { label: t("common.datePicker.previousPeriod") },

                        { label: t("common.datePicker.previousMonth") },

                        { label: t("common.datePicker.previousYear") },

                        { label: t("common.datePicker.custom") },
                      ]}
                      itemAttribute="label"
                      placeholder={t("common.datePicker.selectPeriod")}
                      size="small"
                      isClearable={false}
                      hasRadio={true}
                    />
                  </div>

                  <div className="dates">
                    <div className="secondDateStartDate">
                      <input
                        type="text"
                        value={
                          picker.secondStartDate
                            ? picker.secondStartDate.format("DD MMM YYYY")
                            : ""
                        }
                        readOnly
                      />
                    </div>

                    <span className="date-separator">-</span>

                    <div className="secondDateEndDate">
                      <input
                        type="text"
                        value={
                          picker.secondEndDate
                            ? picker.secondEndDate.format("DD MMM YYYY")
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="date-picker__inner">
          {isDouble && (
            <div>
              <DatePickerCalendar
                calendar={secondCalendar}
                isSingleDate={isSingleDate}
                value={
                  picker.activeComparison[0] ===
                    t("common.datePicker.previousYear") &&
                  picker.toggleComparison
                    ? picker.value.subtract(1, "year")
                    : picker.value.subtract(1, "month")
                }
              />

              {hasTime && <DatePickerTime isSingleDatePicker={isSingleDate} />}
            </div>
          )}

          <div>
            <DatePickerCalendar
              calendar={calendar}
              isSingleDate={isSingleDate}
              value={picker.value}
            />

            {hasTime && <DatePickerTime isSingleDatePicker={isSingleDate} />}
          </div>
        </div>

        <div className="footer">
          <Button
            aspect="ghost"
            label={
              notExpirationDateLabel
                ? t("admin.user.editViewCreate.never")
                : t("common.datePicker.clearButtonLabel")
            }
            onClick={() => {
              picker.clearDate(oldDate);

              resetCallback && resetCallback();
            }}
            size="small"
          />

          <Button
            aspect="primary"
            disabled={!picker.startDate}
            label={t("common.datePicker.applyButtonLabel")}
            onClick={() => {
              if (handleSubmit) {
                if (isSingleDate) {
                  if (!hasTime) {
                    handleSubmit(picker.value.format(localeFormat));
                  } else {
                    let startHour = picker.fromTime?.split(":")[0] ?? "";
                    let startMinute = picker.fromTime?.split(":")[1] ?? "";
                    let startDate = picker.startDate
                      ?.set("hour", startHour)
                      .set("minute", startMinute);
                    handleSubmit(startDate.format(localeFormat));
                  }
                } else {
                  let startHour = picker.fromTime?.split(":")[0] ?? "";

                  let startMinute = picker.fromTime?.split(":")[1] ?? "";

                  let endHour = picker.toTime?.split(":")[0] ?? "";

                  let endMinute = picker.toTime?.split(":")[1] ?? "";

                  let startDate = picker.startDate

                    ?.set("hour", startHour)

                    .set("minute", startMinute);

                  let endDate = picker.endDate
                    ? picker.endDate

                        ?.set("hour", endHour)

                        .set("minute", endMinute)
                    : picker.startDate

                        ?.set("hour", endHour)

                        .set("minute", endMinute);

                  if (endDate.isSame(startDate)) endDate = null;
                  else {
                    let prevDate = startDate;

                    startDate = startDate?.isAfter(endDate)
                      ? endDate
                      : startDate;

                    endDate = endDate?.isAfter(prevDate) ? endDate : prevDate;
                  }

                  let dateSelected = [
                    startDate,

                    endDate,

                    picker.secondStartDate,

                    picker.secondEndDate,

                    picker.activeComparison,
                  ];

                  handleSubmit(dateSelected, picker.preset);
                }
              }
            }}
            size="small"
          />
        </div>
      </div>

      {hasDatePresets && DatePickerPreset(weekPresets)}

      {/* {isSingleDate && <YearPicker />} */}
    </div>
  );
};

DatePicker.propTypes = {
  isDouble: PropTypes.bool,

  hasTime: PropTypes.bool,

  hasDatePresets: PropTypes.bool,

  hasComparison: PropTypes.bool,

  isSingleDate: PropTypes.bool,

  oldDate: PropTypes.string,

  localeFormat: PropTypes.string,

  setInputValue: PropTypes.func,

  handleSubmit: PropTypes.func,

  resetCallback: PropTypes.func,
};
