import HttpClient from "../../../utils/HttpClient";
import {
  NotificationRuleAggregationCreateRequest,
  NotificationRuleAggregationUpdateRequest,
} from "./notificationRuleAggregationSlice";

export default class NotificationsRuleAggregationRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getFilteredNotificationsRuleAggregations(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = `/tenants/${tenantId}/notifications/rules`;
    let queryParams = "";
    if (customQueryParams) {
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }

  public getNotificationRule(aggregationRuleId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = `/tenants/${tenantId}/notifications/rules/${aggregationRuleId}`;
    return this.instance.get(path);
  }

  public createAdminNotification(
    notificationRule: NotificationRuleAggregationCreateRequest
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path = `/tenants/${tenantId}/notifications/rules`;
    return this.instance.post(path, notificationRule);
  }

  public editAdminNotification(
    notificationRule: NotificationRuleAggregationUpdateRequest,
    aggregationRuleId: number
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path = `/tenants/${tenantId}/notifications/rules/${aggregationRuleId}`;
    return this.instance.put(path, notificationRule);
  }

  public deleteNotificationsRuleAggregations(
    notificationAggregationRuleId: number
  ) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.delete(
      `tenants/${tenantId}/notifications/rules/${notificationAggregationRuleId}`
    );
  }

  public updateStatus(notificationRuleId: number, status: any) {
    const tenantId = localStorage.getItem("tenantId");
    let path = `/tenants/${tenantId}/notifications/rules/${notificationRuleId}/status/${status}`;
    return this.instance.patch(path);
  }
}
