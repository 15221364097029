import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../ui/Forms/DatePickerDropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconDown } from "../../ui/Icon/Line/Down";
import { IconDownload } from "../../ui/Icon/Line/Download";
import { IconLocatorOff } from "../../ui/Icon/Line/LocatorOff";
import { ToastNotification } from "../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../utils/Utils";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../driver/driversSlice";
import {
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import ReportsRepository from "../report/reportRepository";
import { Preferences } from "../users/preference/preferencesSlice";
import {
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
} from "../users/preset/presetsSlice";
import UserContext from "../users/userContext";
import {
  getFilteredVehiclesDetailsViewsAsync,
  selectVehiclesViewSliceStatus,
  vehiclesViewEmptyState,
  vehiclesViewSelectors,
  VehicleView,
} from "../vehicle/vehiclesViewSlice";
import {
  activitiesSelectors,
  Activity,
  activityStatusValues,
  activityTypeValues,
  getActivitiesPaginationAsync,
} from "./activitiesSlice";
import "./DashboardActivities.css";

interface QueryParams {
  [paramName: string]: any;
}

interface DropDownItem {
  key: string;
  label: string;
  checked: boolean;
}

interface DashboardActivitiesFilterBarProps {
  presets: any[];
  callback: (buildQueryParam: string) => any;
}

let context: string = "driverActivityDashboard";

export const DashboardActivitiesFilterBar: React.FC<DashboardActivitiesFilterBarProps> =
  ({ presets, callback }) => {
    //#region states
    const [searchKey, setSearchKey] = useState<string>("");
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
    const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
    const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
    const [queryString, setQueryString] = useState("");
    const [preferencesContext]: [Preferences] = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location: Location = useLocation();
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;

    const activites: Activity[] = useAppSelector((state: any) =>
      activitiesSelectors.selectAll(state)
    );
    const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
    const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);
    const driversSliceStatus = useAppSelector(selectDriverStatusSliceStatus);
    const vehiclesSliceStatus = useAppSelector(selectVehiclesViewSliceStatus);
    const vehicles: VehicleView[] = useAppSelector(
      vehiclesViewSelectors.selectAll
    );
    const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
    const [vehicleInfos, setVehicleInfos] = useState<DropDownItem[]>([]);
    const [driverInfos, setDriverInfos] = useState<DropDownItem[]>([]);

    const reportsRepository = new ReportsRepository();

    const [statusesValues, setStatusesValues] = useState<DropDownItem[]>(
      _.keys(activityStatusValues).map((key) => {
        const labelStatus = t("activity.status." + key);
        return {
          key: key,
          label: labelStatus,
          value: key,
          checked: key !== activityStatusValues.ARCHIVED ? true : false,
        };
      })
    );
    const [typeValues, setTypeValues] = useState<DropDownItem[]>(
      _.keys(activityTypeValues).map((key) => {
        const labelType = t("activity.type." + key);
        return {
          key: key,
          label: labelType,
          value: key,
          checked: false,
        };
      })
    );
    //#endregion states

    //#region functions
    const debouncedSearch = useRef(
      _.debounce(async (val) => {
        setSearchKey(val);
        const map = new Map();
        const splittedVal = val.split(" ");
        map.set("name", splittedVal);
        handleChanges(map);
      }, 1000)
    ).current;

    async function handleSearch(val: string) {
      await debouncedSearch(val);
    }

    const downloadResult = (pdf: boolean) => {
      if (activites?.length !== 0) {
        const params =
          queryString.replace("size=10&", "") +
          (pdf ? "&isPdf=true" : "&isPdf=false");
        pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
        reportsRepository
          .getDriverActivityDownload(params)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const fileName = data.split("/").pop();
            const attachmentDownloadLink = document.createElement("a");
            attachmentDownloadLink.href =
              process.env.REACT_APP_BUCKET_URL + data;
            attachmentDownloadLink.download = fileName;
            attachmentDownloadLink.target = "_blank";
            attachmentDownloadLink.style.display = "none";
            document.body.appendChild(attachmentDownloadLink);
            attachmentDownloadLink.click();
            document.body.removeChild(attachmentDownloadLink);
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          })
          .catch((error) => {
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
            if (
              error.response &&
              error.response.data.message ==
                GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
            ) {
              console.log(error?.message || "Report Timeout Exception");
              ToastNotification({
                toastId: "reportTimeoutError",
                status: "default",
                title: t("common.reportTimeoutErrorTitle"),
                description: t("common.reportTimeoutErrorDesc"),
              });
            } else {
              console.log(error.response?.data?.error || "Unknown Error");
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t("common.networkError"),
              });
            }
          });
      }
    };

    const sendReportEmail = () => {
      if (vehicles?.length !== 0 && queryString !== "") {
        reportsRepository
          .getGpsDataReportEmail(queryString)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            ToastNotification({
              toastId: "requestSendingMailSuccess",
              status: "success",
              description: t(
                "report.toastNotification.requestSendingMailSuccess"
              ),
            });
          })
          .catch((error) => {
            if (!error.response) console.log(error?.message || "Unknown Error");
            else console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          });
      }
    };

    const exportList = [
      {
        id: 0,
        title: t("report.filterBar.downloadExcel"),
        icon: isExcelGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner" />
        ),
        onClick: () => {
          downloadResult(false);
        },
      },
      {
        id: 1,
        title: t("report.filterBar.downloadPDF"),
        icon: isPdfGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner" />
        ),
        onClick: () => {
          downloadResult(true);
        },
      },
      {
        id: 2,
        title: t("report.filterBar.sendReportEmail"),
        icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          sendReportEmail();
        },
      },
    ];

    const handleChanges = (params: Map<string, string[] | string>): void => {
      let queryString = "";
      let executeQuery = false;
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
            executeQuery = true;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              executeQuery = true;
            }
          }
        });
        queryString = getQueryString(queryParams);
      }
      // effettuare chiamata API con parametri in query string
      if (executeQuery) {
        store.dispatch(
          getActivitiesPaginationAsync({
            queryParams: queryString,
          })
        );
        navigate({
          pathname: "/dashboard/activities",
          search: queryString,
        });
        callback(queryString);
      }
      setQueryString(queryString);
    };
    //#endregion functions

    //#region useEffect
    useEffect(() => {
      store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
      store.dispatch(getFilteredVehiclesDetailsViewsAsync(""));
      return () => {
        store.dispatch(vehiclesViewEmptyState());
        store.dispatch(driversEmptyState());
      };
    }, []);

    useEffect(() => {
      const vehicleNames = searchParams.getAll("vehicle.id");
      setVehicleInfos(
        vehicles
          .sort((a, b) => a.alias.localeCompare(b.alias))
          .map((vehicle: VehicleView) => {
            const isVehicleChecked =
              vehicleNames && vehicleNames.includes(vehicle.id.toString());
            return {
              label: vehicle.alias + " - " + vehicle.plate,
              key: vehicle.id.toString(),
              checked: isVehicleChecked ? true : false,
            };
          })
      );
    }, [vehiclesSliceStatus]);

    useEffect(() => {
      const driverNames = searchParams.getAll("driver.id");
      setDriverInfos(
        drivers
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map((driver: Driver) => {
            const isDriverChecked =
              driverNames && driverNames.includes(driver.id.toString());
            return {
              label: driver.firstName + " " + driver.lastName,
              key: driver.id.toString(),
              checked: isDriverChecked ? true : false,
            };
          })
      );
    }, [driversSliceStatus]);

    useEffect(() => {
      let presetNotification: {
        status: string | undefined;
        description: string | undefined;
      } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
      if (
        presetNotification.status !== undefined &&
        presetNotification.description !== undefined
      ) {
        ToastNotification({
          toastId: t(presetNotification.description),
          status: presetNotification.status as TypeOptions,
          description: t(presetNotification.description),
        });
        store.dispatch(restoreState());
      }
    }, [presetsSliceStatus, presetsSliceReasonCode]);

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const size = currentSearchParams.get("size");
        map.set("size", size ?? "10");

        // Search Field
        const nameSearch = currentSearchParams.get("name");
        if (nameSearch) {
          setSearchKey(nameSearch);
          map.set("name", nameSearch);
        }

        // Date picker
        const startPeriod = currentSearchParams.get("startPeriod");
        const endPeriod = currentSearchParams.get("endPeriod");
        if (startPeriod) {
          map.set("startPeriod", startPeriod);
          setInitialDateValue((prev) => {
            let prevValue = prev;
            prevValue[0] = new Date(startPeriod);
            return prevValue;
          });
        }
        if (endPeriod) {
          map.set("endPeriod", endPeriod);
          setInitialDateValue((prev) => {
            let prevValue = prev;
            prevValue[1] = new Date(endPeriod);
            return prevValue;
          });
        }

        // Drivers Dropdown
        const driverNames = currentSearchParams.getAll("driver.id");
        if (!_.isEmpty(driverNames)) {
          map.set(
            "driver.id",
            driverNames.map((x) => x)
          );
        }

        // Vehicles Dropdown
        const vehicleIds = currentSearchParams.getAll("vehicle.id");
        if (!_.isEmpty(vehicleIds)) {
          map.set(
            "vehicle.id",
            vehicleIds.map((x) => x)
          );
        }

        // Statuses Dropdown
        const activityStatus = currentSearchParams.getAll("status");
        if (activityStatus && activityStatus.length > 0) {
          setStatusesValues(
            statusesValues.map((statusesValue) => ({
              ...statusesValue,
              checked: activityStatus.includes(statusesValue.key),
            }))
          );
          map.set("status", activityStatus);
        } else {
          map.set(
            "status",
            statusesValues.filter((x) => x.checked).map((x) => x.key)
          );
        }

        // Types Dropdown
        const activityTypes = currentSearchParams.getAll("type");
        if (activityTypes && activityTypes.length > 0) {
          setTypeValues(
            typeValues.map((typeValue) => ({
              ...typeValue,
              checked: activityTypes.includes(typeValue.key),
            }))
          );
          map.set("type", activityTypes);
        } else {
          map.set(
            "type",
            typeValues.filter((x) => x.checked).map((x) => x.key)
          );
        }
      }

      if (map.size === 0 && _.isEmpty(queryParams)) {
        map.set("size", "10");
        map.set(
          "status",
          statusesValues.filter((x) => x.checked).map((x) => x.key)
        );
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);
    //#endregion useEffect

    return (
      <div className="vehicle-filterbar-row-splitter">
        <>
          <div className="vehicle-filterbar-search">
            <Form>
              <SearchField
                name="search"
                id="activityName"
                size="small"
                placeholder={t("activity.filterBar.searchActivity")}
                value={searchKey}
                onChange={(val) => {
                  handleSearch(val);
                }}
              />
            </Form>
          </div>

          <>
            <div className="report-vehicle-date-picker">
              <Form>
                <DatePickerDropdown
                  placeholder={t("table.columns.dateTime")}
                  setDate={(val) => {
                    const map = new Map();
                    let startPeriod = "";
                    let endPeriod = "";
                    if (_.isArray(val)) {
                      if (val.length === 1) {
                        endPeriod =
                          typeof val[0] === "string"
                            ? val[0]
                            : val[0].format("YYYY-MM-DDTHH:mm");
                        const startPeriodDate = new Date(endPeriod);
                        startPeriodDate.setHours(0, 0, 0, 0);
                        startPeriod =
                          dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
                      } else {
                        startPeriod =
                          typeof val[0] === "string"
                            ? val[0]
                            : val[0].format("YYYY-MM-DDTHH:mm");
                        endPeriod = val[1]?.format("YYYY-MM-DDTHH:mm") ?? "";
                      }
                    }
                    map.set("startPeriod", startPeriod);
                    map.set("endPeriod", endPeriod);
                    handleChanges(map);
                  }}
                  hasTime={true}
                  localeFormat={
                    preferencesContext.localeFormat ?? "DD / MM / YYYY"
                  }
                  language={preferencesContext.language ?? "it"}
                  icon={
                    <IconCalendar size={12} color="--global-colors-ink-light" />
                  }
                  initialValue={initialDateValue.length ? initialDateValue : []}
                  defaultValue={initialDateValue[1]}
                  dateRange={[dayjs().subtract(1, "year"), dayjs()]}
                  limitDaysRange={31}
                  clearCallback={() => {
                    const map = new Map();
                    map.set("startPeriod", "");
                    map.set("endPeriod", "");
                    handleChanges(map);
                    setInitialDateValue([]);
                  }}
                />
              </Form>
            </div>
            <div className="drivers-activity-filterbar-dropdown">
              <Dropdown
                placeholderInValue={t(
                  "activity.filterBar.placeholderInValVehicles"
                )}
                loading={vehiclesSliceStatus === "loading"}
                itemAttribute="label"
                placeholder={t("activity.filterBar.vehicles")}
                size={"small"}
                hasCheckbox={true}
                onChange={(val: DropDownItem[]) => {
                  setVehicleInfos(
                    vehicleInfos.map((vehicleInfo) => {
                      if (val.includes(vehicleInfo)) {
                        vehicleInfo.checked = true;
                      } else {
                        vehicleInfo.checked = false;
                      }
                      return vehicleInfo;
                    })
                  );
                  const map = new Map();
                  map.set(
                    "vehicle.id",
                    val.map((x) => x.key)
                  );
                  handleChanges(map);
                }}
                options={vehicleInfos}
                value={vehicleInfos.filter((x) => x.checked)}
              />
            </div>
            <div className="drivers-activity-filterbar-dropdown">
              <Dropdown
                placeholderInValue={t(
                  "activity.filterBar.placeholderInValDrivers"
                )}
                itemAttribute="label"
                hasCheckbox={true}
                placeholder={t("activity.filterBar.drivers")}
                size={"small"}
                onChange={(val: DropDownItem[]) => {
                  setDriverInfos(
                    driverInfos.map((driverInfo) => {
                      if (val.includes(driverInfo)) {
                        driverInfo.checked = true;
                      } else {
                        driverInfo.checked = false;
                      }
                      return driverInfo;
                    })
                  );
                  const map = new Map();
                  map.set(
                    "driver.id",
                    val.map((x) => x.key)
                  );
                  handleChanges(map);
                }}
                loading={driversSliceStatus === "loading"}
                options={driverInfos}
                value={driverInfos.filter((x) => x.checked)}
              />
            </div>
          </>
          <div className="drivers-activity-filterbar-dropdown">
            <Dropdown
              placeholderInValue={t(
                "fleetControl.filterBar.placeholderInValStatus"
              )}
              itemAttribute="label"
              hasCheckbox={true}
              placeholder={t("fleetControl.filterBar.status")}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                setStatusesValues(
                  statusesValues.map((statusesValue) => {
                    if (val.includes(statusesValue)) {
                      statusesValue.checked = true;
                    } else {
                      statusesValue.checked = false;
                    }
                    return statusesValue;
                  })
                );
                const map = new Map();
                map.set(
                  "status",
                  val.map((x) => x.key)
                );
                handleChanges(map);
              }}
              options={statusesValues}
              value={statusesValues.filter((x) => x.checked)}
            />
          </div>
          <div className="drivers-activity-filterbar-dropdown">
            <Dropdown
              placeholderInValue={t("activity.filterBar.type")}
              itemAttribute="label"
              hasCheckbox={true}
              placeholder={t("activity.filterBar.type")}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                setTypeValues(
                  typeValues.map((typeValue) => {
                    if (val.includes(typeValue)) {
                      typeValue.checked = true;
                    } else {
                      typeValue.checked = false;
                    }
                    return typeValue;
                  })
                );
                const map = new Map();
                map.set(
                  "type",
                  val.map((x) => x.key)
                );
                handleChanges(map);
              }}
              options={typeValues}
              value={typeValues.filter((x) => x.checked)}
            />
          </div>
          <div className="drivers-activity-filterbar-export">
            <DropdownButton
              aspect="secondary"
              size="small"
              label={t("common.export")}
              list={exportList}
              disabled={activites.length == 0}
            >
              <IconDown size={14} color="--global-colors-ink-ink" />
            </DropdownButton>
          </div>
        </>
      </div>
    );
  };
