import { schema } from "normalizr";

const publicTransportStatusSchema: schema.Entity<any> = new schema.Entity(
  "publicTransportStatus",
  {},
  { idAttribute: "id" }
);

export const publicTransportSchema: schema.Entity<any> = new schema.Entity(
  "publicTransport",
  { publicTransportStatus: publicTransportStatusSchema }
);
