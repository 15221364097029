import PropTypes from "prop-types";

export const IllustrationCamper = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 70 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="c"
        x1="35.14"
        y1="70.44"
        x2="32.61"
        y2="64.74"
        gradientTransform="translate(49.21 -17.15) rotate(173.67) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".37" stopColor="#effdff" />
        <stop offset=".55" stopColor="#e0f2f4" />
        <stop offset="1" stopColor="#a5b3b3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="33.14"
        y1="70.25"
        x2="35.31"
        y2="67.24"
        gradientTransform="translate(49.21 -17.15) rotate(173.67) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bbc9cc" />
        <stop offset="1" stopColor="#778083" />
      </linearGradient>
      <clipPath id="e">
        <path
          d="M22.61,52.55c.94-.57,1.72-.15,1.74,.94,.02,1.08-.72,2.42-1.66,2.99-.94,.57-1.72,.15-1.74-.94-.02-1.08,.72-2.42,1.66-2.99Z"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="f"
        x1="50.53"
        y1="53.05"
        x2="49.08"
        y2="55.56"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        xlinkHref="#d"
      />
      <linearGradient
        id="g"
        x1="50.24"
        y1="53.38"
        x2="49.08"
        y2="55.39"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bbc9cc" />
        <stop offset="1" stopColor="#909d9d" />
      </linearGradient>
      <linearGradient
        id="h"
        x1="51.14"
        y1="54.17"
        x2="50.1"
        y2="55.98"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#e0f2f4" />
        <stop offset="1" stopColor="#a5b3b3" />
      </linearGradient>
      <linearGradient
        id="i"
        x1="51.49"
        y1="54.13"
        x2="50.27"
        y2="56.25"
        xlinkHref="#h"
      />
      <linearGradient
        id="j"
        x1="34.89"
        y1="68.05"
        x2="34.79"
        y2="69.59"
        gradientTransform="translate(49.21 -17.15) rotate(173.67) scale(1 -1)"
        xlinkHref="#h"
      />
      <linearGradient
        id="k"
        x1="33.71"
        y1="68.24"
        x2="35.05"
        y2="68.24"
        gradientTransform="translate(49.21 -17.15) rotate(173.67) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bbc9cc" />
        <stop offset="1" stopColor="#a5b3b3" />
      </linearGradient>
      <linearGradient
        id="l"
        x1="56.77"
        y1="39.75"
        x2="60.03"
        y2="45.4"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#eff1ea" />
        <stop offset="1" stopColor="#fffff9" />
      </linearGradient>
      <linearGradient
        id="m"
        x1="61.09"
        y1="44.73"
        x2="61.27"
        y2="45.04"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fffff9" />
        <stop offset="1" stopColor="#eff1ea" />
      </linearGradient>
      <linearGradient
        id="n"
        x1="50.17"
        y1="24.64"
        x2="55.49"
        y2="33.86"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#eff1ea" />
        <stop offset=".02" stopColor="#f6f7f0" />
        <stop offset=".05" stopColor="#fdfdf7" />
        <stop offset=".14" stopColor="#fffff9" />
        <stop offset=".83" stopColor="#eff1ea" />
        <stop offset=".86" stopColor="#e6e8e2" />
        <stop offset=".93" stopColor="#dcddd8" />
        <stop offset="1" stopColor="#d9dad5" />
      </linearGradient>
      <linearGradient
        id="o"
        x1="231.72"
        y1="26.85"
        x2="231.62"
        y2="28.39"
        gradientTransform="translate(281.88 -17.15) rotate(173.67) scale(1 -1)"
        xlinkHref="#h"
      />
      <linearGradient
        id="p"
        x1="230.54"
        y1="27.04"
        x2="231.88"
        y2="27.04"
        gradientTransform="translate(281.88 -17.15) rotate(173.67) scale(1 -1)"
        xlinkHref="#k"
      />
      <linearGradient
        id="q"
        x1="231.59"
        y1="29.51"
        x2="229.07"
        y2="23.81"
        gradientTransform="translate(281.88 -17.15) rotate(173.67) scale(1 -1)"
        xlinkHref="#c"
      />
      <linearGradient
        id="r"
        x1="229.6"
        y1="29.32"
        x2="231.76"
        y2="26.31"
        gradientTransform="translate(281.88 -17.15) rotate(173.67) scale(1 -1)"
        xlinkHref="#d"
      />
      <linearGradient
        id="s"
        x1="53.1"
        y1="50.99"
        x2="65.01"
        y2="45.3"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9e9f98" />
        <stop offset="1" stopColor="#bbbcb4" />
      </linearGradient>
      <linearGradient
        id="t"
        x1="53.3"
        y1="51.42"
        x2="65.21"
        y2="45.72"
        xlinkHref="#s"
      />
      <linearGradient
        id="u"
        x1="65.86"
        y1="49.11"
        x2="66.16"
        y2="49.64"
        gradientTransform="translate(72.06) rotate(-180) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#abb9bf" />
        <stop offset=".55" stopColor="#e0e7ea" />
        <stop offset=".78" stopColor="#faffff" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="v"
        x1="57.82"
        y1="53.77"
        x2="58.13"
        y2="54.3"
        xlinkHref="#u"
      />
    </defs>
    <g isolation="isolate">
      <g id="a">
        <path
          d="M68.7,29.12l-19.06-10.36c-.56-.3-1.45-.28-1.99,.05L1.27,47.73c-.54,.33-.53,.85,.03,1.15l19.06,10.36c.56,.3,1.45,.28,1.99-.05l23.13-14.42,2.78,2.17,7.21-5.08-1.61-2.32,14.86-9.26c.54-.33,.53-.85-.03-1.15Z"
          mix-blend-mode="multiply"
          opacity=".1"
        />
        <g>
          <g id="b">
            <g>
              <g isolation="isolate">
                <polygon
                  points="10.74 42.41 25.72 51.06 31.85 47.52 16.87 38.87 10.74 42.41"
                  fill="#bbbcb4"
                />
                <g isolation="isolate">
                  <path
                    d="M58.28,32.21l-16.83-9.78c.54,.31,.88,.96,.92,1.87l16.83,9.78c-.04-.91-.39-1.55-.92-1.87h0Z"
                    fill="#404046"
                  />
                </g>
                <polygon
                  points="33.4 35.06 16.57 25.28 16.61 39.18 33.44 48.96 33.4 35.06"
                  fill="#d7d8cf"
                />
                <polygon
                  points="31.5 33.96 31.5 47.83 16.61 39.18 16.57 25.28 31.5 33.96"
                  fill="#bbbcb4"
                />
                <polygon
                  points="64.02 10.33 47.19 .55 13.68 19.9 30.51 29.68 64.02 10.33"
                  fill="#eff1ea"
                />
                <g isolation="isolate">
                  <path
                    d="M3.65,40.92l.02,5.43,1.48-.86c.27-1.23,1.11-2.49,2.13-3.08l1.19-.68c1.55-.89,2.59-.41,2.64,1.95l6.65-3.85-.05-13.9-5.78,3.33-2.75,7.02c-.28,.21-5.54,4.16-5.54,4.64Zm6.29-5.03c.06-.17,1.27-3.39,2.3-6.04l4.77-2.75,.02,4.69-7.09,4.1Z"
                    fill="#bbbcb4"
                  />
                  <g isolation="isolate">
                    <path
                      d="M24.63,49.72l-14.33-8.33c.49,.28,.78,1,.77,2.34l9.39,7.97,2.82-1,2.15,1.25c-.02-1.22-.31-1.94-.8-2.23h0Z"
                      fill="#404046"
                    />
                  </g>
                  <g>
                    <g>
                      <g isolation="isolate">
                        <path
                          d="M18.94,53.27c.26-.59,.62-1.16,1.04-1.64,.35-.4,.75-.75,1.18-.99,.75-.43,1.43-.47,1.91-.19l1.48,.86c-.49-.28-1.17-.24-1.91,.19-.42,.24-.82,.59-1.18,.99-.42,.48-.78,1.04-1.04,1.64-.29,.67-.47,1.37-.46,2.01,0,.85,.3,1.44,.78,1.73l-1.48-.86c-.48-.28-.78-.88-.78-1.73,0-.65,.17-1.35,.46-2.01Z"
                          fill="#191817"
                        />
                        <path
                          d="M22.64,51.5c1.49-.86,2.69-.17,2.7,1.54,0,1.71-1.2,3.79-2.68,4.64s-2.69,.17-2.7-1.54c0-1.7,1.2-3.78,2.68-4.64Z"
                          fill="#292928"
                        />
                      </g>
                      <path
                        d="M22.64,52.38c1.07-.62,1.93-.12,1.94,1.1,0,1.22-.86,2.72-1.92,3.33-1.07,.62-1.93,.12-1.94-1.1,0-1.22,.86-2.71,1.92-3.33Z"
                        fill="#191817"
                        opacity=".5"
                      />
                      <g>
                        <g isolation="isolate">
                          <g isolation="isolate">
                            <path
                              d="M23.36,52.3h-.41s-.11,.03-.03,.05c.35,.09,.58,.44,.6,.98,.02,.91-.61,2.04-1.4,2.52-.48,.29-.91,.27-1.18,.01-.05-.05,.03,.13,.03,.13l.16,.28c.28,.51,.88,.62,1.57,.21,.94-.57,1.68-1.9,1.66-2.99-.02-.77-.42-1.21-.99-1.19h0Z"
                              fill="url(#c)"
                            />
                          </g>
                          <path
                            d="M24.45,53.42c.03,1.15-.76,2.57-1.76,3.17-1,.6-1.83,.16-1.85-.99-.03-1.15,.76-2.57,1.76-3.17s1.83-.16,1.85,.99Zm-1.85-.87c-.94,.57-1.68,1.9-1.66,2.99,.02,1.08,.8,1.5,1.74,.94,.94-.57,1.68-1.9,1.66-2.99-.02-1.08-.8-1.5-1.74-.94h0Z"
                            fill="#b7c5c7"
                          />
                        </g>
                        <path
                          d="M20.99,55.91c.27,.21,.68,.2,1.12-.06,.79-.48,1.42-1.61,1.4-2.52-.01-.5-.21-.83-.52-.95-.12,.04-.25,.1-.38,.18-.94,.57-1.68,1.9-1.66,2.99,0,.13,.02,.26,.04,.37Zm.37-1.29c0-.46,.3-1.02,.7-1.26,.4-.24,.72-.06,.73,.39,0,.46-.3,1.02-.7,1.26-.4,.24-.72,.06-.73-.39Z"
                          fill="url(#d)"
                        />
                      </g>
                      <g clip-path="url(#e)">
                        <g>
                          <g isolation="isolate">
                            <g isolation="isolate">
                              <g isolation="isolate">
                                <path
                                  d="M23.67,53.37v.08c.02,.9-.59,2.01-1.36,2.49l-.07,.04c.81-.5,1.45-1.67,1.43-2.61h0Z"
                                  fill="#e0f2f4"
                                />
                              </g>
                            </g>
                            <path
                              d="M23.67,53.43c.03,.91-.58,2.03-1.36,2.52-.78,.48-1.44,.14-1.47-.77-.03-.91,.58-2.03,1.36-2.52,.78-.48,1.44-.14,1.47,.77Zm-1.46-.6c-.7,.44-1.25,1.45-1.22,2.27,.03,.82,.62,1.13,1.32,.69s1.25-1.45,1.22-2.27c-.03-.82-.62-1.13-1.32-.69h0Z"
                              fill="url(#f)"
                            />
                            <g isolation="isolate">
                              <g isolation="isolate">
                                <path
                                  d="M23.53,53.54v.16c.02,.72-.47,1.61-1.09,1.99l-.14,.08c.7-.43,1.24-1.43,1.23-2.24h0Z"
                                  fill="#e0f2f4"
                                />
                              </g>
                            </g>
                            <path
                              d="M23.53,53.68c.02,.73-.46,1.63-1.09,2.02-.63,.39-1.15,.11-1.18-.61-.02-.73,.46-1.63,1.09-2.02,.63-.39,1.15-.11,1.18,.61Zm-1.16-.01c-.34,.21-.6,.7-.59,1.09,.01,.39,.3,.54,.64,.33,.34-.21,.6-.7,.59-1.09-.01-.39-.3-.54-.64-.33h0Z"
                              fill="url(#g)"
                            />
                            <path
                              d="M22.43,52.71l.12,.25c.53-.22,.95,.07,.98,.72,0,0,0,.02,0,.02v-.16s0-.02,0-.03c-.02-.73-.5-1.05-1.1-.81h0Z"
                              fill="#e0f2f4"
                            />
                            <path
                              d="M22.39,52.4l.06,.13c.67-.27,1.19,.09,1.22,.9,0,0,0,.02,0,.03v-.08s0-.02,0-.03c-.03-.85-.58-1.23-1.28-.94h0Z"
                              fill="#e0f2f4"
                            />
                            <path
                              d="M22.31,55.78l.14-.08c-.63,.39-1.15,.11-1.18-.61-.01-.31,.07-.66,.22-.98l-.26-.11c-.17,.36-.26,.75-.25,1.1,.03,.82,.62,1.13,1.32,.69h0Z"
                              fill="url(#h)"
                            />
                            <g isolation="isolate">
                              <g isolation="isolate">
                                <path
                                  d="M22.24,55.99l.07-.04c-.78,.48-1.44,.14-1.47-.77-.01-.39,.09-.82,.28-1.22l-.13-.06c-.2,.42-.31,.88-.29,1.29,.03,.95,.72,1.31,1.54,.81h0Z"
                                  fill="url(#i)"
                                />
                                <g>
                                  <path
                                    d="M22.21,52.82c.08-.05,.15-.09,.23-.12l.12,.25c-.07,.03-.13,.06-.2,.1-.36,.22-.67,.61-.87,1.03l-.26-.11c.22-.48,.57-.92,.97-1.16Z"
                                    fill="#fff"
                                  />
                                  <path
                                    d="M22.13,52.54c.09-.06,.18-.1,.26-.14l.06,.13c-.08,.03-.17,.08-.25,.13-.44,.27-.83,.76-1.08,1.29l-.13-.06c.26-.56,.67-1.07,1.13-1.36Z"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <path
                            d="M23.25,53.48s.08-.04,.09-.02c.02,.03,0,.08-.04,.11-.04,.04-.08,.04-.09,.02s0-.08,.04-.11Zm-.54,1.63s.06,.02,.09-.02c.04-.04,.05-.09,.04-.11-.02-.03-.06-.02-.09,.02-.04,.04-.05,.09-.04,.11Zm-1.1-.59s0-.07-.04-.07c-.04,0-.08,.05-.09,.09-.01,.04,0,.07,.04,.07,.04,0,.08-.05,.09-.09Zm1.6-.26s0,.07,.04,.07c.04,0,.08-.05,.09-.09,.01-.04,0-.07-.04-.07-.04,0-.08,.05-.09,.09Zm-1.23-.46s.06,.02,.09-.02c.04-.04,.05-.09,.04-.11-.02-.03-.06-.02-.09,.02-.04,.04-.05,.09-.04,.11Zm-.5,1.51s.06,.02,.09-.02c.04-.04,.05-.09,.04-.11-.02-.03-.06-.02-.09,.02-.04,.04-.05,.09-.04,.11Zm.51,.16s0,.07,.04,.07c.04,0,.08-.05,.09-.09,.01-.04,0-.07-.04-.07-.04,0-.08,.05-.09,.09Zm.71-2.14s0,.07,.04,.07c.04,0,.08-.05,.09-.09,.01-.04,0-.07-.04-.07-.04,0-.08,.05-.09,.09Z"
                            fill="#494948"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g isolation="isolate">
                        <g isolation="isolate">
                          <path
                            d="M23.19,53.98v.1c.01,.4-.26,.9-.61,1.11-.01,0-.1,.06-.1,.06,.41-.25,.72-.81,.71-1.26h0Z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        d="M22.58,53.53l.08,.16c.3-.12,.53,.04,.53,.4v-.1c-.01-.41-.27-.59-.61-.46h0Z"
                        fill="#e0f2f4"
                      />
                      <g isolation="isolate">
                        <g isolation="isolate">
                          <path
                            d="M22.48,55.25l.09-.05c-.34,.2-.63,.04-.63-.35,0-.17,.05-.36,.13-.54l-.16-.07c-.1,.2-.15,.42-.15,.62,0,.45,.33,.63,.72,.4h0Z"
                            fill="url(#j)"
                          />
                          <path
                            d="M22.46,53.59s.08-.05,.13-.06l.08,.16s-.07,.03-.11,.06c-.2,.12-.37,.33-.48,.56l-.16-.07c.13-.27,.33-.51,.55-.64Z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        d="M22.55,53.74c-.35,.21-.62,.7-.61,1.11,0,.4,.3,.56,.65,.35,.35-.21,.62-.7,.61-1.11,0-.4-.3-.56-.65-.35Z"
                        fill="url(#k)"
                      />
                    </g>
                  </g>
                  <g>
                    <polygon
                      points="19.82 53.95 17.49 55.3 17.5 56.98 19.82 55.65 19.82 53.95"
                      fill="#323234"
                    />
                    <polygon
                      points="19.82 53.95 4.77 45.2 2.44 46.55 17.49 55.3 19.82 53.95"
                      fill="#59595f"
                    />
                    <polygon
                      points="17.49 55.3 2.44 46.55 2.45 48.23 17.5 56.98 17.49 55.3"
                      fill="#404046"
                    />
                  </g>
                  <polygon
                    points="32.04 34.2 17.71 25.87 11.93 29.2 26.27 37.53 32.04 34.2"
                    fill="#eff1ea"
                  />
                  <polygon
                    points="17.97 49.19 3.64 40.86 3.66 46.29 17.99 54.62 17.97 49.19"
                    fill="#d9dad5"
                  />
                  <path
                    d="M26.27,37.53l5.78-3.33,.05,13.9-6.65,3.85c-.04-2.36-1.09-2.84-2.64-1.95l-1.19,.68c-1.02,.59-1.86,1.85-2.13,3.08l-1.48,.86-.02-5.43c0-.48,5.26-4.43,5.54-4.64l2.75-7.02Zm5.09,2.54l-.02-4.69-4.77,2.75c-1.03,2.65-2.24,5.87-2.3,6.04l7.09-4.1h0Z"
                    fill="#bbbcb4"
                  />
                  <path
                    d="M26.17,38.64l.24-.14c-.02,.05-.04,.11-.06,.16l-.09,.05-2.21,5.7v5.18s-.07,0-.11,0v-5.19s2.23-5.75,2.23-5.75v-.02Zm-.76,12.89l6.13-3.53v-12.46s-.08,.05-.08,.05l-.13,.07v.13s.1-.06,.1-.06v12.21s-6.03,3.47-6.03,3.47c0,.04,0,.08,0,.12Z"
                    fill="#53534b"
                    opacity=".4"
                  />
                  <g>
                    <path
                      d="M29.76,43.15l.53-.31c.18-.11,.33-.34,.33-.52v-.25c0-.18-1.2,.51-1.2,.69v.25c0,.18,.15,.25,.33,.14Z"
                      fill="#53534b"
                      opacity=".4"
                    />
                    <path
                      d="M29.41,42.88l1.23-.71c.13-.07,.23-.24,.23-.37s-.1-.17-.23-.1l-1.23,.71c-.13,.07-.23,.24-.23,.37s.1,.17,.23,.1Z"
                      fill="#383a34"
                    />
                  </g>
                  <path
                    d="M31.35,40.06l-7.09,4.1c.06-.17,1.27-3.39,2.3-6.04l4.77-2.75,.02,4.69h0Z"
                    fill="#212121"
                  />
                  <path
                    d="M31.06,39.89l-.02-4.35,.29-.17,.02,4.69-7.09,4.1s.07-.19,.16-.43l6.63-3.84Z"
                    fill="#d9dad5"
                  />
                  <g opacity=".5">
                    <path
                      d="M12.87,36.86c.19,.88,1.14,1.86,2.13,2.18,.99,.32,1.64-.13,1.45-1.01s-1.14-1.86-2.13-2.18c-.99-.32-1.64,.13-1.45,1.01Zm2.87,.28l-1.1,.32-.47-1.37c.06,.01,.11,.02,.17,.04,.53,.17,1.04,.56,1.4,1.01Zm-1.8-1.09l.57,1.66,1.36-.39c.15,.22,.26,.46,.31,.68,.16,.76-.4,1.15-1.25,.87-.85-.28-1.67-1.12-1.83-1.87-.13-.63,.23-1,.84-.95Z"
                      fill="#e0e0e0"
                    />
                    <path
                      d="M9.99,35.83c4.57,1.46,10.64,5.31,13.5,8.08l-.42,.79-2.29,1.55-12.22-7.59c-.09-1.29,.57-1.72,1.42-2.84Z"
                      fill="#e0e0e0"
                    />
                    <path
                      d="M11.42,36.31c.36,1.32,3.35,2.83,5.59,2.74,0,0-.89-1.19-2.51-2-1.9-.96-3.08-.74-3.08-.74Z"
                      fill="#fcfcfc"
                    />
                  </g>
                  <g isolation="isolate">
                    <g isolation="isolate">
                      <path
                        d="M23.51,44.55l-14.33-8.33c-.25,.19-4.47,3.36-5.37,4.38l14.33,8.33c.9-1.02,5.12-4.19,5.37-4.38h0Z"
                        fill="url(#l)"
                      />
                      <path
                        d="M3.66,40.79c.03-.05,.08-.12,.15-.19l14.33,8.33c-.07,.08-.12,.14-.15,.19-.02,.03-.02,.05-.02,.07L3.64,40.86s0-.04,.02-.07Z"
                        fill="url(#m)"
                      />
                    </g>
                  </g>
                  <path
                    d="M25.52,37.48l-13.09-7.61-2.64,6.78c3.75,2.79,8.08,5.34,13.09,7.59l2.63-6.77Z"
                    fill="#212121"
                  />
                  <path
                    d="M25.52,37.48l-13.09-7.61-2.64,6.78c-.2-.15-.41-.29-.61-.44l2.75-7.02,14.33,8.33-2.75,7.02c-.21-.1-.42-.2-.63-.3l2.63-6.77Z"
                    fill="#e3e4dd"
                  />
                </g>
                <path
                  d="M8.67,27.9l1.19-3.19c.07-.18,.14-.36,.22-.53,.43-.98,1.02-1.89,1.69-2.66,.59-.67,1.24-1.22,1.92-1.62l16.83,9.78c-.68,.39-1.33,.95-1.92,1.62-.67,.77-1.26,1.68-1.69,2.66-.08,.18-.15,.35-.22,.53l-1.19,3.19c-.26,.7-.14,1.25,.19,1.45L8.86,29.35c-.34-.2-.46-.75-.19-1.45Z"
                  fill="url(#n)"
                />
                <g>
                  <g isolation="isolate">
                    <path
                      d="M51.84,34.26c.26-.59,.62-1.16,1.04-1.64,.35-.4,.75-.75,1.17-.99,.75-.43,1.42-.47,1.91-.19l1.48,.86c-.49-.28-1.17-.24-1.91,.19-.42,.24-.82,.59-1.17,.99-.42,.48-.78,1.04-1.04,1.64-.29,.67-.47,1.37-.46,2.01,0,.85,.3,1.44,.78,1.72l-1.48-.86c-.48-.28-.78-.88-.78-1.72,0-.65,.17-1.35,.46-2.01Z"
                      fill="#191817"
                    />
                    <path
                      d="M55.54,32.5c1.48-.86,2.69-.17,2.7,1.53,0,1.71-1.19,3.78-2.68,4.64-1.48,.86-2.69,.17-2.7-1.54,0-1.7,1.19-3.78,2.68-4.64Z"
                      fill="#292928"
                    />
                  </g>
                  <path
                    d="M55.54,33.37c1.07-.62,1.93-.12,1.94,1.1,0,1.22-.86,2.71-1.92,3.33s-1.93,.12-1.94-1.1c0-1.22,.86-2.71,1.92-3.33Z"
                    fill="#191817"
                    opacity=".5"
                  />
                  <g>
                    <g isolation="isolate">
                      <g isolation="isolate">
                        <g isolation="isolate">
                          <path
                            d="M55.69,34.74v.1c.01,.4-.26,.9-.61,1.1-.01,0-.1,.06-.1,.06,.41-.25,.72-.81,.71-1.26h0Z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        d="M55.09,34.29l.08,.16c.3-.12,.53,.04,.53,.4v-.1c-.01-.41-.27-.59-.61-.46h0Z"
                        fill="#e0f2f4"
                      />
                      <g isolation="isolate">
                        <g isolation="isolate">
                          <path
                            d="M54.98,36.01l.09-.05c-.34,.2-.63,.04-.63-.35,0-.17,.05-.36,.13-.54l-.16-.07c-.1,.2-.15,.42-.15,.62,0,.45,.33,.63,.72,.4h0Z"
                            fill="url(#o)"
                          />
                          <path
                            d="M54.96,34.35s.08-.05,.13-.06l.07,.16s-.07,.03-.11,.06c-.2,.12-.37,.33-.48,.56l-.16-.07c.13-.27,.32-.51,.55-.64Z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        d="M55.05,34.5c-.35,.21-.62,.7-.61,1.1,0,.4,.3,.56,.64,.35s.62-.7,.61-1.1c0-.4-.3-.56-.64-.35Z"
                        fill="url(#p)"
                      />
                      <g isolation="isolate">
                        <path
                          d="M56.27,33.29h-.41s-.11,.03-.03,.05c.35,.09,.58,.44,.6,.98,.02,.91-.61,2.04-1.4,2.52-.48,.29-.91,.27-1.18,.01-.05-.05,.03,.13,.03,.13l.16,.28c.28,.51,.88,.62,1.57,.2,.94-.57,1.68-1.9,1.66-2.99-.02-.77-.42-1.2-.98-1.19h0Z"
                          fill="url(#q)"
                        />
                      </g>
                      <path
                        d="M57.36,34.41c.03,1.15-.76,2.57-1.76,3.17-1,.6-1.83,.16-1.85-.99-.03-1.15,.76-2.57,1.76-3.17,1-.6,1.83-.16,1.85,.99Zm-1.85-.87c-.94,.57-1.68,1.9-1.66,2.99,.02,1.08,.8,1.5,1.74,.94,.94-.57,1.68-1.9,1.66-2.99-.02-1.08-.8-1.5-1.74-.94h0Z"
                        fill="#b7c5c7"
                      />
                    </g>
                    <path
                      d="M53.89,36.9c.27,.21,.68,.2,1.12-.06,.79-.48,1.42-1.61,1.4-2.52-.01-.5-.21-.83-.52-.95-.12,.04-.25,.1-.38,.18-.94,.57-1.68,1.9-1.66,2.99,0,.13,.02,.26,.04,.37Zm.37-1.29c0-.46,.3-1.02,.7-1.26,.4-.24,.72-.06,.73,.39,0,.46-.3,1.02-.7,1.26-.4,.24-.72,.06-.73-.39Z"
                      fill="url(#r)"
                    />
                  </g>
                </g>
                <path
                  d="M30.51,29.68c-1.59,.92-3.06,2.76-3.83,4.81l-1.19,3.19c-.42,1.11,.14,1.87,1,1.37l6.9-3.98,.04,13.9,19.44-11.23c.08-1.98,1.45-4.32,3.15-5.3,1.7-.98,3.08-.24,3.17,1.65l.68-.39,5.9-4.67-.05-17.52-1.72-1.17L30.51,29.68Z"
                  fill="#bbbcb4"
                />
              </g>
              <path
                d="M17.44,52.12l-3.91-2.26,.8-1.54s3.11,1.8,3.11,1.81c.07,.03,.54,.28,.54,.28,0,0,1.01-1.1,1.07-.93,.03,.07-.37,2.1-.47,2.52-.05,.19-.51,.38-.62,.37-.11-.01-.47-.22-.52-.24ZM3.65,43.94s.01,.03,.02,.05c.04,.09,.1,.17,.19,.22,0,0,.4,.24,.4,.25l3.21,1.89-.43-2.3s-2.78-1.59-2.78-1.58c-.03-.02-.34-.18-.39-.22-.11-.08-.11-.39-.11-.39-.05,.05-.08,.11-.11,.17v1.92Z"
                fill="url(#s)"
              />
              <g>
                <g>
                  <g>
                    <path
                      d="M4.17,41.95v1.99s-.41-.25-.41-.25c-.09-.05-.15-.12-.19-.22-.08-.22-.14-.19-.11-1.33,0-.24-.02-.57,.2-.8,0,0,0,.31,.11,.39,.05,.04,.4,.23,.4,.23Z"
                      fill="#b6701a"
                    />
                    <polygon
                      points="17.44 51.6 13.53 49.35 14.33 47.8 17.44 49.6 17.44 51.6"
                      fill="#fff"
                    />
                  </g>
                  <polygon
                    points="6.93 43.52 7.37 45.82 4.16 43.93 4.16 41.93 6.93 43.52"
                    fill="#fff"
                  />
                  <path
                    d="M17.42,49.6s.56,.29,.56,.29c0,0,1.01-.58,1.07-.42,.03,.07-.25,1.51-.35,1.94-.05,.19-.63,.44-.73,.43-.11-.01-.52-.24-.54-.25v-1.99Z"
                    fill="#b6701a"
                  />
                </g>
                <g>
                  <polygon
                    points="14.47 47.87 6.93 43.52 7.73 47.8 13.67 51.24 14.47 47.87"
                    fill="url(#t)"
                  />
                  <polygon
                    points="7.92 47.66 13.72 51.01 14.46 47.88 7.17 43.67 7.92 47.66"
                    fill="#323234"
                  />
                  <path
                    d="M7.84,47.23l-.03-.15,6.02,3.48-.03,.12-5.97-3.44Zm-.22-1.16l.03,.15,6.34,3.66,.03-.12-6.4-3.69Zm.09,.51l.03,.15,6.15,3.55,.03-.12-6.21-3.58Zm-.19-1.01l.03,.15,6.53,3.77,.03-.12-6.58-3.8Zm-.28-1.52l.03,.15,7.09,4.09,.03-.12-7.15-4.13Zm.19,1.01l.03,.15,6.72,3.88,.03-.12-6.77-3.91Zm-.09-.51l.03,.15,6.9,3.99,.03-.12-6.96-4.02Z"
                    fill="#59595f"
                  />
                </g>
              </g>
              <g>
                <polygon
                  points="7.38 50.89 12.67 53.94 12.67 52.68 7.38 49.63 7.38 50.89"
                  fill="#323234"
                />
                <polygon
                  points="7.57 49.74 7.57 50.75 12.67 53.69 12.67 53.94 7.38 50.89 7.38 49.63 7.57 49.74"
                  fill="#59595f"
                />
              </g>
              <g>
                <path
                  d="M6.12,49.94c.18,0,.31-.14,.31-.33,0-.22-.16-.48-.38-.61-.08-.04-.15-.07-.23-.07-.18,0-.31,.14-.31,.33s.16,.48,.38,.61c.08,.04,.15,.07,.23,.07Z"
                  fill="#59595f"
                />
                <path
                  d="M5.7,49.17c0,.19,.16,.44,.35,.56,.19,.11,.35,.05,.35-.15,0-.19-.16-.44-.35-.56-.19-.11-.35-.05-.35,.15Z"
                  fill="url(#u)"
                />
              </g>
              <g>
                <path
                  d="M14.15,54.6c.18,0,.31-.14,.31-.33,0-.22-.16-.48-.38-.61-.08-.04-.15-.07-.23-.07-.18,0-.31,.14-.31,.33,0,.22,.16,.48,.38,.61,.08,.04,.15,.07,.23,.07Z"
                  fill="#59595f"
                />
                <path
                  d="M13.73,53.83c0,.19,.16,.44,.35,.56,.19,.11,.35,.05,.35-.15s-.16-.44-.35-.56c-.19-.11-.35-.05-.35,.15Z"
                  fill="url(#v)"
                />
              </g>
            </g>
            <g>
              <path
                d="M49.9,31.28l.53-.31c.18-.11,.33-.34,.33-.52v-.25c0-.18-1.2,.51-1.2,.69v.25c0,.18,.15,.25,.33,.14Z"
                fill="#53534b"
                opacity=".4"
              />
              <path
                d="M49.55,31.01l1.23-.71c.13-.07,.23-.24,.23-.37,0-.13-.1-.17-.23-.1l-1.23,.71c-.13,.07-.23,.24-.23,.37s.1,.17,.23,.1Z"
                fill="#383a34"
              />
            </g>
            <path
              d="M35.21,37.12l7.62-4.4c.12-.07,.22-.23,.22-.35v-5.39c0-.12-.1-.16-.22-.09l-7.62,4.4c-.12,.07-.22,.23-.22,.35v5.39c0,.12,.1,.16,.22,.09Z"
              fill="#212121"
            />
            <path
              d="M45.76,28.43l4.83-2.79c.12-.07,.22-.23,.22-.35v-2.55c0-.12-.1-.16-.22-.09l-4.83,2.79c-.12,.07-.22,.23-.22,.35v2.55c0,.12,.1,.16,.22,.09Z"
              fill="#212121"
            />
            <path
              d="M33.43,46.9l20.12-11.61s-.05,.1-.08,.15l-20.04,11.57v-.1Zm25.17-14.43l7.18-4.15v-.1s-7.26,4.19-7.26,4.19c.02,.02,.05,.03,.08,.06Z"
              fill="#53534b"
              opacity=".4"
            />
            <path
              d="M51.57,36.43v-15.15c0-.06-.03-.06-.05-.06-.02,0-.05,0-.08,.03l-6.53,3.77c-.1,.06-.19,.19-.19,.29v15.08s-.13,.07-.13,.07v-15.15c0-.14,.11-.32,.25-.4l6.53-3.77c.16-.09,.31-.01,.31,.15v15.08s-.13,.07-.13,.07Z"
              fill="#53534b"
              opacity=".4"
            />
            <g>
              <g isolation="isolate">
                <polygon
                  points="37.27 10.45 37.27 12.17 44.4 16.31 44.4 14.59 37.27 10.45"
                  fill="#404046"
                />
                <polygon
                  points="44.4 14.59 44.4 16.31 57.19 8.93 57.18 7.21 44.4 14.59"
                  fill="#323234"
                />
                <polygon
                  points="37.27 10.45 44.4 14.59 57.18 7.21 50.06 3.07 37.27 10.45"
                  fill="#6b6d70"
                />
              </g>
              <g isolation="isolate">
                <path
                  d="M57.27,6.31c.16-.09,.24-.21,.24-.33v1.83c0,.12-.07,.24-.23,.33l-12.5,7.21v-1.83s12.49-7.21,12.49-7.21Z"
                  fill="#bbbcb4"
                />
                <path
                  d="M37.18,9.76l6.47,3.76c.31,.18,.81,.18,1.13,0v1.83c-.31,.18-.81,.18-1.12,0l-6.47-3.76c-.15-.09-.23-.21-.23-.33v-1.83c0,.12,.07,.23,.23,.33Z"
                  fill="#d9dad5"
                />
                <path
                  d="M37.18,9.11c-.31,.18-.31,.47,0,.65l6.47,3.76c.31,.18,.81,.18,1.13,0l12.5-7.21c.31-.18,.31-.47,0-.65l-6.47-3.76c-.31-.18-.82-.18-1.13,0l-12.5,7.21Z"
                  fill="#fffff9"
                />
              </g>
            </g>
            <g>
              <polygon
                points="21.11 22.16 26.56 25.31 31.08 22.7 25.62 19.55 21.11 22.16"
                fill="#323234"
              />
              <g>
                <polygon
                  points="26.5 23.77 21.05 20.6 20.98 20.94 26.43 24.11 26.5 23.77"
                  fill="#fffff9"
                />
                <polygon
                  points="31.14 22.69 25.69 19.52 21.05 20.6 26.5 23.77 31.14 22.69"
                  fill="#d7d8cf"
                />
                <polygon
                  points="26.5 23.77 26.43 24.11 30.2 23.23 31.14 22.69 26.5 23.77"
                  fill="#bbbcb4"
                />
              </g>
            </g>
            <g>
              <path
                d="M21.84,49.11l.43-.25c.12-.07,.22-.03,.22,.09,0,.14-.1,.29-.22,.36l-.43,.25c-.12,.07-.22,.03-.22-.09s.1-.29,.22-.36Z"
                fill="#b6701a"
              />
              <path
                d="M64.06,27.88l.43-.25c.12-.07,.22-.03,.22,.09,0,.14-.1,.29-.22,.36l-.43,.25c-.12,.07-.22,.03-.22-.09,0-.14,.1-.29,.22-.36Z"
                fill="#b6701a"
              />
            </g>
            <g>
              <path
                d="M50.36,25.51c.12-.07,.22-.23,.22-.35v-2.52c.13-.07,.23-.03,.23,.09v2.55c0,.12-.1,.28-.22,.35l-4.83,2.79c-.12,.07-.22,.03-.22-.09v-.04s4.83-2.79,4.83-2.79Z"
                fill="#d9dad5"
              />
              <path
                d="M42.6,32.59c.12-.07,.22-.23,.22-.35v-5.35c.13-.07,.23-.03,.23,.09v5.39c0,.12-.1,.28-.22,.35l-7.62,4.4c-.12,.07-.22,.03-.22-.09v-.04s7.61-4.39,7.61-4.39Z"
                fill="#d9dad5"
              />
              <path
                d="M45.54,27.8l4.39-2.54c.12-.07,.22-.23,.22-.35v-2.02s.23-.13,.23-.13v2.28c0,.12-.1,.28-.22,.35l-4.62,2.67v-.26Z"
                fill="#d9dad5"
                opacity=".2"
              />
              <path
                d="M34.98,36.48l7.18-4.15c.12-.07,.22-.23,.22-.35v-4.85s.23-.13,.23-.13v5.11c0,.12-.1,.28-.22,.35l-7.41,4.28v-.26Z"
                fill="#d9dad5"
                opacity=".2"
              />
            </g>
            <path
              d="M59.3,28.37v.69c0,.15,.12,.2,.28,.12l.97-.56c.15-.09,.28-.28,.28-.44v-.69c0-.15-.12-.2-.28-.12l-.97,.56c-.15,.09-.28,.28-.28,.44Zm.08-.08c0-.08,.07-.19,.15-.24l1.05-.61c.08-.05,.15-.02,.15,.06v.75c0,.08-.07,.19-.15,.24l-1.05,.61c-.08,.05-.15,.02-.15-.06v-.75Z"
              fill="#53534b"
              opacity=".4"
            />
          </g>
          <g>
            <g>
              <polygon
                points="51.27 36.7 51.47 36.58 52.81 37.36 52.61 37.47 51.27 36.7"
                fill="#59595f"
              />
              <polygon
                points="52.61 37.47 52.81 37.36 55.42 41.55 55.22 41.66 52.61 37.47"
                fill="#323234"
              />
              <polygon
                points="52.61 37.47 55.22 41.66 53.88 40.89 51.27 36.7 52.61 37.47"
                fill="#404046"
              />
            </g>
            <g>
              <polygon
                points="46.14 41.28 52.42 37.64 52.42 37.51 46.14 41.14 46.14 41.28"
                fill="#a8a9a4"
              />
              <polygon
                points="46.14 41.14 52.42 37.51 51.58 37.02 45.3 40.66 46.14 41.14"
                fill="#dcddd6"
              />
              <polygon
                points="46.14 41.28 46.14 41.14 45.3 40.66 45.3 40.79 46.14 41.28"
                fill="#d8dce6"
              />
            </g>
            <g>
              <polygon
                points="46.86 42.5 53.14 38.86 53.14 38.73 46.86 42.37 46.86 42.5"
                fill="#a8a9a4"
              />
              <polygon
                points="46.86 42.37 53.14 38.73 52.3 38.24 46.02 41.88 46.86 42.37"
                fill="#dcddd6"
              />
              <polygon
                points="46.86 42.5 46.86 42.37 46.02 41.88 46.02 42.01 46.86 42.5"
                fill="#d8dce6"
              />
            </g>
            <g>
              <polygon
                points="47.58 43.72 53.86 40.08 53.86 39.95 47.58 43.59 47.58 43.72"
                fill="#a8a9a4"
              />
              <polygon
                points="47.58 43.59 53.86 39.95 53.02 39.46 46.74 43.1 47.58 43.59"
                fill="#dcddd6"
              />
              <polygon
                points="47.58 43.72 47.58 43.59 46.74 43.1 46.74 43.23 47.58 43.72"
                fill="#d8dce6"
              />
            </g>
            <g>
              <polygon
                points="48.3 44.94 54.58 41.31 54.58 41.17 48.3 44.81 48.3 44.94"
                fill="#a8a9a4"
              />
              <polygon
                points="48.3 44.81 54.58 41.17 53.74 40.69 47.46 44.32 48.3 44.81"
                fill="#dcddd6"
              />
              <polygon
                points="48.3 44.94 48.3 44.81 47.46 44.32 47.46 44.46 48.3 44.94"
                fill="#d8dce6"
              />
            </g>
            <g>
              <polygon
                points="44.89 40.39 45.09 40.27 46.43 41.04 46.23 41.16 44.89 40.39"
                fill="#59595f"
              />
              <polygon
                points="46.23 41.16 46.43 41.04 49.04 45.23 48.84 45.35 46.23 41.16"
                fill="#323234"
              />
              <polygon
                points="46.23 41.16 48.84 45.35 47.49 44.57 44.89 40.39 46.23 41.16"
                fill="#404046"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationCamper.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationCamper.defaultProps = {
  size: 70,
};

export { IllustrationCamper as default } from "./Camper";
