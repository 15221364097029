import i18next, { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { NewCategoryModal } from "../../ui/Modal/CustomModals/NewCategoryModal";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  GeofenceCategory,
  createGeofenceCategoryAsync,
  geofenceCategoriesEmptyState,
  geofenceCategoriesRestoreStatusAndReasonCode,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import "./CreateGeofenceSaveBar.css";
import {
  Circle,
  Geofence,
  GeofenceShapeEnum,
  Polygon,
  createGeofenceAsync,
  geofencesEmptyState,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "./geofenceSlice";

interface DropdownCategoriesProps {
  label: string;
  color: string;
}
interface CreateGeofenceSaveBarProps {
  drawedPoints: Polygon | Circle;
  shapeType: GeofenceShapeEnum;
}

export const CreateGeofenceSaveBar: React.FC<CreateGeofenceSaveBarProps> = ({
  drawedPoints,
  shapeType,
}) => {
  const [geofenceName, setGeofenceName] = useState("");
  const [newCategory, setNewCategory] = useState(false);

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState(
    {} as DropdownCategoriesProps
  );
  const [addGeofenceInfo, setAddGeofenceInfo] = useState(
    {} as Partial<Geofence>
  );

  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);

  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );

  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );

  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  // Chiamata API per la dropdown delle categorie
  useEffect(() => {
    store.dispatch(getGeofenceCategoriesAsync());
    return function cleanUp() {
      store.dispatch(geofencesEmptyState());
      store.dispatch(geofenceCategoriesEmptyState());
    };
  }, []);

  function calculateWidth(inputValue: string) {
    return { width: inputValue.length + 5 + "ch" };
  }

  useEffect(() => {
    if (geofencesSliceStatus === "idle") {
      if (geofencesSliceReasonCode === GTFleetSuccessCodes.POST) {
        ToastNotification({
          toastId: "newGeofenceSuccess",
          status: "success",
          description: t("common.newGeofenceSuccess"),
        });
        navigate("/admin/geofences");
      }
    }
    if (geofencesSliceStatus === "failed") {
      switch (geofencesSliceReasonCode) {
        case GTFleetErrorCodes.GEOFENCE_NAME_ALREADY_EXIST:
          ToastNotification({
            toastId: "geofencesNameExists",
            status: "error",
            description: t(
              "admin.geofences.editViewCreate.geofencesNameExists"
            ),
          });
          break;
        case GTFleetErrorCodes.FLEET_DEFAULT_NOT_FOUND:
          ToastNotification({
            toastId: "defaultFleetError",
            status: "error",
            description: t("admin.groups.error.FLEET_DEFAULT_NOT_FOUND"),
          });
          break;
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "idle") {
      if (geofenceCategoriesSliceReasonCode === GTFleetSuccessCodes.POST) {
        store.dispatch(geofenceCategoriesRestoreStatusAndReasonCode());
        ToastNotification({
          toastId: "geofenceCategorySuccess",
          status: "success",
          description: t("common.newCategorySuccess"),
        });
      }
    }
    if (geofenceCategoriesSliceStatus === "failed") {
      switch (geofenceCategoriesSliceReasonCode) {
        case GTFleetErrorCodes.GEOFENCE_CATEGORIES_NAME_ALREADY_EXISTS:
          ToastNotification({
            toastId: "geofenceCategoriesNameExists",
            status: "error",
            description: t(
              "admin.geofences.editViewCreate.geofenceCategoriesNameExists"
            ),
          });
          break;
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  return (
    <>
      {_.isEmpty(drawedPoints) ? (
        <div className="cg-savebar-subtopbar">
          <div className="cg-savebar-subsection-name">
            {i18next.t("admin.geofences.editViewCreate.createGeofence")}
          </div>
        </div>
      ) : (
        <div className="cg-savebar-row-splitter">
          <div className="cg-savebar-search">
            <Form>
              <TextField
                id={t("common.name")}
                validate="alphabetic"
                placeholder={t(
                  "admin.geofences.editViewCreate.filterBar.namePlaceholder"
                )}
                value={geofenceName}
                events={{
                  onChange: (data) => {
                    setAddGeofenceInfo({
                      ...addGeofenceInfo,
                      name: data.value,
                    });
                    setGeofenceName(data.value);
                  },
                }}
                maxLength="10"
              />
            </Form>
          </div>
          <div className="cg-savebar-dropdown-columns">
            <Dropdown
              value={selectedCategory}
              itemAttribute="label"
              placeholder={t(
                "admin.geofences.editViewCreate.filterBar.categoryPlaceholder"
              )}
              placeholderInValue={t(
                "admin.geofences.editViewCreate.filterBar.categoryDefaultPlaceholder"
              )}
              size={"small"}
              onChange={(val: any) => {
                setAddGeofenceInfo({
                  ...addGeofenceInfo,
                  geofenceCategory: parseInt(val[0]?.id),
                });
                setSelectedCategory({
                  label: val[0]?.label,
                  color: val[0]?.color,
                });
              }}
              options={geofenceCategories.map((category) => {
                return {
                  id: category.id,
                  label: category.name,
                  color: category.color,
                };
              })}
              hasButton={true}
              buttonClick={() => setNewCategory(true)}
              buttonText={t(
                "admin.geofences.editViewCreate.filterBar.buttonDropdown"
              )}
            >
              <IconAdd color="--global-colors-ink-light" size={14} />
            </Dropdown>
          </div>
          <div className="cg-savebar-btn">
            <Button
              size="small"
              aspect="primary"
              label={i18next.t("common.save")}
              onClick={() => {
                store.dispatch(
                  createGeofenceAsync({
                    ...addGeofenceInfo,
                    shape: drawedPoints,
                    geofenceShapeEnum: shapeType,
                  })
                );
              }}
              disabled={geofenceName === "" || _.isEmpty(selectedCategory)}
            />
          </div>
        </div>
      )}
      <NewCategoryModal
        type="geofence"
        open={newCategory}
        onClose={() => {
          setNewCategory(false);
        }}
        primaryAction={(e?: any) => {
          if (e !== undefined) {
            let geofenceCategory: GeofenceCategory = e as GeofenceCategory;
            store.dispatch(createGeofenceCategoryAsync({ geofenceCategory }));
          }
          setNewCategory(false);
        }}
      />
    </>
  );
};
