import { schema } from "normalizr";
import { addressBookSchema } from "../../address/addressBookNormalization";
import { tenantSchema } from "../../tenants/tenantNormalization";

export const notificationRuleDestinationSchema: schema.Entity<any> =
  new schema.Entity(
    "notificationRuleDestination",
    {
      tenant: tenantSchema,
      address: addressBookSchema,
      rule: new schema.Entity("notificationRule"),
    },
    {
      idAttribute: "id",
    }
  );

export const notificationTemplateSchema: schema.Entity<any> = new schema.Entity(
  "notificationTemplate",
  {},
  {
    idAttribute: "id",
  }
);

export const notificationRuleSchema: schema.Entity<any> = new schema.Entity(
  "notificationRule",
  {
    tenant: tenantSchema,
    destinations: [notificationRuleDestinationSchema],
    templates: [notificationTemplateSchema],
  },
  {
    idAttribute: "id",
  }
);

export const notificationRuleAggregationSchema: schema.Entity<any> =
  new schema.Entity(
    "notificationRuleAggregation",
    {
      tenant: tenantSchema,
      notificationRules: [notificationRuleSchema],
      templates: [notificationTemplateSchema], // Associa i templates qui
    },
    {
      idAttribute: "id",
    }
  );

export const notificationRuleAggregationsSchema = new schema.Array(
  notificationRuleAggregationSchema
);
