import PropTypes from "prop-types";

export const IllustrationAirplane = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 65 54"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="40.8927564%"
        y1="30.1498639%"
        x2="64.4650219%"
        y2="64.3907741%"
        id="airplane-linearGradient-1"
      >
        <stop stopColor="#E0E7EB" offset="0%"></stop>
        <stop stopColor="#E9F1F5" offset="16%"></stop>
        <stop stopColor="#F3FBFF" offset="44%"></stop>
        <stop stopColor="#E1E9EC" offset="68%"></stop>
        <stop stopColor="#C3C9CC" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="34.0808627%"
        y1="2.63850185%"
        x2="66.9860283%"
        y2="100.644181%"
        id="airplane-linearGradient-2"
      >
        <stop stopColor="#C6CDD1" offset="0%"></stop>
        <stop stopColor="#CFD7DB" offset="16%"></stop>
        <stop stopColor="#D9E1E5" offset="44%"></stop>
        <stop stopColor="#C7CFD2" offset="68%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="32.0759128%"
        y1="6.9566886%"
        x2="60.5407234%"
        y2="106.052498%"
        id="airplane-linearGradient-3"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="52%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-2991.00885%"
        y1="2.0117524%"
        x2="-2967.51789%"
        y2="120.897967%"
        id="airplane-linearGradient-4"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="52%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="33.9733968%"
        y1="53.5800971%"
        x2="60.5651055%"
        y2="38.3495146%"
        id="airplane-linearGradient-5"
      >
        <stop stopColor="#DEE4E8" offset="0%"></stop>
        <stop stopColor="#ECF3F7" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.0847457627%"
        y1="49.9744507%"
        x2="100%"
        y2="49.9744507%"
        id="airplane-linearGradient-6"
      >
        <stop stopColor="#C5CBCF" offset="0%"></stop>
        <stop stopColor="#D3DADE" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-5.52951545e-13%"
        y1="49.9694746%"
        x2="100.389105%"
        y2="49.9694746%"
        id="airplane-linearGradient-7"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-34272.3479%"
        y1="1.80610538%"
        x2="-34276.4362%"
        y2="93.7731525%"
        id="airplane-linearGradient-8"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-26237.8906%"
        y1="49.9156835%"
        x2="-26137.1094%"
        y2="49.9156835%"
        id="airplane-linearGradient-9"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-34272.3479%"
        y1="1.80610538%"
        x2="-34276.4362%"
        y2="94.4543899%"
        id="airplane-linearGradient-10"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-26135.4086%"
        y1="49.9731744%"
        x2="-26035.0195%"
        y2="49.9731744%"
        id="airplane-linearGradient-11"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-34271.8369%"
        y1="1.80610538%"
        x2="-34275.9251%"
        y2="93.7731525%"
        id="airplane-linearGradient-12"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.171623664%"
        y1="49.1233673%"
        x2="100.127072%"
        y2="49.1233673%"
        id="airplane-linearGradient-13"
      >
        <stop stopColor="#C6C9CB" offset="0%"></stop>
        <stop stopColor="#ADB0B2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3.02358611e-12%"
        y1="50.5641142%"
        x2="100.425532%"
        y2="50.5641142%"
        id="airplane-linearGradient-14"
      >
        <stop stopColor="#ADB0B2" offset="0%"></stop>
        <stop stopColor="#949799" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-6390.77069%"
        y1="49.9334221%"
        x2="-6290.77069%"
        y2="49.9334221%"
        id="airplane-linearGradient-15"
      >
        <stop stopColor="#ADB0B2" offset="0%"></stop>
        <stop stopColor="#949799" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-9.08041835e-13%"
        y1="50%"
        x2="100.319489%"
        y2="50%"
        id="airplane-linearGradient-16"
      >
        <stop stopColor="#949799" offset="0%"></stop>
        <stop stopColor="#7B7E80" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-9264.55172%"
        y1="50.0834725%"
        x2="-9164.41379%"
        y2="50.0834725%"
        id="airplane-linearGradient-17"
      >
        <stop stopColor="#949799" offset="0%"></stop>
        <stop stopColor="#7B7E80" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.149700599%"
        y1="49.8461538%"
        x2="100%"
        y2="49.8461538%"
        id="airplane-linearGradient-18"
      >
        <stop stopColor="#7B7E80" offset="0%"></stop>
        <stop stopColor="#626567" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-26033.3333%"
        y1="49.9156835%"
        x2="-25933.3333%"
        y2="49.9156835%"
        id="airplane-linearGradient-19"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-35083.4739%"
        y1="2.34329716%"
        x2="-35087.1356%"
        y2="96.0792506%"
        id="airplane-linearGradient-20"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-26033.7209%"
        y1="50.1505028%"
        x2="-25934.1085%"
        y2="50.1505028%"
        id="airplane-linearGradient-21"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-34789.4661%"
        y1="1.51216336%"
        x2="-34793.0972%"
        y2="94.4377892%"
        id="airplane-linearGradient-22"
      >
        <stop stopColor="#DDDFE1" offset="0%"></stop>
        <stop stopColor="#939698" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-31833.1754%"
        y1="50.0734214%"
        x2="-31732.7014%"
        y2="50.0734214%"
        id="airplane-linearGradient-23"
      >
        <stop stopColor="#949799" offset="0%"></stop>
        <stop stopColor="#7B7E80" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-26340.3922%"
        y1="50.0734214%"
        x2="-26240.3922%"
        y2="50.0734214%"
        id="airplane-linearGradient-24"
      >
        <stop stopColor="#ADB0B2" offset="0%"></stop>
        <stop stopColor="#949799" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="44.8011553%"
        y1="44.6437341%"
        x2="54.9329321%"
        y2="55.6969722%"
        id="airplane-linearGradient-25"
      >
        <stop stopColor="#FDFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="39%"></stop>
        <stop stopColor="#E1E9EC" offset="68%"></stop>
        <stop stopColor="#C3C9CC" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-2118.16462%"
        y1="49.9951256%"
        x2="-2018.19615%"
        y2="49.9951256%"
        id="airplane-linearGradient-26"
      >
        <stop stopColor="#DEE4E8" offset="0%"></stop>
        <stop stopColor="#ECF3F7" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-1082.44922%"
        y1="50.0055547%"
        x2="-982.436123%"
        y2="50.0055547%"
        id="airplane-linearGradient-27"
      >
        <stop stopColor="#DEE4E8" offset="0%"></stop>
        <stop stopColor="#ECF3F7" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-1.79203716e-13%"
        y1="49.9073502%"
        x2="100.157629%"
        y2="49.9073502%"
        id="airplane-linearGradient-28"
      >
        <stop stopColor="#C7CACC" offset="0%"></stop>
        <stop stopColor="#AEB1B3" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-1680.85876%"
        y1="30.1316954%"
        x2="-1657.27603%"
        y2="64.4015023%"
        id="airplane-linearGradient-29"
      >
        <stop stopColor="#E0E7EB" offset="0%"></stop>
        <stop stopColor="#E9F1F5" offset="16%"></stop>
        <stop stopColor="#F3FBFF" offset="44%"></stop>
        <stop stopColor="#E1E9EC" offset="68%"></stop>
        <stop stopColor="#C3C9CC" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3741.16559%"
        y1="2.63850185%"
        x2="-3708.22923%"
        y2="100.717429%"
        id="airplane-linearGradient-30"
      >
        <stop stopColor="#C6CDD1" offset="0%"></stop>
        <stop stopColor="#CFD7DB" offset="16%"></stop>
        <stop stopColor="#D9E1E5" offset="44%"></stop>
        <stop stopColor="#C7CFD2" offset="68%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3230.52719%"
        y1="6.95661807%"
        x2="-3202.06295%"
        y2="106.051422%"
        id="airplane-linearGradient-31"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="52%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-2976.40593%"
        y1="2.00842471%"
        x2="-2952.98195%"
        y2="120.898677%"
        id="airplane-linearGradient-32"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="52%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="51.9076312%"
        y1="83.2052288%"
        x2="44.7951416%"
        y2="9.04119809%"
        id="airplane-linearGradient-33"
      >
        <stop stopColor="#D9E1E5" offset="0%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3447.96856%"
        y1="82.6317057%"
        x2="-3455.05505%"
        y2="8.51198236%"
        id="airplane-linearGradient-34"
      >
        <stop stopColor="#D9E1E5" offset="0%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3452.12887%"
        y1="83.2426519%"
        x2="-3459.22348%"
        y2="9.04526454%"
        id="airplane-linearGradient-35"
      >
        <stop stopColor="#D9E1E5" offset="0%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3443.12606%"
        y1="83.1104513%"
        x2="-3450.1506%"
        y2="9.16178203%"
        id="airplane-linearGradient-36"
      >
        <stop stopColor="#D9E1E5" offset="0%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3447.68559%"
        y1="83.1693006%"
        x2="-3454.7193%"
        y2="9.03729408%"
        id="airplane-linearGradient-37"
      >
        <stop stopColor="#D9E1E5" offset="0%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3446.39595%"
        y1="83.1693006%"
        x2="-3453.47915%"
        y2="9.03729408%"
        id="airplane-linearGradient-38"
      >
        <stop stopColor="#D9E1E5" offset="0%"></stop>
        <stop stopColor="#A9AFB2" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-970.441169%"
        y1="53.8011065%"
        x2="-947.235611%"
        y2="46.1945344%"
        id="airplane-linearGradient-39"
      >
        <stop stopColor="#DEE4E8" offset="0%"></stop>
        <stop stopColor="#ECF3F7" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-374.875596%"
        y1="52.8217323%"
        x2="-371.62108%"
        y2="48.5356525%"
        id="airplane-linearGradient-40"
      >
        <stop stopColor="#DEE4E8" offset="0%"></stop>
        <stop stopColor="#ECF3F7" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-3333.3995%"
        y1="50%"
        x2="-3233.34988%"
        y2="50%"
        id="airplane-linearGradient-41"
      >
        <stop stopColor="#C5CBCF" offset="0%"></stop>
        <stop stopColor="#D3DADE" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="airplane-Image-and-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="airplane-Illustration"
        transform="translate(-85.000000, -317.000000)"
        fillRule="nonzero"
      >
        <g id="airplane-Group" transform="translate(82.000000, 314.000000)">
          <g id="airplane" transform="translate(3.000000, 3.000000)">
            <path
              d="M34.845098,40.2133649 L31.9264706,38.2734597 C32.627451,37.7309005 34.195098,36.4563981 35.9590196,34.9873934 C36.1663311,35.2665292 36.4261314,35.5021566 36.7237255,35.6809479 C36.9853026,35.8584094 37.3099332,35.9152148 37.6158824,35.8370616 L37.5852941,35.8370616 C38.6221965,35.4951088 39.6235649,35.0528426 40.5752941,34.5164929 C41.5490196,33.9227488 42.51,33.4441706 42.9535294,33.1217062 C43.6901961,32.5842654 45.11,31.2022749 45.3903922,30.769763 L45.3903922,30.769763 C45.5847708,30.5836752 45.6850085,30.3193784 45.6631373,30.0506161 C45.6179325,29.2086761 45.1758161,28.438761 44.4727451,27.9776303 C44.3461401,27.9023313 44.2071623,27.8503307 44.0623529,27.8240758 C44.8831373,26.9539336 45.7268627,26.004455 46.3564706,25.295545 C49.397451,25.6282464 59.5068627,26.46 59.5068627,26.46 L62.2496078,24.8758294 L51.3041176,21.6383886 C52.1911765,20.6146919 52.4052941,19.7419905 51.9082353,19.6191469 C51.5896078,19.5372512 50.7305882,19.9109005 49.7466667,20.4278673 L44.7862745,14.3957346 L42.3443137,15.9159242 C42.3443137,15.9159242 43.6188235,20.5635071 44.2943137,22.976872 C40.3484314,24.617346 33.1576471,27.6449289 30.6698039,28.9347867 C29.1403922,29.7307109 26.2854902,31.2381043 23.4101961,32.7941232 L9.61235294,16.1590521 L6.69627451,17.9146919 L15.6815686,36.9963981 C15.3731373,37.1653081 15.1258824,37.3060664 14.944902,37.408436 C13,38.5268246 6.62745098,42.4245498 4.91196078,44.1801896 C3.19647059,45.9358294 1.75882353,49.1604739 1.29745098,50.0869194 C0.836078431,51.0133649 -0.231960784,53.7338389 0.0484313725,53.9462559 C0.328823529,54.158673 3.9127451,53.4344076 6.0845098,52.845782 C8.25627451,52.2571564 16.0996078,48.1572512 17.8431373,47.0567773 C18.5135294,46.6345024 20.4992157,45.4265403 22.7754902,44.0343128 C22.8804789,44.2142994 23.0516438,44.3457151 23.2521569,44.4002844 L59.6470588,50.3479621 L63.1494118,48.1137441 L34.845098,40.2133649 Z"
              id="airplane-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M34.9954902,19.4630332 C34.7962795,19.3315736 34.5556922,19.2787056 34.32,19.3145972 L34.32,19.3145972 C34.2851946,19.3229781 34.2511019,19.3341027 34.2180392,19.3478673 C33.7413725,19.5090995 31.8194118,20.1617062 30.9043137,20.6070142 C29.8541176,21.1188626 29.2117647,21.5820853 28.6331373,21.907109 C27.7897592,22.4356625 26.9924673,23.0349593 26.2498039,23.6985782 L26.2498039,23.6985782 C26.5792658,23.5547023 26.9598309,23.5955365 27.2515686,23.8060664 C28.1017109,24.3620549 28.6367128,25.2918428 28.6917647,26.3090047 C28.6917647,26.8541232 28.464902,27.2072986 28.1156863,27.3096682 L28.1156863,27.3096682 C29.1493067,26.9708611 30.1480329,26.532916 31.0980392,26.0018957 C32.0717647,25.4081517 33.0327451,24.9295735 33.4762745,24.607109 C34.2129412,24.0722275 35.6327451,22.6876777 35.9131373,22.2551659 L35.9131373,22.2551659 C36.1112655,22.0709836 36.2153539,21.8064239 36.1960784,21.536019 C36.1485699,20.6921764 35.7024466,19.9218813 34.9954902,19.4630332 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-1)"
            ></path>
            <path
              d="M28.6841176,26.3218009 C28.6841176,27.243128 28.0392157,27.6167773 27.2439216,27.1561137 C26.3953649,26.598972 25.8615606,25.6696206 25.8062745,24.6531754 C25.8062745,23.7318483 26.4511765,23.3735545 27.2439216,23.8188626 C28.0940638,24.3748511 28.6290658,25.304639 28.6841176,26.3218009 L28.6841176,26.3218009 Z"
              id="airplane-Path"
              fill="#D2D9DD"
            ></path>
            <path
              d="M28.5898039,26.2680569 C28.5898039,27.1305213 27.9882353,27.4785782 27.2439216,27.0358294 C26.450529,26.5143365 25.9517331,25.6448327 25.9005882,24.6941232 C25.9005882,23.834218 26.5021569,23.4836019 27.2439216,23.9263507 C28.0398273,24.4458084 28.5401182,25.3162667 28.5898039,26.2680569 L28.5898039,26.2680569 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-2)"
            ></path>
            <path
              d="M27.3739216,26.9948815 C26.5799511,26.4747736 26.0801504,25.6061188 26.0280392,24.6557346 C25.989753,24.3062334 26.1491012,23.9645321 26.4409804,23.770237 C26.1121569,23.8649289 25.9005882,24.1950711 25.9005882,24.7069194 C25.9517331,25.6576289 26.450529,26.5271327 27.2439216,27.0486256 C27.5166394,27.2441918 27.8711357,27.282984 28.1794118,27.1509953 C27.9009415,27.2110538 27.6101188,27.1546889 27.3739216,26.9948815 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-3)"
              opacity="0.5"
              style={{ mixBlendMode: "screen" }}
            ></path>
            <path
              d="M27.2745098,23.9391469 C27.0236675,24.0565261 26.8780323,24.3240761 26.915098,24.5994313 C26.9510081,25.2773437 27.3071072,25.8973109 27.8735294,26.2680569 C28.0852589,26.4296749 28.374495,26.442921 28.6,26.301327 L28.6,26.2680569 C28.5489608,25.3252456 28.0576215,24.4619535 27.2745098,23.9391469 L27.2745098,23.9391469 Z"
              id="airplane-Path"
              fill="#555E61"
            ></path>
            <path
              d="M28.1080392,27.3224645 L28.1080392,27.3224645 C28.1080392,27.3224645 28.3017647,27.2610427 28.6178431,27.148436 C28.7975562,26.9089112 28.8877733,26.6136241 28.8727451,26.3141232 C28.8058932,25.2070147 28.2106426,24.2004671 27.2745098,23.611564 C27.0365606,23.478391 26.7593773,23.4339726 26.4919608,23.4861611 C26.3517647,23.6064455 26.3364706,23.6218009 26.2370588,23.7113744 L26.2370588,23.7113744 C26.5665207,23.5674985 26.9470858,23.6083327 27.2388235,23.8188626 C28.0889658,24.3748511 28.6239677,25.304639 28.6790196,26.3218009 C28.6841176,26.8720379 28.4572549,27.2200948 28.1080392,27.3224645 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-4)"
              opacity="0.5"
              style={{ mixBlendMode: "screen" }}
            ></path>
            <path
              d="M28.2941176,25.3185782 C28.2941176,25.5463507 28.1360784,25.6359242 27.9398039,25.5233175 C27.7300576,25.3866318 27.5982977,25.1572687 27.5854902,24.9065403 C27.5854902,24.681327 27.7435294,24.5891943 27.9398039,24.7018009 C28.1495503,24.8384867 28.2813102,25.0678498 28.2941176,25.3185782 Z"
              id="airplane-Path"
              fill="#8897A0"
            ></path>
            <path
              d="M31.0266667,26.0454028 C31.2029893,25.7790925 31.2921022,25.4641571 31.2815686,25.1445498 C31.2160132,23.8980068 30.5627958,22.7574209 29.5227451,22.0734597 C29.2919107,21.9302839 29.028976,21.8475673 28.7580392,21.832891 L28.6866667,21.8738389 L28.727451,21.8738389 C28.998655,21.8849172 29.2622668,21.9669646 29.4921569,22.1118483 C30.5190351,22.7856176 31.165414,23.9096773 31.2331373,25.1394313 C31.253192,25.4851411 31.1451457,25.8260771 30.9298039,26.0965877 L31.0266667,26.0454028 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <path
              d="M33.9937255,24.1899526 C34.0513542,24.005311 34.0797393,23.8127272 34.0778431,23.6192417 C34.0129318,22.3718618 33.3584177,21.2306656 32.3164706,20.5481517 C32.0867156,20.4003588 31.8238903,20.312399 31.5517647,20.2922275 L31.4523529,20.3331754 L31.5109804,20.3331754 C31.7822454,20.3438982 32.0459463,20.4259733 32.2756863,20.5711848 C33.3034854,21.2440788 33.9501589,22.3686507 34.0166667,23.5987678 C34.0202662,23.8105402 33.9857244,24.0212231 33.9147059,24.2206635 L33.9937255,24.1899526 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-5)"
              points="15.8013725 32.781327 6.69627451 13.4436967 9.61235294 11.6931754 23.8027451 28.7991469"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-6)"
              points="8.78901961 9.15696682 6.69627451 13.4436967 9.61235294 11.6931754 9.70411765 8.43526066"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="10.004902 12.1666351 9.30137255 12.7219905 22.0031373 29.6948815 22.0439216 29.6744076 18.067451 24.3614218 19.4515686 23.5527014 19.4235294 23.5194313 18.0419608 24.3281517 12.867451 17.4130806 13.9813725 16.9600948 13.9533333 16.9268246 12.8394118 17.3772512 9.36254902 12.7296682 10.0303922 12.1999052"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="16.4794118 32.4435071 16.5176471 32.4255924 7.22137255 13.1314692 7.20352941 13.1391469 7.18313725 13.1519431"
            ></polygon>
            <path
              d="M8.25882353,47.3869194 L7.90196078,47.2512796 C7.77417404,47.2180505 7.63830931,47.2423426 7.52980392,47.3178199 C7.16923905,47.54959 6.94088965,47.9405775 6.9154902,48.3696682 C6.8997878,48.522437 6.96693187,48.6719187 7.09137255,48.7612322 L7.47627451,48.9096682 C7.60450209,48.9395001 7.73931066,48.9144698 7.84843137,48.8405687 C8.20550284,48.605695 8.42983926,48.2141454 8.45254902,47.7861611 C8.47039463,47.6266495 8.39493029,47.4711278 8.25882353,47.3869194 L8.25882353,47.3869194 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M8.45254902,47.7887204 C8.42983926,48.2167046 8.20550284,48.6082542 7.84843137,48.843128 C7.51196078,49.0376303 7.24176471,48.8815166 7.24176471,48.4925118 C7.26423554,48.0638099 7.4897713,47.671821 7.84843137,47.4381043 C8.18235294,47.2436019 8.45254902,47.4022749 8.45254902,47.7887204 Z"
              id="airplane-Path"
              fill="#646363"
            ></path>
            <path
              d="M8.39647059,47.8399052 C8.37981968,48.2082089 8.19080461,48.5469538 7.88666667,48.7535545 C7.59607843,48.9224645 7.36156863,48.7842654 7.36156863,48.4490047 C7.38127759,48.0784335 7.57648615,47.73973 7.88666667,47.5379147 C8.15686275,47.3690047 8.39647059,47.5046445 8.39647059,47.8399052 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M8.21294118,47.9601896 C8.20093097,48.1923915 8.07861446,48.4046858 7.88411765,48.5309005 C7.70313725,48.6358294 7.55784314,48.5513744 7.55784314,48.3415166 C7.56970382,48.1092325 7.69081687,47.8964347 7.88411765,47.7682464 C8.06764706,47.6633175 8.21294118,47.7503318 8.21294118,47.9601896 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-7)"
            ></path>
            <path
              d="M8.02686275,48.0676777 C8.02463768,48.1715127 7.97113303,48.2674399 7.88411765,48.3236019 C7.80764706,48.3696682 7.74392157,48.3236019 7.74392157,48.2417062 C7.74687446,48.1387074 7.7990609,48.0434425 7.88411765,47.985782 C7.96313725,47.9397156 8.02686275,47.9781043 8.02686275,48.0676777 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-8)"
            ></path>
            <polygon
              id="airplane-Path"
              fill="#363A3B"
              points="7.84588235 46.2787678 8.2945098 46.0202844 8.31235294 45.8795261 7.62156863 46.2787678 7.58333333 47.9269194 7.8254902 48.7765877 8.05235294 48.7765877 7.81019608 47.9269194"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="#4E5254"
              points="8.17215686 47.4150711 8.17215686 46.0893839 7.84588235 46.2787678 7.81019608 47.9269194 8.05235294 48.7765877 8.53666667 48.4566825 8.53666667 47.7272986"
            ></polygon>
            <path
              d="M9.29117647,47.9192417 L8.92156863,47.7836019 C8.79378188,47.7503728 8.65791716,47.7746649 8.54941176,47.8501422 C8.1907517,48.0838589 7.96521593,48.4758478 7.9427451,48.9045498 C7.92649409,49.0574071 7.99375744,49.2071544 8.11862745,49.2961137 L8.50352941,49.4445498 C8.63090691,49.475579 8.76542072,49.4504311 8.87313725,49.3754502 C9.23171429,49.142848 9.45735514,48.7516283 9.47980392,48.3236019 C9.50011523,48.163531 9.42665528,48.006055 9.29117647,47.9192417 L9.29117647,47.9192417 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M9.48490196,48.3236019 C9.46245318,48.7516283 9.23681233,49.142848 8.87823529,49.3754502 C8.54431373,49.5699526 8.27411765,49.4138389 8.27411765,49.0248341 C8.2968274,48.5968499 8.52116382,48.2053003 8.87823529,47.9704265 C9.21470588,47.7784834 9.48490196,47.9345972 9.48490196,48.3236019 Z"
              id="airplane-Path"
              fill="#646363"
            ></path>
            <path
              d="M9.43137255,48.3696682 C9.41125491,48.7401173 9.21614594,49.0786481 8.90627451,49.2807583 C8.61823529,49.4496682 8.39647059,49.3114692 8.39647059,48.9762085 C8.41311232,48.6085916 8.60222426,48.2706224 8.90627451,48.0651185 C9.19431373,47.901327 9.43137255,48.0369668 9.43137255,48.3696682 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M9.24529412,48.4925118 C9.23443486,48.7237614 9.11412302,48.9358648 8.92156863,49.0632227 C8.74058824,49.1681517 8.5927451,49.0836967 8.5927451,48.8738389 C8.60516601,48.6417593 8.72738227,48.429639 8.92156863,48.303128 C9.1,48.1956398 9.24529412,48.282654 9.24529412,48.4925118 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-9)"
            ></path>
            <path
              d="M9.05921569,48.6 C9.05790708,48.7040835 9.00417588,48.8004168 8.91647059,48.8559242 C8.84,48.9019905 8.77627451,48.8559242 8.77627451,48.7740284 C8.77922741,48.6710297 8.83141384,48.5757648 8.91647059,48.5181043 C8.9954902,48.4720379 9.05921569,48.5104265 9.05921569,48.6 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-10)"
            ></path>
            <path
              d="M26.8717647,39.4251185 L26.5072549,39.2894787 C26.3794682,39.2562495 26.2436034,39.2805417 26.135098,39.356019 C25.7777552,39.5897991 25.5525121,39.9803293 25.5284314,40.4078673 C25.512729,40.560636 25.5798731,40.7101178 25.7043137,40.7994313 L26.0892157,40.9504265 C26.2174501,40.9799445 26.3521337,40.9549374 26.4613725,40.881327 C26.818444,40.6464533 27.0427804,40.2549037 27.0654902,39.8269194 C27.0843727,39.6665345 27.008774,39.5097373 26.8717647,39.4251185 L26.8717647,39.4251185 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M27.0654902,39.8269194 C27.0427804,40.2549037 26.818444,40.6464533 26.4613725,40.881327 C26.124902,41.0758294 25.8547059,40.9197156 25.8547059,40.5307109 C25.8771767,40.102009 26.1027125,39.71002 26.4613725,39.4763033 C26.7952941,39.2818009 27.0654902,39.4404739 27.0654902,39.8269194 Z"
              id="airplane-Path"
              fill="#646363"
            ></path>
            <path
              d="M27.0196078,39.8806635 C27.0021672,40.2480505 26.8132429,40.5856841 26.5098039,40.7917536 C26.2192157,40.9581043 25.9847059,40.8224645 25.9847059,40.4872038 C26.0044148,40.1166326 26.1996234,39.777929 26.5098039,39.5761137 C26.7647059,39.4123223 27.0196078,39.5428436 27.0196078,39.8806635 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M26.8258824,39.9983886 C26.8140375,40.2299926 26.692823,40.4420179 26.4996078,40.5690995 C26.3160784,40.6740284 26.1707843,40.5895735 26.1707843,40.3771564 C26.1827945,40.1449544 26.305111,39.9326601 26.4996078,39.8064455 C26.6805882,39.7015166 26.8258824,39.7885308 26.8258824,39.9983886 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-11)"
            ></path>
            <path
              d="M26.6398039,40.1058768 C26.636851,40.2088755 26.5846646,40.3041404 26.4996078,40.3618009 C26.4205882,40.4078673 26.3568627,40.3618009 26.3568627,40.2799052 C26.3595318,40.1761922 26.412928,40.0804594 26.4996078,40.023981 C26.5760784,39.9779147 26.6398039,40.0163033 26.6398039,40.1058768 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-8)"
            ></path>
            <path
              d="M28.7835294,38.3272038 L28.4190196,38.191564 C28.2912329,38.1583349 28.1553681,38.182627 28.0468627,38.2581043 C27.6895199,38.4918844 27.4642768,38.8824146 27.4401961,39.3099526 C27.4233877,39.462899 27.490773,39.6129178 27.6160784,39.7015166 L28.0009804,39.8525118 C28.1283579,39.8835411 28.2628717,39.8583932 28.3705882,39.7834123 C28.7285456,39.5502181 28.9539862,39.1593454 28.9772549,38.731564 C28.9965274,38.5704165 28.9209549,38.4126753 28.7835294,38.3272038 L28.7835294,38.3272038 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M28.9772549,38.731564 C28.9539862,39.1593454 28.7285456,39.5502181 28.3705882,39.7834123 C28.0366667,39.9779147 27.7664706,39.8192417 27.7664706,39.4327962 C27.7923403,39.0084948 28.0163036,38.6213915 28.3705882,38.3886256 C28.7070588,38.1838863 28.9772549,38.3425592 28.9772549,38.731564 Z"
              id="airplane-Path"
              fill="#646363"
            ></path>
            <path
              d="M28.9211765,38.7801896 C28.9045256,39.1484933 28.7155105,39.4872382 28.4113725,39.6938389 C28.1207843,39.8627488 27.8862745,39.7245498 27.8862745,39.3892891 C27.9059835,39.0187179 28.101192,38.6800143 28.4113725,38.4781991 C28.6866667,38.3092891 28.9211765,38.4449289 28.9211765,38.7801896 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M28.7376471,38.9004739 C28.7258022,39.132078 28.6045877,39.3441032 28.4113725,39.4711848 C28.2278431,39.5761137 28.082549,39.4916588 28.082549,39.2792417 C28.0945592,39.0470397 28.2168757,38.8347454 28.4113725,38.7085308 C28.5923529,38.6036019 28.7376471,38.6906161 28.7376471,38.9004739 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-11)"
            ></path>
            <path
              d="M28.5490196,39.0079621 C28.5469203,39.1111922 28.494532,39.2068256 28.4088235,39.2638863 C28.3298039,39.3099526 28.2660784,39.2638863 28.2660784,39.1819905 C28.2687475,39.0782775 28.3221437,38.9825447 28.4088235,38.9260664 C28.4878431,38.88 28.5490196,38.9183886 28.5490196,39.0079621 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-12)"
            ></path>
            <path
              d="M26.8156863,39.9241706 L26.6347059,39.8576303 C26.5716468,39.8407376 26.5043699,39.8529335 26.4511765,39.8909005 C26.2751755,40.0034231 26.1633817,40.1936638 26.1503922,40.4027488 C26.1419111,40.4785811 26.1750971,40.5530592 26.2370588,40.5972512 L26.4282353,40.6714692 C26.4913441,40.6869718 26.5580405,40.6748811 26.6117647,40.6381991 C26.7852939,40.5236664 26.8956025,40.3343487 26.91,40.1263507 C26.919837,40.0463658 26.883183,39.9677907 26.8156863,39.9241706 L26.8156863,39.9241706 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-13)"
            ></path>
            <path
              d="M26.91,40.1135545 C26.8956025,40.3215525 26.7852939,40.5108702 26.6117647,40.6254028 C26.4460784,40.722654 26.3109804,40.6433175 26.3109804,40.4513744 C26.3263466,40.243029 26.4375578,40.0537796 26.6117647,39.9395261 C26.777451,39.8448341 26.91,39.9241706 26.91,40.1135545 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-14)"
            ></path>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-15)"
              points="28.2992157 39.7066351 27.2184314 40.807109 27.1878431 41.2293839 29.8668627 39.8781043 29.8439216 39.5633175 29.2321569 39.3073934"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-16)"
              points="27.1878431 41.2293839 26.4205882 40.796872 26.4511765 40.3745972 27.2184314 40.807109"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-17)"
              points="28.2992157 39.7066351 27.5294118 39.2741232 26.4511765 40.3745972 27.2184314 40.807109"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-18)"
              points="28.2992157 39.7066351 27.5294118 39.2741232 28.4623529 38.8748815 29.2321569 39.3073934"
            ></polygon>
            <path
              d="M28.597451,40.5307109 L28.2329412,40.3925118 C28.1042855,40.3605402 27.9681628,40.3847128 27.8582353,40.4590521 C27.5011638,40.6939259 27.2768274,41.0854755 27.2541176,41.5134597 C27.2383852,41.6656488 27.3043947,41.8147659 27.427451,41.9050237 L27.814902,42.0534597 C27.9422795,42.0844889 28.0767933,42.0593411 28.1845098,41.9843602 C28.5421592,41.7508722 28.7675008,41.3601714 28.7911765,40.9325118 C28.8094127,40.7722366 28.7339737,40.6157705 28.597451,40.5307109 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M28.8039216,40.9325118 C28.7802459,41.3601714 28.5549043,41.7508722 28.1972549,41.9843602 C27.8633333,42.1788626 27.5905882,42.0227488 27.5905882,41.6337441 C27.6146833,41.2055273 27.8398249,40.8142235 28.1972549,40.5793365 C28.5184314,40.3873934 28.8039216,40.5435071 28.8039216,40.9325118 Z"
              id="airplane-Path"
              fill="#646363"
            ></path>
            <path
              d="M28.735098,40.9836967 C28.7153891,41.3542679 28.5201805,41.6929714 28.21,41.8947867 C27.9194118,42.0636967 27.684902,41.9280569 27.684902,41.590237 C27.7050196,41.2197879 27.9001286,40.8812571 28.21,40.6791469 C28.5005882,40.510237 28.735098,40.648436 28.735098,40.9836967 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M28.5490196,41.1014218 C28.5361916,41.3333796 28.4140744,41.5453281 28.2201961,41.6721327 C28.0392157,41.7770616 27.8913725,41.6926066 27.8913725,41.4827488 C27.9042005,41.250791 28.0263178,41.0388426 28.2201961,40.9120379 C28.4037255,40.8045498 28.5490196,40.891564 28.5490196,41.1014218 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-19)"
            ></path>
            <path
              d="M28.3654902,41.2037915 C28.3628212,41.3075045 28.3094249,41.4032373 28.2227451,41.4597156 C28.1437255,41.5032227 28.082549,41.4597156 28.082549,41.3752607 C28.0842082,41.2719127 28.1367019,41.1760871 28.2227451,41.1193365 C28.2941176,41.0835071 28.3654902,41.1193365 28.3654902,41.2037915 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-20)"
            ></path>
            <path
              d="M30.5066667,39.4327962 L30.1447059,39.2945972 C30.0160502,39.2626255 29.8799275,39.2867981 29.77,39.3611374 C29.4129285,39.5960112 29.1885921,39.9875608 29.1658824,40.415545 C29.1501499,40.5677341 29.2161594,40.7168512 29.3392157,40.807109 L29.7266667,40.955545 C29.8537135,40.9863298 29.9877963,40.9621909 30.0962745,40.8890047 C30.4540101,40.654408 30.6792492,40.2629347 30.7029412,39.8345972 C30.7213123,39.6736675 30.6446522,39.5167338 30.5066667,39.4327962 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M30.7029412,39.8345972 C30.6792492,40.2629347 30.4540101,40.654408 30.0962745,40.8890047 C29.7623529,41.0835071 29.4896078,40.9248341 29.4896078,40.5358294 C29.5132835,40.1081699 29.7386251,39.717469 30.0962745,39.483981 C30.4301961,39.2894787 30.7029412,39.4455924 30.7029412,39.8345972 Z"
              id="airplane-Path"
              fill="#646363"
            ></path>
            <path
              d="M30.6468627,39.885782 C30.6271538,40.2563532 30.4319452,40.5950567 30.1217647,40.796872 C29.8311765,40.965782 29.5966667,40.8301422 29.5966667,40.4923223 C29.6167843,40.1218732 29.8118933,39.7833424 30.1217647,39.5812322 C30.4123529,39.4123223 30.6468627,39.5505213 30.6468627,39.885782 Z"
              id="airplane-Path"
              fill="#474646"
            ></path>
            <path
              d="M30.4633333,40.0035071 C30.4505053,40.2354649 30.3283881,40.4474134 30.1345098,40.574218 C29.9535294,40.6791469 29.8056863,40.5946919 29.8056863,40.3848341 C29.8185143,40.1528763 29.9406315,39.9409279 30.1345098,39.8141232 C30.3154902,39.7091943 30.4633333,39.7936493 30.4633333,40.0035071 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-21)"
            ></path>
            <path
              d="M30.2772549,40.1109953 C30.2745859,40.2147083 30.2211896,40.3104411 30.1345098,40.3669194 C30.0554902,40.4129858 29.9917647,40.3669194 29.9917647,40.2850237 C29.9944337,40.1813107 30.04783,40.0855779 30.1345098,40.0290995 C30.2135294,39.9855924 30.2772549,40.0214218 30.2772549,40.1109953 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-22)"
            ></path>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-23)"
              points="28.345098 38.0763981 28.334902 39.5479621 27.8072549 39.2792417 27.817451 37.8051185"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-24)"
              points="28.345098 38.0763981 28.334902 39.5479621 28.9721569 39.2792417 28.984902 37.8051185"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="#ACB3B7"
              points="29.5966667 39.3073934 27.8939216 40.3771564 27.8939216 38.0456872 29.5966667 37.9074882"
            ></polygon>
            <path
              d="M46.9682353,17.4028436 C46.9682353,17.4028436 34.1696078,22.6518483 30.6698039,24.4663507 C27.17,26.2808531 16.8872549,31.8190521 14.944902,32.9374408 C13.002549,34.0558294 6.62745098,37.9561137 4.91196078,39.7143128 C3.19647059,41.4725118 1.75882353,44.6920379 1.29745098,45.6184834 C0.836078431,46.5449289 -0.231960784,49.2654028 0.0484313725,49.4803791 C0.328823529,49.6953555 3.9127451,48.9685308 6.0845098,48.3799052 C8.25627451,47.7912796 16.0996078,43.6888152 17.8431373,42.5883412 C19.5866667,41.4878673 30.3001961,35.0232227 31.7429412,33.9432227 C33.1856863,32.8632227 41.5643137,25.9123223 43.295098,24.1566825 C45.0258824,22.4010427 47.2868627,19.7445498 47.2868627,19.7445498 C47.2868627,19.7445498 48.1764706,17.6408531 46.9682353,17.4028436 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-25)"
            ></path>
            <path
              d="M46.9682353,17.4028436 C46.9682353,17.4028436 34.1696078,22.6518483 30.6698039,24.4663507 C27.17,26.2808531 16.8872549,31.8190521 14.944902,32.9374408 C13.002549,34.0558294 6.62745098,37.9561137 4.91196078,39.7143128 C3.19647059,41.4725118 1.75882353,44.6920379 1.29745098,45.6184834 C0.836078431,46.5449289 -0.231960784,49.2654028 0.0484313725,49.4803791 C0.328823529,49.6953555 3.9127451,48.9685308 6.0845098,48.3799052 C8.25627451,47.7912796 16.0996078,43.6888152 17.8431373,42.5883412 C19.5866667,41.4878673 30.3001961,35.0232227 31.7429412,33.9432227 C33.1856863,32.8632227 41.5643137,25.9123223 43.295098,24.1566825 C45.0258824,22.4010427 47.2868627,19.7445498 47.2868627,19.7445498 C47.2868627,19.7445498 48.1764706,17.6408531 46.9682353,17.4028436 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-25)"
            ></path>
            <path
              d="M40.7231373,26.4881517 C41.4394118,23.8188626 41.2329412,21.4387678 40.7766667,20.9141232 C40.4962745,20.5890995 39.8054902,20.5865403 39.0407843,20.7170616 L39.0407843,20.7170616 C36.9760784,21.5948815 34.8629412,22.5085308 33.1780392,23.2763033 C34.1288235,23.1944076 34.9164706,23.3172512 35.3752941,23.7446445 C36.2521569,24.5661611 34.8654902,28.6686256 32.1737255,31.1203791 C29.4819608,33.5721327 19.1431373,41.1218957 13.6678431,44.4437915 C8.19254902,47.7656872 1.84803922,48.9762085 0.802941176,49.2090995 C0.541179452,49.2897262 0.258357107,49.2639955 0.0152941176,49.1374408 C-0.0101960784,49.3217062 -0.00509803922,49.447109 0.0484313725,49.4880569 C0.336470588,49.7004739 3.9127451,48.9762085 6.0845098,48.3875829 C8.25627451,47.7989573 16.0996078,43.6888152 17.8431373,42.5883412 C19.5866667,41.4878673 30.3001961,35.0232227 31.7429412,33.9432227 C32.7956863,33.1524171 37.5572549,29.2290995 40.7180392,26.4881517 L40.7231373,26.4881517 Z"
              id="airplane-Path"
              fill="#006C9B"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M38.6839216,22.0453081 C39.2217647,22.669763 39.3109804,25.7306161 37.9472549,28.8554502 C38.9209804,28.0364929 39.8794118,27.2200948 40.7231373,26.4881517 C41.4394118,23.8188626 41.2329412,21.4387678 40.7766667,20.9141232 C40.4962745,20.5890995 39.8054902,20.5865403 39.0407843,20.7170616 C37.9472549,21.1828436 36.8384314,21.6588626 35.7882353,22.1169668 C36.914902,21.7996209 38.2607843,21.5564929 38.6839216,22.0453081 Z"
              id="airplane-Path"
              fill="#90A7AC"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M33.6903922,24.4177251 C34.4678431,25.147109 33.0276471,29.5745972 30.807451,32.2745972 C31.3656863,31.8190521 31.8270588,31.4274882 32.1711765,31.1178199 C34.8654902,28.6660664 36.2496078,24.5636019 35.3727451,23.7420853 C34.9139216,23.3146919 34.1262745,23.1918483 33.1780392,23.2737441 C32.5688235,23.5501422 32.0131373,23.8086256 31.5390196,24.0415166 C32.4566667,23.8930806 33.2137255,23.9724171 33.6903922,24.4177251 Z"
              id="airplane-Path"
              fill="#ED1C24"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M44.7862745,0 L50.4272549,6.85876777 L45.1660784,10.4212322 C45.1660784,10.4212322 44.9341176,10.5952607 44.6996078,9.952891 C44.465098,9.31052133 42.3443137,1.50739336 42.3443137,1.50739336 L44.7862745,0 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-26)"
            ></path>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="45.8619608 9.94777251 44.3478431 5.56123223 47.9980392 3.90540284 47.967451 3.86957346 44.332549 5.52028436 43.0096078 1.68909953 42.9688235 1.70445498 45.8237255 9.97336493"
            ></polygon>
            <path
              d="M50.6158824,7.98739336 L46.4558824,18.0580095 C46.4558824,18.0580095 37.9039216,22.7107109 37.2386275,22.9052133 C36.5733333,23.0997156 36.5707843,22.7286256 36.5707843,22.7286256 C36.5707843,21.9147867 36.8052941,21.7330806 37.2411765,21.4208531 C37.6770588,21.1086256 39.6270588,19.9083412 39.9762745,19.4860664 C39.9762745,19.4860664 41.5311765,16.9012322 41.9084314,16.1590521 C42.2856863,15.416872 45.1176471,9.90938389 45.6605882,9.13649289 C46.2035294,8.3636019 47.6080392,7.60094787 48.6990196,7.13772512 C49.79,6.67450237 52.3033333,5.79412322 52.3747059,5.96815166 C52.3747059,5.96815166 52.517451,6.41601896 51.864902,6.81526066 C51.2123529,7.21450237 50.8198039,7.49345972 50.6158824,7.98739336 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-27)"
            ></path>
            <path
              d="M40.435098,25.9686256 C40.435098,25.9686256 38.0568627,26.7363981 37.2233333,27.5041706 C36.3898039,28.2719431 37.2488235,28.9347867 37.8070588,28.9629384 C38.3652941,28.99109 40.7843137,26.5981991 40.7843137,26.5981991 L40.435098,25.9686256 Z"
              id="airplane-Path"
              fill="#D2D9DD"
            ></path>
            <path
              d="M7.60372549,41.971564 L7.47372549,41.971564 C6.67928623,41.9735377 5.90089599,41.7470769 5.23058824,41.3189573 L5.12607843,41.2370616 C4.80065774,40.9741843 4.57264149,40.6091571 4.47862745,40.2005687 C3.85449173,40.9688533 3.29919492,41.791008 2.81921569,42.6574408 C2.93137255,43.2153555 3.15568627,43.7579147 3.58392157,44.0343128 C3.9254902,44.2492891 4.53980392,44.343981 5.28156863,44.343981 C6.98941176,44.343981 9.37784314,43.8321327 10.6345098,43.1232227 L10.9047059,41.3547867 C10.9047059,41.3547867 9.7372549,41.971564 7.60372549,41.971564 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-28)"
            ></path>
            <path
              d="M44.4676471,25.00891 C44.268154,24.8781251 44.0278249,24.8253138 43.7921569,24.8604739 L43.7921569,24.8604739 C43.75807,24.8684671 43.7247914,24.8796045 43.6927451,24.8937441 C43.2135294,25.0549763 41.2941176,25.7075829 40.3790196,26.152891 C39.3288235,26.6647393 38.6864706,27.1305213 38.1078431,27.4529858 C37.2652769,27.9815582 36.4688333,28.5808582 35.7270588,29.244455 L35.7270588,29.244455 C36.0565207,29.1005791 36.4370858,29.1414132 36.7288235,29.3519431 C37.5773802,29.9090849 38.1111845,30.8384363 38.1664706,31.8548815 C38.1664706,32.4 37.9421569,32.7531754 37.5903922,32.855545 L37.5903922,32.855545 C38.6272945,32.5135922 39.628663,32.0713261 40.5803922,31.5349763 C41.5541176,30.9412322 42.515098,30.462654 42.9586275,30.1401896 C43.6952941,29.6053081 45.115098,28.2207583 45.3954902,27.7882464 L45.3954902,27.7882464 C45.5898688,27.6021586 45.6901066,27.3378618 45.6682353,27.0690995 C45.6167619,26.2298659 45.1711119,25.465138 44.4676471,25.00891 L44.4676471,25.00891 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-29)"
            ></path>
            <path
              d="M38.1562745,31.8676777 C38.1562745,32.7890047 37.5113725,33.162654 36.7186275,32.7019905 C35.8684852,32.146002 35.3334833,31.216214 35.2784314,30.1990521 C35.2784314,29.2777251 35.9233333,28.9040758 36.7186275,29.3647393 C37.5671841,29.9218811 38.1009884,30.8512325 38.1562745,31.8676777 L38.1562745,31.8676777 Z"
              id="airplane-Path"
              fill="#D2D9DD"
            ></path>
            <path
              d="M38.0645098,31.8139336 C38.0645098,32.6763981 37.4603922,33.024455 36.7186275,32.5817062 C35.9233404,32.0616597 35.4232481,31.1915471 35.3727451,30.24 C35.3727451,29.3800948 35.9743137,29.0294787 36.7186275,29.4722275 C37.5136061,29.9925673 38.0135995,30.8625079 38.0645098,31.8139336 L38.0645098,31.8139336 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-30)"
            ></path>
            <path
              d="M36.8486275,32.5407583 C36.054042,32.0212354 35.5540443,31.1522381 35.5027451,30.2016114 C35.4645104,29.8527264 35.6226826,29.5114403 35.9131373,29.3161137 C35.5843137,29.4108057 35.3727451,29.7409479 35.3727451,30.2527962 C35.4232481,31.2043433 35.9233404,32.0744559 36.7186275,32.5945024 C36.9903717,32.7901005 37.344159,32.8289208 37.6515686,32.696872 C37.3739065,32.7559061 37.0842678,32.6995924 36.8486275,32.5407583 L36.8486275,32.5407583 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-31)"
              opacity="0.5"
              style={{ mixBlendMode: "screen" }}
            ></path>
            <path
              d="M36.7364706,29.4875829 C36.4848364,29.6027463 36.3386039,29.8703525 36.3770588,30.1453081 C36.4139522,30.8235744 36.770954,31.4434647 37.3380392,31.8139336 C37.548706,31.9758359 37.837443,31.9891057 38.0619608,31.8472038 C38.0619608,31.8472038 38.0619608,31.8267299 38.0619608,31.8139336 C38.011329,30.8716877 37.5197963,30.0090052 36.7364706,29.4875829 L36.7364706,29.4875829 Z"
              id="airplane-Path"
              fill="#555E61"
            ></path>
            <path
              d="M37.5801961,32.8683412 L37.5801961,32.8683412 C37.5801961,32.8683412 37.7739216,32.8069194 38.0772549,32.6943128 C38.256968,32.454788 38.3471851,32.1595009 38.3321569,31.86 C38.2677327,30.7554372 37.6776208,29.7495575 36.7466667,29.1574408 C36.5086446,29.0244789 36.2315378,28.9800728 35.9641176,29.0320379 C35.8239216,29.1523223 35.8060784,29.170237 35.7092157,29.2572512 L35.7092157,29.2572512 C36.0386776,29.1133753 36.4192426,29.1542094 36.7109804,29.3647393 C37.559537,29.9218811 38.0933413,30.8512325 38.1486275,31.8676777 C38.1562745,32.4127962 37.9319608,32.7582938 37.5801961,32.8683412 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-32)"
              opacity="0.5"
              style={{ mixBlendMode: "screen" }}
            ></path>
            <path
              d="M37.7739216,30.864455 C37.7739216,31.0922275 37.6133333,31.1843602 37.4196078,31.0691943 C37.2092,30.9327855 37.0765373,30.7035004 37.0627451,30.4524171 C37.0627451,30.2272038 37.2233333,30.1350711 37.4196078,30.2476777 C37.6284306,30.3852411 37.759895,30.6140898 37.7739216,30.864455 Z"
              id="airplane-Path"
              fill="#8897A0"
            ></path>
            <path
              d="M40.4988235,31.5912796 C40.6751462,31.3249693 40.7642591,31.0100339 40.7537255,30.6904265 C40.688983,29.4436425 40.0355691,28.3027135 38.994902,27.6193365 C38.7640675,27.4761607 38.5011328,27.3934441 38.2301961,27.3787678 L38.1613725,27.4197156 L38.1996078,27.4197156 C38.4708119,27.430794 38.7344237,27.5128414 38.9643137,27.6577251 C39.9911919,28.3314944 40.6375709,29.4555541 40.7052941,30.6853081 C40.7253489,31.0310179 40.6173025,31.3719539 40.4019608,31.6424645 L40.4988235,31.5912796 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <path
              d="M43.4684314,29.7358294 C43.5239337,29.5507671 43.5514301,29.3583832 43.55,29.1651185 C43.4859062,27.9174961 42.8311938,26.7759542 41.7886275,26.0940284 C41.562608,25.9482278 41.304402,25.8603494 41.0366667,25.8381043 L40.9398039,25.8790521 L40.9958824,25.8790521 C41.2672086,25.8894156 41.5310001,25.9715189 41.7605882,26.1170616 C42.7994461,26.7947994 43.450169,27.9328931 43.5092157,29.1753555 C43.5122845,29.3868761 43.4786448,29.5973178 43.4098039,29.7972512 L43.4684314,29.7358294 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <path
              d="M14.6033333,43.8295735 C14.5250193,43.8316892 14.4493368,43.8011095 14.3943137,43.7451185 C14.3162897,43.6576957 14.2767616,43.5423277 14.2847059,43.4252133 L14.2847059,42.4015166 C14.2795883,41.9913704 14.2489403,41.5819465 14.1929412,41.1756398 L14.0909804,40.489763 C14.0535023,40.1157908 14.2289966,39.752506 14.5447059,39.5505213 L15.5286275,38.9874882 C15.6059537,38.941452 15.6936692,38.9159125 15.7835294,38.9132701 C16.0003222,38.919692 16.1757241,39.0924536 16.1862745,39.3099526 L16.3366667,40.3029384 C16.3927519,40.7116341 16.4183134,41.123969 16.4131373,41.5364929 L16.3952941,42.2479621 C16.3625041,42.632411 16.1545619,42.9800562 15.8319608,43.189763 L14.8505882,43.7630332 C14.7751478,43.8059653 14.6900468,43.8288674 14.6033333,43.8295735 Z M15.7860784,38.9670142 C15.7031811,38.9687125 15.6222327,38.9925644 15.5515686,39.0361137 L14.5676471,39.5991469 C14.2667479,39.7907792 14.0991078,40.1363146 14.1343137,40.4923223 L14.2388235,41.1807583 C14.2927177,41.5889643 14.3225083,42.0000143 14.3280392,42.4117536 L14.3280392,43.4354502 C14.320919,43.540922 14.3557533,43.6449236 14.424902,43.7246445 C14.5439417,43.8181726 14.7111563,43.8181726 14.8301961,43.7246445 L15.8039216,43.1564929 C16.1137987,42.9545543 16.3132808,42.6201211 16.3443137,42.2505213 L16.3621569,41.5390521 C16.3672538,41.1299313 16.3416915,40.7210052 16.2856863,40.3157346 L16.1352941,39.3201896 C16.1277941,39.1284452 15.9768497,38.9735769 15.7860784,38.9618957 L15.7860784,38.9670142 Z"
              id="airplane-Shape"
              fill="#87949D"
            ></path>
            <path
              d="M21.8552941,36.9784834 L21.7941176,36.5792417 C21.7900423,36.5037736 21.7335155,36.441666 21.6590196,36.4308057 C21.7002447,36.4602023 21.7271113,36.5057797 21.7329412,36.5562085 L21.7915686,36.9554502 C21.8144425,37.1190558 21.8246691,37.2841936 21.8221569,37.4493839 L21.8221569,37.736019 C21.8105564,37.8871979 21.7282871,38.0239139 21.6003922,38.1045498 L21.2052941,38.3323223 C21.1746371,38.3509371 21.1391086,38.3598548 21.1033333,38.3579147 C21.1531506,38.3926289 21.2192023,38.3926289 21.2690196,38.3579147 L21.6666667,38.1327014 C21.7928123,38.0495511 21.8736996,37.9126245 21.8858824,37.7616114 L21.8858824,37.4749763 C21.8872462,37.3089646 21.8770251,37.1430609 21.8552941,36.9784834 L21.8552941,36.9784834 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-33)"
            ></path>
            <path
              d="M21.5927451,38.1045498 C21.72064,38.0239139 21.8029093,37.8871979 21.8145098,37.736019 L21.8145098,37.4493839 C21.8170221,37.2841936 21.8067955,37.1190558 21.7839216,36.9554502 L21.7252941,36.5562085 C21.7194643,36.5057797 21.6925976,36.4602023 21.6513725,36.4308057 C21.6105452,36.4261027 21.5693156,36.4351016 21.5341176,36.4563981 L21.1390196,36.6841706 C21.0142304,36.7626365 20.9449614,36.9057041 20.9605882,37.0527014 L21.0013725,37.3265403 C21.0242379,37.490237 21.0370073,37.6551959 21.0396078,37.8204739 L21.0396078,38.2299526 C21.036673,38.278117 21.0553789,38.3250692 21.0905882,38.3579147 C21.1263635,38.3598548 21.161892,38.3509371 21.192549,38.3323223 L21.5927451,38.1045498 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M19.4745098,38.3886256 L19.4133333,37.9868246 C19.4083093,37.9117598 19.3522458,37.8501613 19.2782353,37.8383886 C19.3187433,37.8680633 19.3447097,37.9136867 19.3496078,37.9637915 L19.4107843,38.3630332 C19.4336582,38.5266387 19.4438848,38.6917765 19.4413725,38.8569668 L19.4413725,39.1436019 C19.4293708,39.2953281 19.3473013,39.4326591 19.2196078,39.5146919 L18.8245098,39.7399052 C18.7938527,39.75852 18.7583243,39.7674377 18.722549,39.7654976 C18.7718701,39.8017686 18.8389142,39.8017686 18.8882353,39.7654976 L19.2833333,39.5402844 C19.409479,39.457134 19.4903662,39.3202075 19.502549,39.1691943 L19.502549,38.8825592 C19.5061236,38.7174311 19.4967482,38.5522766 19.4745098,38.3886256 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-34)"
            ></path>
            <path
              d="M19.2119608,39.504455 C19.3396543,39.4224221 19.4217237,39.2850912 19.4337255,39.1333649 L19.4337255,38.8467299 C19.4362378,38.6815395 19.4260111,38.5164018 19.4031373,38.3527962 L19.3419608,37.9535545 C19.3370626,37.9034497 19.3110962,37.8578264 19.2705882,37.8281517 C19.2294073,37.8231464 19.187804,37.8331349 19.1533333,37.8563033 L18.7582353,38.0815166 C18.6334461,38.1599824 18.5641771,38.30305 18.5798039,38.4500474 L18.6205882,38.7238863 C18.6434536,38.887583 18.656223,39.0525418 18.6588235,39.2178199 L18.6588235,39.6272986 C18.6547268,39.6756437 18.673629,39.7230888 18.7098039,39.7552607 C18.7455792,39.7572008 18.7811076,39.748283 18.8117647,39.7296682 L19.2119608,39.504455 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M24.2692157,35.5734597 L24.2156863,35.1818957 C24.2106623,35.1068309 24.1545988,35.0452324 24.0805882,35.0334597 C24.1216086,35.0626711 24.1477203,35.1085498 24.1519608,35.1588626 L24.2131373,35.5581043 C24.2360929,35.7217015 24.2463199,35.8868457 24.2437255,36.0520379 L24.2437255,36.3361137 C24.2321142,36.4879652 24.149948,36.6254579 24.0219608,36.7072038 L23.6268627,36.9324171 C23.5963628,36.9516412 23.5609037,36.9614315 23.524902,36.9605687 C23.5741495,36.9941122 23.6387917,36.9941122 23.6880392,36.9605687 L24.0856863,36.7327962 C24.2127018,36.6504835 24.2938684,36.513084 24.3049021,36.3617062 L24.3049021,36.0776303 C24.3049453,35.9089359 24.2930197,35.7404526 24.2692157,35.5734597 L24.2692157,35.5734597 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-35)"
            ></path>
            <path
              d="M24.0066667,36.7072038 C24.1346539,36.6254579 24.21682,36.4879652 24.2284314,36.3361137 L24.2284314,36.0520379 C24.2310258,35.8868457 24.2207988,35.7217015 24.1978431,35.5581043 L24.1366667,35.1588626 C24.1324262,35.1085498 24.1063145,35.0626711 24.0652941,35.0334597 C24.0244667,35.0287567 23.9832371,35.0377556 23.9480392,35.0590521 L23.5529412,35.2842654 C23.4286202,35.3631526 23.3595023,35.5059082 23.3745098,35.6527962 L23.4152941,35.9291943 C23.4382573,36.0928804 23.4510272,36.2578453 23.4535294,36.423128 L23.4535294,36.8326066 C23.4505946,36.880771 23.4693004,36.9277232 23.5045098,36.9605687 C23.5405116,36.9614315 23.5759706,36.9516412 23.6064706,36.9324171 L24.0066667,36.7072038 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M26.6805882,34.1837915 L26.6219608,33.7845498 C26.6171589,33.7088929 26.5612731,33.6464309 26.4868627,33.6335545 C26.5275033,33.6642464 26.5534074,33.7106892 26.5582353,33.7615166 L26.6194118,34.1607583 C26.6410968,34.3236245 26.6513182,34.487823 26.65,34.6521327 L26.65,34.9387678 C26.6379982,35.090494 26.5559288,35.227825 26.4282353,35.3098578 L26.0331373,35.5350711 C26.0021606,35.5531823 25.9670212,35.5628844 25.9311765,35.5632227 C25.9801988,35.5974944 26.0452914,35.5974944 26.0943137,35.5632227 L26.4894118,35.3380095 C26.6179968,35.2568499 26.7003617,35.1190246 26.7111765,34.9669194 L26.7111765,34.6828436 C26.7141222,34.5159429 26.7038941,34.3490702 26.6805882,34.1837915 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-36)"
            ></path>
            <path
              d="M26.4205882,35.3175355 C26.5482817,35.2355027 26.6303512,35.0981717 26.6423529,34.9464455 L26.6423529,34.6598104 C26.6436712,34.4955008 26.6334497,34.3313022 26.6117647,34.168436 L26.5505882,33.7691943 C26.5457604,33.7183669 26.5198563,33.6719241 26.4792157,33.6412322 C26.4382251,33.6388496 26.3974434,33.6486409 26.3619608,33.6693839 L25.9668627,33.8945972 C25.8420735,33.973063 25.7728046,34.1161306 25.7884314,34.263128 L25.8292157,34.5395261 C25.8522786,34.7032012 25.865049,34.8681724 25.867451,35.0334597 L25.867451,35.4403791 C25.8641026,35.4893586 25.8828137,35.5372631 25.9184314,35.5709005 C25.9542761,35.5705621 25.9894155,35.56086 26.0203922,35.5427488 L26.4205882,35.3175355 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M29.0945098,32.7838863 L29.0358824,32.3846445 C29.029957,32.3099696 28.9743271,32.2488474 28.9007843,32.2362085 C28.9412923,32.2658832 28.9672587,32.3115066 28.9721569,32.3616114 L29.0333333,32.7608531 C29.0549458,32.9245843 29.0651666,33.0896287 29.0639216,33.2547867 L29.0639216,33.5414218 C29.0482802,33.6919533 28.9670876,33.8278169 28.8421569,33.9125118 L28.4470588,34.1377251 C28.4164018,34.1563399 28.3808733,34.1652576 28.345098,34.1633175 C28.3936191,34.1991567 28.4597142,34.1991567 28.5082353,34.1633175 L28.9033333,33.9381043 C29.0313206,33.8563584 29.1134867,33.7188657 29.125098,33.5670142 L29.125098,33.2803791 C29.1277423,33.1143355 29.1175154,32.948337 29.0945098,32.7838863 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-37)"
            ></path>
            <path
              d="M28.8345098,33.9125118 C28.9594405,33.8278169 29.0406331,33.6919533 29.0562745,33.5414218 L29.0562745,33.2547867 C29.0575196,33.0896287 29.0472987,32.9245843 29.0256863,32.7608531 L28.9645098,32.3616114 C28.9596116,32.3115066 28.9336453,32.2658832 28.8931373,32.2362085 C28.8519563,32.2312033 28.810353,32.2411918 28.7758824,32.2643602 L28.3782353,32.4895735 C28.2548871,32.5691168 28.1868172,32.7117455 28.2023529,32.8581043 L28.2431373,33.1319431 C28.2651627,33.2956917 28.2770809,33.4606511 28.2788235,33.6258768 L28.2788235,34.0379147 C28.2758887,34.0860791 28.2945946,34.1330313 28.3298039,34.1658768 C28.3655792,34.1678169 28.4011076,34.1588992 28.4317647,34.1402844 L28.8345098,33.9125118 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M31.5084314,31.3865403 L31.4472549,30.9872986 C31.4434103,30.9126504 31.3881967,30.8508189 31.3147059,30.8388626 C31.3552139,30.8685373 31.3811803,30.9141606 31.3860784,30.9642654 L31.4472549,31.3635071 C31.4688674,31.5272383 31.4790882,31.6922827 31.4778431,31.8574408 L31.4778431,32.1415166 C31.4647003,32.2928736 31.3829105,32.4297367 31.2560784,32.5126066 L30.8609804,32.7403791 C30.830205,32.7587068 30.7947647,32.7676025 30.7590196,32.7659716 C30.8075407,32.8018108 30.8736358,32.8018108 30.9221569,32.7659716 L31.3172549,32.5407583 C31.4449484,32.4587255 31.5270178,32.3213945 31.5390196,32.1696682 L31.5390196,31.8830332 C31.5403835,31.7170215 31.5301624,31.5511177 31.5084314,31.3865403 L31.5084314,31.3865403 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-38)"
            ></path>
            <path
              d="M31.2484314,32.5023697 C31.3752634,32.4194997 31.4570533,32.2826367 31.4701961,32.1312796 L31.4701961,31.8472038 C31.4714411,31.6820457 31.4612203,31.5170013 31.4396078,31.3532701 L31.3784314,30.9540284 C31.3735332,30.9039237 31.3475668,30.8583003 31.3070588,30.8286256 C31.2662124,30.8235206 31.2248529,30.8325479 31.1898039,30.854218 L30.7921569,31.0794313 C30.6697222,31.1597813 30.6019373,31.3018128 30.6162745,31.4479621 L30.6570588,31.7243602 C30.6790843,31.8881087 30.6910025,32.0530681 30.6927451,32.2182938 L30.6927451,32.6277725 C30.6898103,32.6759369 30.7085161,32.7228891 30.7437255,32.7557346 C30.7794706,32.7573655 30.8149109,32.7484699 30.8456863,32.7301422 L31.2484314,32.5023697 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="49.1170588 8.85753555 44.4319608 18.4802844 46.7515686 17.343981 46.7770588 17.2825592 44.5262745 18.3855924 49.1552941 8.87800948"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="48.6480392 11.6727014 46.9962745 15.4680569 47.6284314 15.2223697 47.6513725 15.1660664 47.0778431 15.3887204 48.6760784 11.7162085 49.0762745 11.7162085 49.0941176 11.6727014"
            ></polygon>
            <path
              d="M36.8027451,22.3319431 L36.787451,22.2909953 C36.8180392,22.2909953 40.0145098,20.9448341 40.7562745,20.0951659 C41.4980392,19.2454976 46.1372549,9.80445498 46.1831373,9.70720379 L46.2239216,9.72767773 C46.1754902,9.82236967 41.5362745,19.2685308 40.7894118,20.1233175 C40.042549,20.9781043 36.8358824,22.3191469 36.8027451,22.3319431 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <path
              d="M8.1772549,42.130237 C7.99372549,42.1404739 7.80254902,42.1455924 7.60372549,42.1455924 L7.47372549,42.1455924 C6.68324662,42.1353767 5.90880639,41.9202733 5.2254902,41.5211374 L3.77764706,43.9319431 C4.26021847,44.1130045 4.77477467,44.1922834 5.28921569,44.1648341 C6.21384112,44.1535941 7.13477497,44.0455208 8.03705882,43.8423697 L8.1772549,42.130237 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M5.02666667,41.3803791 C4.73055426,41.0881307 4.50324811,40.7331254 4.36137255,40.341327 C3.8272369,41.0182875 3.3441323,41.7343018 2.91607843,42.4834123 C2.98372902,42.9280262 3.17251565,43.3451994 3.46156863,43.6888152 L5.02666667,41.3803791 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M8.35058824,42.12 L8.35058824,43.7630332 C9.09092483,43.5948 9.80819331,43.3369915 10.4866667,42.9952607 L10.7058824,41.6388626 C9.94896107,41.9158651 9.15513828,42.0780265 8.35058824,42.12 Z"
              id="airplane-Path"
              fill="#485154"
            ></path>
            <path
              d="M44.8627451,10.5184834 L44.8627451,10.5184834 C43.815098,12.4404739 42.1888235,15.6369668 41.9084314,16.1718483 C41.5311765,16.9012322 39.9762745,19.4988626 39.9762745,19.4988626 C39.6270588,19.9211374 37.6821569,21.1214218 37.2411765,21.4336493 C36.8001961,21.7458768 36.5707843,21.9275829 36.5707843,22.7414218 C36.5707843,22.7414218 36.5707843,23.1125118 37.2386275,22.9180095 C37.4170588,22.8668246 38.1741176,22.4880569 39.1809804,21.9659716 L39.1809804,21.9659716 L40.8582353,21.0830332 L40.8735294,21.0830332 L46.0429412,10.8204739 C45.624866,10.8882005 45.1975355,10.7788545 44.8627451,10.5184834 Z"
              id="airplane-Path"
              fill="#A9C0C5"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M44.8627451,10.5184834 L44.8627451,10.5184834 C43.815098,12.4404739 42.1888235,15.6369668 41.9084314,16.1718483 C41.5311765,16.9012322 39.9762745,19.4988626 39.9762745,19.4988626 C39.7443137,19.7803791 38.7986275,20.4073934 38.0670588,20.8859716 C38.1371131,20.940473 38.2043262,20.9985594 38.2684314,21.06 C38.5666667,21.3363981 38.7782353,22.1783886 38.7782353,22.1809479 C39.384902,21.8687204 40.1164706,21.4822749 40.8862745,21.0727962 L46.0556863,10.810237 C45.6349062,10.8845463 45.2023496,10.7787574 44.8627451,10.5184834 Z"
              id="airplane-Path"
              fill="#2771B0"
            ></path>
            <path
              d="M52.3747059,5.96815166 C52.3033333,5.79412322 49.787451,6.66938389 48.6990196,7.13772512 C47.6105882,7.60606635 46.2111765,8.3636019 45.6605882,9.13649289 C45.5178431,9.33611374 45.2247059,9.85052133 44.8627451,10.5184834 C45.1176471,10.7283412 45.5892157,10.9254028 46.3921569,10.7385782 C47.3429412,10.5159242 49.1960784,9.40521327 50.3252941,8.69118483 L50.6209804,7.97971564 C50.824902,7.48578199 51.22,7.21194313 51.872549,6.80758294 C52.525098,6.40322275 52.3747059,5.96815166 52.3747059,5.96815166 Z"
              id="airplane-Path"
              fill="#2771B0"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M52.3747059,5.96815166 C52.3033333,5.79412322 49.787451,6.66938389 48.6990196,7.13772512 C47.6105882,7.60606635 46.2111765,8.3636019 45.6605882,9.13649289 C45.11,9.90938389 42.2882353,15.4399052 41.9084314,16.1718483 C41.5286275,16.9037915 39.9762745,19.4988626 39.9762745,19.4988626 C39.6270588,19.9211374 37.6821569,21.1214218 37.2411765,21.4336493 C36.8001961,21.7458768 36.5707843,21.9275829 36.5707843,22.7414218 C36.5733158,22.790541 36.5921614,22.8373934 36.6243137,22.8745024 C36.6498039,21.6998104 38.864902,20.7938389 39.5888235,20.2794313 C40.3535294,19.736872 40.4682353,19.376019 40.8047059,18.9204739 C41.1411765,18.4649289 44.6078431,11.7725118 45.2705882,10.4417062 C45.9333333,9.11090047 47.2690196,8.52995261 47.8935294,8.12047393 C48.4288235,7.76985782 51.4978431,6.51327014 52.3823529,6.1549763 C52.3922678,6.09281428 52.3896676,6.02928726 52.3747059,5.96815166 Z"
              id="airplane-Path"
              fill="#FFFFFF"
              opacity="0.5"
              style={{ mixBlendMode: "screen" }}
            ></path>
            <path
              d="M51.214902,7.21450237 C51.214902,7.21450237 45.5815686,9.89402844 45.4184314,10.2369668 C45.2552941,10.5799052 45.3470588,10.6566825 45.4898039,10.8025592 C45.632549,10.948436 59.4941176,12.0821801 59.4941176,12.0821801 L62.2368627,10.492891 L51.214902,7.21450237 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-39)"
            ></path>
            <path
              d="M58.7498039,12.0130806 C59.3768627,11.583128 60.5927451,10.7641706 61.49,10.2676777 L61.4313725,10.249763 C60.7227451,10.6464455 59.8254902,11.2325118 59.1754902,11.6701422 L52.8386275,10.6925118 L55.7139216,8.55042654 L55.6603922,8.53507109 L52.777451,10.6822749 L46.3921569,9.69696682 L46.3921569,9.74047393 L59.1372549,11.7085308 L58.6962745,12.0079621 L58.7498039,12.0130806 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <path
              d="M23.2572549,39.9241706 C23.2572549,39.9241706 22.2656863,39.6682464 23.015098,38.6778199 C23.6345098,37.8588626 30.1268627,34.3424645 31.9366667,33.797346 L34.8552941,35.7398104 L63.1494118,43.6248341 L59.6470588,45.8795261 L23.2572549,39.9241706 Z"
              id="airplane-Path"
              fill="url(#airplane-linearGradient-40)"
            ></path>
            <polygon
              id="airplane-Path"
              fill="url(#airplane-linearGradient-41)"
              points="63.9854902 41.0067299 59.6496078 45.8795261 63.1494118 43.6325118 64.7858824 40.4590521"
            ></polygon>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="54.1131373 42.3017062 54.8319608 41.3163981 54.7860784 41.3036019 54.0698039 42.2914692 43.5907843 39.4660664 44.5262745 38.4449289 44.4778431 38.4295735 43.5423529 39.4532701 33.5476471 36.7583886 34.9088235 35.7654028 34.8552941 35.7500474 33.4482353 36.7788626 61.8876471 44.4437915 61.9360784 44.4105213"
            ></polygon>
            <path
              d="M33.4329412,34.8056872 L33.3870588,34.7749763 C30.2339216,35.9522275 26.395098,38.1019905 25.4290196,38.7827488 C24.9541637,39.0996687 24.6057755,39.5745068 24.445098,40.1237915 L24.4884314,40.1237915 C24.6449715,39.585547 24.9874997,39.120962 25.4545098,38.8134597 C26.4205882,38.1327014 30.2772549,35.9803791 33.4329412,34.8056872 Z"
              id="airplane-Path"
              fill="#C6CDD1"
            ></path>
            <polygon
              id="airplane-Path"
              fill="#C6CDD1"
              points="25.0721569 39.1896682 60.2613725 45.4879621 60.314902 45.4521327 25.0798039 39.1487204"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationAirplane.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationAirplane.defaultProps = {
  size: 70,
};

export { IllustrationAirplane as default } from "./Airplane";
