import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { UserInfo } from "../users/usersInfoSlice";
import { activitiesSchema, activitySchema } from "./activitiesNormalization";
import ActivitiesRepository from "./activitiesRepository";

//#region Type
export const activityTypeValues = {
  REFUELING: "REFUELING",
  PARKING: "PARKING",
  TOLL: "TOLL",
  CRASH: "CRASH",
  MISSION: "MISSION",
  OTHER: "OTHER",
};

export const activityStatusValues = {
  COMPLETED: "COMPLETED",
  MANAGED: "MANAGED",
  DRAFT: "DRAFT",
  ARCHIVED: "ARCHIVED",
};

export type ActivityType = keyof typeof activityTypeValues;
export type ActivityStatusType = keyof typeof activityStatusValues;

export type Activity = {
  id: number;
  name: string;
  type: ActivityType;
  status: ActivityStatusType;
  tenant: number;
  user: number;
  driver: number;
  vehicle: number;
  latitude: number;
  longitude: number;
  address: string;
  date: Date;
  note: string;
  dynamicFields: {
    address: string;
    refuelQuantity: string;
    cost: string;
    selectedPayment: string;
    attached: string;
  };
};

export type ActivityUpdateRequest = {
  type: ActivityType;
  status: ActivityStatusType;
  user: UserInfo;
  latitude: number;
  longitude: number;
  date: Date;
};

//#endregion Type

//#region API

export const getActivitiesPaginationAsync = createAsyncThunk(
  "activities/getActivitiesPaginationAsync",
  async (data: { queryParams?: string }, { rejectWithValue, dispatch }) => {
    try {
      const activitiesRepository = new ActivitiesRepository();
      const response = await activitiesRepository.getActivities(
        data.queryParams
      );
      const activities = _.get(response, Config.ACTIVITIES_RESPONSE_PATH);
      const totalPages = _.get(response, Config.ACTIVITIES_TOTAL_PAGES);
      const totalElements = _.get(response, Config.ACTIVITIES_TOTAL_ELEMENTS);
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const normalizedData = normalize(activities, activitiesSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateActivityAsync = createAsyncThunk(
  "activities/updateActivityAsync",
  async (
    data: { activity: ActivityUpdateRequest; id: number },
    { rejectWithValue }
  ) => {
    try {
      const activitiesRepository = new ActivitiesRepository();
      const response = await activitiesRepository.updateActivity(
        data.activity,
        data.id
      );
      const activities = _.get(response, Config.ACTIVITY_RESPONSE_PATH);
      const normalizedData = normalize(activities, activitySchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deleteActivityAsync = createAsyncThunk(
  "activities/deleteActivityAsync",
  async (activityId: number, { rejectWithValue }) => {
    try {
      const activitiesRepository = new ActivitiesRepository();
      const response = await activitiesRepository.deleteActivity(activityId);
      const activity = _.get(response, Config.ACTIVITY_RESPONSE_PATH);
      return activity;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload?.activity
    ? activitiesAdapter.setAll(state, action.payload.activity)
    : activitiesAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const activitiesAdapter = createEntityAdapter<Activity>({
  selectId: (driver) => driver.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: activitiesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
    activitiesEmptyState: (state) => {
      activitiesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getActivitiesPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getActivitiesPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getActivitiesPaginationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateActivityAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          activitiesAdapter.upsertMany(state, action.payload.activity ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        updateActivityAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updateActivityAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteActivityAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          activitiesAdapter.upsertMany(state, action.payload.activity ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(
        deleteActivityAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(deleteActivityAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});
//#endregion Slice

//#region Status
export const activitiesSelectors = activitiesAdapter.getSelectors<RootState>(
  (state) => state.activities
);

export const selectActivitiesSliceStatus = (state: any) =>
  state.activities.status;
export const selectActivitiesSliceReasonCode = (state: any) =>
  state.activities.reasonCode;
export const selectActivitiesSlicePage = (state: any) =>
  state.activities.totalPages;
export const selectActivitiesSliceTotalElements = (state: any) =>
  state.activities.totalElements;

export const { activitiesEmptyState, setNumberOfPages, setNumberOfElements } =
  activitiesSlice.actions;

export default activitiesSlice.reducer;
