import HttpClient from "../../../utils/HttpClient";

export default class PublicTransportsStatusRepository extends HttpClient {
  constructor() {
    super(true);
  }

  /**
   *
   * @param fleetId
   * @param id
   */
  getPublicTransport(fleetId: number, id: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/fleets/" +
        fleetId +
        "/publicTransports/" +
        id +
        "/status"
    );
  }
}
