import { schema } from "normalizr";
import { tenantSchema } from "../tenants/tenantNormalization";

export const geofenceCategorySchema: schema.Entity<any> = new schema.Entity(
  "geofenceCategory",
  {
    tenant: tenantSchema,
  },
  { idAttribute: "id" }
);

export const geofenceCategoriesSchema = new schema.Array(
  geofenceCategorySchema
);
