import i18next from "i18next";
import PropTypes from "prop-types";
import { Button } from "../Button/Button";
import { DropdownButton } from "../Button/DropdownButton";
import { IconDownload } from "../Icon/Line/Download";

export const ModalFooter = ({
  primaryAction,
  primaryLabel,
  secondaryAction,
  secondaryLabel,
  secondaryIconLabel,
  loadingPrimaryButton,
  disablePrimaryButton,
  disableSecondaryButton,
  hasBack,
  downloadButtonList,
  isDanger,
  backAction,
  iconFooter,
  padding,
}) => {
  const secondaryActionTrigger = () => {
    secondaryAction();
  };

  const primaryActionTrigger = () => {
    primaryAction();
  };

  return (
    <footer
      className={
        hasBack ? "mn-modal__dialog-footer-back" : "mn-modal__dialog-footer"
      }
      style={padding ? { paddingTop: padding } : null}
    >
      {secondaryLabel && secondaryAction && (
        <Button
          aspect="secondary"
          label={secondaryLabel}
          onClick={secondaryActionTrigger}
          disabled={disableSecondaryButton}
        />
      )}
      {secondaryIconLabel && (
        <Button
          aspect="secondary"
          label={secondaryIconLabel}
          onClick={secondaryActionTrigger}
        >
          <IconDownload size={14} color="--global-colors-ink-ink" />
        </Button>
      )}
      {hasBack && backAction && (
        <Button
          size="regular"
          aspect="secondary"
          label={i18next.t("common.back")}
          onClick={backAction}
        />
      )}
      {primaryLabel && primaryAction && (
        <Button
          aspect={isDanger ? "danger" : "primary"}
          label={primaryLabel}
          disabled={disablePrimaryButton}
          isLoading={loadingPrimaryButton}
          onClick={primaryActionTrigger}
        >
          {iconFooter}
        </Button>
      )}
      {downloadButtonList && downloadButtonList.length > 0 && (
        <DropdownButton
          aspect="primary"
          size="regular"
          label={i18next.t("common.download")}
          list={downloadButtonList}
        >
          <IconDownload size={14} color="--global-colors-ui-white" />
        </DropdownButton>
      )}
    </footer>
  );
};

ModalFooter.propTypes = {
  primaryAction: PropTypes.func,
  primaryLabel: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryLabel: PropTypes.string,
  secondaryIconLabel: PropTypes.string,
  loadingPrimaryButton: PropTypes.bool,
  disablePrimaryButton: PropTypes.bool,
  disableSecondaryButton: PropTypes.bool,
  hasBack: PropTypes.bool,
  isDanger: PropTypes.bool,
  backAction: PropTypes.func,
  padding: PropTypes.string,
  downloadButtonList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  iconFooter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
