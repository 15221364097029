import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { Button } from "../../../ui/Button/Button";
import { TextFieldDropdown } from "../../../ui/Chat/TextFieldDropdown";
import Form from "../../../ui/Forms/Form";
import { Switch } from "../../../ui/Forms/Switch";
import TextField from "../../../ui/Forms/TextField";
import { IconClock } from "../../../ui/Icon/Line/Clock";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { ToastNotification } from "../../../utils/ToastNotification";
import { convertWeekOfMontInInteger } from "../../../utils/Utils";
import {
  AddressBook,
  addressBookSelectors,
  getAddressBooksAsync,
} from "../../address/addressBookSlice";
import { ResourceProps } from "../../components/AdminPrivilegesResourceTab";
import {
  Event,
  eventsSelectors,
  getEventsAsync,
} from "../../event/eventsSlice";
import { EventSourceEnum } from "../../log/logSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import "./AdminNotificationFilterBar.css";
import "./AdminNotificationManagement.css";
import AdminNotificationRuleEntities, {
  SelectedEntities,
} from "./AdminNotificationRuleEntities";
import { AdminNotificationRuleEventTemplate } from "./AdminNotificationRuleEventTemplate";
import AdminNotificationRulePeriod from "./AdminNotificationRulePeriod";
import AddressBookModals from "./modals/AddressBookModal";
import {
  getNotificationDefaultTemplateAsync,
  notificationDefaultTemplateSelectors,
} from "./notificationDefaultTemplateSlice";
import {
  ChannelType,
  NotificationChannelEnum,
  NotificationRuleAggregation,
  NotificationRuleAggregationCreateRequest,
  NotificationRuleAggregationUpdateRequest,
  NotificationRuleEntityDTO,
  NotificationRuleType,
  NotificationTemplateDTO,
  createNotificationRuleAsync,
  editNotificationRuleAsync,
  getNotificationRule,
  notificationRuleAggregationsSelectors,
  notificationsRuleAggregationEmptyState,
  selectNotificationRuleAggregationSliceReasonCode,
  selectNotificationRuleAggregationSliceStatus,
} from "./notificationRuleAggregationSlice";
import {
  NotificationRuleDestination,
  notificationRuleDestinationsSelectors,
} from "./notificationRuleDestinationSlice";
import {
  NotificationRule,
  notificationRulesSelectors,
} from "./notificationRuleSlice";

interface CreateEditOrViewNotificationRuleProps {
  permissions: UserPermissions;
}

interface DropDownItem {
  id?: number | string;
  title: string;
  iconTitle?: JSX.Element;
  subTitle?: string;
  type?: string;
  phone?: string;
  icon?: JSX.Element;
  iconSubTitle?: JSX.Element;
  clickCallback?: (id: number) => void;
}

const CreateEditOrViewNotificationRule: React.FC<CreateEditOrViewNotificationRuleProps> =
  ({ permissions }) => {
    const navigate = useNavigate();
    const notificationRuleViewRoute = useMatch(
      "/admin/notifications/view-rule/:notificationRuleId"
    );
    const notificationRuleEditRoute = useMatch(
      "/admin/notifications/edit-rule/:notificationRuleId"
    );
    const notificationRuleRoute =
      notificationRuleViewRoute ?? notificationRuleEditRoute;
    const notificationRuleAggregationId = parseInt(
      notificationRuleRoute?.params?.notificationRuleId ?? "-1"
    );
    const location = useLocation();
    const { pathname } = location;

    const isAddRule = pathname.includes("/add-rule");
    const isViewRule = pathname.includes("/view-rule");
    const isEditRule = pathname.includes("/edit-rule");
    const [manageEntities, setManageEntities] = useState(false);
    const [manageTemplates, setManageTemplates] = useState(false);
    const [modifiedPrivilege, setModifiedPrivilege] =
      useState<SelectedEntities>({} as SelectedEntities);
    const [privilegesHasChanged, setPrivilegesHasChanged] = useState(false);
    const [vehiclesCanSee, setVehiclesCanSee] = useState<ResourceProps[]>(
      [] as ResourceProps[]
    );
    const [driversCanSee, setDriversCanSee] = useState<ResourceProps[]>(
      [] as ResourceProps[]
    );
    const [geofencesCanSee, setGeofencesCanSee] = useState<ResourceProps[]>(
      [] as ResourceProps[]
    );
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const templates: NotificationTemplateDTO[] = useAppSelector(
      notificationDefaultTemplateSelectors.selectAll
    );

    useEffect(() => {
      store.dispatch(getAddressBooksAsync({}));
      store.dispatch(getEventsAsync());
      store.dispatch(
        getNotificationDefaultTemplateAsync({
          queryParams: "&language=" + preferencesContext.language,
        })
      );
      return () => {
        // empty state of slices
        store.dispatch(notificationsRuleAggregationEmptyState());
      };
    }, []);

    //#region Upload Data
    const [id, setId] = useState<number>(-1);

    const notificationRuleAggregation: NotificationRuleAggregation =
      useAppSelector((state) =>
        notificationRuleAggregationsSelectors.selectById(state, id)
      ) ?? ({} as NotificationRuleAggregation);

    const notificationRuleAggregationSliceStatus = useAppSelector(
      selectNotificationRuleAggregationSliceStatus
    );
    const notificationRuleAggregationSliceReasonCode = useAppSelector(
      selectNotificationRuleAggregationSliceReasonCode
    );
    const notificationRules: NotificationRule[] = useAppSelector(
      notificationRulesSelectors.selectAll
    );
    const addressBookList: AddressBook[] = useAppSelector(
      addressBookSelectors.selectAll
    );

    useEffect(() => {
      if (notificationRuleRoute !== null) {
        if (notificationRuleAggregationId) {
          setId(notificationRuleAggregationId);
          store.dispatch(
            getNotificationRule({
              aggregationRuleId: notificationRuleAggregationId,
            })
          );
          return;
        }
      }
    }, [navigate, notificationRuleRoute]);

    useEffect(() => {
      notificationRuleAggregation?.notificationRulesEntities?.length > 0 &&
        setManageEntities(true);
    }, [notificationRuleAggregation]);

    useEffect(() => {
      templates.filter(
        (template) =>
          template.notificationRuleAggregationId !== null &&
          template.notificationRuleAggregationId !== undefined
      ).length > 0 && setManageTemplates(true);
    }, [templates]);

    // states for each field to fill or view
    const [name, setName] = useState<string>("");
    const [frequency, setFrequency] = useState<number>(0);
    const [events, setEvents] = useState<DropDownItem[]>();
    const [channels, setChannels] = useState<DropDownItem[]>();
    const [contacts, setContacts] = useState<DropDownItem[]>();
    const [, setType] = useState<NotificationRuleType>("CUSTOM");

    // states for each field for edit
    const [newName, setNewName] = useState<string>("");
    const [newFrequency, setNewFrequency] = useState<number>(0);
    const [updatedEvents, setUpdatedEvents] = useState<DropDownItem[]>();
    const [updatedContacts, setUpdatedContacts] = useState<DropDownItem[]>();
    const [updatedChannels, setUpdatedChannels] = useState<DropDownItem[]>();
    const eventNames: Event[] = useAppSelector(eventsSelectors.selectAll);
    const [contactsValues, setContactsValues] = useState<DropDownItem[]>(
      [] as DropDownItem[]
    );

    // #region Create Timed notifications
    const [startDate, setStartDate] = useState();
    const [
      valueDropdownReceiveNotification,
      setValueDropdownReceiveNotification,
    ] = useState<any>();
    const [valueDropdownDaysOfWeek, setValueDropdownDaysOfWeek] =
      useState<any[]>();
    const [
      valueDropdownDaysOfWeekinMonthly,
      setValueDropdownDaysOfWeekinMonthly,
    ] = useState<any>();
    const [valueTextRecursEvery, setValueTextRecursEvery] =
      useState<number | null>();
    const [monthSelected, setMonthSelected] = useState<any>();
    const [valueMonthlyDate, setValueMonthlyDate] = useState<any>();
    const [valueMonthlyRange, setValueMonthlyRange] = useState<any>();
    const [timeSlotList, setTimeSlotList] = useState([]);

    // #endregion Create Timed notifications

    const [createNotificationRule, setCreateNotificationRule] =
      useState<NotificationRuleAggregationCreateRequest>(
        {} as NotificationRuleAggregationCreateRequest
      );
    const [editNotificationRule, setEditNotificationRule] =
      useState<NotificationRuleAggregationUpdateRequest>(
        {} as NotificationRuleAggregationUpdateRequest
      );

    const [eventTemplatesMap, setEventTemplates] = useState<any>();

    const [openAddressBookModal, setOpenAddressBookModal] = useState(false);
    const [currentAction, setCurrentAction] = useState("");
    const [contactsHeight, setContactsHeight] = useState<number>();
    const [eventsHeight, setEventsHeight] = useState<number>();
    const [channelsHeight, setChannelsHeight] = useState<number>();

    const [eventTypeValues, setEventTypeValues] = useState<DropDownItem[]>(
      _.sortBy(
        eventNames.map((key) => {
          const labelEventType =
            t("eventType." + key.name) !== "eventType." + key.name
              ? t("eventType." + key.name)
              : key.name
              ? key.name.split("_").join(" ").toLowerCase()
              : "";
          return {
            title: labelEventType,
            id: key.name,
          };
        }),
        "id"
      )
    );

    useEffect(() => {
      if (eventNames && eventNames.length > 0) {
        setEventTypeValues(
          _.sortBy(
            eventNames.map((key) => {
              const labelEventType =
                t("eventType." + key.name) !== "eventType." + key.name
                  ? t("eventType." + key.name)
                  : key.name
                  ? key.name.split("_").join(" ").toLowerCase()
                  : "";
              return {
                title: labelEventType,
                id: key.name,
              };
            }),
            "id"
          )
        );
      }
    }, [eventNames]);

    const [channelTypeValues, setChannelTypeValues] = useState<DropDownItem[]>(
      _.keys(NotificationChannelEnum).map((key) => {
        const labelChannelType = t("channelType." + key);
        return {
          title: labelChannelType,
          id: key,
        };
      })
    );

    const addressBookCreateRoute = useMatch(
      "admin/notifications/add-rule/:action"
    );
    const addressBookEditRoute = useMatch(
      "admin/notifications/edit-rule/" +
        notificationRuleAggregation.id +
        "/:action"
    );
    const addressBookRoute = addressBookCreateRoute ?? addressBookEditRoute;

    //#region useEffects
    useEffect(() => {
      if (addressBookRoute !== null) {
        setOpenAddressBookModal(true);
        setCurrentAction(addressBookRoute.params.action ?? "");
      } else if (isAddRule || isEditRule) {
        setOpenAddressBookModal(false);
      }
    }, [navigate, addressBookRoute]);

    useEffect(() => {
      if (addressBookList.length > 0) {
        const contactsValueTemp = addressBookList.map((addressBook) => {
          return {
            title: addressBook.firstName + " " + addressBook.lastName,
            id: addressBook.id,
          };
        });
        setContactsValues(contactsValueTemp);
      }
    }, [addressBookList]);

    function chainTimes(timesList: any[]) {
      const timesString = timesList.map(
        (time: any) => `${time.startTime} | ${time.endTime}`
      );
      const finalString = timesString.join(" & ");
      return finalString;
    }

    const createOrEditScheduledNotificationRequestBody = (
      startDate: any,
      valueDropdownReceiveNotification: any,
      valueTextRecursEvery: any,
      valueDropdownDaysOfWeek: any,
      valueDropdownDaysOfWeekinMonthly: any,
      monthSelected: any,
      valueMonthlyDate: any,
      valueMonthlyRange: any,
      timeSlotList: any
    ) => {
      if (
        startDate &&
        valueDropdownReceiveNotification &&
        (valueTextRecursEvery ||
          valueDropdownDaysOfWeek ||
          monthSelected ||
          valueMonthlyDate ||
          valueMonthlyRange ||
          timeSlotList)
      ) {
        return {
          startDate: startDate ?? new Date().toUTCString(),
          periodEnum: valueDropdownReceiveNotification ?? null,
          recursEvery: _.isEqual(valueDropdownReceiveNotification, "DAILY")
            ? valueTextRecursEvery ?? 1
            : 1,
          dayOfWeek: _.isEqual(valueDropdownReceiveNotification, "WEEKLY")
            ? !_.isEmpty(valueDropdownDaysOfWeek)
              ? valueDropdownDaysOfWeek?.map((el: any) => el.value)
              : []
            : _.isEqual(valueDropdownReceiveNotification, "MONTHLY")
            ? [valueDropdownDaysOfWeekinMonthly]
            : [],
          monthOfYear: !_.isEmpty(monthSelected)
            ? monthSelected?.map((el: any) => el.value)
            : null,
          numDayOfMonth: valueMonthlyDate ?? 0,
          weekOfMonth: convertWeekOfMontInInteger(valueMonthlyRange) ?? 0,
          timeSlot: chainTimes(timeSlotList) ?? "",
        };
      }
      return null;
    };

    // useful for creating the object for the createRule api
    useEffect(() => {
      if (name && contacts && events && channels) {
        const addressBookIdList: number[] = contacts.map((item: DropDownItem) =>
          Number(item.id)
        );

        const eventNameTemplate: { [key: string]: string } =
          events.length > 0 && eventTemplatesMap
            ? Object.fromEntries(eventTemplatesMap)
            : {};
        const mumsChannels: ChannelType[] = channels.map(
          (item: DropDownItem) => item.id as ChannelType
        );

        const scheduledNotificationRequestBody =
          createOrEditScheduledNotificationRequestBody(
            startDate,
            valueDropdownReceiveNotification?.value,
            valueTextRecursEvery,
            valueDropdownDaysOfWeek,
            valueDropdownDaysOfWeekinMonthly?.value,
            monthSelected,
            valueMonthlyDate?.value,
            valueMonthlyRange?.value,
            timeSlotList
          );

        const notificationRuleEntityDTO: NotificationRuleEntityDTO[] = [];
        Object.entries(modifiedPrivilege).forEach((privileges) => {
          const [key, value] = privileges;
          notificationRuleEntityDTO.push(
            ...value.map((val) => {
              return {
                notificationRuleEntityId: val,
                notificationRuleEntityType: key.toUpperCase(),
              };
            })
          );
        });
        setCreateNotificationRule({
          ...createNotificationRule,
          name: name ?? "",
          addressBookIdList: addressBookIdList,
          eventTemplatesMap: eventNameTemplate,
          mumsChannelTypeEnums: mumsChannels,
          entities: manageEntities ? notificationRuleEntityDTO : [],
          ...(frequency !== undefined && { frequency }),
          scheduledNotificationRequestBody: scheduledNotificationRequestBody,
        });
      }
    }, [
      name,
      frequency,
      events,
      channels,
      contacts,
      startDate,
      valueDropdownReceiveNotification,
      valueTextRecursEvery,
      valueDropdownDaysOfWeek,
      monthSelected,
      valueMonthlyDate,
      valueMonthlyRange,
      timeSlotList,
      modifiedPrivilege,
      modifiedPrivilege,
      eventTemplatesMap,
    ]);

    // useful for creating the object for the editRule api
    useEffect(() => {
      if (
        isEditRule &&
        (newName ||
          updatedEvents ||
          updatedContacts ||
          updatedChannels ||
          newFrequency)
      ) {
        const eventsRemoved: DropDownItem[] = findRemovedItems(
          events,
          updatedEvents
        );
        const contactsAdded: DropDownItem[] = findAddedItems(
          contacts,
          updatedContacts
        );
        const contactsRemoved: DropDownItem[] = findRemovedItems(
          contacts,
          updatedContacts
        );
        const channelsAdded: DropDownItem[] = findAddedItems(
          channels,
          updatedChannels
        );
        const channelsRemoved: DropDownItem[] = findRemovedItems(
          channels,
          updatedChannels
        );

        const newAddress: number[] = contactsAdded.map((item) =>
          Number(item.id)
        );
        const removeAddress: number[] = contactsRemoved.map((item) =>
          Number(item.id)
        );
        const removeEventSourceEnums: EventSourceEnum[] = eventsRemoved.map(
          (item) => item.id as EventSourceEnum
        );
        const newChannel: ChannelType[] = channelsAdded.map(
          (item) => item.id as ChannelType
        );
        const removeChannel: ChannelType[] = channelsRemoved.map(
          (item) => item.id as ChannelType
        );
        const eventNameTemplate: { [key: string]: string } = eventTemplatesMap
          ? Object.fromEntries(eventTemplatesMap)
          : {};

        const notificationRuleEntityDTO: NotificationRuleEntityDTO[] = [];
        Object.entries(modifiedPrivilege).forEach((privileges) => {
          const [key, value] = privileges;
          notificationRuleEntityDTO.push(
            ...value.map((val) => {
              return {
                notificationRuleEntityId: val,
                notificationRuleEntityType: key.toUpperCase(),
              };
            })
          );
        });

        const scheduledNotificationRequestBody =
          createOrEditScheduledNotificationRequestBody(
            startDate,
            valueDropdownReceiveNotification?.value,
            valueTextRecursEvery,
            valueDropdownDaysOfWeek,
            valueDropdownDaysOfWeekinMonthly?.value,
            monthSelected,
            valueMonthlyDate?.value,
            valueMonthlyRange?.value,
            timeSlotList
          );

        setEditNotificationRule({
          ...editNotificationRule,
          newName: newName ?? "",
          newAddress: newAddress,
          removeAddress: removeAddress,
          newChannel: newChannel,
          removeChannel: removeChannel,
          newEventTemplatesMap: eventNameTemplate,
          removeEventNameEnums: removeEventSourceEnums,
          entities: manageEntities ? notificationRuleEntityDTO : [],
          scheduledNotificationRequestBody: scheduledNotificationRequestBody,
          ...(newFrequency !== undefined && { frequency: newFrequency }),
        });
      }
    }, [
      newName,
      updatedEvents,
      updatedContacts,
      updatedChannels,
      newFrequency,
      modifiedPrivilege,
      startDate,
      valueDropdownReceiveNotification,
      valueTextRecursEvery,
      valueDropdownDaysOfWeek,
      valueDropdownDaysOfWeekinMonthly,
      monthSelected,
      valueMonthlyDate,
      valueMonthlyRange,
      timeSlotList,
      eventTemplatesMap,
    ]);

    // useful for pre-filling text field and dropdown values
    useEffect(() => {
      if (
        notificationRuleAggregation &&
        !_.isEmpty(notificationRuleAggregation)
      ) {
        const notificationRulesOfAnAggregation = notificationRules.filter((x) =>
          notificationRuleAggregation?.notificationRules.includes(x?.id)
        );

        const notificationRuleDestinationsOfRules: NotificationRuleDestination[] =
          [];
        const addressBooks: AddressBook[] = [];
        notificationRulesOfAnAggregation.forEach((x: NotificationRule) => {
          x.destinations.forEach((y: number) => {
            const notificationRuleDestination =
              notificationRuleDestinationsSelectors.selectById(
                store.getState(),
                y
              );

            const notificationRuleScheduled =
              notificationRuleDestinationsSelectors.selectById(
                store.getState(),
                y
              );
            if (
              notificationRuleDestination &&
              !_.includes(
                notificationRuleDestinationsOfRules,
                notificationRuleDestination
              )
            ) {
              const addressBook = addressBookSelectors.selectById(
                store.getState(),
                notificationRuleDestination?.address
              );
              if (
                addressBook &&
                !_.includes(
                  notificationRuleDestinationsOfRules,
                  notificationRuleDestination
                )
              ) {
                addressBooks.push(addressBook);
              }
              notificationRuleDestinationsOfRules.push(
                notificationRuleDestination
              );
            }
          });
        });
        setName(notificationRuleAggregation.name);
        setNewName(notificationRuleAggregation.name);
        const eventsList = notificationRulesOfAnAggregation.map((x) => x.event);
        setEvents(
          eventsList.map((event) => {
            const eventNameTranslated =
              t("eventType." + event) !== "eventType." + event
                ? t("eventType." + event)
                : event
                ? event.split("_").join(" ").toLowerCase()
                : "";
            return {
              title: eventNameTranslated,
              id: event,
            };
          })
        );
        const channels = Array.from(
          new Set(notificationRuleDestinationsOfRules.map((x) => x.channel))
        );
        setChannels(
          channels.map((channel) => {
            return {
              id: channel,
              title: t("channelType." + channel),
            };
          })
        );
        const addresses = Array.from(new Set(addressBooks.map((x) => x)));
        setContacts(
          addresses.map((address) => {
            return {
              id: address.id,
              title: address.firstName + " " + address.lastName,
            };
          })
        );
        const frequency = Array.from(
          new Set(notificationRulesOfAnAggregation.map((x) => x.frequency))
        );
        setFrequency(frequency[0] ?? 0);
        setNewFrequency(frequency[0] ?? 0);
        setType("CUSTOM");
      }
    }, [notificationRuleAggregation]);

    //#region functions

    const createActionOnClick = () => {
      if (Object.values(createNotificationRule).length > 4) {
        store.dispatch(
          createNotificationRuleAsync({
            notificationRuleAggregation: createNotificationRule,
          })
        );
      }
    };

    const editPageOnClick = () => {
      navigate(
        "/admin/notifications/edit-rule/" + notificationRuleAggregation.id
      );
    };

    const editActionOnClick = () => {
      if (Object.values(editNotificationRule).length > 0) {
        store.dispatch(
          editNotificationRuleAsync({
            notificationRuleAggregation: editNotificationRule,
            aggregationRuleId: notificationRuleAggregation.id,
          })
        );
      }
    };

    function findAddedItems(
      originalItems: DropDownItem[] | undefined,
      updatedItems: DropDownItem[] | undefined
    ): DropDownItem[] {
      return originalItems && updatedItems
        ? updatedItems.filter(
            (item) =>
              !originalItems.some((originalItem) => originalItem.id === item.id)
          )
        : [];
    }

    function findRemovedItems(
      originalItems: DropDownItem[] | undefined,
      updatedItems: DropDownItem[] | undefined
    ): DropDownItem[] {
      return originalItems && updatedItems
        ? originalItems.filter(
            (item) =>
              !updatedItems.some((updatedItem) => updatedItem.id === item.id)
          )
        : [];
    }

    function closeAddressBookModal() {
      setOpenAddressBookModal(false);
      navigate(-1);
    }

    //#endregion functions

    //#region Toast notifications

    useEffect(() => {
      if (
        notificationRuleAggregationSliceStatus === "idle" &&
        notificationRuleAggregationSliceReasonCode === GTFleetSuccessCodes.POST
      ) {
        ToastNotification({
          toastId: "notificationRuleCreateSuccess",
          status: "success",
          description: t("admin.notifications.success.CREATED"),
        });
        navigate("/admin/notifications");
      }
      if (
        notificationRuleAggregationSliceStatus === "idle" &&
        notificationRuleAggregationSliceReasonCode === GTFleetSuccessCodes.PUT
      ) {
        ToastNotification({
          toastId: "notificationRuleEditSuccess",
          status: "success",
          description: t("admin.notifications.success.UPDATED"),
        });
        navigate("/admin/notifications");
      }
      if (
        notificationRuleAggregationSliceStatus === "failed" &&
        notificationRuleAggregationSliceReasonCode ===
          GTFleetErrorCodes.NOTIFICATION_RULE_AGGREGATION_NAME_ALREADY_EXISTS
      ) {
        console.error(t("common.userNotFoundError"));
        ToastNotification({
          toastId: "notificationRuleNameAlreadyExists",
          status: "error",
          description: t(
            "admin.notifications.error.NOTIFICATION_RULE_AGGREGATION_NAME_ALREADY_EXISTS"
          ),
        });
      } else if (
        notificationRuleAggregationSliceStatus === "failed" &&
        notificationRuleAggregationSliceReasonCode ===
          GTFleetErrorCodes.NOTIFICATION_RULE_ALREADY_EXISTS
      ) {
        ToastNotification({
          toastId: "notificationRuleAlreadyExists",
          status: "error",
          description: t(
            "admin.notifications.error.NOTIFICATION_RULE_ALREADY_EXISTS"
          ),
        });
      } else if (
        notificationRuleAggregationSliceStatus === "failed" &&
        notificationRuleAggregationSliceReasonCode ===
          GTFleetErrorCodes.NOTIFICATION_RULE_AGGREGATION_FREQUENCY_NULL
      ) {
        ToastNotification({
          toastId: "notificationRuleFrequencyNull",
          status: "error",
          description: t(
            "admin.notifications.error.NOTIFICATION_RULE_AGGREGATION_FREQUENCY_NULL"
          ),
        });
      } else if (
        notificationRuleAggregationSliceStatus === "failed" &&
        notificationRuleAggregationSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [
      notificationRuleAggregationSliceStatus,
      notificationRuleAggregationSliceReasonCode,
      navigate,
    ]);

    // #endregion Toast notifications
    //#endregion Toast notifications

    const areThereEntities = manageEntities
      ? permissions && permissions.vehicles.write
        ? vehiclesCanSee.length > 0
        : true && permissions && permissions.drivers.write
        ? driversCanSee.length > 0
        : true && permissions && permissions.geofences.write
        ? geofencesCanSee.length > 0
        : true
      : true;

    function creatRuleIsValidToBeSaved(
      createNotificationRule: NotificationRuleAggregationCreateRequest
    ) {
      return (
        Object.values(createNotificationRule).length > 4 &&
        !_.isEmpty(createNotificationRule.eventTemplatesMap) &&
        createNotificationRule.mumsChannelTypeEnums.length > 0 &&
        createNotificationRule.addressBookIdList.length > 0
      );
    }

    function editRuleIsValidToBeSaved(
      editNotificationRule: NotificationRuleAggregationUpdateRequest
    ) {
      if (!_.isEmpty(editNotificationRule)) {
        const entries = Object.entries(editNotificationRule);
        return (
          entries.some((x) => {
            if (x?.length > 0) {
              return true;
            } else {
              return false;
            }
          }) &&
          newName.length > 0 &&
          updatedEvents &&
          updatedEvents?.length > 0 &&
          updatedChannels &&
          updatedChannels?.length > 0 &&
          updatedContacts &&
          updatedContacts?.length > 0
        );
      } else {
        return false;
      }
    }

    const isButtonDisabled =
      isAddRule &&
      creatRuleIsValidToBeSaved(createNotificationRule) &&
      areThereEntities
        ? false
        : isViewRule
        ? false
        : isEditRule &&
          editRuleIsValidToBeSaved(editNotificationRule) &&
          areThereEntities
        ? false
        : true;

    const selectedEvents = isViewRule ? events : updatedEvents ?? events;

    return (
      <>
        <PageFilters>
          <div className="col col-16">
            <div className="admin-notification-subtopbar">
              <div className="admin-notification-subsection-name">
                {(isViewRule && name) ||
                  (isEditRule &&
                    t("admin.notifications.actions.create.editRule")) ||
                  (isAddRule &&
                    t("admin.notifications.actions.create.createRule"))}
              </div>
              <div className="admin-notification-buttons">
                <Button
                  size="small"
                  aspect="secondary"
                  label={
                    !isViewRule ? t("common.cancel") : t("common.allRules")
                  }
                  onClick={() => navigate("/admin/notifications")}
                />
                <Button
                  size="small"
                  aspect="primary"
                  label={isViewRule ? t("common.edit") : t("common.save")}
                  disabled={isButtonDisabled}
                  onClick={() => {
                    isAddRule && createActionOnClick();
                    isViewRule && editPageOnClick();
                    isEditRule && editActionOnClick();
                  }}
                >
                  {isViewRule && (
                    <IconEdit
                      className=""
                      size={14}
                      color="--global-colors-white"
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </PageFilters>
        <PageContent>
          <div className="admin-notification-page-container">
            <div className="admin-notification-info-form">
              <div className="admin-notification-forms-title">
                {t("admin.notifications.actions.create.parameters")}
              </div>
              {
                // Name
              }
              <Form>
                <div className="admin-notification-forms-container">
                  <div className="admin-notification-left-form-label">
                    {t("common.name")} *
                  </div>
                  <div className="admin-notification-forms">
                    <div className="admin-notification-left-form-item">
                      <TextField
                        id={t("common.name")}
                        type="text"
                        placeholder={t("common.writeRuleName")}
                        value={!isEditRule ? name : newName}
                        events={{
                          onChange: (data) => {
                            isEditRule
                              ? setNewName(data.value)
                              : setName(data.value);
                          },
                        }}
                        disabled={isViewRule ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </Form>

              {
                // Contacts
              }
              <Form>
                <div
                  className="admin-notification-forms-container"
                  style={{
                    height: contactsHeight
                      ? `${contactsHeight + 42}px`
                      : "42px",
                  }}
                >
                  <div className="admin-notification-left-form-label">
                    {t("table.columns.contacts")} *
                  </div>
                  <div className="admin-notification-forms">
                    <div className="admin-notification-left-form-item">
                      <TextFieldDropdown
                        id="contacts"
                        hasDropdown={false}
                        hasUserBox={false}
                        values={contactsValues ?? []}
                        placeholder={t("common.findContacts")}
                        getValues={(data) => {
                          isEditRule
                            ? setUpdatedContacts(data)
                            : setContacts(data);
                        }}
                        defaultValues={contacts}
                        isDisabled={isViewRule}
                        getOffsetHeight={setContactsHeight}
                        hasAddContact={true}
                        handleAddContactClick={() =>
                          isAddRule
                            ? navigate(
                                "/admin/notifications/add-rule/add-contact"
                              )
                            : navigate(
                                "/admin/notifications/edit-rule/" +
                                  notificationRuleAggregation.id +
                                  "/add-contact"
                              )
                        }
                      />
                    </div>
                  </div>
                </div>
              </Form>
              {
                // Events
              }
              <Form>
                <div
                  className="admin-notification-forms-container"
                  style={{
                    height: eventsHeight ? `${eventsHeight + 42}px` : "42px",
                  }}
                >
                  <div className="admin-notification-left-form-label">
                    {t("table.columns.events")} *
                  </div>
                  <div className="admin-notification-forms">
                    <div className="admin-notification-left-form-item">
                      <TextFieldDropdown
                        id="events"
                        hasDropdown={false}
                        hasUserBox={false}
                        values={eventTypeValues ?? []}
                        placeholder={t("common.findEvents")}
                        getValues={(data) => {
                          isEditRule ? setUpdatedEvents(data) : setEvents(data);
                        }}
                        defaultValues={events}
                        isDisabled={isViewRule}
                        getOffsetHeight={setEventsHeight}
                      ></TextFieldDropdown>
                    </div>
                  </div>
                </div>
              </Form>
              {
                // Channels
              }
              <Form>
                <div
                  className="admin-notification-forms-container"
                  style={{
                    height: channelsHeight
                      ? `${channelsHeight + 42}px`
                      : "42px",
                  }}
                >
                  <div className="admin-notification-left-form-label">
                    {t("table.columns.channels")} *
                  </div>
                  <div className="admin-notification-forms">
                    <div className="admin-notification-left-form-item">
                      <TextFieldDropdown
                        id="channels"
                        hasDropdown={false}
                        hasUserBox={false}
                        values={channelTypeValues ?? []}
                        placeholder={t("common.findChannels")}
                        getValues={(data) => {
                          isEditRule
                            ? setUpdatedChannels(data)
                            : setChannels(data);
                        }}
                        defaultValues={channels}
                        isDisabled={isViewRule}
                        getOffsetHeight={setChannelsHeight}
                      />
                    </div>
                  </div>
                </div>
              </Form>
              {
                // Frequency
              }
              <Form>
                <div className="admin-notification-forms-container">
                  <div className="admin-notification-left-form-label">
                    {t("table.columns.frequency")} *(min)
                  </div>
                  <div className="admin-notification-forms">
                    <div className="admin-notification-left-form-item">
                      <TextField
                        id={t("table.columns.frequency")}
                        type="number"
                        placeholder="0"
                        value={!isEditRule ? frequency ?? 0 : newFrequency ?? 0}
                        events={{
                          onChange: (data) =>
                            isEditRule
                              ? setNewFrequency(data?.value)
                              : setFrequency(data?.value),
                        }}
                        disabled={isViewRule ? true : false}
                      >
                        <IconClock
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      </TextField>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            {/* Card Period */}
            <AdminNotificationRulePeriod
              preferencesContext={preferencesContext}
              setValueDropdownDaysOfWeekinMonthlyProps={
                setValueDropdownDaysOfWeekinMonthly
              }
              isAddRule={isAddRule}
              isViewRule={isViewRule}
              isEditRule={isEditRule}
              setValueDropdownDaysOfWeekProps={setValueDropdownDaysOfWeek}
              setStartDateProps={setStartDate}
              setValueTextRecursEveryProps={setValueTextRecursEvery}
              setMonthSelectedProps={setMonthSelected}
              setValueMonthlyDateProps={setValueMonthlyDate}
              setValueMonthlyRangeProps={setValueMonthlyRange}
              setTimeSlotListProps={setTimeSlotList}
              setValueDropdownReceiveNotificationProps={
                setValueDropdownReceiveNotification
              }
              notificationRuleAggregation={notificationRuleAggregation}
            ></AdminNotificationRulePeriod>
            {/* Card Period */}
            {selectedEvents && selectedEvents?.length > 0 ? (
              <div className="admin-notification-info-form">
                <div className="admin-notification-forms-title-entities">
                  {t("admin.notifications.actions.manageText.manage")}
                  <Form className="form-function">
                    <Switch
                      checked={manageTemplates}
                      onChange={() => setManageTemplates(!manageTemplates)}
                      label=""
                      disabled={isViewRule}
                    />
                  </Form>
                </div>
                {
                  <AdminNotificationRuleEventTemplate
                    open={manageTemplates}
                    notificationRuleAggregationId={
                      notificationRuleAggregation.id
                    }
                    canModify={!isViewRule}
                    sms={
                      (isEditRule ? updatedChannels : channels)?.find(
                        (channel) => channel.title === "SMS"
                      )
                        ? true
                        : false
                    }
                    events={eventNames.filter((event) => {
                      let currentEvents = isViewRule
                        ? events
                        : !!updatedEvents
                        ? updatedEvents
                        : events;

                      return (
                        currentEvents &&
                        currentEvents.find((el) => el.id === event.name)
                      );
                    })}
                    responseTemplatesMap={(e) => {
                      setEventTemplates(e);
                    }}
                  />
                }
              </div>
            ) : (
              <></>
            )}
            <div className="admin-notification-info-form">
              <div className="admin-notification-forms-title-entities">
                {t("admin.notifications.actions.entity.manage")}
                <Form className="form-function">
                  <Switch
                    checked={manageEntities}
                    onChange={() => setManageEntities(!manageEntities)}
                    label=""
                    disabled={isViewRule}
                  />
                </Form>
              </div>
              <AdminNotificationRuleEntities
                manageEntities={manageEntities}
                notificationRuleAggregation={notificationRuleAggregation}
                permissions={permissions}
                setDriversCanSeeOutside={setDriversCanSee}
                setGeofencesCanSeeOutside={setGeofencesCanSee}
                setVehiclesCanSeeOutside={setVehiclesCanSee}
                setModifiedPrivilegeOutside={setModifiedPrivilege}
                setPrivilegesHasChangedOutSide={setPrivilegesHasChanged}
              />
            </div>
          </div>
          <AddressBookModals
            open={openAddressBookModal}
            sections={[{ id: 0, name: "add-contact" }]}
            currentActive="add-contact"
            onClose={closeAddressBookModal}
          />
        </PageContent>
      </>
    );
  };
export default CreateEditOrViewNotificationRule;
