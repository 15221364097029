export class GTFleetErrorCodes {
  //#region
  static INTERNAL_SERVER_ERROR = "500";
  //#endregion
  //#region AUTHENTICATION FEATURE ERRORS
  static NEW_PASSWORD_CONFLICT = "E0";
  static CODE_NOT_FOUND = "E1";
  static INVALID_PASSWORD = "E2";
  static UNABLE_REFRESH_TOKEN = "E3";
  static EXPIRED_TOKEN = "E6";
  static NO_ACTIVE_CONTRACTS = "E7";
  //#endregion AUTHENTICATION FEATURE ERRORS

  //#region DRIVERS FEATURE ERRORS
  static DRIVER_NOT_FOUND = "E10";
  static DRIVER_ALREADY_EXISTS = "E11";
  static USER_ALREADY_EXISTS = "E12";
  static DRIVER_STATUS_NOT_FOUND = "E13";
  static TENANT_NOT_FOUND = "E14";
  //#endregion DRIVERS FEATURE ERRORS

  //#region FILES FEATURE ERRORS
  static FILE_NOT_UPLOAD = "E20";
  //#endregion FILES FEATURE ERRORS

  //#region USERS FEATURE ERRORS
  static USERNAME_NOT_FOUND = "E30";
  static PRESET_NAME_ALREADY_EXISTS = "E31";
  static PRESET_NOT_FOUND = "E32";
  static USER_UNAUTHORIZED = "E33";
  static USER_DISABLED = "E34";
  static USER_EXPIRED = "E35";
  static PRESET_MISSING_INFO = "E36";
  //#endregion USERS FEATURE ERRORS

  //#region VEHICLES FEATURE ERRORS
  static VEHICLE_NOT_FOUND = "E40";
  static VEHICLE_STATUS_NOT_FOUND = "E41";
  static VEHICLE_ALIAS_ALREADY_EXISTS = "E43";

  //#endregion VEHICLES FEATURE ERRORS

  //#region DEADLINES FEATURE ERRORS
  static DEADLINE_TO_BE_DELETED_NOT_FOUND = "E50";
  //#endregion DEADLINES FEATURE ERRORS

  //#region DEADLINES FEATURE ERRORS
  static ROUTE_HISTORY_DOES_NOT_EXIST = "E60";
  //#endregion DEADLINES FEATURE ERRORS

  //#region Command FEATURE ERRORS
  static DEVICE_NOT_FOUND = "E80";
  static COMMAND_REQUESTS_NOT_FOUND = "E90";
  //#endregion

  //#region GEOFENCES FEATURE ERRORS
  static GEOFENCE_TO_BE_DELETED_NOT_FOUND = "E100";
  static GEOFENCE_NAME_ALREADY_EXIST = "E101";
  static GEOFENCE_TO_BE_DEACTIVATED_NOT_FOUND = "E102";
  static GEOFENCE_TO_BE_ACTIVATED_NOT_FOUND = "E103";
  static GEOFENCE_CATEGORIES_TO_BE_DELETED_NOT_FOUND = "E104";
  static GEOFENCE_CATEGORIES_NAME_ALREADY_EXISTS = "E105";
  //#endregion GEOFENCES FEATURE ERRORS

  //#region CHATS FEATURE ERRORS
  static CHAT_NOT_FOUND = "E111";
  static CHAT_INSUFFICIENT_SMS = "E112";
  //#endregion CHATS FEATURE ERRORS

  //#region FLEETS FEATURE ERRORS
  static FLEET_ALREADY_EXISTS = "E121";
  static FLEET_NOT_FOUND = "E120";
  static FLEET_BAD_REQUEST = "E122";
  static FLEET_DEFAULT_NOT_FOUND = "E123";
  static FLEET_ALREADY_DELETED = "E124";
  static RESOURCE_ALREADY_ASSOCIATED_TO_A_FLEET = "E125";
  static DEFAULT_FLEET_CANNOT_BE_MODIFIED = "E126";
  //#endregion FLEETS FEATURE ERRORS

  //#region NOTIFICATION RULE AGGREGATION ERRORS
  static NOTIFICATION_RULE_AGGREGATION_NAME_ALREADY_EXISTS = "E131";
  static NOTIFICATION_RULE_ALREADY_EXISTS = "E132";
  static NOTIFICATION_RULE_AGGREGATION_NOT_FOUND = "E133";
  static NOTIFICATION_RULE_AGGREGATION_ALREADY_DEACTIVATED = "E134";
  static NOTIFICATION_RULE_AGGREGATION_ALREADY_ACTIVATED = "E135";
  static NOTIFICATION_RULE_AGGREGATION_FREQUENCY_NULL = "E136";
  //#endregion NOTIFICATION RULE AGGREGATION ERRORS

  //#region ADDRESS BOOK ERRORS
  static ADDRESS_BOOK_NOT_FOUND = "E141";
  static ADDRESS_BOOK_ALREADY_EXISTS = "E142";
  static ADDRESS_BOOK_ASSOCIATED_TO_NOTIFICATION_RULE = "E143";
  //#endregion ADDRESS BOOK ERRORS

  //#region REPORT TIMEOUT ERRORS
  static REPORT_TIMEOUT_EXCEPTION = "E170";
  //#endregion REPORT TIMEOUT ERRORS
}
