import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DatePickerContext } from "./DatePickerContext";
import TextField from "./TextField";

export const DatePickerTime = ({ isSingleDatePicker = false }) => {
  const picker = useContext(DatePickerContext);
  const { t } = useTranslation();

  return (
    <div className="time">
      <div>
        <label>{t("common.datePicker.fromTime")}</label>
        <TextField
          type="time"
          id="from-time"
          size="small"
          placeholder=""
          value={picker.fromTime}
          events={{ onChange: (data) => picker.setFromTime(data.value) }}
        />
      </div>
      {!isSingleDatePicker && (
        <div>
          <label>{t("common.datePicker.toTime")}</label>
          <TextField
            type="time"
            id="to-time"
            size="small"
            placeholder=""
            value={picker.toTime}
            events={{
              onChange: (data) => {
                picker.setToTime(data.value);
                if (picker.startDate && !picker.endDate) {
                  picker.setEndDate(picker.startDate);
                }
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
