import { schema } from "normalizr";
import { contractSchema } from "../contract/contractNormalization";
import { deviceSchema } from "../device/deviceNormalization";
import { geofenceSchema } from "../geofence/geofenceNormalization";
import { userInfoSchemaWithoutPrivilegeAndSecurity } from "../users/userInfoNormalization";

export const fleetMaintenanceCostsSchema = new schema.Entity(
  "VehiclesMaintenanceCosts",
  {},
  { idAttribute: "tenantId" }
);

const vehicleForFleetSchema: schema.Entity<any> = new schema.Entity("vehicle", {
  driver: new schema.Entity("driver"),
  device: deviceSchema,
  contract: contractSchema,
});

export const fleetSchema = new schema.Entity(
  "fleet",
  {
    user: userInfoSchemaWithoutPrivilegeAndSecurity,
  },
  { idAttribute: "id" }
);

export const fleetObjSchema = new schema.Entity("fleet", {
  user: userInfoSchemaWithoutPrivilegeAndSecurity,
  vehicles: [vehicleForFleetSchema],
  drivers: [new schema.Entity("driver")],
  geofences: [geofenceSchema],
});

export const fleetsSchema = new schema.Array(fleetSchema);

export const fleetsObjSchema = new schema.Array(fleetObjSchema);
