import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { Button } from "../../../../ui/Button/Button";
import { IconArrowLeft } from "../../../../ui/Icon/Line/ArrowLeft";
import { IconArrowRight } from "../../../../ui/Icon/Line/ArrowRight";
import { ModalBody } from "../../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../../ui/Modal/ModalWrapper";
import { downloadFileFromS3 } from "../../../../utils/S3Client";
import { UserInfo, userInfoSelectors } from "../../../users/usersInfoSlice";
import "./AttachmentsModal.css";

interface AttachmentsModalProps {
  open: boolean;
  onClose: (e: any) => any;
  attachments: string;
}

export const AttachmentsModal: React.FC<AttachmentsModalProps> = ({
  open,
  onClose,
  attachments,
}) => {
  const [currentPhotoUrl, setCurrentPhotoUrl] = useState("");

  const userInfo: UserInfo =
    useAppSelector((state) =>
      userInfoSelectors.selectById(
        state,
        Number(localStorage.getItem("customerId") ?? -1)
      )
    ) ?? ({} as UserInfo);

  const separationAttachedString =
    attachments != null && attachments !== "" ? attachments.split(",") : [];

  const [photos, setPhotos] = useState<string[]>(separationAttachedString);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (attachments?.trim()) {
      setPhotos(attachments.split(","));
    }
  }, [attachments]);

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const prevPhoto = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + photos.length) % photos.length
    );
  };

  useEffect(() => {
    setCurrentPhotoUrl(
      `https://gtfleetbucket.s3.eu-central-1.amazonaws.com/tenants/${userInfo.tenant}/users/${userInfo.id}/activities/${photos[currentIndex]}`
    );
  }, [currentIndex, userInfo, photos]);

  return (
    <ModalWrapper open={open} closeAction={onClose}>
      <ModalBody
        title={t("common.preview")}
        desc={`${photos.length} ${t("refuelReport.attached")}`}
      >
        <div className="container-buttons-and-photos-attached">
          <div className="container-buttons-attached">
            <div className="photo-size">
              {currentIndex + 1 + "/" + photos.length}
            </div>
            <div className="buttons-attached">
              <Button
                onClick={prevPhoto}
                size="small"
                label={undefined}
                disabled={currentIndex === 0}
              >
                <IconArrowLeft size={14} color="black" />
              </Button>
              <Button
                onClick={nextPhoto}
                size="small"
                label={undefined}
                disabled={currentIndex === photos.length - 1}
              >
                <IconArrowRight size={14} color="black" />
              </Button>
            </div>
          </div>
          <div className="container-photos-attached">
            {photos.length > 0 && (
              <img
                className="photos-attached"
                src={currentPhotoUrl}
                alt={`${currentIndex + 1}`}
              />
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        primaryLabel={t("common.download")}
        primaryAction={() => {
          downloadFileFromS3(
            `tenants/${userInfo.tenant}/users/${userInfo.id}/activities/${photos[currentIndex]}`,
            photos[currentIndex],
            process.env.REACT_APP_AMAZON_S3_BUCKET_ACTIVITIES
          );
        }}
        secondaryLabel={t("common.cancel")}
        secondaryAction={onClose}
      />
    </ModalWrapper>
  );
};
