import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../../ui/Button/Button";
import { TextFieldTag } from "../../../ui/Chat/TextFieldTag";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import Form from "../../../ui/Forms/Form";
import { Radio } from "../../../ui/Forms/Radio";
import { SingleDatePicker } from "../../../ui/Forms/SingleDatePicker";
import TextField from "../../../ui/Forms/TextField";
import { TimePicker } from "../../../ui/Forms/TimePicker";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconCheck } from "../../../ui/Icon/Line/Check";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  convertIntegerInWeekOfMont,
  dayOfWeekManagement,
  monthsOfYearManagement,
  periodEnumManagement,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  DaysOfWeekEnum,
  MonthsOfYearEnum,
  NotificationRuleAggregation,
  receiveNotificationEnum,
  WeeksOfMonthEnum,
} from "./notificationRuleAggregationSlice";

interface AdminNotificationRulePeriodProps {
  preferencesContext: Preferences;
  isAddRule: boolean;
  isViewRule: boolean;
  isEditRule: boolean;
  notificationRuleAggregation: NotificationRuleAggregation;
  setStartDateProps: (a: any) => any;
  setValueDropdownDaysOfWeekProps: (a: any) => any;
  setValueTextRecursEveryProps: (a: any) => any;
  setMonthSelectedProps: (a: any) => any;
  setValueMonthlyDateProps: (a: any) => any;
  setValueMonthlyRangeProps: (a: any) => any;
  setTimeSlotListProps: (a: any) => any;
  setValueDropdownReceiveNotificationProps: (a: any) => any;
  setValueDropdownDaysOfWeekinMonthlyProps: (a: any) => any;
}

const AdminNotificationRulePeriod: React.FC<AdminNotificationRulePeriodProps> =
  ({
    preferencesContext,
    isAddRule,
    isViewRule,
    isEditRule,
    notificationRuleAggregation,
    setStartDateProps,
    setValueDropdownDaysOfWeekinMonthlyProps,
    setValueDropdownDaysOfWeekProps,
    setValueTextRecursEveryProps,
    setMonthSelectedProps,
    setValueMonthlyDateProps,
    setValueMonthlyRangeProps,
    setTimeSlotListProps,
    setValueDropdownReceiveNotificationProps,
  }) => {
    // #region Create Timed notifications
    const [startDate, setStartDate] = useState<Date>();
    const [
      valueDropdownReceiveNotification,
      setValueDropdownReceiveNotification,
    ] = useState<any>({});
    const [valueDropdownDaysOfWeek, setValueDropdownDaysOfWeek] =
      useState<any[] | null>();
    const [
      valueDropdownDaysOfWeekinMonthly,
      setValueDropdownDaysOfWeekinMonthly,
    ] = useState<any>();
    const [valueTextRecursEvery, setValueTextRecursEvery] =
      useState<number | null>();
    const [monthSelected, setMonthSelected] = useState<any>();
    const [valueMonthlyDate, setValueMonthlyDate] = useState<any>();
    const [valueMonthlyRange, setValueMonthlyRange] = useState<any>();
    const [valueTimeSlot, setValueTimeSlot] = useState<any[]>([]);
    const [timeSlotList, setTimeSlotList] = useState<any[]>([]);
    const [isVisibleTimePicker, setIsVisibleTimePicker] = useState(false);
    const [isMonthlyDate, setIsMonthlyDate] = useState(true);
    const [isMonthlyRange, setIsMonthlyRange] = useState(false);
    const [changeInputStartTime, setChangeInputStartTime] = useState("");
    const [changeInputEndTime, setChangeInputEndTime] = useState("");
    // #endregion Create Timed notifications

    // #region useEffect
    useEffect(() => {
      setStartDateProps(startDate);
      setValueDropdownDaysOfWeekinMonthlyProps(
        valueDropdownDaysOfWeekinMonthly
      );
      setValueDropdownDaysOfWeekProps(valueDropdownDaysOfWeek);
      setValueTextRecursEveryProps(valueTextRecursEvery);
      setMonthSelectedProps(monthSelected);
      setValueMonthlyDateProps(valueMonthlyDate);
      setValueMonthlyRangeProps(valueMonthlyRange);
      setTimeSlotListProps(timeSlotList);
      setValueDropdownReceiveNotificationProps(
        valueDropdownReceiveNotification
      );
    }, [
      startDate,
      valueDropdownDaysOfWeek,
      valueTextRecursEvery,
      monthSelected,
      valueMonthlyDate,
      valueMonthlyRange,
      timeSlotList,
      valueDropdownReceiveNotification,
      valueDropdownDaysOfWeekinMonthly,
    ]);

    useEffect(() => {
      if (
        notificationRuleAggregation != undefined &&
        notificationRuleAggregation?.notificationRuleSchedulation != undefined
      ) {
        let oldValue =
          notificationRuleAggregation?.notificationRuleSchedulation;
        setStartDate(oldValue.startDate);
        setValueDropdownReceiveNotification(
          periodEnumManagement(oldValue.periodEnum)
        );
        setValueTimeSlot(
          oldValue.timeSlot ? oldValue.timeSlot.split(" & ") : valueTimeSlot
        );
        setValueTextRecursEvery(oldValue.recursEvery);
        setValueDropdownDaysOfWeek(dayOfWeekManagement(oldValue.dayOfWeek));
        setValueDropdownDaysOfWeekinMonthly(
          dayOfWeekManagement(oldValue.dayOfWeek)
        );
        setMonthSelected(monthsOfYearManagement(oldValue.monthOfYear));
        setValueMonthlyDate({
          label: oldValue.numDayOfMonth,
          value: oldValue.numDayOfMonth,
        });
        setValueMonthlyRange(convertIntegerInWeekOfMont(oldValue.weekOfMonth));
        setIsMonthlyDate(
          oldValue.numDayOfMonth != null && oldValue.numDayOfMonth != 0
        );
        setIsMonthlyRange(
          oldValue.weekOfMonth != null && oldValue.weekOfMonth != 0
        );
      }
    }, [notificationRuleAggregation]);

    // #endregion useEffect

    const handelChangeTimeSlot = (data: any[] | null) => {
      setValueTimeSlot([]);
      setTimeSlotList([]);
      if (data != null && _.isEmpty(data)) {
        data.map((dat) => {
          const time = dat.split("|");

          const startTime = time[0].trim();
          const endTime = time[1].trim();

          setValueTimeSlot((prevValueTimeSlot) =>
            prevValueTimeSlot.concat(`${startTime} | ${endTime}`)
          );

          setTimeSlotList((prevTimeSlotList) =>
            prevTimeSlotList.concat({ startTime, endTime })
          );
        });
      }
    };

    const handleSetStartTime = (time: string) => {
      setChangeInputStartTime(time);
    };

    const handleSetEndTime = (time: string) => {
      setChangeInputEndTime(time);
    };

    const createTagTimeSlot = (startTime: string, endTime: string) => {
      const tag = `${t(
        "admin.notifications.actions.period.from"
      )} ${startTime} ${t("admin.notifications.actions.period.to")} ${endTime}`;

      setValueTimeSlot((prevValueTimeSlot) =>
        prevValueTimeSlot.concat(`${startTime} | ${endTime}`)
      );
      setTimeSlotList((prevTimeSlotList) =>
        prevTimeSlotList.concat({ startTime, endTime })
      );
      console.log(valueTimeSlot);
    };

    const compareTimes = (
      startTime: string,
      endTime: string,
      periodList: any[] | null
    ) => {
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      const startTotalMinutes = startHour * 60 + startMinute;
      const endTotalMinutes = endHour * 60 + endMinute;

      let overlaps = false;
      if (periodList != null && !_.isEmpty(periodList))
        overlaps = periodList.some((period) => {
          const [existingStartHour, existingStartMinute] = period.startTime
            .split(":")
            .map(Number);
          const [existingEndHour, existingEndMinute] = period.endTime
            .split(":")
            .map(Number);

          const existingStartTotalMinutes =
            existingStartHour * 60 + existingStartMinute;
          const existingEndTotalMinutes =
            existingEndHour * 60 + existingEndMinute;

          return (
            (startTotalMinutes >= existingStartTotalMinutes &&
              startTotalMinutes < existingEndTotalMinutes) ||
            (endTotalMinutes > existingStartTotalMinutes &&
              endTotalMinutes <= existingEndTotalMinutes) ||
            (startTotalMinutes <= existingStartTotalMinutes &&
              endTotalMinutes >= existingEndTotalMinutes)
          );
        });

      if (overlaps) {
        ToastNotification({
          toastId: "notifications",
          status: "default",
          title: t("admin.notifications.actions.period.overlaps"),
          description: "",
        });
      } else if (startTotalMinutes >= endTotalMinutes) {
        ToastNotification({
          toastId: "notifications",
          status: "default",
          title: t(
            "admin.notifications.actions.period.toastNotificationPeriod"
          ),
          description: "",
        });
      } else {
        createTagTimeSlot(startTime, endTime);
        setIsVisibleTimePicker(!isVisibleTimePicker);
        setChangeInputStartTime("");
        setChangeInputEndTime("");
      }
    };

    const [receiveNotificationValues, setReceiveNotificationValues] = useState<
      any[]
    >(
      _.keys(receiveNotificationEnum).map((key) => {
        const labelReceiveNotification = t(
          "admin.notifications.actions.period." + key.toLowerCase()
        );
        return {
          label: labelReceiveNotification,
          value: key,
        };
      })
    );

    const [monthsOfYear, setMonthsOfYear] = useState<any[]>(
      _.keys(MonthsOfYearEnum).map((key) => {
        const labelMonthOfYearType = t("monthsOfYear." + key.toLowerCase());
        return {
          label: labelMonthOfYearType,
          value: key,
        };
      })
    );

    const [daysOfWeek, setDaysOfWeek] = useState<any[]>(
      _.keys(DaysOfWeekEnum).map((key) => {
        const labelDaysOfWeekType = t("daysOfWeek." + key.toLowerCase());
        return {
          label: labelDaysOfWeekType,
          value: key,
        };
      })
    );

    const [weeksOfMonth, setWeeksOfMonth] = useState<any[]>(
      _.keys(WeeksOfMonthEnum).map((key) => {
        const labelWeeksOfMonthEnumType = t(
          "weeksOfMonth." + key.toLowerCase()
        );
        return {
          label: labelWeeksOfMonthEnumType,
          value: key,
        };
      })
    );

    const handleDisabledButton = (startTime: string, endTime: string) => {
      if (
        startTime !== "" &&
        startTime !== undefined &&
        endTime !== "" &&
        endTime !== undefined
      ) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <div className="admin-notification-info-form-period">
        <div className="admin-notification-forms-title">
          {t("admin.notifications.actions.create.period")}
        </div>
        {/* #region dropdown Start Date */}
        <Form>
          <div className="admin-notification-forms-container">
            <div className="admin-notification-left-form-label">
              {t("admin.notifications.actions.period.startFrom")}
            </div>
            <div className="admin-notification-forms">
              <div className="admin-notification-left-form-item">
                <div className={isViewRule ? "ea-only-view" : ""}>
                  <SingleDatePicker
                    id="StartForm"
                    placeholder={t("common.today")}
                    localeFormat={
                      preferencesContext.localeFormat ?? "DD / MM / YYYY"
                    }
                    oldDate={
                      notificationRuleAggregation &&
                      notificationRuleAggregation.notificationRuleSchedulation &&
                      notificationRuleAggregation.notificationRuleSchedulation
                        .startDate
                        ? String(
                            notificationRuleAggregation
                              .notificationRuleSchedulation.startDate
                          )
                        : ""
                    }
                    setDate={(data) => {
                      setStartDate(data);
                    }}
                    errorList={[]}
                  ></SingleDatePicker>
                </div>
              </div>
            </div>
          </div>
        </Form>
        {/* #endregion dropdown Start Date */}

        {/* #region dropdown Receive Notifications */}
        <div className="admin-notification-forms-container">
          <div className="admin-notification-left-form-label">
            {t("admin.notifications.actions.period.receiveNotification")}
          </div>
          <div className="admin-notification-forms">
            <div className="admin-notification-left-form-item">
              <Dropdown
                isDisabled={isViewRule}
                isClearable={!isViewRule}
                placeholder={t("admin.notifications.actions.period.daily")}
                itemAttribute="label"
                onChange={(e) => {
                  setValueDropdownReceiveNotification({
                    label: e[0]?.label,
                    value: e[0]?.value,
                  });
                  setValueTextRecursEvery(null);
                  setValueTimeSlot([]);
                  setValueDropdownDaysOfWeek(null);
                  setMonthSelected(null);
                  setValueMonthlyDate(null);
                  setValueMonthlyRange(null);
                  setIsMonthlyRange(false);
                  setIsMonthlyDate(true);
                }}
                value={valueDropdownReceiveNotification}
                options={receiveNotificationValues}
                defaultValue={undefined}
              ></Dropdown>
            </div>
          </div>
        </div>
        {/* #endregion dropdown Receive Notifications */}

        {/* #region dropdown Receive Notifications */}
        {/* #region dropdown Receive Notifications */}
        {valueDropdownReceiveNotification.value !== "WEEKLY" && (
          <Form>
            <div className="admin-notification-forms-container">
              <div className="admin-notification-left-form-label">
                {t("admin.notifications.actions.period.recursEvery")}
              </div>
              {valueDropdownReceiveNotification.value !== "MONTHLY" ? (
                <div className="admin-notification-forms">
                  <div className="admin-notification-left-form-item">
                    {/* #region dropdown Receive Notifications DAILY*/}
                    <TextField
                      disabled={isViewRule}
                      type="number"
                      placeholder={t("common.day")}
                      events={{
                        onChange: (data) => {
                          setValueTextRecursEvery(data.value);
                        },
                      }}
                      value={valueTextRecursEvery ?? 0}
                      id="textarea"
                    ></TextField>
                    {/* #endegion dropdown Receive Notifications DAILY*/}
                  </div>
                </div>
              ) : (
                <div className="admin-notification-forms">
                  <div className="admin-notification-left-form-item">
                    {/* #region dropdown Receive Notifications MONTHLY*/}
                    <Dropdown
                      isDisabled={isViewRule}
                      placeholder={t(
                        "admin.notifications.actions.period.month"
                      )}
                      hasCheckbox
                      itemAttribute="label"
                      onChange={(e) => {
                        setMonthSelected(e);
                      }}
                      hasSelectAll={true}
                      value={monthSelected ?? []}
                      options={monthsOfYear}
                    ></Dropdown>
                    {/* #endregion dropdown Receive Notifications MONTHLY*/}
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
        {valueDropdownReceiveNotification.value === "WEEKLY" ? (
          <div className="admin-notification-forms-container">
            <div className="admin-notification-left-form-label">
              {t("admin.notifications.actions.period.on")}
            </div>
            <div className="admin-notification-forms">
              <div className="admin-notification-left-form-item">
                <Dropdown
                  isDisabled={isViewRule}
                  placeholder={t("admin.notifications.actions.period.day")}
                  hasCheckbox
                  itemAttribute="label"
                  onChange={(e) => {
                    setValueDropdownDaysOfWeek(e);
                  }}
                  value={valueDropdownDaysOfWeek ?? []}
                  options={daysOfWeek}
                ></Dropdown>
              </div>
            </div>
          </div>
        ) : valueDropdownReceiveNotification.value === "MONTHLY" ? (
          <>
            <div className="admin-notification-forms-container">
              <div className="admin-notification-forms">
                <div className="admin-notification-left-form-item">
                  <div className="admin-notification-left-form-label">
                    <div className={isViewRule ? "ea-only-view" : ""}>
                      <Radio
                        label={t("admin.notifications.actions.period.date")}
                        checked={isMonthlyDate}
                        onChange={() => {
                          setIsMonthlyRange(false);
                          setIsMonthlyDate(true);
                          setValueMonthlyRange(0);
                        }}
                      ></Radio>
                    </div>
                  </div>
                  <div className="admin-notification-forms">
                    <div className="admin-notification-left-form-item">
                      <Dropdown
                        isDisabled={!isMonthlyDate || isViewRule}
                        placeholder={t(
                          "admin.notifications.actions.period.day"
                        )}
                        itemAttribute="label"
                        onChange={(e) => {
                          setValueMonthlyDate({
                            label: e[0].label,
                            value: e[0].value,
                          });
                        }}
                        value={valueMonthlyDate ?? 1}
                        options={
                          Array.from(
                            { length: 31 },
                            (_, index) => index + 1
                          ).map((day) => ({
                            value: day.toString(),
                            label: day.toString(),
                          })) ?? []
                        }
                      ></Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-notification-forms-container">
              <div className="admin-notification-forms">
                <div className="admin-notification-left-form-item">
                  <div className="admin-notification-left-form-label">
                    <div className={isViewRule ? "ea-only-view" : ""}>
                      <Radio
                        label={t("admin.notifications.actions.period.every")}
                        checked={isMonthlyRange}
                        onChange={() => {
                          setIsMonthlyRange(true);
                          setIsMonthlyDate(false);
                          setValueMonthlyDate(undefined);
                        }}
                      ></Radio>
                    </div>
                  </div>
                  <div className="admin-notification-forms-dropdown">
                    <div className="admin-notification-left-form-item">
                      <Dropdown
                        isDisabled={!isMonthlyRange || isViewRule}
                        placeholder={t(
                          "admin.notifications.actions.period.week"
                        )}
                        itemAttribute="label"
                        onChange={(e) => {
                          setValueMonthlyRange({
                            label: e[0].label,
                            value: e[0].value,
                          });
                        }}
                        value={valueMonthlyRange ?? 0}
                        options={weeksOfMonth}
                      ></Dropdown>
                    </div>
                    <div className="admin-notification-left-form-item">
                      <Dropdown
                        isDisabled={!isMonthlyRange || isViewRule}
                        placeholder={t(
                          "admin.notifications.actions.period.day"
                        )}
                        itemAttribute="label"
                        onChange={(e) => {
                          setValueDropdownDaysOfWeekinMonthly({
                            label: e[0].label,
                            value: e[0].value,
                          });
                        }}
                        value={valueDropdownDaysOfWeekinMonthly ?? []}
                        options={daysOfWeek}
                      ></Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <Form>
          {/* #endregion dropdown Receive Notifications */}
          {/* #endregion dropdown Receive Notifications */}

          <div className="admin-notification-forms-container">
            <div className="admin-notification-left-form-label">
              {t("admin.notifications.actions.period.timeSlot")}
            </div>
            <div className={isViewRule ? "ea-only-view" : ""}>
              <div className="admin-notification-forms">
                <div className="admin-notification-left-form-item">
                  <div className="text-field-tag-container">
                    <div className="text-field-tag-input">
                      <TextFieldTag
                        values={valueTimeSlot ?? null}
                        isDisabled={isViewRule}
                        defaultValue={t(
                          "admin.notifications.actions.period.allDay"
                        )}
                        getValues={(data) => {
                          handelChangeTimeSlot(data);
                        }}
                      ></TextFieldTag>
                    </div>
                    <div className="text-field-tag-button">
                      <Button
                        disabled={isViewRule}
                        size="small"
                        label={
                          <IconAdd
                            size={15}
                            color="--global-colors-ink-ink"
                          ></IconAdd>
                        }
                        onClick={() => {
                          setIsVisibleTimePicker(!isVisibleTimePicker);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        {isVisibleTimePicker && (
          <Form>
            <div className="admin-notification-forms-container">
              <div className="admin-notification-left-form-label">{""}</div>
              <div className="admin-notification-forms">
                <div className="admin-notification-left-form-item">
                  <div className="text-field-tag-container">
                    <div className="row-start-end-time-container">
                      <div className="row-start-time-container">
                        <div className="row-start-time-text">
                          {t("admin.notifications.actions.period.startingTime")}
                        </div>
                        <div className="row-start-time-datepicker">
                          <TimePicker
                            id="start-time"
                            date={changeInputStartTime}
                            setDate={(data) => {
                              handleSetStartTime(data);
                            }}
                          ></TimePicker>
                        </div>
                      </div>
                      <div className="row-end-time-container">
                        <div className="row-end-time-text">
                          {t("admin.notifications.actions.period.endTime")}
                        </div>
                        <div className="row-end-time-datepicker">
                          <TimePicker
                            id="end-time"
                            date={changeInputEndTime}
                            setDate={(data) => {
                              handleSetEndTime(data);
                            }}
                          ></TimePicker>
                        </div>
                      </div>
                    </div>
                    <div className="start-end-date-button">
                      <Button
                        disabled={handleDisabledButton(
                          changeInputStartTime,
                          changeInputEndTime
                        )}
                        size={"small"}
                        label={
                          <IconCheck
                            size={15}
                            color="--global-colors-ui-white"
                          ></IconCheck>
                        }
                        onClick={() => {
                          compareTimes(
                            changeInputStartTime,
                            changeInputEndTime,
                            timeSlotList
                          );
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    );
  };
export default AdminNotificationRulePeriod;
