import { schema } from "normalizr";
import { ReportArchive } from "./reportArchiveSlice";

// Definizione dello schema per ReportArchive
export const reportArchiveSchema: schema.Entity<ReportArchive> =
  new schema.Entity("reportArchives", {}, { idAttribute: "id" });

export const reportArchivesSchema = new schema.Array(reportArchiveSchema);

export const reportArchiveExecutionsSchema = new schema.Entity(
  "reportArchiveExecutions",
  {},
  {
    idAttribute: "id",
  }
);

// Se hai un array di questi esecuzioni
export const reportArchiveExecutionsArraySchema = new schema.Array(
  reportArchiveExecutionsSchema
);
