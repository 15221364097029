import HttpClient from "../../utils/HttpClient";
import { ActivityUpdateRequest } from "./activitiesSlice";

export default class ActivitiesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getActivities(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    const queryParamsObj = new URLSearchParams(queryParams);
    const toUTC = (dateString: any) => {
      if (dateString) {
        const localDate = new Date(dateString);
        return localDate.toISOString().slice(0, 16);
      }
    };
    if (queryParamsObj.get("startPeriod") || queryParamsObj.get("endPeriod")) {
      const startDate = queryParamsObj.get("startPeriod");
      const endDate = queryParamsObj.get("endPeriod");
      queryParamsObj.set("startPeriod", toUTC(startDate) ?? "");
      queryParamsObj.set("endPeriod", toUTC(endDate) ?? "");
    }
    let path = "/tenants/" + tenantId + "/activities?";
    return this.instance.get(path + queryParamsObj.toString());
  }

  updateActivity(activity: ActivityUpdateRequest, id: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/activities/" + id;
    return this.instance.patch(path, activity);
  }

  deleteActivity(activityId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/activities/" + activityId;
    return this.instance.delete(path);
  }
}
