import { t } from "i18next";
import { useState } from "react";
import { Tooltip } from "../Forms/Tooltip";
import { IconMenuRoutes } from "../Icon/Line/MenuRoutes";

interface RouteButtonProps {
  onClick: (e: boolean) => any;
}

export const RouteButton: React.FC<RouteButtonProps> = ({ onClick }) => {
  const [active, setActive] = useState<boolean>(false);

  const handleClick = () => {
    setActive((prevActive) => !prevActive);
    onClick(!active);
  };

  return (
    <>
      <Tooltip />
      <div
        className="zoom-control"
        data-for="tooltip"
        data-tip={t("liveTracking.showAllRoute")}
      >
        <div
          onClick={handleClick}
          className={
            !active
              ? "map-button-view-arrow-deactive"
              : "map-button-view-arrow-active"
          }
        >
          <IconMenuRoutes
            size={18}
            color={
              !active
                ? "--global-colors-ui-white"
                : "--global-colors-ui-primary"
            }
          />
        </div>
      </div>
    </>
  );
};
