import { t } from "i18next";
import { store } from "../../../app/store";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ToastNotification } from "../../../utils/ToastNotification";
import { deleteScheduledReportAsync } from "../archive/reportArchiveSlice";

interface SchedulationDeleteModalProps {
  isOpen: boolean;
  schedulationId: number;
  reportType: string;
  onClose: () => any;
}

export const SchedulationDeleteModal: React.FC<SchedulationDeleteModalProps> =
  ({ isOpen, schedulationId, onClose, reportType }) => {
    return (
      <>
        <div className="schedulation-modal">
          <ModalWrapper open={isOpen} closeAction={onClose}>
            <ModalBody
              title={t("reportSchedulation.modalDeleteSchedule.title")}
              desc={t("reportSchedulation.modalDeleteSchedule.desc").replace(
                "*reportName",
                t("reports.types." + reportType)
              )}
              stylePadding={"0px"}
            ></ModalBody>
            <ModalFooter
              primaryAction={() => {
                store
                  .dispatch(
                    deleteScheduledReportAsync({
                      scheduledReportId: schedulationId,
                    })
                  )
                  .then((response) => {
                    response.meta.requestStatus === "fulfilled"
                      ? ToastNotification({
                          toastId: "schedulationDeleted",
                          status: "success",
                          description: t("common.schedulationDeleteSuccess"),
                        })
                      : ToastNotification({
                          toastId: "schedulationError",
                          status: "error",
                          description: t("common.networkError"),
                        });
                    onClose();
                  });
              }}
              isDanger={true}
              secondaryAction={onClose}
              primaryLabel={t("common.confirm")}
              secondaryLabel={t("reportSchedulation.modalSchedule.cancel")}
              padding={"20px"}
            />
          </ModalWrapper>
        </div>
      </>
    );
  };
