import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import NotificationDefaultTemplateRepository from "./notificationDefaultTemplateRepository";
import { NotificationTemplateDTO } from "./notificationRuleAggregationSlice";

export const getNotificationDefaultTemplateAsync = createAsyncThunk(
  "notificationsTemplate/getNotificationDefaultTemplate",
  async (data: { queryParams?: string }) => {
    const notificationDefaultTemplateRepository =
      new NotificationDefaultTemplateRepository();
    const response =
      await notificationDefaultTemplateRepository.getNotificationDefaultTemplate(
        data.queryParams
      );
    return response.data;
  }
);

const notificationDefaultTemplateAdapter =
  createEntityAdapter<NotificationTemplateDTO>({
    selectId: (notificationDefaultTemplate) => notificationDefaultTemplate.id,
  });

export const notificationDefaultTemplateSlice = createSlice({
  name: "notificationDefaultTemplate",
  initialState: notificationDefaultTemplateAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    notificationDefaultTemplateEmptyState: (state: any) => {
      notificationDefaultTemplateAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getNotificationDefaultTemplateAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notificationDefaultTemplateAdapter.setAll(state, action.payload);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getNotificationDefaultTemplateAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getNotificationDefaultTemplateAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const notificationDefaultTemplateSelectors =
  notificationDefaultTemplateAdapter.getSelectors<RootState>(
    (state) => state.notificationDefaultTemplates
  );

export const selectNotificationDefaultTemplateSliceStatus = (state: any) =>
  state.notificationDefaultTemplates.status;
export const selectNotificationDefaultTemplateSliceReasonCode = (state: any) =>
  state.notificationDefaultTemplates.reasonCode;

export const { notificationDefaultTemplateEmptyState } =
  notificationDefaultTemplateSlice.actions;

export default notificationDefaultTemplateSlice.reducer;
