import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageFilters from "../../../layout/PageFilters";
import { FuelLevelSeries, RefuelReport } from "../../../ui/Chart/RefuelReport";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { getQueryString } from "../../../utils/Utils";
import { Driver, driversSelectors } from "../../driver/driversSlice";
import { getFilteredDriversStatusAndDetailsAsync } from "../../driver/driversStatusSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { getVehicleAsync } from "../../vehicle/vehiclesStatusSlice";
import "./RefuellingReport.css";
import { RefuellingReportDetails } from "./RefuellingReportDetails";
import { RefuellingReportFilterBar } from "./RefuellingReportFilterBar";
import { RefuellingReportTableBuilder } from "./RefuellingReportTableBuilder";
import {
  RefuellingChartDataReports,
  refuellingChartDataReportEmptyState,
  refuellingChartDataReportSelectors,
} from "./refuellingChartDataReportSlice";
import {
  RefuellingReports,
  getRefuellingReportAsync,
  refuellingReportEmptyState,
  refuellingReportSelectors,
  selectRefuellingReportSlicePage,
  selectRefuellingReportSliceStatus,
  selectRefuellingReportSliceTotalElements,
} from "./refuellingReportSlice";
import {
  RefuellingReportSummary,
  refuellingReportSummaryEmptyState,
  refuellingReportSummarySelectors,
} from "./refuellingReportSummarySlice";

interface RefuellingReportProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const context = "refuellingReport";
export const RefuellingReport: React.FC<RefuellingReportProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<RefuellingReportTableBuilder>(
    new RefuellingReportTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [columnData, setColumnData] = useState<number[]>();
  const [lineData, setLineData] = useState(
    {} as { fuelLevelSeries: FuelLevelSeries }
  );
  const [titleRangeDate, setTitleRangeDate] = useState<string>("");
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  //#region slices and statuses
  const refuellingReportSummary: RefuellingReportSummary[] = useAppSelector(
    refuellingReportSummarySelectors.selectAll
  );

  const refuellingReport: RefuellingReports[] = useAppSelector(
    refuellingReportSelectors.selectAll
  );

  const refuellingChartDataReport: RefuellingChartDataReports[] =
    useAppSelector(refuellingChartDataReportSelectors.selectAll);

  const refuellingReportSliceStatus = useAppSelector(
    selectRefuellingReportSliceStatus
  );
  const refuellingReportPages = useAppSelector(selectRefuellingReportSlicePage);

  const refuellingReportTotalElements = useAppSelector(
    selectRefuellingReportSliceTotalElements
  );

  const [resetPage, setResetPage] = useState<boolean>(false);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [vehicleId, setVehicleId] = useState(-1);
  const [fleetId, setFleetId] = useState(-1);

  //#endregion

  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [queryParamsChanged]);

  //#region Table Builder
  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "name" ? true : false,
            };
          })
        : []
    );

    tableData.rows =
      refuellingReportSliceStatus === "idle" &&
      presetsSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? refuellingReport.map((refuellingReport: RefuellingReports) => {
            const driver: Driver =
              driversSelectors.selectById(
                store.getState(),
                refuellingReport.driverId
              ) ?? ({} as Driver);

            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                refuellingReport: refuellingReport,
                driver: driver,
                vehicle: vehicle ?? ({} as Vehicle),
              },
              preferencesContext
            );
          })
        : tableData.rows;
  }

  //#endregion Table Builder
  useEffect(() => {
    document.title =
      t("breadcrumbs.refuellingReport") + " - " + t("breadcrumbs.reports");
    store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
    return () => {
      store.dispatch(refuellingReportSummaryEmptyState());
      store.dispatch(refuellingReportEmptyState());
      store.dispatch(refuellingChartDataReportEmptyState());
    };
  }, []);

  //#region chart data
  useEffect(() => {
    const fuelLevelSeries: FuelLevelSeries = {
      fuelLevel: refuellingChartDataReport.map((item) => item.fuelLevel),
      dates: refuellingChartDataReport.map((item) => item.fueldate),
    };
    // Calculate columnData
    setColumnData(
      refuellingChartDataReport.map((item: any) =>
        item.isRefuel ? item.fuelLevel : 0
      )
    );

    // Create the lineData object
    setLineData({ fuelLevelSeries: fuelLevelSeries });

    // Convert dates to the desired format 'DD Month YYYY'
    const formattedData = fuelLevelSeries.dates.map((item: any) => {
      const formattedDate = new Date(item).toLocaleDateString(
        preferencesContext.language,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      );
      return { date: formattedDate, value: item };
    });

    // Extract formatted dates for the title from the input values
    if (formattedData.length > 0) {
      if (
        formattedData[0] !== undefined &&
        formattedData[formattedData.length - 1] !== undefined
      ) {
        const startDate = formattedData[0].date;
        const endDate = formattedData[formattedData.length - 1].date;

        // Check if the dates are the same (same day)
        if (startDate === endDate) {
          setTitleRangeDate(startDate);
        } else {
          setTitleRangeDate(`${startDate.split(" ")[0]} - ${endDate}`);
        }
      }
    } else {
      setTitleRangeDate("");
    }
  }, [refuellingChartDataReport]);
  //#endregion chart data

  useEffect(() => {
    if (
      vehicleId != undefined &&
      vehicleId != -1 &&
      fleetId != undefined &&
      fleetId != -1
    ) {
      store
        .dispatch(getVehicleAsync({ id: vehicleId, fleetId: fleetId }))
        .then((response) => {
          if (response != undefined && response?.payload != undefined) {
            let payload: any = response?.payload;
            setVehicle(payload?.vehicle[vehicleId]);
          }
        });
    }
  }, [vehicleId, fleetId]);

  return (
    <React.Fragment>
      <PageFilters>
        <div className="col col-16">
          <RefuellingReportFilterBar
            presets={presets}
            callback={(vehicleId, fleetId, queryString) => {
              setQueryParamsFromFilterBar(queryString);
              setFleetId(fleetId);
              setVehicleId(vehicleId);
            }}
            disableButton={tableData.rows?.length === 0}
          />
        </div>

        {queryParamsFromFilterBar !== "" && (
          <div className="col col-16">
            {lineData && columnData && (
              <RefuelReport
                lineData={lineData}
                columnData={columnData}
                title={titleRangeDate}
              />
            )}
            <div className="details-refuelling-report-wrapper">
              <div className="details-refuelling-report-title">
                {t("report.details.title")}
                {refuellingReportSliceStatus === "loading" && (
                  <div className="details-refuelling-report-loader" />
                )}
              </div>
              <div className="details-refuelling-report-container">
                <div className="details-refuelling-report-icons">
                  <RefuellingReportDetails
                    totalTraveled={refuellingReportSummary[0]?.totalTraveled}
                    refueled={refuellingReportSummary[0]?.refueled}
                    refuels={refuellingReportSummary[0]?.refuels}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <div className="refuelling-report-table-container">
          <div className="refuelling-report-rows-container">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <PageCounter
            isActionPerforming={refuellingReportSliceStatus === "loading"}
            totalElements={refuellingReportTotalElements}
            resetPage={resetPage}
            numOfPages={refuellingReportPages}
            onClick={(id) => {
              const pageAndSize = getQueryString({
                page: id - 1,
                size: "10",
              });
              const finalQueryParams = queryParamsFromFilterBar
                ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                : pageAndSize;
              store.dispatch(
                getRefuellingReportAsync({
                  vehicleId: vehicleId,
                  queryParams: finalQueryParams,
                })
              );
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};
