import { t } from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReportRoute } from "../../utils/Utils";
import { DropdownButton } from "../Button/DropdownButton";
import { DataLabel } from "../DataLabel/DataLabel";
import { Tooltip } from "../Forms/Tooltip";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { IconCalendar } from "../Icon/Line/Calendar";
import "./Report.css";

export const Report = ({
  id,
  title,
  description,
  isScheduled,
  isLoading,
  scheduleOptions,
  getReport,
  isReportFound,
  lang,
}) => {
  const cronstrue = require("cronstrue/i18n");
  const navigate = useNavigate();
  const [schedulationSelected, setSchedulationSelected] = useState(0);

  const dropdownList = [
    {
      id: 0,
      title: t("reportSchedulation.editReportSchedulation"),
      onClick: () => {
        window.open(
          "/reports/" +
            getReportRoute(scheduleOptions[0]?.type) +
            "/editSchedulation/" +
            schedulationSelected,
          "_blank"
        );
      },
    },
    {
      id: 1,
      title: t("reportSchedulation.viewArchive"),
      onClick: () => {
        window.open(
          "/reports/schedulation/archive/?schedulationName=" +
            scheduleOptions.find((el) => el.id === schedulationSelected)?.name +
            "&type=" +
            scheduleOptions.find((el) => el.id === schedulationSelected)?.type,
          "_blank"
        );
      },
    },
  ];

  const [isOpen, setIsOpen] = useState({ id: id, open: false });
  const [isOpenSchedules, setIsOpenSchedules] = useState(false);

  const handleClick = () => {
    setIsOpenSchedules((prevState) => !prevState);
  };

  const onClick = () => {
    getReport(id);
  };

  const extractReportFileType = (parameters) => {
    const xlsxPattern = /isXlxs=([^&]+)/g;
    const pdfPattern = /isPdf=([^&]+)/g;
    const xlsxMatches = parameters.match(xlsxPattern);
    const pdfMatches = parameters.match(pdfPattern);
    if (xlsxMatches && pdfMatches) {
      return "XLSX, PDF";
    } else if (xlsxMatches && !pdfMatches) {
      return "XLSX";
    } else if (!xlsxMatches && pdfMatches) {
      return "PDF";
    }
  };

  const extractEmails = (parameters) => {
    const emailPattern = /emails=([^&]+)/g;
    const matches = parameters.match(emailPattern);
    if (!matches) {
      return [];
    }
    const emails = matches.map((match) => match.split("=")[1]);
    const concatenatedEmails =
      emails.length > 1 ? emails[0] : emails.join(", ");
    return concatenatedEmails;
  };

  const remainingEmailsCount = (parameters) => {
    const emailPattern = /emails=([^&]+)/g;
    const matches = parameters.match(emailPattern);
    if (!matches) {
      return [];
    }
    const emails = matches.map((match) => match.split("=")[1]);
    return emails.length - 1;
  };

  const remainingEmails = (parameters) => {
    const emailPattern = /emails=([^&]+)/g;
    const matches = parameters.match(emailPattern);
    if (!matches) {
      return [];
    }
    const emails = matches.map((match) => match.split("=")[1]);
    const remainingEmails = emails.slice(1).join(", ");
    return remainingEmails;
  };

  return (
    <>
      <div
        key={id}
        className={
          isReportFound ? "mn-report" : "mn-report mn-report-not-found"
        }
      >
        <div onClick={isReportFound ? onClick : undefined}>
          <DataLabel
            dataText={description}
            label={title}
            isLoading={isLoading}
            isScheduled={isScheduled}
          />
        </div>

        {isReportFound && (
          <span className="mn-report__chevron" onClick={handleClick}>
            {isOpenSchedules ? (
              <IconArrowDown size={14} color="--global-colors-ink-dark" />
            ) : (
              <IconArrowRight size={14} color="--global-colors-ink-dark" />
            )}
          </span>
        )}
      </div>
      {isOpenSchedules &&
        scheduleOptions &&
        scheduleOptions.map((el, index) => {
          return (
            <div key={index} className="mn-report__schedule">
              <div className="mn-report__schedule-expand"></div>
              <div className="mn-report__schedule-text">
                <div>
                  <IconCalendar size={12} color="--global-colors-ink-light" />
                  <span className="mn-report__schedule-title">
                    {t("reports.scheduled")}
                  </span>
                </div>
                <span className="mn-report__schedule-sub-title-bold">
                  {cronstrue.toString(el.cronExpression, { locale: lang })}
                  <span className="mn-report__schedule-sub-title">
                    {t("reports.system")}
                    <span className="mn-report__schedule-sub-title-bold">
                      {extractReportFileType(el.parameters)}
                    </span>
                    {" " + t("reports.to")} {extractEmails(el.parameters)}
                  </span>
                  <Tooltip />
                  <span
                    data-for="tooltip"
                    data-tip={
                      remainingEmailsCount(el.parameters) > 0
                        ? remainingEmails(el.parameters)
                        : ""
                    }
                  >
                    <span className="mn-report__schedule-sub-title-bold">
                      {remainingEmailsCount(el.parameters) > 0
                        ? " +" + remainingEmailsCount(el.parameters)
                        : ""}
                    </span>
                  </span>
                </span>
              </div>
              <div
                className="mn-report__schedule-dropdown"
                style={{
                  zIndex: isOpen.open && isOpen.id === index ? 99999 : "",
                }}
              >
                <DropdownButton
                  aspect="ghost"
                  size="small"
                  list={dropdownList}
                  onClick={
                    isReportFound &&
                    (() => {
                      setSchedulationSelected(el.id);

                      if (isOpen.id !== index) {
                        setIsOpen({
                          id: index,
                          open: true,
                        });
                      } else {
                        setIsOpen({
                          id: -1,
                          open: false,
                        });
                      }
                    })
                  }
                  disabled={false}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

Report.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isScheduled: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasSchedule: PropTypes.bool,
  isReportFound: PropTypes.bool,
};

Report.defaultProps = {
  isScheduled: false,
  isLoading: false,
  isReportFound: true,
};
