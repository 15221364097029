import HttpClient from "../../utils/HttpClient";
export default class NotificationsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getFilteredNotifications(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    let path = "/tenants/" + tenantId + "/users/" + userId + "/notifications";
    return this.instance.get(path + (customQueryParams ?? ""));
  }

  public readOneNotification(id: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.patch(
      "/tenants/" + tenantId + "/users/notifications/" + id + "/read/true"
    );
  }

  public readAllNotifications() {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.patch(
      "/tenants/" + tenantId + "/users/" + userId + "/notifications/read"
    );
  }

  public getNotificationMetadata(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/notifications/metadata" + queryParams
    );
  }
}
