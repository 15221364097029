import React, { useState } from "react";
import TextField from "./TextField";

interface TimePickerProps {
  id: string;
  date: string;
  setDate: (value: string) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  date,
  setDate,
  id,
}) => {
  const [value, setValue] = useState(date);

  const handleDateChange = (data: any) => {
    setValue(data.value);
    setDate(data.value);
  };

  return (
    <div className="time-picker">
      <TextField
        type="time"
        id={id}
        size="small"
        placeholder=""
        value={value}
        events={{
          onChange: handleDateChange,
        }}
      />
    </div>
  );
};
