import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { ReportArchiveExecutions } from "./reportArchiveExecutionsSlice";
import {
  reportArchiveSchema,
  reportArchivesSchema,
} from "./reportArchiveNormalization";
import ReportsArchiveRepository from "./reportArchiveRepository";

export const WeekSequenceEnumValues = {
  FIRST: "FIRST",
  SECOND: "SECOND",
  THIRD: "THIRD",
  FOURTH: "FOURTH",
  LAST: "LAST",
};
export type WeekSequenceEnum = keyof typeof WeekSequenceEnumValues;

export const ReportPeriodEnumValues = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};
export type ReportPeriodEnum = keyof typeof ReportPeriodEnumValues;

export interface ReportArchive {
  id: number;
  name: string;
  cronExpression: string;
  startDate: Date;
  active: boolean;
  type: string;
  parameters: string;
  jobName: string;
  jobGroup: string;
  lastScheduledReportExecution: ReportArchiveExecutions;
  cronExpressionModule: CronExpressionModule;
}

export interface CronExpressionModule {
  id: number;
  reportPeriodEnum: ReportPeriodEnum;
  recurrence: number;
  dayOfMonth: number;
  daysOfWeek: string[];
  months: string[];
  weekSequence: WeekSequenceEnum;
}

export interface CronExpressionRequest {
  reportPeriodEnum: ReportPeriodEnum;
  recurrence: number;
  dayOfMonth: number;
  daysOfWeek: number[];
  months: number[];
  weekSequence: WeekSequenceEnum;
}

export interface ReportSchedulateRequest {
  name: string;
  cronExpressionRequest: CronExpressionRequest;
  startDate: Date;
  type: string;
  reportTypes: any[];
  addressBookIds: number[];
  parameters: string;
  presetName: string;
  optionalCronExpression: string;
  sleepTime: number;
}

export const createNewSchedulationAsync = createAsyncThunk(
  "report/scheduledReports/create",
  async (data: { object: any }, { rejectWithValue, dispatch }) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.createReportSchedulation(
        data.object
      );
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateReportSchedulationAsync = createAsyncThunk(
  "report/scheduledReports/update",
  async (
    data: { object: any; schedulationId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.updateReportSchedulation(
        data.object,
        data.schedulationId
      );
      return response;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getReportScheduledAsync = createAsyncThunk(
  "report/scheduledReport",
  async (
    data: { scheduledReportId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.getReportArchive(
        data.scheduledReportId
      );
      const normalizedData = normalize(response.data, reportArchiveSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getAllScheduledReportAsync = createAsyncThunk(
  "report/scheduledReports",
  async (data: { queryParams: string }, { rejectWithValue, dispatch }) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.getAllReportsArchive(
        data.queryParams
      );
      const totalPages = _.get(response, Config.ARCHIVE_REPORT_TOTAL_PAGES);
      const totalElements = _.get(
        response,
        Config.ARCHIVE_REPORT_TOTAL_ELEMENS
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const content = _.get(response, Config.ARCHIVE_REPORT_RESPONSE_PATH);
      const normalizedData = normalize(content, reportArchivesSchema);

      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deleteScheduledReportAsync = createAsyncThunk(
  "report/scheduledReports/delete",
  async (
    data: { scheduledReportId: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const reportsArchiveRepository = new ReportsArchiveRepository();
      const response = await reportsArchiveRepository.deleteReportSchedulation(
        data.scheduledReportId
      );
      return response;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

const reportsArchiveAdapter = createEntityAdapter<ReportArchive>({
  selectId: (reportArchive) => reportArchive.id,
});

export const reportsArchiveSlice = createSlice({
  name: "reportsArchive",
  initialState: reportsArchiveAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    reportsArchiveEmptyState: (state) => {
      reportsArchiveAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllScheduledReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsArchiveAdapter.setAll(
            state,
            action?.payload?.reportArchives ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getAllScheduledReportAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAllScheduledReportAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getReportScheduledAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsArchiveAdapter.upsertMany(
            state,
            action?.payload?.reportArchives ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getReportScheduledAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportScheduledAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        createNewSchedulationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsArchiveAdapter.upsertMany(
            state,
            action?.payload?.reportArchives ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(createNewSchedulationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createNewSchedulationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        updateReportSchedulationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsArchiveAdapter.upsertMany(
            state,
            action?.payload?.reportArchives ?? {}
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(updateReportSchedulationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(updateReportSchedulationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        deleteScheduledReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(deleteScheduledReportAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(deleteScheduledReportAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const reportsArchiveSelectors =
  reportsArchiveAdapter.getSelectors<RootState>(
    (state) => state.reportsArchive
  );

export const selectReportArchiveStatusSliceStatus = (state: any) =>
  state.reportsArchive.status;
export const selectReportArchiveStatusSliceReasonCode = (state: any) =>
  state.reportsArchive.reasonCode;
export const selectReportArchiveStatusSlicePage = (state: any) =>
  state.reportsArchive.totalPages;
export const selectReportArchiveStatusSlicTotalElements = (state: any) =>
  state.reportsArchive.totalElements;
export const {
  setNumberOfPages,
  setNumberOfElements,
  reportsArchiveEmptyState,
} = reportsArchiveSlice.actions;

export default reportsArchiveSlice.reducer;
