import PropTypes from "prop-types";
import "../IconFont/icons.css";
import { IconExternalLink } from "./ExternalLink";

export const IconExternalLinkWithText = ({ size, text, ...props }) => (
  <div className="toast-description">
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconExternalLink
        className=""
        size={14}
        color="--global-colors-ui-white"
        onClick={() => window.open("/reports/schedulation/archive")}
      />
      <div style={{ marginLeft: "10px" }}>{text}</div>
    </div>
  </div>
);

IconExternalLinkWithText.propTypes = {
  /**
   * Size of the icon.
   */
  size: PropTypes.number,
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
  /**
   * Color of the icon.
   */
  text: PropTypes.string,
};

IconExternalLinkWithText.defaultProps = {
  color: "--global-colors-ui-primary",
  size: 24,
  text: "",
};
