import HttpClient from "../../utils/HttpClient";
import { AddressBook } from "./addressBookSlice";

export default class AddressBookRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getAddressesBook(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let queryParams = "";
    if (customQueryParams) {
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(
      "/tenants/" + tenantId + "/addressesBook" + queryParams
    );
  }

  public getAddressBook(addressBookId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/addressesBook/" + addressBookId;
    return this.instance.get(path);
  }

  public createAddressBook(addressBook: AddressBook) {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/addressesBook";
    return this.instance.post(path, addressBook);
  }

  public editAddressBook(addressBook: AddressBook) {
    const tenantId = localStorage.getItem("tenantId");
    const path =
      "/tenants/" + tenantId + "/addressBook/contact/" + addressBook.id;
    return this.instance.put(path, addressBook);
  }

  public deleteAddressBook(addressBookId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/addressBook/" + addressBookId;
    return this.instance.delete(path);
  }

  public validateAddressBook(addressBookId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/addressBook/" + addressBookId;
    return this.instance.get(path);
  }
}
