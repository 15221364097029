import { schema } from "normalizr";
import { tenantSchema } from "../tenants/tenantNormalization";
import { preferencesSchema } from "./preference/preferencesNormalization";

/**
 * This schema must be used when you want to ignore security and privileges properties
 * because the server-side functionality (i.e., the API) might not return them valued
 */
export const userInfoSchemaWithoutPrivilegeAndSecurity = new schema.Entity(
  "userInfo",
  {
    preferences: preferencesSchema,
    tenant: tenantSchema,
  },
  {
    processStrategy: (value, parent, key) => {
      const { securityProfile, privileges, ...rest } = value;
      return rest;
    },
  }
);

export const userInfoSchema = new schema.Entity<any>("userInfo", {
  preferences: preferencesSchema,
  tenant: tenantSchema,
});

export const usersInfoSchema = new schema.Array(userInfoSchema);
