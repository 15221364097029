import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { Button } from "../../../ui/Button/Button";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconAlerts } from "../../../ui/Icon/Line/Alerts";
import { IconTitle } from "../../../ui/IconTitle/IconTitle";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString } from "../../../utils/Utils";
import {
  AddressBook,
  addressBookSelectors,
} from "../../address/addressBookSlice";
import {
  Preset,
  getPresetsAsync,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import "./AdminNotification";
import "./AdminNotification.css";
import { AdminNotificationsFilterBar } from "./AdminNotificationFilterBar";
import { AdminNotificationTableBuilder } from "./AdminNotificationTableBuilder";
import ActivateNotificationRuleAggregationModal from "./modals/ActivateNotificationRuleAggregationModal";

import { Preferences } from "../../users/preference/preferencesSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import { AddressBookModals } from "./modals/AddressBookModal";
import DeactivateNotificationRuleAggregationModal from "./modals/DeactivateNotificationRuleAggregationModal";
import DeleteNotificationRuleAggregationModal from "./modals/DeleteNotificationRuleAggregationModal";
import {
  NotificationRuleAggregation,
  NotificationRuleSchedulation,
  deleteNotificationRuleAggregationAsync,
  getFilteredNotificationsRuleAggregationsAsync,
  notificationRuleAggregationsSelectors,
  notificationsRuleAggregationEmptyState,
  selectNotificationRuleAggregationSlicePage,
  selectNotificationRuleAggregationSliceReasonCode,
  selectNotificationRuleAggregationSliceStatus,
  selectNotificationRuleAggregationSliceTotalElements,
  updateStatus,
} from "./notificationRuleAggregationSlice";
import {
  NotificationRuleDestination,
  notificationRuleDestinationsSelectors,
} from "./notificationRuleDestinationSlice";
import {
  NotificationRule,
  notificationRulesSelectors,
} from "./notificationRuleSlice";

interface AdminNotificationProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

export const NotificationStatusEnum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const AdminNotification: React.FC<AdminNotificationProps> = ({
  permissions,
}) => {
  //#region query params declaration
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const noQueryParamsFromFilterBar =
    (searchParams.toString().split("&").length === 1 &&
      !!searchParams.get("size")) ||
    searchParams.toString() === "";
  //#endregion query params declaration

  //#region table builder declaration
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const context = "notificationRulesAdmin";
  const tableBuilderRef = useRef<AdminNotificationTableBuilder>(
    new AdminNotificationTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  const tableData: TableData = {} as TableData;
  //#endregion table builder declaration

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  //#region data slice declaration
  const [isLoading, setIsLoading] = useState(true);
  const [currentAction, setCurrentAction] = useState("");
  const notificationRuleAggregationSliceStatus = useAppSelector(
    selectNotificationRuleAggregationSliceStatus
  );
  const notificationRuleAggregationSliceReasonCode = useAppSelector(
    selectNotificationRuleAggregationSliceReasonCode
  );
  const notificationRulePages = useAppSelector(
    selectNotificationRuleAggregationSlicePage
  );

  const notificationRuleTotalElements = useAppSelector(
    selectNotificationRuleAggregationSliceTotalElements
  );
  const isNotificationRuleIdle =
    notificationRuleAggregationSliceStatus === "idle";
  const inLoading = notificationRuleAggregationSliceStatus === "loading";
  const [
    selectedNotificationRuleAggregationId,
    setSelectedNotificationRuleAggregationId,
  ] = useState(-1);

  const selectedNotificationRuleAggregation: NotificationRuleAggregation =
    notificationRuleAggregationsSelectors.selectById(
      store.getState(),
      selectedNotificationRuleAggregationId
    ) ?? ({} as NotificationRuleAggregation);
  const notificationRuleAggregations: NotificationRuleAggregation[] =
    useAppSelector(notificationRuleAggregationsSelectors.selectAll);
  const notificationRules: NotificationRule[] = useAppSelector(
    notificationRulesSelectors.selectAll
  );
  //#region MODAL_STATES
  const [resetPage, setResetPage] = useState<boolean>(false);

  const [openAddressbookModal, setOpenAddressBookModal] = useState(false);
  const [
    openDeactivateNotificationAggregationModal,
    setopenDeactivateNotificationAggregationModal,
  ] = useState(false);
  const [
    openActivateNotificationAggregationModal,
    setopenActivateNotificationAggregationModal,
  ] = useState(false);

  const [
    openDeleteNotificationRuleAggregationModal,
    setOpenDeleteNotificationRuleAggregationModal,
  ] = useState(false);

  //#endregion MODAL_STATES

  //#region Route
  const navigate = useNavigate();

  const notificationRoute = useMatch(
    "/admin/notifications/:action/:notificationAggregationId"
  );
  const location = useLocation();
  const { pathname } = location;
  const isEditContact = pathname.includes("/edit-contact");
  const isValidateContact = pathname.includes("/validate-contact");
  const isDeleteContact = pathname.includes("/delete-contact");
  const updateContact = isEditContact || isValidateContact || isDeleteContact;

  const notificationsRoute = useMatch("/admin/notifications/:action");
  useEffect(() => {
    if (notificationRoute !== null) {
      if (notificationRoute?.params?.notificationAggregationId) {
        setSelectedNotificationRuleAggregationId(
          Number(notificationRoute.params.notificationAggregationId)
        );
      }

      switch (notificationRoute.params.action) {
        case "activate":
          setopenActivateNotificationAggregationModal(true);
          break;
        case "deactivate":
          setopenDeactivateNotificationAggregationModal(true);
          break;
        case "delete":
          setOpenDeleteNotificationRuleAggregationModal(true);
          break;
        case "delete-contact":
        case "validate-contact":
        case "edit-contact":
          setOpenAddressBookModal(true);
          setCurrentAction("contacts-list");
          break;
        default:
          break;
      }
    }
    if (notificationsRoute !== null) {
      setOpenAddressBookModal(true);
      setCurrentAction(notificationsRoute.params.action ?? "");
    } else if (notificationRoute == null) {
      setOpenAddressBookModal(false);
    }
  }, [navigate, notificationRoute, notificationsRoute]);

  //#endregion route

  //#region toast notifications
  useEffect(() => {
    if (notificationRuleAggregationSliceStatus === "idle") {
      switch (notificationRuleAggregationSliceReasonCode) {
        case GTFleetSuccessCodes.DELETE:
          ToastNotification({
            toastId: "notificationRuleDeleteSuccess",
            status: "success",
            description: t("admin.notifications.success.DELETE"),
          });
          break;
        case GTFleetSuccessCodes.PATCH:
          ToastNotification({
            toastId: "rulePatchSuccess",
            status: "success",
            description:
              notificationRoute?.params.action == "ACTIVATE"
                ? t("admin.notifications.success.ACTIVATED")
                : t("admin.notifications.success.DEACTIVATED"),
          });
          break;
        default:
          break;
      }
    }

    if (notificationRuleAggregationSliceStatus === "failed") {
      switch (notificationRuleAggregationSliceReasonCode) {
        case GTFleetErrorCodes.NOTIFICATION_RULE_AGGREGATION_ALREADY_ACTIVATED:
          ToastNotification({
            toastId: "ruleAlreadyActivated",
            status: "error",
            description: t("admin.notifications.error.ACTIVATED"),
          });
          break;
        case GTFleetErrorCodes.NOTIFICATION_RULE_AGGREGATION_ALREADY_DEACTIVATED:
          ToastNotification({
            toastId: "ruleAlreadyDeactivated",
            status: "error",
            description: t("admin.notifications.error.DEACTIVATED"),
          });
          break;
        case GTFleetErrorCodes.NOTIFICATION_RULE_ALREADY_EXISTS:
        case GTFleetErrorCodes.NOTIFICATION_RULE_AGGREGATION_NAME_ALREADY_EXISTS:
          ToastNotification({
            toastId: "notificationRuleAlreadyExists",
            status: "error",
            description: t(
              "admin.notifications.error.NOTIFICATION_RULE_AGGREGATION_NAME_ALREADY_EXISTS"
            ),
          });
          break;
        case GTFleetErrorCodes.NOTIFICATION_RULE_AGGREGATION_NOT_FOUND:
          ToastNotification({
            toastId: "notificationRuleNotFoundError",
            status: "error",
            description: t(
              "admin.notifications.error.NOTIFICATION_RULE_AGGREGATION_NOT_FOUND"
            ),
          });
          break;
        case GTFleetErrorCodes.INTERNAL_SERVER_ERROR:
        case "":
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [
    notificationRuleAggregationSliceStatus,
    notificationRuleAggregationSliceReasonCode,
  ]);
  //#endregion toast notifications

  //#region Modal

  function closeDeleteNotificationRuleAggregationModal(
    notificationAggregationRuleId?: number
  ) {
    if (notificationAggregationRuleId) {
      store.dispatch(
        deleteNotificationRuleAggregationAsync(notificationAggregationRuleId)
      );
    }
    setOpenDeleteNotificationRuleAggregationModal(false);
    navigate({
      pathname: "/admin/notifications/",
      search: queryParamsFromFilterBar,
    });
  }

  function closeActivateNotificationAggregationModal(id?: number) {
    if (id) {
      store.dispatch(
        updateStatus({
          notificationRuleId: Number(
            notificationRoute?.params.notificationAggregationId
          ),
          status: String(NotificationStatusEnum.ACTIVE),
        })
      );
    }
    setopenActivateNotificationAggregationModal(false);
    navigate({
      pathname: "/admin/notifications",
      search: queryParamsFromFilterBar,
    });
  }

  function closeDectivateNotificationAggregationModal(id?: number) {
    if (id) {
      store.dispatch(
        updateStatus({
          notificationRuleId: Number(
            notificationRoute?.params.notificationAggregationId
          ),
          status: String(NotificationStatusEnum.INACTIVE),
        })
      );
    }
    setopenDeactivateNotificationAggregationModal(false);
    navigate({
      pathname: "/admin/notifications",
      search: queryParamsFromFilterBar,
    });
  }

  function closeAddressBookModal() {
    setOpenAddressBookModal(false);
    navigate({
      pathname: "/admin/notifications",
      search: queryParamsFromFilterBar,
    });
  }
  //#endregion Modal

  //#region query params

  useEffect(() => {
    if (queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isNotificationRuleIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isNotificationRuleIdle, queryParamsChanged]);
  //#endregion query params

  //#region Table
  useEffect(() => {
    document.title = t("navigation.adminMenu.notifications") + " - Admin";
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    /**
     * When the component is unmountend the related redux state should be cleaned.
     */
    return function cleanUp() {
      store.dispatch(notificationsRuleAggregationEmptyState());
    };
  }, []);

  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "name" ? true : false,
            };
          })
        : []
    );

    if (tableData.columns && tableData?.columns?.length > 0) {
      tableData.rows = notificationRuleAggregations.map(
        (notificationRuleAggregation: NotificationRuleAggregation) => {
          const notificationRulesOfAnAggregation = notificationRules.filter(
            (x) =>
              notificationRuleAggregation?.notificationRules.includes(x?.id)
          );
          const notificationRuleDestinationsOfRules: NotificationRuleDestination[] =
            [];
          const addressBooks: AddressBook[] = [];
          notificationRulesOfAnAggregation.forEach((x) => {
            x.destinations.forEach((y) => {
              const notificationRuleDestination =
                notificationRuleDestinationsSelectors.selectById(
                  store.getState(),
                  y
                );
              if (
                notificationRuleDestination &&
                !_.includes(
                  notificationRuleDestinationsOfRules,
                  notificationRuleDestination
                )
              ) {
                const addressBook = addressBookSelectors.selectById(
                  store.getState(),
                  notificationRuleDestination?.address
                );
                if (
                  addressBook &&
                  !_.includes(
                    notificationRuleDestinationsOfRules,
                    notificationRuleDestination
                  )
                ) {
                  if (
                    addressBooks.find(
                      (contact) => contact.id === addressBook.id
                    ) === undefined
                  )
                    addressBooks.push(addressBook);
                }
                notificationRuleDestinationsOfRules.push(
                  notificationRuleDestination
                );
              }
            });
          });
          return tableBuilder.rowsBuilder(
            tableData.columns,
            {
              notificationRuleAggregation: notificationRuleAggregation,
              notificationRuleDestinations: notificationRuleDestinationsOfRules,
              notificationRules: notificationRulesOfAnAggregation,
              notificationRulesEntities:
                notificationRuleAggregation.notificationRulesEntities,
              notificationRuleSchedulation:
                notificationRuleAggregation.notificationRuleSchedulation ??
                ({} as NotificationRuleSchedulation),
              preference: preferencesContext,
              addressBooks: _.uniqBy(addressBooks, "id"),
            },
            navigate,
            permissions.notificationRules.write
          );
        }
      );
    } else if (!notificationRuleAggregations.length) {
      tableData.rows = [];
    }
  }

  //#endegion Table

  //#region Render
  return (
    <>
      {permissions.notificationRules.write ? (
        <>
          <PageFilters>
            <div className="col col-16">
              <AdminNotificationsFilterBar
                callback={(queryString: string) => {
                  setQueryParamsFromFilterBar(queryString);
                  isLoading && setIsLoading(false);
                }}
                presets={presets}
              />
            </div>
          </PageFilters>
        </>
      ) : null}
      {openAddressbookModal ? (
        <AddressBookModals
          open={openAddressbookModal}
          sections={[
            { id: 0, name: "add-contact" },
            { id: 1, name: "contacts-list" },
          ]}
          currentActive={currentAction}
          onClose={closeAddressBookModal}
        />
      ) : null}
      {openActivateNotificationAggregationModal && (
        <ActivateNotificationRuleAggregationModal
          selectedNotificationRuleAggreagtion={
            selectedNotificationRuleAggregation
          }
          open={openActivateNotificationAggregationModal}
          onClose={closeActivateNotificationAggregationModal}
        />
      )}
      {openDeactivateNotificationAggregationModal && (
        <DeactivateNotificationRuleAggregationModal
          selectedNotificationRuleAggreagtion={
            selectedNotificationRuleAggregation
          }
          open={openDeactivateNotificationAggregationModal}
          onClose={closeDectivateNotificationAggregationModal}
        />
      )}
      {openDeleteNotificationRuleAggregationModal && (
        <DeleteNotificationRuleAggregationModal
          openDeleteNotificationRuleAggregationModal={
            openDeleteNotificationRuleAggregationModal
          }
          selectedNotificationRuleAggregation={
            selectedNotificationRuleAggregation
          }
          closeDeleteNotificationRuleAggregationModal={
            closeDeleteNotificationRuleAggregationModal
          }
        />
      )}
      {tableData?.rows?.length > 0 && (
        <PageContent>
          <div className="notification-table">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <div className="adminNotification-pagination">
            <PageCounter
              isActionPerforming={inLoading}
              totalElements={notificationRuleTotalElements}
              resetPage={resetPage}
              disabled={!isNotificationRuleIdle}
              numOfPages={notificationRulePages}
              onClick={(id, currentPage) => {
                if (id !== currentPage) {
                  const pageAndSize = getQueryString({
                    page: id - 1,
                    size: "10",
                  });
                  const finalQueryParams = queryParamsFromFilterBar
                    ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                    : pageAndSize;
                  store.dispatch(
                    getFilteredNotificationsRuleAggregationsAsync({
                      queryParams: finalQueryParams,
                    })
                  );
                }
              }}
            />
          </div>
        </PageContent>
      )}
      {!isLoading &&
        isNotificationRuleIdle &&
        notificationRuleAggregations.length === 0 &&
        noQueryParamsFromFilterBar && (
          <PageContent>
            <div className="col">
              <div className="notification-page-withoutTable">
                <div className="notification-page-withoutTable-item">
                  <IconTitle
                    icon={<IconAlerts color="currentColor" size={48} />}
                    title={t("admin.notifications.withoutTableComponent.title")}
                    text={t("admin.notifications.withoutTableComponent.desc")}
                  />
                  <div className="notification-page-withoutTable-addRule">
                    <Button
                      aspect="primary"
                      size="small"
                      label={t("admin.notifications.actions.create.addRule")}
                      onClick={() => navigate("/admin/notifications/add-rule")}
                    >
                      <IconAdd size={14} color="--global-colors-ink-white" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </PageContent>
        )}
    </>
  );
};
//#endregion Render

export default AdminNotification;
