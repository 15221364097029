import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Tag } from "../Tags/Tag";
import "./TextFieldTag.css";

export const TextFieldTag = ({ values, defaultValue, getValues }) => {
  const [items, setItems] = useState(values);

  const handleItem = (item) => {
    const updatedItems = values.filter((prevItem) => prevItem !== item);
    setItems(updatedItems);
  };

  const tag = (times) => {
    const time = times.split("|");

    const startTime = time[0].trim();
    const endTime = time[1].trim();

    return `${t("admin.notifications.actions.period.from")} ${startTime} ${t(
      "admin.notifications.actions.period.to"
    )} ${endTime}`;
  };

  useEffect(() => {
    getValues(items);
  }, [items]);

  return (
    <div>
      <div className="textfield-container-tags">
        {!_.isEmpty(values) ? (
          values.map((el, idx) => {
            return (
              <div className="textfield-tags-time-slot" key={idx}>
                <Tag
                  text={tag(el)}
                  size="regular"
                  type="neutro"
                  mode="fill"
                  hasIcon={true}
                  onClick={() => {
                    handleItem(el);
                  }}
                />
              </div>
            );
          })
        ) : (
          <div className="textfield-tags-time-slot-default-value">
            <Tag
              text={defaultValue}
              hasIcon={false}
              size="regular"
              type="neutro2"
              mode="fill"
              onClick={() => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};
