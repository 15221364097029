import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Button } from "../../../ui/Button/Button";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { getQueryString } from "../../../utils/Utils";
import { selectReportArchiveExecutionsStatusSliceStatus } from "./reportArchiveExecutionsSlice";
import { selectReportArchiveStatusSliceStatus } from "./reportArchiveSlice";

interface ReportsArchiveFilterBarProps {
  filter: (params: any) => any;
}

interface QueryParams {
  [paramName: string]: any;
}

export const ReportsArchiveModalFilterBar: React.FC<ReportsArchiveFilterBarProps> =
  ({ filter }) => {
    const [generationDate, setGenerationDate] = useState<string[]>(["", ""]);
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const [stringifiedQueryString, setStringifiedQueryString] = useState("");

    const archiveSliceStatus = useAppSelector(
      selectReportArchiveStatusSliceStatus
    );

    const archiveExecutionSliceStatus = useAppSelector(
      selectReportArchiveExecutionsStatusSliceStatus
    );

    useEffect(() => {
      queryParams["page"] = 0;
      queryParams["size"] = 5;
      queryParams["sort"] = "executionTime,DESC";
      filter(getQueryString(queryParams));
    }, []);

    const handleChanges = (
      params: Map<string, string[] | string>,
      generateImmediatly?: boolean
    ): void => {
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value) {
            queryParams[key] = value;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
            }
          }
        });

        queryParams["endPeriod"] == null && delete queryParams["startPeriod"];

        setStringifiedQueryString(getQueryString(queryParams));
      }
    };

    return (
      <>
        <div className="report-archive-filterbar">
          <Form>
            <DatePickerDropdown
              icon={<IconCalendar size={14} color="#687484" />}
              placeholder={t("report.archive.generationDate")}
              initialValue={initialDateValue}
              setDate={(val) => {
                const period = [];
                if (val[0]) {
                  period.push(val[0].format("YYYY/MM/DD"));
                }
                if (val[1]) {
                  period.push(val[1].format("YYYY/MM/DD"));
                }
                setGenerationDate(period);

                const map = new Map();
                const startPeriod =
                  typeof val[0] === "string"
                    ? val[0]
                    : val[0].format("YYYY-MM-DDTHH:mm");
                map.set("startPeriod", startPeriod);
                map.set("endPeriod", val[1]?.format("YYYY-MM-DDTHH:mm") ?? "");

                if (map.get("endPeriod") === "") {
                  map.delete("startPeriod");
                  map.delete("endPeriod");
                  delete queryParams["startPeriod"];
                  delete queryParams["endPeriod"];
                }

                handleChanges(map);
              }}
            />
          </Form>
          <Button
            isLoading={
              archiveSliceStatus === "loading" ||
              archiveExecutionSliceStatus === "loading"
            }
            aspect="primary"
            size={"small"}
            label={t("common.search")}
            onClick={() => filter(stringifiedQueryString)}
          />
        </div>
      </>
    );
  };
