import PropTypes from "prop-types";

export const IllustrationMiniExcavator = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 70 60"
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="100.191%"
          x2=".37%"
          y1="49.903%"
          y2="49.903%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-2"
          x1="39.141%"
          x2="51.043%"
          y1="52.714%"
          y2="47.376%"
        >
          <stop offset="13%" stopColor="#DEE3E7"></stop>
          <stop offset="84%" stopColor="#A7AAAC"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-3"
          x1="30.071%"
          x2="61.308%"
          y1="69.819%"
          y2="22.886%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-4"
          x1="39.773%"
          x2="43.345%"
          y1="51.779%"
          y2="49.094%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-5"
          x1="18350.905%"
          x2="18309.065%"
          y1="-1088400.84%"
          y2="-1088326.64%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-6"
          x1="18374.256%"
          x2="18331.698%"
          y1="-1089505.47%"
          y2="-1089430.54%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-7"
          x1="9565.956%"
          x2="9548.418%"
          y1="-427513.22%"
          y2="-427489.858%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-8"
          x1="19113.629%"
          x2="19070.231%"
          y1="-1247115.92%"
          y2="-1247030.16%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-9"
          x1="13543.081%"
          x2="13512.178%"
          y1="-674062.743%"
          y2="-674015.975%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-10"
          x1="20011.127%"
          x2="19965.134%"
          y1="-1284018.49%"
          y2="-1283930.2%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-11"
          x1="48.938%"
          x2="50.495%"
          y1="52.55%"
          y2="54.944%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-12"
          x1="10098.756%"
          x2="10067.519%"
          y1="-501705.077%"
          y2="-501658.144%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-13"
          x1="22.4%"
          x2="64.176%"
          y1="84.804%"
          y2="4.551%"
        >
          <stop offset="16%" stopColor="#828586"></stop>
          <stop offset="87%" stopColor="#BABEC1"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-14"
          x1="10832.301%"
          x2="10888.908%"
          y1="-175264.4%"
          y2="-175264.4%"
        >
          <stop offset="13%" stopColor="#DEE3E7"></stop>
          <stop offset="84%" stopColor="#A7AAAC"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-15"
          x1="8865.081%"
          x2="8823.524%"
          y1="-726580.463%"
          y2="-726493.25%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-16"
          x1="3787.468%"
          x2="3887.289%"
          y1="49.903%"
          y2="49.903%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-17"
          x1="16392.817%"
          x2="16355.034%"
          y1="-1088517.15%"
          y2="-1088442.95%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-18"
          x1="16927.719%"
          x2="16887.755%"
          y1="-1118830.55%"
          y2="-1118753.63%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-19"
          x1="18544.788%"
          x2="18501.39%"
          y1="-1246164.1%"
          y2="-1246078.34%"
        >
          <stop offset="16%" stopColor="#A7AAAC"></stop>
          <stop offset="87%" stopColor="#DEE3E7"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-80 -763)">
          <g fillRule="nonzero" transform="translate(80 763) translate(1 9)">
            <path
              fill="#031832"
              fillOpacity="0.122"
              d="M3.236 38.696l6.155 3.941c.56.358 1.247.427 1.865.196l6.486-2.477-4.679-2.282 9.072-3.48 1.784.852 6.75-3.169 6.955 4.172-4.587 2.512a.657.657 0 00-.023 1.13l2.414 1.417a10.455 10.455 0 004.885 1.429l1.555.058a8.21 8.21 0 004.485-1.153l20.729-12.354c.857-.507.857-1.763 0-2.27L51.82 18.275l-28.325 9.23-4.027 4.807-16.244 6.396.011-.012z"
            ></path>
            <g>
              <g transform="translate(36.91 19.7)">
                <g transform="translate(26.813 2.213)">
                  <path
                    fill="#586066"
                    d="M3.36452488 1.95041447L0.0344490602 0.125465258 0.172245301 0 3.4908381 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.4908381 1.85916701L3.4908381 2.78304755 3.36452488 2.87429501 3.36452488 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0.0344490602 0.125465258L0 1.03793987 3.36452488 2.87429501 3.36452488 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(26.56 4.118)">
                  <path
                    fill="#586066"
                    d="M3.3760079 1.95041447L0.0344490602 0.125465258 0.172245301 0 3.4908381 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.4908381 1.85916701L3.10041542 2.82867128 2.97410219 2.91991874 3.3760079 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0.0344490602 0.125465258L0 1.02653393 2.97410219 2.91991874 3.3760079 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(26.296 .456)">
                  <path
                    fill="#586066"
                    d="M3.33007582 1.95041447L0 0.125465258 0.137796241 0 3.45638904 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.45638904 1.85916701L3.9501589 2.78304755 3.82384568 2.87429501 3.33007582 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.125465258L0.447837782 1.03793987 3.82384568 2.87429501 3.33007582 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(2.538 19.47)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(4.364 18.386)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(5.948 17.485)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.29259245L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(7.533 16.573)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(9.118 15.66)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.50930517 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.50930517 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(10.702 14.76)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.29259245L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(12.287 13.847)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(13.871 12.934)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.50930517 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.50930517 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(15.456 12.033)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(17.04 11.12)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(18.637 10.22)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(20.222 9.307)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(21.806 8.395)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(23.39 7.494)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(25.125 5.931)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.62336449L0 0.798415281 0.631566103 0 3.9501589 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.9501589 1.85916701L3.9501589 2.05306787 3.3760079 2.81726535 3.3760079 2.62336449z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.798415281L0 0.980910202 3.3760079 2.81726535 3.3760079 2.62336449z"
                  ></path>
                </g>
                <path
                  fill="#204473"
                  d="M.126 17.748c0 1.072.356 1.825.93 2.133l.092.057c.173.08.368.125.586.125.321 0 .677-.103 1.045-.32l.723-.41.218-.125L24.137 7.494c1.401-.81 2.55-2.897 2.653-4.768v-.262c0-.194 0-.388-.035-.56-.091-.684-.356-1.197-.734-1.482L25.676.24l-.16-.046a1.462 1.462 0 00-.322-.034c-.333 0-.678.102-1.057.319L2.78 12.729c-1.183.673-2.182 2.27-2.526 3.878-.08.388-.127.776-.127 1.152v-.011z"
                ></path>
                <path
                  fill="#454545"
                  d="M24.149.456l2.618 1.437.746.411 1.608-.205L26.032.41l-.344-.183-.161-.046a1.462 1.462 0 00-.322-.034c-.333 0-.677.103-1.056.32V.456z"
                ></path>
                <path
                  fill="#42484D"
                  d="M.253 16.596l3.341 1.939 2.538-3.981-3.365-1.836c-1.182.673-2.181 2.27-2.526 3.878h.012z"
                ></path>
                <path
                  fill="#2C3033"
                  d="M.126 17.748c0 1.072.356 1.825.93 2.133l.092.057 2.997 1.585-.436-2.327-.126-.673L.24 16.596c-.08.387-.126.775-.126 1.152h.011z"
                ></path>
                <path
                  fill="#212426"
                  d="M6.143 21.58L27.502 9.33c1.47-.844 2.652-3.091 2.652-5.03s-1.182-2.829-2.652-1.996L6.143 14.554c-1.47.844-2.652 3.091-2.652 5.03s1.183 2.829 2.652 1.996z"
                ></path>
                <path
                  fill="#204473"
                  d="M5.087 20.861c.15 0 .333-.068.528-.182l21.359-12.25c1.136-.65 2.135-2.59 2.135-4.14 0-.616-.172-1.278-.562-1.278-.15 0-.333.069-.528.183L6.66 15.444c-1.137.65-2.136 2.589-2.136 4.14 0 .616.173 1.277.563 1.277z"
                ></path>
                <path
                  fill="#172E4D"
                  d="M25.193746 6.95761888L6.09748365 17.8845023 6.09748365 17.0974929 25.193746 6.18201546z"
                ></path>
                <path
                  fill="#42484D"
                  d="M25.952 7.961c1.136-.65 2.135-2.589 2.135-4.14 0-.205-.022-.422-.068-.616h.011c.195-.125.39-.182.528-.182.38 0 .563.661.563 1.277 0 1.551-1 3.49-2.136 4.14L5.627 20.69c-.196.114-.39.183-.529.183-.252 0-.413-.285-.493-.662l21.347-12.25z"
                ></path>
                <path
                  fill="#42484D"
                  d="M6.14341573 14.55397L2.77889085 12.7176148 24.1487912 0.456237303 27.5133161 2.30399838z"
                ></path>
                <g>
                  <path
                    fill="#0989E6"
                    d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                    transform="translate(5.835 4.72) translate(0 10.63)"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M2.283.242C1.778-.043 1.02.345.572 1.109c-.448.764-.402 1.62.103 1.905.506.285 1.264-.103 1.711-.867.448-.764.402-1.62-.103-1.905z"
                    transform="translate(5.835 4.72) translate(0 10.63)"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                    transform="translate(5.835 4.72) translate(0 10.63) translate(.651 .623)"
                  ></path>
                  <path
                    fill="#1B375C"
                    d="M1.62.224C1.276.03.76.292.46.805.163 1.32.197 1.9.53 2.095c.344.193.86-.07 1.16-.583C1.987 1 1.952.418 1.62.224z"
                    transform="translate(5.835 4.72) translate(0 10.63) translate(.651 .623)"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                    transform="translate(5.835 4.72) translate(4.616 7.973)"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                    transform="translate(5.835 4.72) translate(4.616 7.973)"
                  ></path>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                      transform="translate(5.835 4.72) translate(4.616 7.973) translate(.64 .623)"
                    ></path>
                    <path
                      fill="#1B375C"
                      d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.344.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                      transform="translate(5.835 4.72) translate(4.616 7.973) translate(.64 .623)"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                      transform="translate(5.835 4.72) translate(9.22 5.315)"
                    ></path>
                    <path
                      fill="#172E4D"
                      d="M2.283.242C1.778-.043 1.02.345.572 1.109c-.448.764-.402 1.62.103 1.905.506.285 1.264-.103 1.711-.867.448-.764.402-1.62-.103-1.905z"
                      transform="translate(5.835 4.72) translate(9.22 5.315)"
                    ></path>
                    <g>
                      <path
                        fill="#0989E6"
                        d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                        transform="translate(5.835 4.72) translate(9.22 5.315) translate(.651 .623)"
                      ></path>
                      <path
                        fill="#1B375C"
                        d="M1.62.224C1.276.03.76.292.46.805.163 1.32.197 1.9.53 2.095c.344.193.86-.07 1.16-.583C1.987 1 1.952.418 1.62.224z"
                        transform="translate(5.835 4.72) translate(9.22 5.315) translate(.651 .623)"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                      transform="translate(5.835 4.72) translate(13.837 2.658)"
                    ></path>
                    <path
                      fill="#172E4D"
                      d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                      transform="translate(5.835 4.72) translate(13.837 2.658)"
                    ></path>
                    <g>
                      <path
                        fill="#0989E6"
                        d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                        transform="translate(5.835 4.72) translate(13.837 2.658) translate(.64 .623)"
                      ></path>
                      <path
                        fill="#1B375C"
                        d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.333.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                        transform="translate(5.835 4.72) translate(13.837 2.658) translate(.64 .623)"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                      transform="translate(5.835 4.72) translate(18.453)"
                    ></path>
                    <path
                      fill="#172E4D"
                      d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                      transform="translate(5.835 4.72) translate(18.453)"
                    ></path>
                    <g>
                      <path
                        fill="#0989E6"
                        d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                        transform="translate(5.835 4.72) translate(18.453) translate(.64 .623)"
                      ></path>
                      <path
                        fill="#1B375C"
                        d="M1.62.224C1.276.03.76.292.46.805.163 1.32.197 1.9.53 2.095c.332.193.86-.07 1.16-.583C1.987 1 1.952.418 1.62.224z"
                        transform="translate(5.835 4.72) translate(18.453) translate(.64 .623)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g transform="translate(2.779 12.056)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.29259245L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(1.229 13.208)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.66898822L0 0.844039011 0.711947243 0 4.03054004 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.03054004 1.85916701L4.03054004 2.05306787 3.36452488 2.87429501 3.36452488 2.66898822z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.844039011L0 1.02653393 3.36452488 2.87429501 3.36452488 2.66898822z"
                  ></path>
                </g>
                <g transform="translate(.218 15.204)">
                  <path
                    fill="#586066"
                    d="M3.42193998 2.82867128L0 0.96950427 0.378939662 0 3.74346454 1.89338481z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.74346454 1.89338481L3.74346454 2.0986916 3.42193998 3.03397807 3.42193998 2.82867128z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.96950427L0 1.15199919 3.42193998 3.03397807 3.42193998 2.82867128z"
                  ></path>
                </g>
                <g transform="translate(4.364 11.144)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(5.948 10.231)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.50930517 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.50930517 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(7.533 9.33)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(9.118 8.418)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(0 17.36)">
                  <path
                    fill="#586066"
                    d="M3.36452488 1.9618204L0.0344490602 0.136871191 0.172245301 0 3.4908381 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.4908381 1.87057294L3.4908381 2.78304755 3.36452488 2.87429501 3.36452488 1.9618204z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0.0344490602 0.136871191L0 1.03793987 3.36452488 2.87429501 3.36452488 1.9618204z"
                  ></path>
                </g>
                <g transform="translate(.276 19.23)">
                  <path
                    fill="#586066"
                    d="M3.34155884 1.95041447L0 0.125465258 0.149279261 0 3.46787206 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.46787206 1.85916701L4.19130232 2.60055263 4.0649891 2.69180009 3.34155884 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.125465258L0.700464223 0.844039011 4.0649891 2.69180009 3.34155884 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(10.702 7.517)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.29259245L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(12.287 6.604)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(13.871 5.692)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(15.456 4.79)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.29259245L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(17.04 3.878)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(18.637 2.966)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(20.222 2.064)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(21.806 1.152)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(23.39 .24)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(24.792)">
                  <path
                    fill="#586066"
                    d="M3.3760079 1.85916701L0 0.0342177978 0.964573685 0 4.28316648 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.376 1.859H4.3759999999999994V2.859H3.376z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.0342177978L0 0.216712719 3.3760079 2.0644738 3.3760079 1.85916701z"
                  ></path>
                </g>
              </g>
              <g transform="translate(20.857 10.53)">
                <g transform="translate(26.813 2.213)">
                  <path
                    fill="#586066"
                    d="M3.3760079 1.95041447L0.0344490602 0.125465258 0.172245301 0 3.4908381 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.4908381 1.85916701L3.4908381 2.78304755 3.3760079 2.87429501 3.3760079 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0.0344490602 0.125465258L0 1.03793987 3.3760079 2.87429501 3.3760079 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(26.56 4.118)">
                  <path
                    fill="#586066"
                    d="M3.3760079 1.95041447L0.0344490602 0.125465258 0.183728321 0 3.50232112 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.50232112 1.85916701L3.10041542 2.82867128 2.98558521 2.91991874 3.3760079 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0.0344490602 0.125465258L0 1.02653393 2.98558521 2.91991874 3.3760079 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(26.296 .456)">
                  <path
                    fill="#586066"
                    d="M3.34155884 1.95041447L0 0.125465258 0.137796241 0 3.45638904 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.45638904 1.85916701L3.9501589 2.78304755 3.82384568 2.87429501 3.34155884 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.125465258L0.459320802 1.03793987 3.82384568 2.87429501 3.34155884 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(2.538 19.47)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(4.375 18.386)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(5.96 17.485)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(7.544 16.573)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(9.129 15.66)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(10.714 14.76)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(12.298 13.847)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(13.883 12.934)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(15.468 12.033)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.66154409 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(17.052 11.12)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(18.637 10.22)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(20.222 9.307)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.467643236 0.792328384 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(21.806 8.395)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(23.39 7.494)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.29259245L0 0.467643236 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.3760079 2.49789924 3.3760079 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(25.125 5.92)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.63477043L0 0.809821213 0.631566103 0 3.9501589 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.9501589 1.87057294L3.9501589 2.0644738 3.3760079 2.82867128 3.3760079 2.63477043z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.809821213L0 0.992316135 3.3760079 2.82867128 3.3760079 2.63477043z"
                  ></path>
                </g>
                <path
                  fill="#204473"
                  d="M.126 17.748c0 1.072.356 1.825.93 2.133l.092.057c.173.08.368.125.586.125.321 0 .677-.103 1.045-.32l.723-.41.218-.125L24.137 7.494c1.401-.81 2.55-2.897 2.653-4.768v-.262c0-.194 0-.388-.035-.56-.091-.684-.356-1.197-.734-1.482L25.676.24l-.16-.046a1.462 1.462 0 00-.322-.034c-.333 0-.678.102-1.057.319L2.78 12.729c-1.183.673-2.182 2.27-2.526 3.878-.08.388-.127.776-.127 1.152v-.011z"
                ></path>
                <path
                  fill="#454545"
                  d="M24.149.456l2.618 1.437.746.411 1.608-.205L26.032.41l-.344-.183-.161-.046a1.462 1.462 0 00-.322-.034c-.333 0-.677.103-1.056.32V.456z"
                ></path>
                <path
                  fill="#42484D"
                  d="M.264 16.596l3.342 1.939 2.537-3.981-3.364-1.836c-1.183.673-2.182 2.27-2.526 3.878h.011z"
                ></path>
                <path
                  fill="#2C3033"
                  d="M.126 17.748c0 1.072.356 1.825.93 2.133l.092.057 2.997 1.585-.436-2.327-.126-.673L.24 16.596c-.08.387-.126.775-.126 1.152h.011z"
                ></path>
                <path
                  fill="#212426"
                  d="M6.143 21.58L27.502 9.33c1.47-.844 2.652-3.091 2.652-5.03s-1.182-2.829-2.652-1.996L6.143 14.554c-1.47.844-2.652 3.091-2.652 5.03s1.183 2.829 2.652 1.996z"
                ></path>
                <path
                  fill="#204473"
                  d="M5.098 20.861c.15 0 .333-.068.529-.182l21.358-12.25c1.137-.65 2.136-2.59 2.136-4.14 0-.616-.172-1.278-.563-1.278-.149 0-.333.069-.528.183L6.672 15.444c-1.137.65-2.136 2.589-2.136 4.14 0 .616.172 1.277.562 1.277z"
                ></path>
                <path
                  fill="#172E4D"
                  d="M25.193746 6.95761888L6.09748365 17.8845023 6.09748365 17.0974929 25.193746 6.18201546z"
                ></path>
                <path
                  fill="#42484D"
                  d="M25.952 7.961c1.136-.65 2.135-2.589 2.135-4.14 0-.205-.022-.422-.068-.616h.011c.195-.125.39-.182.528-.182.38 0 .563.661.563 1.277 0 1.551-1 3.49-2.136 4.14L5.627 20.69c-.196.114-.39.183-.529.183-.252 0-.413-.285-.493-.662l21.347-12.25z"
                ></path>
                <path
                  fill="#42484D"
                  d="M6.14341573 14.55397L2.79037387 12.7176148 24.1487912 0.456237303 27.5133161 2.30399838z"
                ></path>
                <g>
                  <path
                    fill="#0989E6"
                    d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                    transform="translate(5.847 4.72) translate(0 10.63)"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                    transform="translate(5.847 4.72) translate(0 10.63)"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                    transform="translate(5.847 4.72) translate(0 10.63) translate(.64 .623)"
                  ></path>
                  <path
                    fill="#1B375C"
                    d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.344.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                    transform="translate(5.847 4.72) translate(0 10.63) translate(.64 .623)"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                    transform="translate(5.847 4.72) translate(4.605 7.973)"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                    transform="translate(5.847 4.72) translate(4.605 7.973)"
                  ></path>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                      transform="translate(5.847 4.72) translate(4.605 7.973) translate(.64 .623)"
                    ></path>
                    <path
                      fill="#1B375C"
                      d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.333.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                      transform="translate(5.847 4.72) translate(4.605 7.973) translate(.64 .623)"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                      transform="translate(5.847 4.72) translate(9.22 5.315)"
                    ></path>
                    <path
                      fill="#172E4D"
                      d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                      transform="translate(5.847 4.72) translate(9.22 5.315)"
                    ></path>
                    <g>
                      <path
                        fill="#0989E6"
                        d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                        transform="translate(5.847 4.72) translate(9.22 5.315) translate(.64 .623)"
                      ></path>
                      <path
                        fill="#1B375C"
                        d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.344.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                        transform="translate(5.847 4.72) translate(9.22 5.315) translate(.64 .623)"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                      transform="translate(5.847 4.72) translate(13.826 2.658)"
                    ></path>
                    <path
                      fill="#172E4D"
                      d="M2.283.242C1.778-.043 1.02.345.572 1.109c-.448.764-.402 1.62.103 1.905.506.285 1.264-.103 1.711-.867.448-.764.402-1.62-.103-1.905z"
                      transform="translate(5.847 4.72) translate(13.826 2.658)"
                    ></path>
                    <g>
                      <path
                        fill="#0989E6"
                        d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                        transform="translate(5.847 4.72) translate(13.826 2.658) translate(.64 .623)"
                      ></path>
                      <path
                        fill="#1B375C"
                        d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.344.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                        transform="translate(5.847 4.72) translate(13.826 2.658) translate(.64 .623)"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <path
                      fill="#0989E6"
                      d="M.664 3.014L.41 2.865C-.094 2.58-.139 1.725.308.961.756.196 1.525-.191 2.018.094l.3.182L.663 3.025v-.011z"
                      transform="translate(5.847 4.72) translate(18.442)"
                    ></path>
                    <path
                      fill="#172E4D"
                      d="M2.272.242C1.766-.043 1.008.345.56 1.109c-.448.764-.402 1.62.103 1.905.505.285 1.263-.103 1.71-.867.449-.764.403-1.62-.102-1.905z"
                      transform="translate(5.847 4.72) translate(18.442)"
                    ></path>
                    <g>
                      <path
                        fill="#0989E6"
                        d="M.54 2.094l-.263-.16C-.068 1.742-.091 1.16.207.647c.3-.514.816-.776 1.16-.582l.3.182-1.115 1.86-.011-.012z"
                        transform="translate(5.847 4.72) translate(18.442) translate(.64 .623)"
                      ></path>
                      <path
                        fill="#1B375C"
                        d="M1.632.224C1.287.03.77.292.472.805.173 1.32.208 1.9.541 2.095c.333.193.861-.07 1.16-.583C1.999 1 1.965.418 1.63.224z"
                        transform="translate(5.847 4.72) translate(18.442) translate(.64 .623)"
                      ></path>
                    </g>
                  </g>
                </g>
                <g transform="translate(2.79 12.056)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(1.229 13.208)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.66898822L0 0.844039011 0.711947243 0 4.03054004 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.03054004 1.85916701L4.03054004 2.05306787 3.3760079 2.87429501 3.3760079 2.66898822z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.844039011L0 1.02653393 3.3760079 2.87429501 3.3760079 2.66898822z"
                  ></path>
                </g>
                <g transform="translate(.218 15.204)">
                  <path
                    fill="#586066"
                    d="M3.42193998 2.82867128L0 0.96950427 0.378939662 0 3.75494756 1.89338481z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.75494756 1.89338481L3.75494756 2.0986916 3.42193998 3.03397807 3.42193998 2.82867128z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.96950427L0 1.15199919 3.42193998 3.03397807 3.42193998 2.82867128z"
                  ></path>
                </g>
                <g transform="translate(4.375 11.144)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(5.96 10.231)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(7.544 9.33)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(9.129 8.418)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(0 17.36)">
                  <path
                    fill="#586066"
                    d="M3.3760079 1.9618204L0.0344490602 0.136871191 0.172245301 0 3.4908381 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.4908381 1.87057294L3.4908381 2.78304755 3.3760079 2.87429501 3.3760079 1.9618204z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0.0344490602 0.136871191L0 1.03793987 3.3760079 2.87429501 3.3760079 1.9618204z"
                  ></path>
                </g>
                <g transform="translate(.287 19.23)">
                  <path
                    fill="#586066"
                    d="M3.33007582 1.95041447L0 0.125465258 0.137796241 0 3.45638904 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.45638904 1.85916701L4.1798193 2.60055263 4.0649891 2.69180009 3.33007582 1.95041447z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.125465258L0.688981203 0.844039011 4.0649891 2.69180009 3.33007582 1.95041447z"
                  ></path>
                </g>
                <g transform="translate(10.714 7.505)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.87057294L4.11092118 2.0644738 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.50930517 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(12.298 6.604)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(13.883 5.692)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(15.468 4.79)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.11092118 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.11092118 1.85916701L4.11092118 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(17.052 3.878)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(18.637 2.966)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.792328384 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(20.222 2.064)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.29259245L0 0.467643236 0.792328384 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.05306787 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.467643236L0 0.650138157 3.36452488 2.49789924 3.36452488 2.29259245z"
                  ></path>
                </g>
                <g transform="translate(21.806 1.152)">
                  <path
                    fill="#586066"
                    d="M3.36452488 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.85916701L4.1224042 2.0644738 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.36452488 2.49789924 3.36452488 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(23.39 .24)">
                  <path
                    fill="#586066"
                    d="M3.3760079 2.30399838L0 0.479049169 0.803811404 0 4.1224042 1.87057294z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M4.1224042 1.87057294L4.1224042 2.0644738 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.479049169L0 0.66154409 3.3760079 2.49789924 3.3760079 2.30399838z"
                  ></path>
                </g>
                <g transform="translate(24.792)">
                  <path
                    fill="#586066"
                    d="M3.3760079 1.85916701L0 0.0342177978 0.964573685 0 4.28316648 1.85916701z"
                  ></path>
                  <path
                    fill="#212426"
                    d="M3.376 1.859H4.3759999999999994V2.859H3.376z"
                  ></path>
                  <path
                    fill="#373C40"
                    d="M0 0.0342177978L0 0.216712719 3.3760079 2.0644738 3.3760079 1.85916701z"
                  ></path>
                </g>
              </g>
              <path
                fill="url(#linearGradient-1)"
                d="M.153 30.821a.99.99 0 01.276-.296l.804-1.22c.367-.548.689-1.118.976-1.7.149-.297.298-.593.436-.901.195-.4.436-.799.563-1.232.126-.445.126-.867.046-1.312-.058-.353-.127-.718-.161-1.083-.035-.32.138-.56.482-.49.31.056.425.17.448.49.092.992.276 1.984-.08 2.942-.207.548-.563.936-.896 1.403-.712.97-1.31 2.03-1.768 3.137-.023.068-.058.137-.08.205 0 0-.185.48-.196.468-.012.023-.012.046-.035.068-.206.354-.55.525-.78.4-.23-.137-.253-.525-.046-.867l.011-.012z"
              ></path>
              <g>
                <g transform="translate(1.835 24.72) translate(9.341) translate(6.017 3.479)">
                  <path
                    fill="#747B80"
                    d="M1.05643785 2.99976027L2.70999273 0.273742382 2.34253609 0 0 2.37243398z"
                  ></path>
                  <path
                    fill="#222224"
                    d="M2.70999273 0.273742382L2.70999273 0.741385618 1.05643785 3.47880944 1.05643785 2.99976027z"
                  ></path>
                </g>
                <g transform="translate(1.835 24.72) translate(9.341) translate(4.513 2.612)">
                  <path
                    fill="#747B80"
                    d="M1.05643785 2.99976027L2.70999273 0.273742382 2.33105307 0 0 2.37243398z"
                  ></path>
                  <path
                    fill="#222224"
                    d="M2.70999273 0.273742382L2.70999273 0.741385618 1.05643785 3.47880944 1.05643785 2.99976027z"
                  ></path>
                </g>
                <g transform="translate(1.835 24.72) translate(9.341) translate(3.009 1.745)">
                  <path
                    fill="#747B80"
                    d="M1.05643785 2.99976027L2.70999273 0.262336449 2.33105307 0 0 2.37243398z"
                  ></path>
                  <path
                    fill="#222224"
                    d="M2.70999273 0.262336449L2.70999273 0.741385618 1.05643785 3.47880944 1.05643785 2.99976027z"
                  ></path>
                </g>
                <g transform="translate(1.835 24.72) translate(9.341) translate(1.504 .878)">
                  <path
                    fill="#747B80"
                    d="M1.05643785 2.99976027L2.70999273 0.262336449 2.33105307 0 0 2.36102804z"
                  ></path>
                  <path
                    fill="#222224"
                    d="M2.70999273 0.262336449L2.70999273 0.741385618 1.05643785 3.46740351 1.05643785 2.99976027z"
                  ></path>
                </g>
                <g transform="translate(1.835 24.72) translate(9.341)">
                  <path
                    fill="#747B80"
                    d="M1.05643785 3.0111662L2.70999273 0.273742382 2.33105307 0 0 2.37243398z"
                  ></path>
                  <path
                    fill="#222224"
                    d="M2.70999273 0.273742382L2.70999273 0.752791551 1.05643785 3.47880944 1.05643785 3.0111662z"
                  ></path>
                </g>
                <path
                  fill="#747B80"
                  d="M.787 5.406s.046-.022.057-.034c.034-.011.069-.034.092-.045.172-.057.367-.046.551-.023.115 0 .23.023.345.023l.689.034c.287 0 .574 0 .872-.023.678-.057 1.229-.297 1.86-.49.471-.137.965-.343 1.459-.605a12.151 12.151 0 002.882-2.167l.184.125-.414.411c-.803 1.893-1.412 3.753-2.32 5.646-.01.034-.034.068-.045.091-.138.297-.287.593-.448.89 0 0-.011 0-.023-.012-.057-.034-.115-.08-.172-.114-.103-.068-.207-.137-.322-.205-.149-.091-.287-.194-.436-.285-.172-.114-.356-.228-.528-.354-.195-.125-.402-.262-.597-.388-.207-.136-.425-.273-.632-.422-.218-.136-.425-.285-.643-.422-.207-.136-.425-.273-.632-.41-.195-.126-.39-.262-.585-.388l-.517-.342c-.138-.091-.276-.183-.425-.274L.88 5.51s-.046-.034-.07-.046c0 0-.022-.011-.034-.022v-.035h.012z"
                  transform="translate(1.835 24.72)"
                ></path>
                <path
                  fill="#42484D"
                  d="M.764 5.44l7.98 5.247 3.307 2.225 4.708-6.513-6.981-4.186c-.907.9-1.895 1.642-2.882 2.167a7.465 7.465 0 01-1.459.604c-.643.194-1.183.434-1.86.49-.287.024-.574.024-.873.024-.46 0-.93-.046-1.39-.08a1.71 1.71 0 00-.481.011l-.08.034.01-.022z"
                  transform="translate(1.835 24.72)"
                ></path>
                <g fill="#747B80">
                  <path
                    d="M2.56 3.33c-.286.023-.573.023-.872.023-.46 0-.93-.045-1.39-.08-.091 0-.183-.01-.287-.01H0l.08-.047c.15-.045.322-.022.483-.01.459.045.93.079 1.39.079.286 0 .573 0 .872-.023.184-.011.356-.046.528-.08a4.296 4.296 0 01-.78.149H2.56zM15.743 4.254L8.888.137 9.026 0l6.981 4.186-4.708 6.513-.149-.103 4.593-6.353v.011z"
                    transform="translate(1.835 24.72) translate(.752 2.201)"
                  ></path>
                </g>
                <path
                  fill="#222224"
                  d="M.775 5.418l5.96 3.935c.16-.297.31-.593.448-.89.011-.034.034-.068.046-.091.987-2.065 1.619-4.106 2.549-6.182-.907.901-1.895 1.642-2.882 2.167a7.465 7.465 0 01-1.459.605c-.643.193-1.183.433-1.86.49-.287.023-.574.023-.873.023-.46 0-.93-.046-1.39-.08a1.71 1.71 0 00-.481.011l-.07.023.012-.011z"
                  transform="translate(1.835 24.72)"
                ></path>
                <path
                  fill="#747B80"
                  d="M7.596 9.535c.172-.08.367-.057.563-.045.459.045.93.08 1.39.091.286 0 .573 0 .86-.023.678-.057 1.229-.296 1.86-.49.471-.137.965-.342 1.459-.605.987-.513 1.975-1.254 2.882-2.156l.15.092-.322.32c-.85 1.972-1.47 3.911-2.411 5.873-.38.787-.816 1.665-1.493 2.213-.827.673-1.998.604-2.572.388-1.608-.582-2.217-2.806-2.619-4.255-.069-.25-.137-.502-.126-.764.023-.262.15-.525.39-.627l-.011-.012z"
                  transform="translate(1.835 24.72)"
                ></path>
                <path
                  fill="#42484D"
                  d="M.373 6c-.023.262.058.513.127.764.252.878.493 1.756.918 2.566a4.657 4.657 0 001.688 1.836c.838.525 6.247 3.707 6.568 3.901h.104V13.55l-1.413-2.304-.62-1.654L.787 5.418a.695.695 0 00-.414.593V6z"
                  transform="translate(1.835 24.72)"
                ></path>
                <path
                  fill="#747B80"
                  d="M9.778 13.584L8.365 11.28l-.62-1.653L.833 5.452s.08-.068.137-.091l6.982 4.22.62 1.654 1.412 2.304v1.505l-.206-.125v-1.335z"
                  transform="translate(1.835 24.72)"
                ></path>
                <path
                  fill="#222224"
                  d="M9.697 9.672c.287 0 .575 0 .862-.023.677-.057 1.228-.296 1.86-.49.47-.137.964-.342 1.458-.605.988-.513 1.975-1.254 2.882-2.155-.034.091-.08.17-.114.262-.885 2.019-1.516 4.004-2.48 6.011-.38.787-.816 1.665-1.493 2.213-.827.673-1.999.604-2.573.388-1.607-.582-2.216-2.806-2.618-4.255-.069-.25-.138-.502-.126-.764.023-.262.15-.525.39-.627.173-.08.368-.057.563-.046.46.046.93.08 1.39.091z"
                  transform="translate(1.835 24.72)"
                ></path>
                <path
                  fill="#2E2F31"
                  d="M.373 6.045c.023-.24.161-.513.39-.616h.012l-.011-.011c-.242.102-.368.376-.39.627z"
                  transform="translate(1.835 24.72)"
                ></path>
              </g>
              <path
                fill="#8D9296"
                d="M4.195 27.993l-.069.49c-.08.627-.264 1.3-.16 1.916l.08.434-.953.137c.172-.947.298-1.974.011-2.886-.057-.194.092-.194.23-.183.126.012.253.035.367.046.161.023.322.034.483.057l.011-.011z"
              ></path>
              <path
                fill="#0876C4"
                d="M1.967 9.424c.793.399 1.734.365 2.619.33 2.342-.09 13.033-1.368 14.239-1.596.367-.069.734-.137 1.102-.194.344-.046.689-.08 1.022-.091a5.048 5.048 0 011.493.17c1.55.457 2.618 1.814 3.743 2.978.609.627 1.275 1.22 1.814 1.916.265.342.494.707.724 1.083.31.514.609 1.038.953 1.517.367.514.827 1.004 1.378 1.346.23.149.482.274.758.342.93.274 1.424-.684 1.78-1.448.62-1.335.643-2.852.413-4.277-.172-1.14-1.32-2.122-1.986-3.069C30.595 6.413 29.194 4.234 27 2.968a8.194 8.194 0 00-2.687-.98c-2.4-.491-5.018-.15-7.475.318-4.8.902-9.542 2.156-14.17 3.742-.678.228-1.424.536-1.711 1.186 0 .011-.012.034-.012.057v.023c-.298.798.276 1.722 1.045 2.11h-.023z"
              ></path>
              <g transform="translate(45.833 1.294)">
                <path
                  fill="#204473"
                  d="M15.2035186 19.7751211L16.1910583 19.2162304 16.1910583 10.308197 15.2035186 10.8670877z"
                ></path>
                <path
                  fill="#0876C4"
                  d="M13.366 7.388l1.826 3.48 1-.56c0-1.346-.828-2.897-1.838-3.479l-.264.137-.724.422zM.62.693l1.217.741.54-.353-1.378.32L2.825.213c-.517-.297-1-.274-1.32 0L.631.693H.62z"
                ></path>
                <path
                  fill="#070E17"
                  d="M15.204 19.775L0 11.061V2.153C0 .818.815.203 1.837.773l11.53 6.615c1.01.582 1.837 2.133 1.837 3.48v8.919-.012z"
                ></path>
                <path
                  fill="#0989E6"
                  d="M2.82482293 0.213946712L14.3537751 6.82938761 13.3662353 7.38827831 1.83728321 0.772837409z"
                ></path>
              </g>
              <g transform="translate(26.426 11.18)">
                <path
                  fill="#586066"
                  d="M4.73100426 14.0064852L24.631078 2.53211703 19.7622775 0 -3.55271368e-15 11.2690614z"
                ></path>
                <path
                  fill="#373C40"
                  d="M-3.55271368e-15 11.2690614L-3.55271368e-15 12.9001098 4.73100426 15.6261276 4.73100426 14.0064852z"
                ></path>
                <path
                  fill="#212426"
                  d="M24.631078 2.53211703L24.631078 4.16316539 4.73100426 15.6261276 4.73100426 14.0064852z"
                ></path>
              </g>
              <g transform="translate(26.426 13.11)">
                <path
                  fill="#204473"
                  d="M4.73100426 12.0761166L14.6752996 6.33893251 14.6752996 4.29727058 4.73100426 10.0344547z"
                ></path>
                <path
                  fill="#0876C4"
                  d="M2.905 6.556l1.826 3.478L8.337 7.96l6.338-3.662c0-1.346-.826-2.897-1.837-3.479l-9.22 5.316-.724.422h.011zM.62 5.871l1.217.742.54-.354L9.956 1.4 11.782.214c-.517-.297-1-.274-1.321 0L.631 5.86l-.01.011z"
                ></path>
                <path
                  fill="#234878"
                  d="M4.731 12.076L0 9.384V7.343c0-1.335.815-1.95 1.837-1.38l1.068.593c1.01.581 1.837 2.133 1.837 3.478v2.042h-.011z"
                ></path>
                <path
                  fill="#0989E6"
                  d="M11.7815786 0.225352645L12.8494994 0.818461139 2.90520407 6.55564523 1.83728321 5.96253673z"
                ></path>
              </g>
              <path
                fill="#1C3A61"
                d="M24.382 9.15v.08l.035 1.14c.344-.045.689-.08 1.022-.09.23.25.47.501.712.74.608.628 2.882 3.673 3.48 4.506.32.445.711.878 1.17 1.209l.953.479 3.778 2.065-.574-1.586-1.171-2.566-1.171-2.555-4.364-2.715-3.881-.718.011.011z"
              ></path>
              <g>
                <path
                  fill="#204473"
                  d="M.02.783l3.33 9.444c.07.308.506.479 1 .387.482-.102.826-.433.757-.752L1.777.395.032.783H.02z"
                  transform="translate(27.508 16.272)"
                ></path>
                <path
                  fill="#0989E6"
                  d="M1.766.418c.069.308-.276.65-.758.752-.482.103-.93-.068-1-.387C-.06.475.285.133.768.03c.482-.103.93.068.999.388z"
                  transform="translate(27.508 16.272)"
                ></path>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-2)"
                  d="M0 .707l3.675 10.026c.045.217.356.342.7.274.345-.069.574-.308.528-.525L1.23.433 0 .707z"
                  transform="translate(24.107 6.242)"
                ></path>
                <path
                  fill="#F0F0F0"
                  d="M1.24.445C1.286.662 1.045.9.712.97.367 1.038.057.924.012.696-.035.468.206.24.54.17c.344-.068.654.046.7.274z"
                  transform="translate(24.107 6.242)"
                ></path>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-3)"
                  d="M2.224 2.822L.25 1.749C-.06 1.58-.084 1.054.192.586.468.118.938-.121 1.237.061l1.998 1.095-1.01 1.677v-.011z"
                  transform="translate(30.862 24.989)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M3.212 1.122c-.31-.171-.78.057-1.045.525-.276.467-.241.992.057 1.163.31.171.781-.057 1.045-.525.276-.467.242-.992-.057-1.163z"
                  transform="translate(30.862 24.989)"
                ></path>
              </g>
              <g>
                <path
                  fill="#204473"
                  d="M.009.783l3.33 9.444c.069.308.505.479.999.387.482-.102.827-.433.758-.752L1.766.395.02.783H.01z"
                  transform="translate(29.621 17.447)"
                ></path>
                <path
                  fill="#0989E6"
                  d="M1.766.418c.069.308-.276.65-.758.752-.482.103-.93-.068-1-.387C-.06.475.285.133.768.03c.482-.103.93.068.999.388z"
                  transform="translate(29.621 17.447)"
                ></path>
              </g>
              <g transform="translate(32.845 14.899)">
                <path
                  fill="#586066"
                  d="M8.31370652 16.0139293L28.2137803 4.53956117 19.7622775 0 0 11.2690614z"
                ></path>
                <path
                  fill="#2C3033"
                  d="M0 11.2690614L0 12.9001098 8.31370652 17.6335718 8.31370652 16.0139293z"
                ></path>
                <path
                  fill="#212426"
                  d="M28.2137803 4.53956117L28.2137803 6.1592036 8.31370652 17.6335718 8.31370652 16.0139293z"
                ></path>
              </g>
              <path
                fill="#0989E6"
                d="M2.255 6.185c.78-.308 1.642-.57 2.468-.696.747-.114 1.55-.206 2.251.16.379.193.77.399 1.034.74.264.332.367.822.16 1.187-.16.297-.482.41-.746.559-.322.182-.643.342-.965.513-.149.08-.264.16-.39.285-.103.103-.23.308-.356.365.16-.08.321-.137.494-.194.425-.125.872-.308 1.309-.342l.838-.068 2.078-.171c1.597-.126 3.204-.263 4.8-.388 1.32-.103 2.641-.217 3.962-.32.39-.034.792-.068 1.183-.09l9.473-2.19 1.355-.32-3.835-1.985h-.023l-1.493-.787h-.011c-2.79-1.106-6.063-.718-9.06-.16-4.8.902-9.543 2.156-14.17 3.753-.115.046-.242.08-.356.137v.012z"
              ></path>
              <g>
                <path
                  fill="#619AE6"
                  d="M1.826.095a.795.795 0 01.24-.09c.081-.012.162 0 .23.045 0 0 .047.023.058.034l1.183.707c-.092.091-.195.183-.287.274-.218.217-.448.433-.666.639-.253.24-.494.479-.747.718a23.092 23.092 0 00-.597.582c-.046.034-.034.068-.023.125a1.276 1.276 0 01-.333-.16C.69 2.857.494 2.754.31 2.628a1.367 1.367 0 01-.207-.17C.07 2.433 0 2.376 0 2.33 0 1.909 1.47.3 1.837.084l-.011.011z"
                  transform="translate(19.984 1.23)"
                ></path>
                <path
                  fill="#204473"
                  d="M3.686 1.19c0 .422-.299.936-.666 1.152-.367.217-1.837 1.14-1.837.719 0-.422 1.458-2.03 1.837-2.247.367-.217.666-.046.666.376z"
                  transform="translate(19.984 1.23)"
                ></path>
              </g>
              <g>
                <path
                  fill="#204473"
                  d="M.465 1.19l6.764.228c.218.034.424-.206.482-.525.057-.33-.07-.616-.287-.65L.637.003.465 1.19z"
                  transform="translate(5.275 1.668) translate(8.733 .282)"
                ></path>
                <path
                  fill="#0989E6"
                  d="M.672.003c.218.035.344.331.287.65-.058.32-.276.56-.482.525C.258 1.144.132.848.189.528.247.198.465-.03.672.003z"
                  transform="translate(5.275 1.668) translate(8.733 .282)"
                ></path>
                <g>
                  <path
                    fill="url(#linearGradient-4)"
                    d="M.505.992l8.716.297c.15.023.298-.137.344-.365C9.6.696 9.508.49 9.36.468L.632.17l-.127.833V.992z"
                    transform="translate(5.275 1.668)"
                  ></path>
                  <path
                    fill="#F0F0F0"
                    d="M.643.16c.15.022.241.228.207.456-.035.228-.195.4-.345.365C.356.958.265.753.3.525S.494.125.643.16z"
                    transform="translate(5.275 1.668)"
                  ></path>
                </g>
              </g>
              <path
                fill="#204473"
                d="M.36 7.827c-.046 1.403.172 2.646.39 3.878.012.08.035.16.046.24.54 3.08.873 6.341 1.378 9.444.425 2.634.597 5.668 1.872 7.46.08.159.184.33.321.398l.023.012.942.661-.172-1.483v-.045l.379-4.266.023-.32.069-2.19.034-1.106.034-1.129.356-10.47 1.022-7.814L4.402.048l-.38.126c-.757.524-2.089 2.35-2.56 3.25C.727 4.827.406 6.424.36 7.827z"
              ></path>
              <g>
                <path
                  fill="url(#linearGradient-5)"
                  d="M.9 1.706l-.712-.399C-.042 1.17-.065.782.142.44c.206-.353.55-.525.78-.399l.735.41L.9 1.707z"
                  transform="translate(3.043 27.644)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M1.634.44c-.23-.137-.574.046-.78.4-.207.353-.184.74.046.866.23.137.574-.045.78-.399.207-.354.184-.741-.046-.867z"
                  transform="translate(3.043 27.644)"
                ></path>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-6)"
                  d="M.9 1.705l-.712-.4C-.042 1.17-.065.782.142.44c.206-.342.55-.525.78-.4l.735.411L.9 1.705z"
                  transform="translate(3.077 30.143)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M1.634.439c-.23-.137-.574.046-.78.4-.207.353-.184.74.046.866.23.137.574-.046.78-.4.207-.353.184-.74-.046-.866z"
                  transform="translate(3.077 30.143)"
                ></path>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-7)"
                  d="M2.754 2.566L.147 1.026C-.037.924-.048.616.113.342.273.068.549-.068.72.034L3.34 1.585l-.585.981z"
                  transform="translate(3.99 30.787)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M3.328 1.585c-.184-.102-.448.035-.609.308-.16.274-.138.582.035.685.183.102.447-.035.608-.308.161-.274.138-.582-.034-.685z"
                  transform="translate(3.99 30.787)"
                ></path>
              </g>
              <path
                fill="#0876C4"
                d="M.36 7.827c-.046 1.403.172 2.646.39 3.878.012.08.035.16.046.24.54 3.08.873 6.341 1.378 9.444.425 2.634.597 5.668 1.872 7.46.08.159.184.33.321.398 0 0 .023 0 .035.012.103.045.218.034.344-.057.207-.149.345-.445.437-.765.057-.205.092-.41.115-.604.091-.753.183-2.087.264-3.707.034-.776.069-1.62.103-2.51l.034-1.106c0-.376.023-.752.035-1.129.126-4.825.16-9.957-.012-10.95C5.458 6.903 4.77 1.417 4.597.356 4.574.208 4.54.048 4.437.014c-.07-.034-.15 0-.23.046-.046.022-.092.057-.138.08-.758.524-2.09 2.349-2.56 3.25C.772 4.793.451 6.39.405 7.793l-.046.034z"
              ></path>
              <g>
                <path
                  fill="url(#linearGradient-8)"
                  d="M.532 1.49l-.344-.183C-.042 1.17-.065.782.142.44c.206-.353.55-.525.78-.399l.368.194L.532 1.49z"
                  transform="translate(.011 30.381)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M1.267.223c-.23-.136-.574.046-.78.4-.208.353-.185.741.045.867.23.125.574-.046.781-.4.207-.353.184-.741-.046-.867z"
                  transform="translate(.011 30.381)"
                ></path>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-9)"
                  d="M1.657 2.105l-1.47-.798C-.041 1.17-.064.782.143.44c.206-.353.55-.525.78-.399l1.493.821-.758 1.255v-.012z"
                  transform="translate(.436 30.61)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M2.392.84c-.23-.137-.574.045-.78.399-.207.353-.184.741.045.866.23.126.575-.045.781-.399.207-.353.184-.741-.046-.867z"
                  transform="translate(.436 30.61)"
                ></path>
              </g>
              <path
                fill="url(#linearGradient-10)"
                d="M2.507 32.909c-.103.022-.252-.092-.333-.137-.23-.137-.252-.525-.046-.867.207-.354.551-.525.781-.4l.368.195c-.092.16-.196.319-.288.479-.08.125-.16.262-.23.387-.057.092-.114.251-.194.308a.087.087 0 01-.046.023l-.012.012z"
              ></path>
              <g>
                <path
                  fill="url(#linearGradient-11)"
                  d="M.103.513l.023 13.63c-.023.16.173.32.425.354.253.034.482-.08.494-.24L1.022.616.103.513z"
                  transform="translate(1.069 9.732) translate(.083 7.243)"
                ></path>
                <path
                  fill="#F0F0F0"
                  d="M1.033.627c-.022.16-.24.274-.493.24C.287.833.092.673.115.513.138.353.356.24.609.273c.252.035.447.195.424.354z"
                  transform="translate(1.069 9.732) translate(.083 7.243)"
                ></path>
                <g>
                  <path
                    fill="#172E4D"
                    d="M.003.479l.034 7.368c-.034.228.242.457.597.502.356.046.678-.103.712-.342L1.323.616.014.468.003.479z"
                    transform="translate(1.069 9.732)"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M1.312.65C1.278.878.967 1.038.6.992.244.947-.032.72.003.49.037.262.347.103.715.148c.356.046.631.274.597.502z"
                    transform="translate(1.069 9.732)"
                  ></path>
                </g>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-12)"
                  d="M2.224 2.822L.25 1.749C-.06 1.58-.084 1.054.192.586.468.118.938-.121 1.237.061l1.998 1.095-1.01 1.677v-.011z"
                  transform="translate(.524 8.587)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M3.212 1.122c-.31-.171-.78.057-1.045.525-.276.467-.241.992.057 1.163.31.171.781-.057 1.045-.525.276-.467.242-.992-.057-1.163z"
                  transform="translate(.524 8.587)"
                ></path>
              </g>
              <path
                fill="#204473"
                d="M2.852 12.39c0-.08-.012-.16-.023-.24-.127-1.243-.264-2.498-.127-3.89.138-1.391.575-2.977 1.401-4.322.563-.924 1.998-2.487 2.733-2.806.08-.035.173-.057.241-.023.092.045.115.205.127.353.103 1.073.608 6.24.77 7.78.183 1.744-1.08 16.047-1.723 19.32-.092.48-.276 1.107-.643 1.324-.333.205-.54-.103-.666-.4-1.149-1.87-1.114-4.915-1.355-7.573-.299-3.136-.402-6.41-.735-9.524z"
              ></path>
              <path
                fill="#204473"
                d="M6.457 11.83c.793.4 1.734.365 2.618.331 2.343-.091 13.034-1.368 14.24-1.597.367-.068.734-.136 1.102-.194.344-.045.689-.08 1.022-.09a5.048 5.048 0 011.493.17c1.55.457 2.618 1.814 3.743 2.977.609.628 1.275 1.22 1.814 1.916.264.343.494.708.724 1.084.31.513.608 1.038.953 1.517.367.513.827 1.004 1.378 1.346.23.148.482.274.758.342.93.274 1.424-.684 1.78-1.448.62-1.335.643-2.852.413-4.278-.172-1.14-1.32-2.121-1.987-3.068-1.423-2.019-2.824-4.197-5.018-5.463a8.194 8.194 0 00-2.687-.981c-2.4-.49-5.018-.148-7.475.32-4.8.9-9.543 2.155-14.17 3.74-.678.228-1.424.536-1.711 1.187 0 .01-.012.034-.012.057v.022c-.298.799.276 1.723 1.045 2.11h-.023z"
              ></path>
              <g>
                <path
                  fill="url(#linearGradient-13)"
                  d="M.982 2.402l-.7-.41c-.345-.206-.38-.788-.07-1.324S1.052-.13 1.408.064l.735.422L.993 2.39l-.011.012z"
                  transform="translate(25.444 6.463)"
                ></path>
                <path
                  fill="#696D70"
                  d="M2.096.486C1.75.28 1.212.554.902 1.09c-.31.536-.276 1.13.068 1.323.345.194.885-.068 1.195-.604.31-.536.275-1.13-.07-1.323z"
                  transform="translate(25.444 6.463)"
                ></path>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-14)"
                  d="M.006.696L3.68 10.722c.046.216.356.342.7.273.345-.068.575-.308.529-.524L1.234.422.006.696z"
                  transform="translate(26.214 7.428)"
                ></path>
                <path
                  fill="#F0F0F0"
                  d="M1.234.445C1.28.662 1.04.9.706.97.362 1.038.052.924.006.696-.04.479.2.24.534.17c.344-.068.654.046.7.274z"
                  transform="translate(26.214 7.428)"
                ></path>
              </g>
              <g transform="translate(35.9 1.842)">
                <path
                  fill="#CCC"
                  d="M9.956 1.605l-.77 7.734 4.915 2.817 4.708 1.825 6.339 3.638v-7.288c0-1.346-.827-2.897-1.837-3.479L11.78.225c-.516-.296-.998-.273-1.32 0-.253.206-.436.56-.482 1.038 0 .046 0 .092-.012.137v.194l-.011.011z"
                ></path>
                <path
                  fill="#204473"
                  d="M15.2035186 23.3337721L25.1592969 17.6079939 25.1592969 10.308197 15.2035186 16.0453811z"
                ></path>
                <path
                  fill="#666"
                  d="M20.1871493 17.6764295L20.5431229 18.0072015 24.9181535 15.4180548 24.9181535 11.9734632 24.4932818 11.4487903z"
                ></path>
                <path
                  fill="#0876C4"
                  d="M13.378 12.567l1.826 3.478 3.605-2.075 6.339-3.662c0-1.346-.827-2.897-1.837-3.479l-9.221 5.316-.724.422h.012zM.632 5.871l1.217.742.54-.354L9.966 1.4 11.793.214c-.517-.297-.999-.274-1.32 0L.643 5.86l-.011.011z"
                ></path>
                <path
                  fill="#234878"
                  d="M15.204 23.334L0 14.62V7.33c0-1.334.815-1.95 1.837-1.38l11.54 6.616c1.011.581 1.838 2.132 1.838 3.478v7.289h-.011z"
                ></path>
                <path
                  fill="#0989E6"
                  d="M11.7815786 0.225352645L23.3220137 6.84079354 13.3777184 12.5665717 1.83728321 5.9511308z"
                ></path>
                <path
                  fill="#999"
                  d="M17.5460546 9.44134617L17.8905452 9.44134617 22.2885419 7.11453593 21.1057909 6.46439777z"
                ></path>
              </g>
              <g transform="translate(43.237 2.763)">
                <path
                  fill="#66BFFF"
                  d="M10.2084048 8.22367739L0 2.33821618 4.2946495 0 14.5030543 5.87405528z"
                ></path>
                <path
                  fill="#172E4D"
                  d="M14.5030543 5.87405528L14.5030543 6.17060953 10.2084048 8.52023164 10.2084048 8.22367739z"
                ></path>
                <path
                  fill="#2D5D9C"
                  d="M0 2.33821618L0 2.63477043 10.2084048 8.52023164 10.2084048 8.22367739z"
                ></path>
              </g>
              <g>
                <g transform="translate(43.777 3.06) translate(9.439 5.315)">
                  <path
                    fill="#080F1A"
                    d="M0 2.0986916L0.310041542 2.26978058 4.1224042 0.182494921 3.80087964 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.80087964 0.102653393L0.0918641604 2.14431533 0 2.0986916 3.80087964 0 4.1224042 0.182494921 4.01905702 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(8.486 4.79)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.321524562 2.26978058 4.1224042 0.171088989 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.102653393L0.103347181 2.14431533 0 2.08728566 3.81236266 0 4.1224042 0.171088989 4.03054004 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(7.544 4.254)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.321524562 2.26978058 4.1224042 0.182494921 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.102653393L0.103347181 2.14431533 0 2.08728566 3.81236266 0 4.1224042 0.182494921 4.03054004 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(6.603 3.718)">
                  <path
                    fill="#080F1A"
                    d="M0 2.0986916L0.310041542 2.26978058 4.1224042 0.182494921 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.114059326L0.0918641604 2.14431533 0 2.0986916 3.81236266 0 4.1224042 0.182494921 4.03054004 0.239524584z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(5.661 3.194)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.310041542 2.26978058 4.1224042 0.171088989 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.102653393L0.0918641604 2.14431533 0 2.08728566 3.81236266 0 4.1224042 0.171088989 4.03054004 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(4.72 2.658)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.310041542 2.26978058 4.1224042 0.182494921 3.80087964 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.80087964 0.102653393L0.0918641604 2.14431533 0 2.08728566 3.80087964 0 4.1224042 0.182494921 4.01905702 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(3.778 2.122)">
                  <path
                    fill="#080F1A"
                    d="M0 2.0986916L0.310041542 2.26978058 4.11092118 0.182494921 3.80087964 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.80087964 0.114059326L0.0918641604 2.14431533 0 2.0986916 3.80087964 0 4.11092118 0.182494921 4.01905702 0.239524584z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(2.825 1.597)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.321524562 2.26978058 4.1224042 0.171088989 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.102653393L0.103347181 2.14431533 0 2.08728566 3.81236266 0 4.1224042 0.171088989 4.03054004 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(1.883 1.06)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.310041542 2.26978058 4.1224042 0.182494921 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.102653393L0.0918641604 2.14431533 0 2.08728566 3.81236266 0 4.1224042 0.182494921 4.03054004 0.228118652z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06) translate(.942 .525)">
                  <path
                    fill="#080F1A"
                    d="M0 2.0986916L0.310041542 2.26978058 4.1224042 0.182494921 3.81236266 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.81236266 0.114059326L0.0918641604 2.14431533 0 2.0986916 3.81236266 0 4.1224042 0.182494921 4.03054004 0.239524584z"
                  ></path>
                </g>
                <g transform="translate(43.777 3.06)">
                  <path
                    fill="#080F1A"
                    d="M0 2.08728566L0.310041542 2.26978058 4.1224042 0.171088989 3.80087964 0z"
                  ></path>
                  <path
                    fill="#172E4D"
                    d="M3.80087964 0.102653393L0.0918641604 2.14431533 0 2.08728566 3.80087964 0 4.1224042 0.171088989 4.01905702 0.228118652z"
                  ></path>
                </g>
              </g>
              <path
                fill="#42484D"
                d="M34.958 7.599l7.97 4.608 5.546-3.182a2.142 2.142 0 011.814-.172l-7.246-4.185a2.215 2.215 0 00-2.537-.251l-5.547 3.182z"
              ></path>
              <path
                fill="#2C3033"
                d="M32.535 23.305v.08c.092.89.184 1.813.31 2.794l8.337 4.78s0-.058-.011-.15c-.104-.957-.219-1.904-.253-2.862-.035-1.209-.023-2.407-.012-3.616v-1.55c.012-.491.012-.982.023-1.472.035-1.084.104-2.167.184-3.25.035-.491.103-.97.184-1.46l.069-.354c.023-.103.069-.206.103-.308.08-.229.15-.457.207-.685.034-.125.069-.25.103-.388.012-.057 0-.125.023-.182.08-.251.195-.48.31-.719.092-.216.218-.41.322-.616.16-.308.287-.638.425-.958.022-.068.034-.148.068-.205 0-.011-.068-.034-.08-.046l-.207-.125c-.252-.148-.493-.285-.746-.433l-1.068-.616c-.413-.24-.838-.48-1.252-.73-.436-.251-.872-.502-1.32-.765-.414-.24-.838-.479-1.252-.718-.356-.205-.7-.41-1.056-.605l-.724-.422c-.092-.057-.183-.102-.275-.16 0 0-3.457 3.799-2.435 15.707l.023.034z"
              ></path>
              <path
                fill="#212426"
                d="M40.665 24.388c0 .228 0 .445.023.685v.068c0 .285.023.57.035.867v.091c.011.274.034.548.057.821 0 .217.023.434.046.662 0 .171.023.342.046.513v.137c0 .08.023.16.023.24.069.752.15 1.54.264 2.35 0 .045 0 .102.012.148l5.511-3.16 1.091-.627 2.733-1.574a2.507 2.507 0 001.263-2.179V10.907c0-1.004-.643-1.78-1.481-2.065a2.142 2.142 0 00-1.814.171l-.7.4-1.092.627-3.755 2.144s-2.02 2.59-2.262 9.878a38.49 38.49 0 000 2.304v.022z"
              ></path>
              <path
                fill="#97F4FF"
                d="M33.385 19.37V20.27c.023.832.069 1.677.126 2.52 0 .149.023.286.035.434v.046c.057.513.103 1.026.16 1.54.024.205 0 .456.115.65.07.148.219.24.356.319.678.388 1.355.776 2.044 1.163.012 0 .035.012.046.023.781.445 1.562.901 2.354 1.346.046.023.08.046.115.068.264.16.528.308.804.457.103.057.184.057.253.023.057-.035.091-.103.103-.194v-.103c0-.194-.012-.388-.023-.582-.035-.97-.023-1.95-.023-2.908v-.764-1.141-.593-.456c0-.274 0-.548.011-.822.023-1.015.104-2.03.161-3.022a2.2 2.2 0 01.023-.274c.046-.559.115-1.13.218-1.7.092-.49.219-.98.368-1.47.126-.446.275-.902.46-1.347.102-.273.32-.661.16-.946-.15-.24-.47-.377-.712-.502-.436-.251-.861-.502-1.298-.753-.493-.285-.976-.57-1.47-.844-.218-.126-.436-.262-.665-.388a95.782 95.782 0 00-1.114-.65 2.122 2.122 0 00-.322-.171c-.184-.091-.39-.126-.54.023-.08.068-.126.24-.172.33-.08.183-.16.365-.23.548-.022.068-.057.137-.08.217a16.55 16.55 0 00-.631 2.11c-.046.182-.08.353-.115.536a22.43 22.43 0 00-.299 1.756 26.485 26.485 0 00-.16 1.643c0 .125-.024.25-.024.376a34.742 34.742 0 00-.057 1.894v.707h.023z"
              ></path>
              <path
                fill="#72B9C2"
                d="M36.06 21.343l3.813 6.01s-.012-1.322 0-2.28v-.605l-1.332-2.053-2.469-1.072h-.011zM33.397 20.27c.022.833.068 1.678.126 2.522l2.698 4.151s.035.012.046.023c.781.445 1.562.901 2.354 1.346l-5.224-8.041zM35.705 9.23l4.593 7.072c.092-.49.218-.981.367-1.472l-2.87-4.402c-.219-.126-.437-.263-.667-.388-.367-.217-.734-.434-1.113-.65a2.122 2.122 0 00-.322-.172l.012.012zM33.454 16.77l2.87 4.413 2.343 1.29-5.018-7.723c-.092.662-.15 1.335-.195 2.02zM33.936 12.994l5.937 9.113c0-.274 0-.547.011-.821.023-1.015.104-2.03.161-3.023l-5.293-8.132c-.023.068-.058.137-.08.217a16.55 16.55 0 00-.632 2.11c-.046.182-.08.353-.115.536h.011z"
              ></path>
              <path
                fill="#565656"
                d="M33.362 19.37c0 1.289.069 2.566.184 3.844v.045c.057.513.103 1.027.16 1.54.024.194 0 .422.092.605 0 .01 0 .034.012.045.069.148.218.24.356.32.7.399 1.39.798 2.09 1.197.827.468 1.642.947 2.469 1.414.264.16.55.308.826.457.104.057.184.057.253.023.08-.058.115-.16.103-.286v-.034c-.218-.125-.47-.25-.7-.388-.827-.467-1.642-.935-2.469-1.414-.7-.4-1.39-.798-2.09-1.198-.138-.08-.275-.16-.356-.319-.092-.194-.08-.445-.115-.662a69.8 69.8 0 01-.149-1.54v-.045a43.057 43.057 0 01-.184-3.844c-.011-2.315.15-4.653.666-6.912.161-.718.368-1.425.62-2.11.104-.25.196-.502.31-.753.023-.057.058-.125.08-.194-.137-.045-.275-.034-.39.08-.08.069-.126.24-.172.331-.115.251-.218.502-.31.764a16.55 16.55 0 00-.631 2.11c-.529 2.259-.69 4.597-.666 6.912l.011.012z"
              ></path>
              <path
                fill="#1A1A1A"
                d="M33.362 19.37V20.145c.023 1.027.08 2.053.172 3.069v.045c.058.513.104 1.027.161 1.54.023.194 0 .422.092.605l.494-.172c-.092-.193-.08-.444-.115-.661-.058-.513-.103-1.027-.15-1.54v-.046c-.08-.855-.126-1.71-.16-2.566 0-.194-.012-.388-.012-.582v-.695c-.011-2.316.15-4.654.666-6.912.161-.719.368-1.426.62-2.11.104-.251.196-.502.31-.753.023-.057.058-.126.08-.194-.137-.046-.275-.034-.39.08-.08.068-.126.24-.172.33-.115.251-.218.502-.31.765a16.55 16.55 0 00-.631 2.11c-.529 2.258-.69 4.596-.666 6.912h.011z"
              ></path>
              <path
                fill="#272727"
                d="M33.385 19.575v.57l.505.274 5.96 3.285v-.547-.035l-5.983-3.285-.493-.273.011.011z"
              ></path>
              <path
                fill="#565656"
                d="M33.3850285 19.5750751L33.3850285 19.586481 39.8729349 23.1679439 39.8729349 22.5748354 33.8673154 19.2785209z"
              ></path>
              <path
                fill="#6BCDD9"
                d="M41.71 22.107c.195 1.973 1.723 1.335 1.895 1.255h.011l.161-.091 1.091-.64 1.056-.615.747-.433V11.237l-1.516.867-1.09.628-.414.239c-.092.148-.23.4-.402.787-.264.593-.597 1.506-.884 2.783-.115.513-.23 1.095-.333 1.745a28.46 28.46 0 00-.218 1.962 29.947 29.947 0 00-.115 1.86h.011z"
              ></path>
              <path
                fill="#4C7C82"
                d="M44.0757202 12.7315156L46.6823657 16.7464038 46.6823657 14.4424054 45.1666071 12.1155952z"
              ></path>
              <path
                fill="#4C7C82"
                d="M41.825 20.237l1.964 3.022 1.09-.639-2.824-4.345a28.46 28.46 0 00-.218 1.962h-.012zM42.376 16.541l3.56 5.475.746-.433v-2.544l-3.422-5.27c-.264.594-.597 1.506-.884 2.784v-.012z"
              ></path>
              <path
                fill="#565656"
                d="M43.237 23.145h.012l3.054-1.78v-9.91l.38-.218v10.334l-3.055 1.78h-.012c-.114.056-.907.387-1.435-.103.47.137.965-.069 1.068-.114l-.012.011z"
              ></path>
              <path
                fill="#6BCDD9"
                d="M47.773 10.61v10.608l1.47-.845.746-.433c.115-.068.219-.148.322-.25.276-.275.425-.64.425-1.039v-7.756c0-.604-.425-.958-.781-1.083a1.226 1.226 0 00-.39-.069c-.196 0-.39.057-.575.16l-.378.217-.85.49h.011z"
              ></path>
              <path
                fill="#4C7C82"
                d="M47.773 15.811v2.304l1.47 2.258.746-.433c.115-.068.219-.148.322-.25l-2.538-3.89v.011z"
              ></path>
              <path
                fill="#4C7C82"
                d="M47.7732527 10.6100121L47.7732527 12.9482283 50.7358718 17.4991954 50.7358718 13.3702478 48.6229961 10.1309629z"
              ></path>
              <path
                fill="#565656"
                d="M47.773 20.796v.433l2.228-1.278a1.46 1.46 0 00.597-.638c.103-.194.15-.41.15-.639v-7.756c0-.605-.426-.958-.781-1.084.218.183.401.48.401.867v7.756c0 .251-.057.49-.172.696-.126.24-.321.445-.562.593l-1.85 1.06-.01-.01z"
              ></path>
              <path
                fill="#272727"
                d="M46.3149091 21.3544006L46.6823657 21.5711133 46.6823657 11.2373384 46.3149091 11.4540511z"
              ></path>
              <path
                fill="#272727"
                d="M50.368 18.457c0 .251-.057.49-.172.696l.402.16c.103-.194.15-.41.15-.639v-7.756c0-.605-.426-.958-.781-1.084.218.183.401.48.401.867v7.756z"
              ></path>
              <path
                fill="#B3B3B3"
                d="M42.64 6.972c.276-.194.563-.365.873-.49.16-.07.322-.126.494-.138.172-.011.356 0 .505.103.08.045.16.125.172.228.023.137-.069.25-.172.354-.218.228-.482.41-.758.57l-.654.114a1.937 1.937 0 01-.506-.593c-.011-.023-.023-.046-.023-.069 0-.022.035-.045.058-.068l.011-.011z"
              ></path>
              <g>
                <path
                  fill="#1D1D1D"
                  d="M1.55 1.995v-.274h-.172c-.023-.011-.034-.022-.057-.034-.31-.17-.793-.182-1.103 0a.25.25 0 00-.057.046H0v.274c0 .114.08.228.23.308.31.17.792.182 1.102 0 .15-.092.23-.206.218-.32z"
                  transform="translate(42.342 5.376)"
                ></path>
                <path
                  fill="#E09C05"
                  d="M0 1.71c0 .125.08.24.23.33.31.172.803.183 1.102 0 .16-.09.23-.205.218-.319-.023-.216-.046-.422-.057-.627C1.47.877 1.458.65 1.378.433c0-.023-.012-.046-.023-.069A.727.727 0 001.102.09C.93-.024.69-.024.505.056.367.113.287.227.218.364a1.336 1.336 0 00-.08.228c-.07.251-.092.514-.115.764 0 .115-.012.229-.012.343L0 1.71z"
                  transform="translate(42.342 5.376)"
                ></path>
                <path
                  fill="#BC5705"
                  d="M.253 1.425c.287-.16.758-.16 1.045 0 .287.171.298.433 0 .604-.287.16-.758.16-1.045 0-.287-.17-.299-.433 0-.604z"
                  transform="translate(42.342 5.376)"
                ></path>
              </g>
              <g>
                <path
                  fill="#B3B3B3"
                  d="M2.165 1.627l.757.49c.092.057.092.137 0 .194l-.712.468c-.103.068-.378.068-.493 0l-.253-.16-.528.354-.241-.16.528-.342L.959 2.3c-.092-.057-.092-.137 0-.194l.712-.468c.103-.068.379-.068.494 0v-.011z"
                  transform="translate(41.647 8.527)"
                ></path>
                <g transform="translate(41.647 8.527) translate(.281 .914)">
                  <path
                    fill="#272727"
                    d="M0.459320802 0.564593663L0.447837782 1.93330557 0.700464223 2.0815827 0.700464223 0.701464854z"
                  ></path>
                  <path
                    fill="#1A1A1A"
                    d="M0.930124625 0.564593663L0.930124625 1.94471151 0.700464223 2.0815827 0.700464223 0.701464854z"
                  ></path>
                  <path
                    fill="#CCC"
                    d="M0.688981203 0.427722472L0.930124625 0.575999595 0.700464223 0.712870787 0.459320802 0.564593663z"
                  ></path>
                </g>
                <g transform="translate(41.647 8.527)">
                  <path
                    fill="#1A1A1A"
                    d="M1.234 2.288l.07.103c.022-.023.057-.045.08-.068.046-.035.092-.08.126-.114.023-.012.034-.035.057-.046.023-.023.046-.034.07-.046.01 0 .022-.022.034-.034.023-.023.023-.057.023-.091l-.23.148-.115.08-.126.08.011-.012z"
                  ></path>
                  <path
                    fill="#272727"
                    d="M.293.635H.43L.626.589l.23-.068S.763.498.728.509H.718L.316.635H.293z"
                  ></path>
                  <path
                    fill="#212121"
                    d="M1.70522848 1.98052613L1.36073788 2.25426852 1.34925486 1.37601171 1.70522848 1.18211085z"
                  ></path>
                  <path
                    fill="#666"
                    d="M1.21145862 1.10226932L1.55594922 0.931180336 0.855484994 0.520566763 0.430613252 0.657437954z"
                  ></path>
                  <path
                    fill="#272727"
                    d="M1.211 1.102l.15.263.333-.194A.313.313 0 001.556.92l-.345.17v.012z"
                  ></path>
                  <path
                    fill="#242424"
                    d="M1.108 2.403l-.78-.445a.316.316 0 01-.15-.263v-.89C.178.67.328.59.442.658l.78.445c.093.057.15.16.15.263v.89c0 .136-.15.216-.264.148z"
                  ></path>
                  <path
                    fill="#E07705"
                    d="M1.2 2.357s.092-.034.092-.091v-.89a.22.22 0 00-.115-.194L.397.737S.361.726.35.726a.09.09 0 00-.092.091v.89c0 .08.046.16.115.194l.781.445s.034.01.046.01z"
                  ></path>
                  <path
                    fill="#E09C05"
                    d="M1.188 2.026v-.639s-.022-.068-.057-.09L.568.976v.639s.023.068.058.09l.562.32z"
                  ></path>
                  <path
                    fill="#565656"
                    d="M.35.714s.035 0 .046.012l.035.023v.89c0 .08.046.159.114.193l.747.434s-.046.09-.092.09c-.012 0-.034 0-.046-.01L.374 1.9a.235.235 0 01-.116-.194v-.89A.09.09 0 01.35.726V.714z"
                  ></path>
                </g>
              </g>
              <g>
                <path
                  fill="#B3B3B3"
                  d="M2.165 1.616l.757.49c.092.057.092.137 0 .194l-.712.468c-.103.068-.378.068-.493 0l-.253-.16-.528.353-.241-.16.528-.341-.264-.172c-.092-.057-.092-.136 0-.193l.712-.468c.103-.068.379-.068.494 0v-.011z"
                  transform="translate(35.664 5.265)"
                ></path>
                <g transform="translate(35.664 5.265) translate(.281 .908)">
                  <path
                    fill="#272727"
                    d="M0.459320802 0.570296629L0.447837782 1.93900854 0.700464223 2.08728566 0.700464223 0.70716782z"
                  ></path>
                  <path
                    fill="#1A1A1A"
                    d="M0.930124625 0.570296629L0.930124625 1.95041447 0.700464223 2.08728566 0.700464223 0.70716782z"
                  ></path>
                  <path
                    fill="#CCC"
                    d="M0.688981203 0.433425438L0.930124625 0.581702562 0.700464223 0.70716782 0.459320802 0.570296629z"
                  ></path>
                </g>
                <g transform="translate(35.664 5.265)">
                  <path
                    fill="#1A1A1A"
                    d="M1.234 2.288l.07.103s.057-.045.08-.068l.126-.114s.034-.035.057-.046c.023-.023.046-.034.07-.046.01 0 .022-.022.034-.034.023-.023.023-.057.023-.091l-.23.148-.115.08-.126.08.011-.012z"
                  ></path>
                  <path
                    fill="#272727"
                    d="M.293.635H.43L.626.589l.23-.068S.763.498.728.509H.718L.316.635H.293z"
                  ></path>
                  <path
                    fill="#212121"
                    d="M1.70522848 1.9691202L1.36073788 2.25426852 1.34925486 1.36460577 1.70522848 1.17070492z"
                  ></path>
                  <path
                    fill="#666"
                    d="M1.21145862 1.10226932L1.55594922 0.931180336 0.855484994 0.520566763 0.430613252 0.657437954z"
                  ></path>
                  <path
                    fill="#272727"
                    d="M1.211 1.102l.15.263.333-.194A.313.313 0 001.556.92l-.345.17v.012z"
                  ></path>
                  <path
                    fill="#242424"
                    d="M1.108 2.403l-.78-.445a.316.316 0 01-.15-.263v-.89C.178.67.328.59.442.658l.78.445c.093.057.15.16.15.263v.89c0 .136-.15.216-.264.148z"
                  ></path>
                  <path
                    fill="#E07705"
                    d="M1.188 2.346s.092-.035.092-.092v-.89a.22.22 0 00-.114-.193L.385.726S.35.714.339.714a.09.09 0 00-.092.092v.89c0 .08.046.16.115.193l.78.445s.035.012.046.012z"
                  ></path>
                  <path
                    fill="#E09C05"
                    d="M1.177 2.015v-.639s-.023-.068-.057-.091L.557.965v.64s.023.068.057.09l.563.32z"
                  ></path>
                  <path
                    fill="#565656"
                    d="M.339.703s.034 0 .046.011l.034.023v.89c0 .08.046.16.115.194l.746.433s-.046.092-.092.092c-.011 0-.034 0-.045-.012L.362 1.89a.235.235 0 01-.115-.194v-.89a.09.09 0 01.092-.09V.702z"
                  ></path>
                </g>
              </g>
              <g transform="translate(55.622 13.29)">
                <path
                  fill="#0989E6"
                  d="M0.465062312 2.54352297L0.683239693 2.69180009 4.98937221 0.148277124 4.77119483 -3.55271368e-15z"
                ></path>
                <path
                  fill="#172E4D"
                  d="M4.98937221 0.148277124L4.98937221 3.83239335 0.683239693 6.36451038 0.683239693 2.68039416z"
                ></path>
                <path
                  fill="#2D5D9C"
                  d="M0.465062312 2.54352297L0.465062312 6.22763919 0.683239693 6.37591631 0.683239693 2.69180009z"
                ></path>
              </g>
              <g>
                <g transform="translate(56.19 13.895) translate(3.41)">
                  <path
                    fill="#101F33"
                    d="M0.688981203 0L0.688981203 3.22787892 0.459320802 3.36475011 0.459320802 0.148277124z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.597117043 3.17084926L0.597117043 0.0570296629 0.688981203 0 0.688981203 3.22787892 0.459320802 3.36475011 0.459320802 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(2.922 .285)">
                  <path
                    fill="#101F33"
                    d="M0.683239693 0L0.683239693 3.22787892 0.465062312 3.36475011 0.465062312 0.148277124z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.602858553 3.17084926L0.602858553 0.0570296629 0.683239693 0 0.683239693 3.22787892 0.465062312 3.36475011 0.465062312 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(2.434 .57)">
                  <path
                    fill="#101F33"
                    d="M0.688981203 0L0.688981203 3.22787892 0.459320802 3.36475011 0.459320802 0.148277124z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.608600063 3.17084926L0.608600063 0.0570296629 0.688981203 0 0.688981203 3.22787892 0.459320802 3.36475011 0.459320802 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(1.952 .855)">
                  <path
                    fill="#101F33"
                    d="M0.688981203 0L0.688981203 3.22787892 0.459320802 3.36475011 0.459320802 0.148277124z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.597117043 3.17084926L0.597117043 0.0570296629 0.688981203 0 0.688981203 3.22787892 0.459320802 3.36475011 0.459320802 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(1.464 1.14)">
                  <path
                    fill="#101F33"
                    d="M0.683239693 0L0.683239693 3.21647299 0.465062312 3.36475011 0.465062312 0.136871191z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.602858553 3.17084926L0.602858553 0.0456237303 0.683239693 0 0.683239693 3.21647299 0.465062312 3.36475011 0.465062312 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(.976 1.426)">
                  <path
                    fill="#101F33"
                    d="M0.688981203 0L0.688981203 3.21647299 0.459320802 3.36475011 0.459320802 0.136871191z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.608600063 3.17084926L0.608600063 0.0456237303 0.688981203 0 0.688981203 3.21647299 0.459320802 3.36475011 0.459320802 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(.494 1.71)">
                  <path
                    fill="#101F33"
                    d="M0.688981203 0L0.688981203 3.21647299 0.459320802 3.36475011 0.459320802 0.136871191z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.597117043 3.17084926L0.597117043 0.0456237303 0.688981203 0 0.688981203 3.21647299 0.459320802 3.36475011 0.459320802 3.26209672z"
                  ></path>
                </g>
                <g transform="translate(56.19 13.895) translate(0 1.996)">
                  <path
                    fill="#101F33"
                    d="M0.688981203 0L0.688981203 3.21647299 0.459320802 3.36475011 0.459320802 0.136871191z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.608600063 3.17084926L0.608600063 0.0456237303 0.688981203 0 0.688981203 3.21647299 0.459320802 3.36475011 0.459320802 3.26209672z"
                  ></path>
                </g>
              </g>
              <g>
                <g transform="translate(41.905 14.283) translate(1.332)">
                  <path
                    fill="#1A1A1A"
                    d="M1.52724167 4.20878912L0 3.33053231 0 0.0456237303 1.52724167 0.923880539z"
                  ></path>
                  <path
                    fill="#272727"
                    d="M0.0803811404 3.20506706L0.0803811404 0.513266966 1.10236993 1.09496953 1.10236993 3.79817555z"
                  ></path>
                  <path
                    fill="#565656"
                    d="M0 0.0456237303L0.0803811404 0.513266966 1.10236993 1.09496953 1.52724167 0.923880539z"
                  ></path>
                  <path
                    fill="#565656"
                    d="M1.10236993 3.79817555L1.52724167 4.20878912 0 3.33053231 0.0803811404 3.20506706z"
                  ></path>
                </g>
                <path
                  fill="#272727"
                  d="M1.7 4.083s.103-.011.149-.045a.307.307 0 00.16-.274V2.327h-.264v1.437l-.023.046s-.034.011-.057 0c-.207-.126-.425-.251-.632-.377l-.516-.308c-.08-.045-.15-.091-.23-.137-.046-.034-.115-.09-.172-.102-.035 0-.058 0-.08.034A.219.219 0 000 3.034c0 .068.023.103.08.137l.104.068c.08.046.16.103.252.149.184.114.368.216.552.33.183.103.356.217.54.32a.265.265 0 00.16.045h.011z"
                  transform="translate(41.905 14.283)"
                ></path>
                <path
                  fill="#1A1A1A"
                  d="M.138 3.194c.08.045.16.102.252.148.184.114.368.217.552.33.183.103.356.217.54.32a.265.265 0 00.16.046.232.232 0 00.15-.046.307.307 0 00.16-.274V2.327h.069v1.437a.307.307 0 01-.16.274.376.376 0 01-.15.045.265.265 0 01-.16-.045c-.185-.103-.357-.217-.54-.32-.184-.114-.368-.216-.552-.33-.08-.046-.16-.103-.252-.149l-.104-.068-.023-.023c.023.011.046.023.058.034v.012z"
                  transform="translate(41.905 14.283)"
                ></path>
                <path
                  fill="#565656"
                  d="M.057 2.931s.046-.045.08-.034c.058.012.127.08.173.103.08.045.15.091.23.137l.516.308c.207.125.425.25.632.376.023.011.046 0 .057 0 0 0 .023-.023.023-.046V2.338h.264v.091h-.218v1.438c0 .022-.023.045-.023.045s-.034.012-.057 0c-.207-.125-.425-.25-.632-.376l-.516-.308c-.08-.046-.15-.091-.23-.137C.299 3.057.241 3 .184 2.988c-.035 0-.058 0-.08.035a.219.219 0 00-.035.114C.046 3.114.034 3.09.034 3.045c0-.045 0-.08.035-.114H.057z"
                  transform="translate(41.905 14.283)"
                ></path>
                <g transform="translate(41.905 14.283) translate(1.28 1.454)">
                  <path
                    fill="#0876C4"
                    d="M0.407647212 1.11207843L0.407647212 0.496158067 0.740654794 0.690058921 0.740654794 1.30597928z"
                  ></path>
                  <path
                    fill="#0989E6"
                    d="M0.407647212 0.496158067L0.476545332 0.450534337 0.821035934 0.644435191 0.740654794 0.690058921z"
                  ></path>
                  <path
                    fill="#204473"
                    d="M0.821035934 1.26035555L0.821035934 0.644435191 0.740654794 0.690058921 0.740654794 1.30597928z"
                  ></path>
                </g>
              </g>
              <g>
                <path
                  fill="url(#linearGradient-15)"
                  d="M.75 2.55L.327 2.3C-.076 2.07-.11 1.386.246.77c.356-.615.976-.923 1.378-.695l.459.274L.75 2.55z"
                  transform="translate(26.112 6.737)"
                ></path>
                <path
                  fill="#8D9296"
                  d="M2.048.326c-.401-.228-1.022.08-1.377.696-.356.616-.322 1.3.08 1.528.402.228 1.022-.08 1.378-.696.356-.615.321-1.3-.08-1.528z"
                  transform="translate(26.112 6.737)"
                ></path>
                <path
                  fill="#204473"
                  d="M1.05 2.413c.298 0 .665-.273.895-.661.15-.262.23-.548.218-.787 0-.126-.046-.354-.218-.445A.354.354 0 001.75.474c-.299 0-.666.274-.896.662-.287.502-.287 1.072 0 1.243a.354.354 0 00.195.046v-.012z"
                  transform="translate(26.112 6.737)"
                ></path>
              </g>
              <path
                fill="#E07705"
                d="M42.468 6.47s.023-.103.035-.149a.715.715 0 01.137-.319.508.508 0 01.173-.137c.023-.011.172-.103.206-.08.07.057.15.114.207.183.138.137.103.342.115.513 0 .217 0 .445-.046.662a.53.53 0 01-.15.285c-.126.091-.298.045-.436 0-.287-.091-.287-.434-.264-.673 0-.103.023-.205.035-.308l-.012.023z"
              ></path>
              <path
                fill="#FFB206"
                d="M42.56 5.751s.034.114.057.16a.536.536 0 00.31.205.8.8 0 00.368 0 .635.635 0 00.31-.137.35.35 0 00.115-.17.161.161 0 00-.023-.07.727.727 0 00-.253-.273c-.172-.114-.413-.114-.597-.034-.138.057-.218.17-.287.308v.011z"
              ></path>
              <path
                fill="url(#linearGradient-16)"
                d="M2.278 31.985a.99.99 0 01.275-.297l.804-1.22c.367-.548.689-1.118.976-1.7.15-.296.299-.593.436-.9.196-.4.437-.8.563-1.233.126-.444.126-.867.046-1.311-.057-.354-.126-.719-.16-1.084-.035-.32.137-.559.481-.49.31.057.425.17.448.49.092.992.276 1.985-.08 2.943-.207.547-.563.935-.896 1.403-.712.97-1.309 2.03-1.768 3.136-.023.069-.058.137-.08.206 0 0-.184.479-.196.467-.011.023-.011.046-.034.069-.207.353-.551.524-.781.399-.23-.137-.253-.525-.046-.867l.012-.011z"
              ></path>
              <g>
                <path
                  fill="#242424"
                  d="M1.32 2.512L.219 1.873A.426.426 0 010 1.497V.242C0 .06.207-.066.367.037L1.47.676c.138.08.218.216.218.376v1.255c0 .182-.207.308-.367.205z"
                  transform="translate(27.184 20.302)"
                ></path>
                <path
                  fill="#E07705"
                  d="M1.447 2.432c.069 0 .138-.045.138-.137V1.041a.307.307 0 00-.161-.274L.322.128S.276.105.252.105C.185.105.116.151.116.242v1.255c0 .114.057.217.16.274l1.103.638s.046.023.069.023z"
                  transform="translate(27.184 20.302)"
                ></path>
                <path
                  fill="#E09C05"
                  d="M1.424 1.964s.011 0 .011-.01V1.04s-.034-.103-.08-.137L.551.448S.54.448.54.458v.913s.034.103.08.137l.804.456z"
                  transform="translate(27.184 20.302)"
                ></path>
                <path
                  fill="#565656"
                  d="M.241.117S.287.117.31.14l.046.022v1.255c0 .114.057.217.16.274l1.057.604c0 .08-.069.126-.126.126-.023 0-.046 0-.069-.023L.276 1.759a.307.307 0 01-.161-.274V.231A.14.14 0 01.253.094L.24.117z"
                  transform="translate(27.184 20.302)"
                ></path>
              </g>
              <path
                fill="url(#linearGradient-17)"
                d="M5.757 30.342l-.712-.399c-.23-.125-.253-.525-.046-.867.207-.353.551-.524.78-.399.104.057.219.125.322.183.08.045.15.068.184.148.035.102.058.205.069.32 0 .056-.046.09-.08.147-.035.057-.07.103-.092.16l-.195.32c-.08.125-.15.25-.23.387z"
              ></path>
              <path
                fill="url(#linearGradient-18)"
                d="M7.089 33.673h-.08c-.08-.034-.173-.091-.253-.137-.126-.068-.241-.137-.368-.205-.23-.137-.252-.525-.045-.867.206-.354.55-.525.78-.4.127.069.322.137.402.252.08.125.207.353.127.49-.08.137-.161.274-.242.4-.068.113-.137.227-.206.353a.31.31 0 01-.115.125v-.011z"
              ></path>
              <path
                fill="#DEE3E7"
                d="M7.031 32.281h-.447a7.717 7.717 0 01-3.043-.49l-.643-.126-.575 1.187h1.08c.964 0 1.929.16 2.825.49l1.01.376-.218-1.437h.011z"
              ></path>
              <path
                fill="#8D9296"
                d="M6.595 32.19c-.207-.787-.517-1.414-.976-2.087a.833.833 0 01-.092-.593c.07-.4.333-.685.597-.64.184.035.31.229.356.469l.046.308c.127.798.356 1.46.758 2.167 0 0 .356.616.345.627.103.148.16.376.114.627-.068.4-.333.685-.597.639-.344-.057-.356-.468-.367-.741 0-.263-.103-.525-.172-.776h-.012z"
              ></path>
              <path
                fill="url(#linearGradient-19)"
                d="M5.435 24.788l-.344-.183c-.23-.137-.253-.525-.046-.867.207-.353.551-.524.78-.399l.368.194-.758 1.255z"
              ></path>
              <path
                fill="#8D9296"
                d="M6.124 23.476c-.23-.137-.574.046-.78.4-.207.353-.184.74.045.866.23.137.575-.046.781-.4.207-.353.184-.74-.046-.866z"
              ></path>
              <path
                fill="#1A1A1A"
                d="M1.933 10.998c.046.707.345 1.688.907 2.156.425.353 1.286.604 2.044.604.38 0 .735-.057.988-.205.838-.48 1.102-1.586.976-2.475-.07-.536-.276-1.038-.494-1.517-.264-.605-.505-1.187-.46-1.78.058-.798.713-1.574 1.597-1.859.77-.262 1.71-.194 2.664.183.861.342 1.63.9 2.411 1.482 1.057.787 2.297 1.609 3.572 1.22.252-.068.482-.193.711-.307.288-.148.563-.285.85-.32.172-.022.356 0 .551.023.23.023.483.057.724.012.884-.137 1.4-.993 1.71-1.62l.047-.08c.298-.616.608-1.254 1.171-1.482.39-.16.884-.137 1.516.08a6.733 6.733 0 013.089 2.212c.115-.148.24-.274.379-.365A7.114 7.114 0 0023.6 4.622c-.769-.262-1.377-.285-1.883-.069-.746.32-1.114 1.073-1.435 1.734l-.035.08c-.264.525-.677 1.243-1.32 1.334-.184.023-.379 0-.586-.022-.218-.023-.436-.057-.666-.023-.39.045-.712.217-1.033.376-.218.114-.414.217-.62.274-1.045.32-2.148-.422-3.112-1.14-.804-.605-1.608-1.175-2.526-1.552-1.057-.422-2.136-.49-3.02-.194-1.08.365-1.86 1.3-1.941 2.316-.058.718.218 1.38.494 2.019.206.467.39.923.447 1.38.092.695-.091 1.608-.711 1.962-.563.319-1.953.057-2.435-.343-.344-.296-.585-.935-.677-1.494l-.494-.274H1.99l-.057.012z"
              ></path>
              <path
                fill="#1A1A1A"
                d="M5.872 23.373l.069.034c0-.16.011-.307 0-.456a7.035 7.035 0 00-.092-.479c-.104-.479-.23-.935-.368-1.403 0-.034-.023-.068-.023-.091-.287-.992-.31-2.167-.31-3.285 0-.707.046-1.437.39-2.042.288-.513.747-.89 1.24-1.288.414-.331.84-.685 1.16-1.118.3-.4.494-.844.701-1.29.299-.66.586-1.288 1.125-1.653.506-.342 1.126-.376 1.574-.342.459.034.907.137 1.39.25l.355.08c1.918.434 3.881.56 5.845.366 1.849-.183 3.341-.753 4.444-1.723.103-.09.218-.194.321-.285.38-.342.735-.661 1.16-.867.23-.114.482-.17.735-.182.126 0 .264 0 .39.034a.51.51 0 01.184.068c.035.012.115.08.15.08.011 0 .022-.011.022-.022.058-.08.058-.194.115-.274a.308.308 0 01.08-.091l.024-.023c0-.012 0-.034-.012-.046-.103-.103-.31-.148-.448-.182a1.855 1.855 0 00-.505-.046c-.321 0-.654.091-.942.24-.493.239-.895.604-1.274.946-.104.091-.207.183-.31.285-1.022.89-2.412 1.426-4.146 1.586a17.97 17.97 0 01-5.684-.354l-.344-.08c-.471-.114-.965-.228-1.47-.262-.746-.057-1.4.091-1.895.422-.666.445-.999 1.175-1.309 1.87-.183.411-.379.845-.643 1.198-.287.388-.666.696-1.068 1.027-.505.41-1.022.844-1.355 1.437-.402.718-.459 1.563-.459 2.293 0 1.152.023 2.36.321 3.398.115.377.219.765.322 1.152.046.194.092.388.126.582.012.091.035.194.046.285 0 .08 0 .171.012.251.069-.011.126-.057.195-.057a.32.32 0 01.103.012.705.705 0 01.138.057l-.08-.012z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

IllustrationMiniExcavator.propType = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationMiniExcavator.defaultProps = {
  size: 70,
};

export { IllustrationMiniExcavator as default } from "./MiniExcavator";
