import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { UserBox } from "../../../ui/Chat/UserBox";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconList } from "../../../ui/Icon/Line/List";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconVehicle } from "../../../ui/Icon/Line/Vehicle";
import { IconDriver } from "../../../ui/Icon/Solid/Driver";
import { CustomizeColumnsModal } from "../../../ui/Modal/CustomModals/CustomizeColumnsModal";
import { ThumbProfile } from "../../../ui/ThumbProfile/ThumbProfile";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../../utils/Utils";
import { getAddressBooksAsync } from "../../address/addressBookSlice";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "../../driver/driversSlice";
import {
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
} from "../../driver/driversStatusSlice";
import { schedulesSelectors } from "../../schedules/schedulesSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  addPreset,
  deletePresetAsync,
  getPresetsAsync,
  newPresetAsync,
  removePreset,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
  updatePresetAsync,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import { vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import { getReportsMetadataAsync } from "../reportsMetadataSlice";
import { SchedulationManageModal } from "../schedulation/SchedulationManageModal";
import { SchedulationModal } from "../schedulation/SchedulationModal";
import "./DriverFilterBarReport.css";
import {
  DriverTravelsMacroSummary,
  driverTravelsMacroSummaryEmptyState,
  driverTravelsMacroSummarySelectors,
  getDriverTravelsMacroSummaryAsync,
  selectDriverTravelsMacroSummarySliceReasonCode,
  selectDriverTravelsMacroSummarySliceStatus,
} from "./driverTravelsMacroSummarySlice";
import {
  driverTravelsSummaryEmptyState,
  getDriverTravelsSummaryAsync,
  selectDriverTravelsSummarySliceReasonCode,
  selectDriverTravelsSummarySliceStatus,
} from "./driverTravelsSummarySlice";

interface QueryParams {
  [paramName: string]: any;
}

interface DriverFilterBarReportProps {
  callback: (driverId: number, buildQueryParam: string) => any;
  presets: Preset[];
  disableButton?: boolean;
}

interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
}

let context: string = "driverTravelReport";
const reportsRepository = new ReportsRepository();

export const DriverFilterBarReport: React.FC<DriverFilterBarReportProps> = ({
  callback,
  presets,
  disableButton,
}) => {
  const navigate = useNavigate();
  const pathMatchAddingSchedulation = useMatch(
    "/reports/driver-travel-report/addSchedulation"
  );
  const pathMatchEditingSchedulation = useMatch(
    "/reports/driver-travel-report/editSchedulation/:schedulationId"
  );
  const [manageModalOpened, setManageModalOpened] = useState(false);
  const schedules = useAppSelector(schedulesSelectors.selectAll);
  const filterSchedulesByType = (type: string) => {
    return schedules.filter((schedule: any) => schedule.type === type);
  };
  const [driverNameSelected, setDriverNameSelected] = useState("");
  const [driverId, setDriverId] = useState(-1);
  const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
  const [dateRange, setDateRange] = useState<string[]>(
    initialDateValue.map((value) => value.toString())
  );
  const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
  const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;

  const [isOpen, setIsOpen] = useState(false);

  const searchbarRef = useRef<HTMLHeadingElement>(null);
  const parentRef = useRef<HTMLHeadingElement>(null);

  const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const driverTravelsMacroSummarySliceStatus = useAppSelector(
    selectDriverTravelsMacroSummarySliceStatus
  );
  const driverTravelsMacroSummarySliceReasonCode = useAppSelector(
    selectDriverTravelsMacroSummarySliceReasonCode
  );

  const driverTravelsSummarySliceStatus = useAppSelector(
    selectDriverTravelsSummarySliceStatus
  );
  const driverTravelsSummarySliceReasonCode = useAppSelector(
    selectDriverTravelsSummarySliceReasonCode
  );

  const [schedulationModal, setSchedulationModal] = useState(
    !!pathMatchAddingSchedulation || !!pathMatchEditingSchedulation
  );

  const isReportsIdle = driverTravelsMacroSummarySliceStatus === "idle";
  const isReportsFailed = driverTravelsMacroSummarySliceStatus === "failed";
  const driversSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driversSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);

  const [queryString, setQueryString] = useState("");
  const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);
  const [justReloaded, setJustReloaded] = useState(false);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);
  let defaultColumns: Preset = {} as Preset;
  let customColumns: TableColumnEntry[] = [];

  useEffect(() => {
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
    store.dispatch(getAddressBooksAsync(""));
    return function cleanUp() {
      store.dispatch(driverTravelsMacroSummaryEmptyState());
      store.dispatch(driverTravelsSummaryEmptyState());
      store.dispatch(driversEmptyState());
    };
  }, []);

  if (!!presets && presets.length > 0) {
    defaultColumns = presets.filter((x) => x.name === "Default")[0];
    if (!!defaultColumns) {
      defaultColumns?.columns.forEach((column, index) =>
        customColumns.push({
          id: index,
          name: t("table.columns." + column),
          value: column,
        })
      );
    }
  }

  //#region FUNCTIONS
  const GenerateResult = () => {
    if (
      (driverId !== -1 && queryString !== "") ||
      isSelectedPeriod ||
      initialDateValue
    ) {
      GenerateResultWithParams(driverId, queryString);
    }
  };

  const GenerateResultWithParams = (
    driverId: number,
    queryString: string,
    macro: boolean = true
  ) => {
    if (driverId !== -1 && queryString !== "" && macro) {
      const macroSummaryQuery = queryString + "&routeStateTypeEnum=TRACK";
      store.dispatch(
        getDriverTravelsMacroSummaryAsync({
          queryString: macroSummaryQuery,
          driverId: driverId,
        })
      );
      callback(driverId, queryString);
    } else if (driverId !== -1 && queryString !== "" && !macro) {
      store.dispatch(
        getDriverTravelsSummaryAsync({
          queryParams: queryString,
          driverId: driverId,
          page: 0,
        })
      );
      callback(driverId, queryString);
    }
  };

  const downloadResult = (pdf: boolean) => {
    if (
      (driverId !== -1 && queryString !== "") ||
      initialDateValue ||
      isSelectedPeriod
    ) {
      const params = queryString + (pdf ? "&isPdf=true" : "&isPdf=false");
      pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
      reportsRepository
        .getDriverTravelReportDownload(params, driverId)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          const fileName = data.split("/").pop();
          const attachmentDownloadLink = document.createElement("a");
          attachmentDownloadLink.href = process.env.REACT_APP_BUCKET_URL + data;
          attachmentDownloadLink.download = fileName;
          attachmentDownloadLink.target = "_blank";
          attachmentDownloadLink.style.display = "none";
          document.body.appendChild(attachmentDownloadLink);
          attachmentDownloadLink.click();
          document.body.removeChild(attachmentDownloadLink);
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
        })
        .catch((error) => {
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          if (
            error.response &&
            error.response.data.message ==
              GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
          ) {
            console.log(error?.message || "Report Timeout Exception");
            ToastNotification({
              toastId: "reportTimeoutError",
              status: "default",
              title: t("common.reportTimeoutErrorTitle"),
              description: t("common.reportTimeoutErrorDesc"),
            });
          } else {
            console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          }
        });
    }
  };

  const sendReportEmail = () => {
    if (
      (driverId !== -1 && queryString !== "") ||
      initialDateValue ||
      isSelectedPeriod
    ) {
      reportsRepository
        .getDriverTravelReportEmail(queryString, driverId)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          ToastNotification({
            toastId: "requestSendingMailSuccess",
            status: "success",
            description: t(
              "report.toastNotification.requestSendingMailSuccess"
            ),
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error?.message || "Unknown Error");
          else console.log(error.response?.data?.error || "Unknown Error");
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        });
    }
  };

  useEffect(() => {
    if (
      driverTravelsMacroSummarySliceStatus === "idle" &&
      driverTravelsMacroSummarySliceReasonCode === "S1" &&
      initialDateValue.length > 0
    ) {
      const driverTravelsMacroSummary: DriverTravelsMacroSummary[] =
        driverTravelsMacroSummarySelectors.selectAll(store.getState());
      let map = new Map();
      map.set(
        "startPeriod",
        dateRange[0] ?? dayjs(initialDateValue[0]).format("YYYY-MM-DDTHH:mm")
      );
      map.set(
        "endPeriod",
        dateRange[1] ?? dayjs(initialDateValue[1]).format("YYYY-MM-DDTHH:mm")
      );
      if (driverTravelsMacroSummary[0]) {
        let valueDayStart = dayjs(driverTravelsMacroSummary[0].firstTimestamp);
        let valueDayEnd = dayjs(driverTravelsMacroSummary[0].lastTimestamp);
        if (
          valueDayStart.isValid() &&
          map.get("startPeriod") &&
          valueDayStart.isBefore(map.get("startPeriod"))
        ) {
          map.set("startPeriod", valueDayStart.format("YYYY-MM-DDTHH:mm"));
        }
        if (
          valueDayEnd.isValid() &&
          map.get("endPeriod") &&
          valueDayEnd.isBefore(map.get("endPeriod"))
        ) {
          map.set("endPeriod", valueDayEnd.format("YYYY-MM-DDTHH:mm"));
        }
        setDateRange([map.get("startPeriod"), map.get("endPeriod")]);
      }
      handleChanges(map, driverId, false, true);
    }
  }, [
    driverTravelsMacroSummarySliceStatus,
    driverTravelsMacroSummarySliceReasonCode,
    initialDateValue,
  ]);

  const updatePreset = (newPreset: Preset) => {
    if (newPreset?.columns?.length > 0) {
      let temporaryPreset = presets.find((preset) => preset.name === undefined);
      if (temporaryPreset) {
        store.dispatch(removePreset(temporaryPreset.id));
      }
      if (newPreset.name) {
        if (newPreset.name !== "Default" && newPreset.context === undefined) {
          newPreset.context = context;
          newPreset.lastSelected = true;
          store.dispatch(newPresetAsync(newPreset));
        } else {
          store.dispatch(
            updatePresetAsync({
              id: newPreset.id,
              preset: {
                lastSelected: true,
              },
              context: context,
            })
          );
        }
        return true;
      } else {
        newPreset.id = Math.max(...presets.map((preset) => preset.id)) + 1;
        store.dispatch(addPreset(newPreset));
        return false;
      }
    }
  };

  const handleChanges = (
    params: Map<string, string[] | string>,
    driverId?: number,
    generateMacroSummary?: boolean,
    generateSummary?: boolean
  ): void => {
    if (generateSummary && driverId && driverId !== -1) {
      let queryParamsFixed: QueryParams = {};
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParamsFixed[key] = value;
        }
      });
      const stringifiedQueryParams = getQueryString(queryParamsFixed);
      GenerateResultWithParams(driverId, stringifiedQueryParams, false);
      return;
    }
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      const stringifiedQueryParams = getQueryString(queryParams);
      setQueryString(stringifiedQueryParams);
      if (driverId && driverId !== -1) {
        pathMatchAddingSchedulation === null &&
          navigate(
            `/reports/driver-travel-report${
              driverId !== -1 ? `/` + driverId : ""
            }${stringifiedQueryParams}`
          );
        pathMatchAddingSchedulation &&
          navigate(
            `/reports/driver-travel-report/addSchedulation${
              driverId !== -1 ? `/` + driverId : ""
            }${stringifiedQueryParams}`
          );
        pathMatchEditingSchedulation &&
          navigate(
            `/reports/driver-travel-report/editSchedulation/${
              pathMatchEditingSchedulation.params.schedulationId
            }/${driverId !== -1 ? `/` + driverId : ""}${stringifiedQueryParams}`
          );
        if (generateMacroSummary) {
          GenerateResultWithParams(driverId, stringifiedQueryParams);
        }
      }
    }
  };

  const displayDrivers = (driver: Driver, index: number) => {
    const firstName = driver.firstName ? driver.firstName : t("common.na");
    const lastName = driver.lastName ? driver.lastName : t("common.na");
    const vehicleDetails = vehiclesSelectors.selectById(
      store.getState(),
      driver?.vehicle
    );
    const vehicleName =
      vehicleDetails?.brand && vehicleDetails?.model
        ? vehicleDetails?.brand + " " + vehicleDetails?.model
        : t("common.na");
    const driverStatus = driversStatusSelectors.selectById(
      store.getState(),
      driver.id
    )?.dynamicFields?.ignitionKey
      ? true
      : false;

    return (
      <div
        key={index}
        className={
          driverNameSelected === firstName + " " + lastName
            ? "report-driver-driverbox-selected"
            : "report-driver-driverbox"
        }
      >
        <UserBox
          size="regular"
          status={driverStatus === true ? "ONLINE" : "OFFLINE"}
          title={firstName + " " + lastName}
          iconSubTitle={
            <IconVehicle
              size={8}
              color={
                driverNameSelected === firstName + " " + lastName
                  ? "--global-colors-ui-white"
                  : "--global-colors-ink-light"
              }
            />
          }
          subTitle={vehicleName}
          icon={
            <ThumbProfile
              size="small"
              alt="User Thumbnail"
              imgUrl={driver.avatar}
            />
          }
          disableClick={true}
          onClicked={() => {
            setDriverNameSelected(firstName + " " + lastName);
            setIsOpen(false);
            setDriverId(driver.id);
          }}
        />
      </div>
    );
  };
  //#endregion

  //#region ERROR_HANDLING
  useEffect(() => {
    let presetNotification: {
      status: string | undefined;
      description: string | undefined;
    } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
    if (
      presetNotification.status !== undefined &&
      presetNotification.description !== undefined
    ) {
      ToastNotification({
        toastId: t(presetNotification.description),
        status: presetNotification.status as TypeOptions,
        description: t(presetNotification.description),
      });
      store.dispatch(restoreState());
    }
  }, [presetsSliceStatus, presetsSliceReasonCode]);

  useEffect(() => {
    if (
      driversSliceStatus === "failed" &&
      driversSliceReasonCode === GTFleetErrorCodes.USER_UNAUTHORIZED
    ) {
      ToastNotification({
        toastId: "unauthorizedError",
        status: "error",
        description: t("common.unauthorizedError"),
      });
    }
  }, [driversSliceStatus, driversSliceReasonCode]);

  useEffect(() => {
    if (
      driverTravelsSummarySliceStatus === "failed" &&
      driverTravelsSummarySliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driverTravelsSummarySliceStatus, driverTravelsSummarySliceReasonCode]);
  //#endregion

  //#region USE_EFFECTS
  /**
   * When another driver is selected, the URL should be changed
   */
  useEffect(() => {
    driverId !== -1 &&
      navigate(
        `/reports/driver-travel-report/${driverId}${getQueryString(
          queryParams
        )}`
      );
  }, [driverId]);

  /**
   * When another driver is selected and there are drivers, the related alias should be saved.
   */
  useEffect(() => {
    if (driverId !== -1 && drivers.length && justReloaded) {
      const aliasDriver = drivers.find(
        (driver) => driver.id === Number(driverId)
      );
      aliasDriver?.firstName !== undefined &&
        aliasDriver?.lastName !== undefined &&
        setDriverNameSelected(
          aliasDriver?.firstName + " " + aliasDriver?.lastName
        );
      setJustReloaded(false);
    }
  }, [driverId, drivers, justReloaded]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        searchbarRef.current &&
        !searchbarRef.current.contains(event.target) &&
        parentRef.current &&
        !parentRef.current.contains(event.target)
      )
        setIsOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchbarRef]);

  //#region " Handle url parameters "
  const urlWithParameters = useRef(false);
  const location: Location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    pathMatchEditingSchedulation && setSchedulationModal(true);
  }, [pathMatchEditingSchedulation]);

  useEffect(() => {
    schedulationModal && setManageModalOpened(false);
  }, [schedulationModal]);
  useEffect(() => {
    manageModalOpened && setSchedulationModal(false);
  }, [manageModalOpened]);

  const driverTravelReportRoute = useMatch(
    `/reports/driver-travel-report/:driverId`
  );
  let isDriverTravelReportRoute = driverTravelReportRoute !== null;

  useEffect(() => {
    const map = new Map();
    let driverIdUrl = -1;
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;

    // Retrieving driver id from URL
    driverIdUrl =
      isDriverTravelReportRoute && driverTravelReportRoute?.params?.driverId
        ? Number(driverTravelReportRoute?.params?.driverId)
        : -1;

    if (driverIdUrl !== -1 && driverId === -1) {
      setDriverId(Number(driverIdUrl));
      setJustReloaded(true);
    }

    // Retrieving query params from URL
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const startPeriod = currentSearchParams.get("startPeriod");
      const endPeriod = currentSearchParams.get("endPeriod");
      if (startPeriod) {
        map.set("startPeriod", startPeriod);
        setIsSelectedPeriod(true);
        setInitialDateValue((prev) => {
          let prevValue = prev;

          prevValue[0] = new Date(startPeriod);

          return prevValue;
        });
      }
      if (endPeriod) {
        map.set("endPeriod", endPeriod);
        setInitialDateValue((prev) => {
          let prevValue = prev;

          prevValue[1] = new Date(endPeriod);

          return prevValue;
        });
      }
    }

    if (map.size === 0 && _.isEmpty(queryParams)) {
      const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
      const startPeriodDate = new Date(endPeriod);
      startPeriodDate.setHours(0, 0, 0, 0);
      const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
      map.set("startPeriod", startPeriod);
      map.set("endPeriod", endPeriod);
      setIsSelectedPeriod(true);
      setInitialDateValue((prev) => {
        let prevValue = prev;
        prevValue[0] = new Date(startPeriod);
        prevValue[1] = new Date(endPeriod);
        return prevValue;
      });
    }

    if (map.size > 0) {
      handleChanges(map, driverIdUrl, true);
    }
  }, [location, drivers]);

  useEffect(() => {
    if (queryString !== "" && isSelectedPeriod && urlWithParameters.current) {
      GenerateResult();
      urlWithParameters.current = false;
    }
  }, [queryString, isSelectedPeriod]);
  //#endregion
  //#endregion
  const list = [
    {
      id: 0,
      title: t("report.filterBar.downloadExcel"),
      icon: isExcelGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(false);
      },
    },
    {
      id: 1,
      title: t("report.filterBar.downloadPDF"),
      icon: isPdfGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(true);
      },
    },
    {
      id: 2,
      title: t("report.filterBar.sendReportEmail"),
      icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
      onClick: () => {
        sendReportEmail();
      },
    },
  ];

  return (
    <div className="report-driver-filterbar-row">
      <div className="report-driver-filterbar-search">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          ref={parentRef}
        >
          <Form>
            <SearchField
              name="search"
              id="search-field"
              size="small"
              placeholder={t(
                "locationHistory.searchDropdown.placeholderDriver"
              )}
              value={driverNameSelected}
              onChange={(val: string) => {
                setDriverNameSelected(val);
              }}
            />
          </Form>
        </div>
        {isOpen && drivers.length > 0 && (
          <div className="report-driver-search-results" ref={searchbarRef}>
            {drivers
              ?.filter((driver: Driver) => {
                const dirverName =
                  driver?.firstName && driver?.lastName
                    ? driver?.firstName.toLowerCase() +
                      " " +
                      driver?.lastName?.toLowerCase()
                    : "";
                const filterDriver =
                  driverNameSelected == "" ||
                  dirverName.includes(driverNameSelected.toLowerCase());
                return filterDriver;
              })
              .map((driver: Driver, idx: number) =>
                displayDrivers(driver, idx)
              )}
          </div>
        )}
      </div>

      <div className="report-driver-date-picker">
        <Form>
          <DatePickerDropdown
            setDate={(val) => {
              const map = new Map();
              let startPeriod = "";
              let endPeriod = "";
              if (_.isArray(val)) {
                if (val.length === 1) {
                  endPeriod =
                    typeof val[0] === "string"
                      ? val[0]
                      : val[0].format("YYYY-MM-DDTHH:mm");
                  const startPeriodDate = new Date(endPeriod);
                  startPeriodDate.setHours(0, 0, 0, 0);
                  startPeriod =
                    dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
                } else {
                  startPeriod =
                    typeof val[0] === "string"
                      ? val[0]
                      : val[0].format("YYYY-MM-DDTHH:mm");
                  endPeriod = val[1]?.format("YYYY-MM-DDTHH:mm") ?? "";
                }
              }
              map.set("startPeriod", startPeriod);
              map.set("endPeriod", endPeriod);
              setIsSelectedPeriod(true);
              setDateRange([startPeriod, endPeriod]);
              handleChanges(map, driverId, false);
            }}
            hasTime={true}
            localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
            language={preferencesContext.language ?? "it"}
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
            limitDaysRange={31}
            initialValue={initialDateValue.length ? initialDateValue : []}
          />
        </Form>
      </div>

      <div className="report-driver-dropdown-customize">
        <CustomizeColumnsModal
          columns={customColumns}
          presets={presets ?? []}
          onClose={(newPreset) => updatePreset(newPreset)}
          handleDelete={(e: any) => store.dispatch(deletePresetAsync(e))}
        />
      </div>

      <div className="report-driver-filterbar-btn-generate">
        <Button
          aspect="primary"
          size="small"
          label={t("report.filterBar.buttonGenerate")}
          onlyIcon={false}
          onClick={() => GenerateResult()}
          isLoading={isReportsIdle || isReportsFailed ? false : true}
          disabled={
            initialDateValue === undefined ||
            driverNameSelected === "" ||
            isOpen
          }
        />
      </div>

      <div className="report-driver-filterbar-btn ">
        <div className="ior-filterbar-btn-schedule">
          <DropdownButton
            aspect="secondary"
            size="small"
            label={t("report.filterBar.schedule")}
            onClick={() =>
              store.dispatch(
                getReportsMetadataAsync("?lang=" + preferencesContext.language)
              )
            }
            list={
              [
                {
                  id: 0,
                  title: t("common.schedulate"),
                  icon: <IconAdd size={14} color={"#687484"} />,
                  onClick: () => {
                    setSchedulationModal(!schedulationModal);
                  },
                },
                {
                  id: 0,
                  title: t("common.schedulationList"),
                  icon: <IconList size={14} color={"#687484"} />,
                  onClick: () => {
                    setManageModalOpened(true);
                  },
                  disabled:
                    filterSchedulesByType("DRIVER_TRAVEL_REPORT").length === 0,
                },
              ] as any
            }
            disabled={false}
          >
            <IconCalendar size={14} color="--global-colors-ink-ink" />
          </DropdownButton>
        </div>
        <DropdownButton
          aspect="secondary"
          size="small"
          disabled={disableButton}
          label={t("common.export")}
          list={list}
        >
          <IconDown size={14} color="--global-colors-ink-ink" />
        </DropdownButton>
      </div>
      {schedulationModal && (
        <SchedulationModal
          reportType="DRIVER_TRAVEL_REPORT"
          onClose={() => {
            setSchedulationModal(false);
          }}
          isOpen={schedulationModal}
          customEntities={drivers.map((driver) => {
            return {
              title: driver.firstName + " " + driver.lastName,
              id: driver.id,
            };
          })}
          customEntitiesParamName="driverId"
          customTextLabel={t("common.selectAllDrivers")}
          schedulationId={pathMatchEditingSchedulation?.params?.schedulationId}
          customIcon={<IconDriver size={14} />}
        />
      )}
      <SchedulationManageModal
        schedulationAddModal={(e) => setSchedulationModal(e)}
        reportType={"DRIVER_TRAVEL_REPORT"}
        isOpen={manageModalOpened}
        onClose={() => {
          setManageModalOpened(false);
        }}
        schedulations={filterSchedulesByType("DRIVER_TRAVEL_REPORT")}
      />
    </div>
  );
};
