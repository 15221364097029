import PropTypes from "prop-types";

export const IllustrationTruck = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 68 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <polygon
        id="truck-path-1"
        points="0.292813286 0.14617673 67.1473556 0.14617673 67.1473556 37.8749029 0.292813286 37.8749029"
      ></polygon>
      <path
        d="M1.95273333,0.36975 C1.02982222,0.922125 0.356244444,2.089875 0.3366,3.159 L0.3366,3.159 C0.318088889,4.10475 1.13144444,4.586625 1.95273333,4.09425 L1.95273333,4.09425 C2.87564444,3.54225 3.54884444,2.373 3.56962222,1.30575 L3.56962222,1.30575 C3.58284444,0.61725 3.15444444,0.174375 2.60364444,0.174375 L2.60364444,0.174375 C2.39813333,0.174375 2.17562222,0.23625 1.95273333,0.36975"
        id="truck-path-3"
      ></path>
      <linearGradient
        x1="76.8789711%"
        y1="13.7632573%"
        x2="18.1432008%"
        y2="92.9182%"
        id="truck-linearGradient-5"
      >
        <stop stopColor="#C1C1C1" offset="0%"></stop>
        <stop stopColor="#DFDDDD" offset="100%"></stop>
      </linearGradient>
      <path
        d="M1.84393333,0.39075 C0.9214,0.9435 0.247822222,2.110875 0.2278,3.179625 L0.2278,3.179625 C0.209666667,4.12575 1.02226667,4.607625 1.84393333,4.11525 L1.84393333,4.11525 C2.76684444,3.56325 3.44004444,2.39475 3.46082222,1.326 L3.46082222,1.326 C3.47404444,0.63825 3.04602222,0.195375 2.49522222,0.195375 L2.49522222,0.195375 C2.28971111,0.195375 2.0672,0.25725 1.84393333,0.39075"
        id="truck-path-6"
      ></path>
      <linearGradient
        x1="76.8813139%"
        y1="13.7637663%"
        x2="18.1468645%"
        y2="92.9170812%"
        id="truck-linearGradient-8"
      >
        <stop stopColor="#C1C1C1" offset="0%"></stop>
        <stop stopColor="#DFDDDD" offset="100%"></stop>
      </linearGradient>
      <polygon
        id="truck-path-9"
        points="0.0896844444 0.277875 49.7614178 0.277875 49.7614178 44.5615125 0.0896844444 44.5615125"
      ></polygon>
      <polygon
        id="truck-path-11"
        points="0.0896844444 0.277875 49.7614178 0.277875 49.7614178 27.1607625 0.0896844444 27.1607625"
      ></polygon>
    </defs>
    <g
      id="Image-and-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="truck-Illustration"
        transform="translate(-699.000000, -314.000000)"
      >
        <g id="truck-Group" transform="translate(698.000000, 314.000000)">
          <g id="truck-Group-205" transform="translate(1.000000, 0.000000)">
            <g id="truck-Group-20" transform="translate(0.755556, 21.847125)">
              <g id="truck-Group-3">
                <mask id="truck-mask-2" fill="white">
                  <use xlinkHref="#truck-path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M66.3973911,9.5564625 C67.3871689,10.0908375 67.39888,10.9859625 66.4234578,11.5458375 L21.3315244,37.4444625 C20.3561022,38.0043375 18.75168,38.0200875 17.76568,37.4797125 L1.03768,28.3053375 C0.0513022222,27.7649625 0.0437466667,26.8664625 1.01992444,26.3088375 L46.1069467,0.5767125 C47.08388,0.0194625 48.69208,0.0007125 49.6818578,0.5347125 L66.3973911,9.5564625 Z"
                  id="Fill-1"
                  fill="#000000"
                  opacity="0.103422619"
                  mask="url(#truck-mask-2)"
                ></path>
              </g>
              <path
                d="M54.4888911,12.2477625 L53.6540022,11.7805125 L48.0666689,14.9803875 C47.9514467,15.4052625 47.8853356,15.8342625 47.8774022,16.2557625 C47.8619133,17.0890125 48.2132467,17.7171375 48.7504467,18.0268875 L50.6238467,19.0802625 C51.1580244,19.3960125 51.8792022,19.3937625 52.60378,18.9591375 C54.2240689,17.9897625 55.4061356,15.9377625 55.4424022,14.0627625 C55.4586467,13.1886375 55.0706689,12.5398875 54.4888911,12.2477625"
                id="Fill-4"
                fill="#212A2D"
              ></path>
              <path
                d="M47.8772511,16.255575 C47.8851844,15.83445 47.9512956,15.405075 48.0668956,14.980575 L46.0170733,16.1547 C46.1583622,16.3602 46.3362956,16.5267 46.5414289,16.6452 L48.2935622,17.629575 C48.0245844,17.288325 47.8666733,16.8192 47.8772511,16.255575"
                id="Fill-6"
                fill="#2E373A"
              ></path>
              <path
                d="M47.8772511,16.255575 C47.8851844,15.83445 47.9512956,15.405075 48.0668956,14.980575 L47.62074,15.235575 C47.58334,15.469575 47.5610511,15.7032 47.5568956,15.93495 C47.5429178,16.683075 47.8254956,17.2647 48.2716511,17.6007 C48.0162733,17.26245 47.8670511,16.803825 47.8772511,16.255575"
                id="Fill-8"
                fill="#151515"
              ></path>
              <polygon
                id="Fill-10"
                fill="#1E1E1E"
                points="28.5860289 29.2095375 28.3612511 29.9381625 26.9634733 28.7580375 24.3779622 27.4234125 25.3307178 26.7247875 26.4240067 26.4450375 27.1398956 26.5072875 27.9985844 27.0675375 28.43114 28.1490375"
              ></polygon>
              <path
                d="M25.1347644,35.0634375 C26.7554311,34.0933125 27.9374978,32.0420625 27.9730089,30.1663125 C27.9900089,29.2925625 27.6016533,28.6441875 27.0198756,28.3524375 L25.3077867,27.3943125 C25.0637422,27.2611875 24.79212,27.1715625 24.5023644,27.1475625 C22.5722978,28.6816875 21.2107867,31.4281875 21.1662089,33.9616875 C21.1658311,33.9935625 21.1669644,34.0258125 21.1669644,34.0580625 C21.2043644,34.0839375 21.2417644,34.1086875 21.2810533,34.1311875 L23.1548311,35.1838125 C23.6890089,35.4995625 24.4101867,35.4973125 25.1347644,35.0634375"
                id="Fill-12"
                fill="#212A2D"
              ></path>
              <path
                d="M27.9728956,30.1664625 C27.9373844,32.0422125 26.7553178,34.0934625 25.1346511,35.0635875 C23.6922956,35.9272125 22.2646733,35.0808375 22.2964067,33.4203375 C22.33154,31.5430875 23.5139844,29.4940875 25.1346511,28.5232125 C26.5766289,27.6595875 28.0050067,28.5070875 27.9728956,30.1664625"
                id="Fill-14"
                fill="#928E8E"
              ></path>
              <path
                d="M27.9728956,30.1664625 C27.9373844,32.0422125 26.7553178,34.0934625 25.1346511,35.0635875 C23.6922956,35.9272125 22.2646733,35.0808375 22.2964067,33.4203375 C22.33154,31.5430875 23.5139844,29.4940875 25.1346511,28.5232125 C26.5766289,27.6595875 28.0050067,28.5070875 27.9728956,30.1664625"
                id="Fill-16"
                fill="#555E61"
              ></path>
              <path
                d="M27.7238644,30.5766 C27.6943978,32.129475 26.7208644,33.827475 25.3865533,34.630725 C24.19882,35.34585 23.0231756,34.64535 23.0492422,33.270225 C23.0787089,31.7166 24.0518644,30.019725 25.3865533,29.2161 C26.5739089,28.501725 27.7503089,29.2026 27.7238644,30.5766"
                id="Fill-18"
                fill="#2E373A"
              ></path>
            </g>
            <g id="truck-Group-23" transform="translate(24.177778, 51.472125)">
              <mask id="truck-mask-4" fill="white">
                <use xlinkHref="#truck-path-3"></use>
              </mask>
              <g id="Clip-22"></g>
              <path
                d="M1.95273333,0.36975 C1.02982222,0.922125 0.356244444,2.089875 0.3366,3.159 L0.3366,3.159 C0.318088889,4.10475 1.13144444,4.586625 1.95273333,4.09425 L1.95273333,4.09425 C2.87564444,3.54225 3.54884444,2.373 3.56962222,1.30575 L3.56962222,1.30575 C3.58284444,0.61725 3.15444444,0.174375 2.60364444,0.174375 L2.60364444,0.174375 C2.39813333,0.174375 2.17562222,0.23625 1.95273333,0.36975"
                id="Fill-21"
                fill="url(#truck-linearGradient-5)"
                mask="url(#truck-mask-4)"
              ></path>
            </g>
            <g id="truck-Group-46" transform="translate(24.555556, 33.847125)">
              <path
                d="M2.27826444,19.517025 C2.26957556,19.964775 1.98737556,20.454525 1.60053111,20.685525 C1.25675333,20.891775 0.915997778,20.690025 0.923553333,20.294025 C0.932242222,19.8459 1.21406444,19.356525 1.60053111,19.12515 C1.94544222,18.919275 2.28582,19.1214 2.27826444,19.517025"
                id="Fill-24"
                fill="#928E8E"
              ></path>
              <path
                d="M2.23584,19.654725 C2.22904,20.001975 2.00992889,20.38185 1.70997333,20.56185 C1.44250667,20.7216 1.17806222,20.565225 1.18410667,20.256975 C1.19052889,19.9101 1.40926222,19.530225 1.70997333,19.350225 C1.97668444,19.190475 2.24188444,19.347225 2.23584,19.654725"
                id="Fill-26"
                fill="#DBDBDA"
              ></path>
              <path
                d="M1.70997333,18.2839875 C1.62232889,18.3274875 1.57888444,18.3488625 1.49161778,18.3919875 C1.38697333,18.4441125 1.30235111,18.5798625 1.30235111,18.6953625 C1.30235111,18.8108625 1.38697333,18.8618625 1.49161778,18.8101125 C1.57888444,18.7666125 1.62232889,18.7452375 1.70997333,18.7021125 C1.81424,18.6503625 1.89924,18.5146125 1.89924,18.3987375 C1.89924,18.2832375 1.81424,18.2318625 1.70997333,18.2839875"
                id="Fill-28"
                fill="#000000"
              ></path>
              <path
                d="M1.70997333,21.0001875 C1.62232889,21.0436875 1.57888444,21.0658125 1.49161778,21.1085625 C1.38697333,21.1610625 1.30197333,21.2964375 1.30197333,21.4115625 C1.30197333,21.5274375 1.38697333,21.5791875 1.49161778,21.5274375 C1.57888444,21.4839375 1.62270667,21.4621875 1.70997333,21.4186875 C1.81461778,21.3669375 1.89924,21.2311875 1.89924,21.1160625 C1.89924,20.9998125 1.81424,20.9484375 1.70997333,21.0001875"
                id="Fill-30"
                fill="#000000"
              ></path>
              <path
                d="M2.52710667,18.9069375 C2.58490667,18.9744375 2.70277333,18.9410625 2.78966222,18.8338125 C2.87692889,18.7269375 2.89959556,18.5855625 2.84141778,18.5180625 C2.79344,18.4621875 2.76888444,18.4336875 2.72052889,18.3778125 C2.66235111,18.3110625 2.54486222,18.3436875 2.45759556,18.4509375 C2.37108444,18.5581875 2.34728444,18.6995625 2.40584,18.7670625 C2.45419556,18.8225625 2.47875111,18.8510625 2.52710667,18.9069375"
                id="Fill-32"
                fill="#000000"
              ></path>
              <path
                d="M0.674937778,20.904225 C0.617515556,20.836725 0.498893333,20.86935 0.411626667,20.976975 C0.325115556,21.08385 0.301693333,21.2256 0.359493333,21.292725 C0.408226667,21.348975 0.432404444,21.37785 0.481515556,21.432975 C0.539693333,21.500475 0.657182222,21.467475 0.743693333,21.35985 C0.83096,21.2526 0.854004444,21.11085 0.796204444,21.0441 C0.747093333,20.988225 0.723671111,20.959725 0.674937778,20.904225"
                id="Fill-34"
                fill="#000000"
              ></path>
              <path
                d="M2.80141111,19.7532375 C2.71263333,19.7359875 2.5959,19.8233625 2.54036667,19.9486125 C2.4939,20.0536125 2.47047778,20.1057375 2.42363333,20.2103625 C2.36847778,20.3359875 2.3953,20.4507375 2.4837,20.4687375 C2.57247778,20.4863625 2.68921111,20.3997375 2.74474444,20.2741125 C2.79158889,20.1691125 2.81463333,20.1166125 2.86147778,20.0119875 C2.91701111,19.8867375 2.88981111,19.7708625 2.80141111,19.7532375"
                id="Fill-36"
                fill="#000000"
              ></path>
              <path
                d="M0.400293333,20.05755 C0.488693333,20.0748 0.605804444,19.987425 0.661715556,19.8618 C0.707804444,19.757175 0.731226667,19.70505 0.777693333,19.600425 C0.833226667,19.475175 0.806782222,19.358925 0.718004444,19.3413 C0.629604444,19.32405 0.512493333,19.4118 0.45696,19.53705 C0.410493333,19.641675 0.387071111,19.6938 0.340604444,19.799175 C0.284693333,19.924425 0.311893333,20.03955 0.400293333,20.05755"
                id="Fill-38"
                fill="#000000"
              ></path>
              <path
                d="M31.6423644,2.0626875 C31.6060978,3.9376875 30.4240311,5.9896875 28.8037422,6.9594375 C27.3610089,7.8230625 25.9337644,6.9770625 25.96512,5.3165625 C26.0010089,3.4393125 27.1830756,1.3899375 28.8037422,0.4194375 C30.2453422,-0.4438125 31.67372,0.4029375 31.6423644,2.0626875"
                id="Fill-40"
                fill="#928E8E"
              ></path>
              <path
                d="M31.6423644,2.0626875 C31.6060978,3.9376875 30.4240311,5.9896875 28.8037422,6.9594375 C27.3610089,7.8230625 25.9337644,6.9770625 25.96512,5.3165625 C26.0010089,3.4393125 27.1830756,1.3899375 28.8037422,0.4194375 C30.2453422,-0.4438125 31.67372,0.4029375 31.6423644,2.0626875"
                id="Fill-42"
                fill="#555E61"
              ></path>
              <path
                d="M31.3929933,2.4728625 C31.3635267,4.0253625 30.3899933,5.7241125 29.0556822,6.5269875 C27.8679489,7.2417375 26.6923044,6.5416125 26.7183711,5.1668625 C26.7478378,3.6128625 27.7209933,1.9159875 29.0556822,1.1123625 C30.24266,0.3979875 31.41906,1.0988625 31.3929933,2.4728625"
                id="Fill-44"
                fill="#2E373A"
              ></path>
            </g>
            <g id="truck-Group-49" transform="translate(51.755556, 35.347125)">
              <mask id="truck-mask-7" fill="white">
                <use xlinkHref="#truck-path-6"></use>
              </mask>
              <g id="Clip-48"></g>
              <path
                d="M1.84393333,0.39075 C0.9214,0.9435 0.247822222,2.110875 0.2278,3.179625 L0.2278,3.179625 C0.209666667,4.12575 1.02226667,4.607625 1.84393333,4.11525 L1.84393333,4.11525 C2.76684444,3.56325 3.44004444,2.39475 3.46082222,1.326 L3.46082222,1.326 C3.47404444,0.63825 3.04602222,0.195375 2.49522222,0.195375 L2.49522222,0.195375 C2.28971111,0.195375 2.0672,0.25725 1.84393333,0.39075"
                id="Fill-47"
                fill="url(#truck-linearGradient-8)"
                mask="url(#truck-mask-7)"
              ></path>
            </g>
            <g id="truck-Group-204" transform="translate(0.000000, 0.097125)">
              <path
                d="M54.3029489,37.163625 C54.2946378,37.610625 54.01206,38.100375 53.6255933,38.33175 C53.28106,38.538 52.9406822,38.335875 52.9482378,37.939875 C52.9569267,37.492125 53.2387489,37.003125 53.6255933,36.771375 C53.9693711,36.565125 54.3105044,36.767625 54.3029489,37.163625"
                id="Fill-50"
                fill="#928E8E"
              ></path>
              <path
                d="M54.2599956,37.3006125 C54.2531956,37.6482375 54.0344622,38.0281125 53.7345067,38.2077375 C53.4674178,38.3678625 53.2025956,38.2111125 53.2090178,37.9036125 C53.2150622,37.5556125 53.4341733,37.1761125 53.7345067,36.9964875 C54.0015956,36.8367375 54.26604,36.9934875 54.2599956,37.3006125"
                id="Fill-52"
                fill="#DBDBDA"
              ></path>
              <path
                d="M53.7340911,35.93025 C53.6472022,35.973375 53.60338,35.995125 53.5161133,36.03825 C53.4114689,36.090375 53.3268467,36.225375 53.3268467,36.34125 C53.3268467,36.45675 53.4114689,36.5085 53.5161133,36.456375 C53.60338,36.412875 53.6472022,36.3915 53.7340911,36.348 C53.8387356,36.296625 53.9237356,36.160125 53.9237356,36.045 C53.9237356,35.929875 53.8387356,35.878125 53.7340911,35.93025"
                id="Fill-54"
                fill="#000000"
              ></path>
              <path
                d="M53.7340911,38.6467875 C53.6472022,38.6899125 53.60338,38.7116625 53.5161133,38.7547875 C53.4114689,38.8072875 53.3264689,38.9419125 53.3264689,39.0581625 C53.3264689,39.1732875 53.4114689,39.2257875 53.5161133,39.1736625 C53.60338,39.1301625 53.64758,39.1080375 53.7344689,39.0649125 C53.8387356,39.0127875 53.9237356,38.8766625 53.9237356,38.7615375 C53.9237356,38.6464125 53.8387356,38.5942875 53.7340911,38.6467875"
                id="Fill-56"
                fill="#000000"
              ></path>
              <path
                d="M54.5516022,36.5527875 C54.6094022,36.6202875 54.7272689,36.5872875 54.8145356,36.4796625 C54.9014244,36.3724125 54.9244689,36.2310375 54.8662911,36.1639125 C54.8179356,36.1080375 54.7937578,36.0795375 54.7454022,36.0244125 C54.6868467,35.9569125 54.5693578,35.9895375 54.4824689,36.0971625 C54.3952022,36.2044125 54.3725356,36.3457875 54.4303356,36.4129125 C54.4790689,36.4687875 54.5032467,36.4969125 54.5516022,36.5527875"
                id="Fill-58"
                fill="#000000"
              ></path>
              <path
                d="M52.6996222,38.5497375 C52.6414444,38.4829875 52.5235778,38.5156125 52.4363111,38.6228625 C52.3498,38.7301125 52.326,38.8714875 52.3845556,38.9386125 C52.4329111,38.9948625 52.4570889,39.0233625 52.5058222,39.0796125 C52.5643778,39.1463625 52.6814889,39.1133625 52.7683778,39.0061125 C52.8556444,38.8984875 52.8786889,38.7574875 52.8208889,38.6899875 C52.7717778,38.6341125 52.7483556,38.6056125 52.6996222,38.5497375"
                id="Fill-60"
                fill="#000000"
              ></path>
              <path
                d="M54.8260956,37.3994625 C54.7373178,37.3818375 54.6205844,37.4692125 54.5650511,37.5948375 C54.5182067,37.6994625 54.4951622,37.7515875 54.4483178,37.8562125 C54.3931622,37.9818375 54.4196067,38.0973375 54.5080067,38.1149625 C54.5967844,38.1322125 54.7138956,38.0455875 54.7694289,37.9203375 C54.8158956,37.8157125 54.8393178,37.7632125 54.8861622,37.6582125 C54.9416956,37.5325875 54.9144956,37.4170875 54.8260956,37.3994625"
                id="Fill-62"
                fill="#000000"
              ></path>
              <path
                d="M52.4244111,37.7034375 C52.5131889,37.7210625 52.6306778,37.6333125 52.6862111,37.5080625 C52.7326778,37.4034375 52.7557222,37.3513125 52.8025667,37.2466875 C52.8581,37.1210625 52.8309,37.0051875 52.7425,36.9875625 C52.6537222,36.9699375 52.5373667,37.0573125 52.4818333,37.1829375 C52.4349889,37.2879375 52.4119444,37.3404375 52.3651,37.4446875 C52.3095667,37.5703125 52.3363889,37.6858125 52.4244111,37.7034375"
                id="Fill-64"
                fill="#000000"
              ></path>
              <path
                d="M57.8560622,30.9373125 L34.0696622,44.5614375 L29.2401511,41.8404375 L29.1245511,45.5011875 L29.1245511,46.4338125 C29.0410622,46.7180625 28.9741956,46.8980625 28.9741956,46.8980625 L26.8136844,48.0725625 C26.5794622,48.1239375 26.3376844,48.2124375 26.0913733,48.3406875 C26.0656844,48.3534375 26.0403733,48.3669375 26.0150622,48.3808125 C25.99164,48.3935625 25.9689733,48.4063125 25.9459289,48.4194375 C25.8907733,48.4516875 25.8344844,48.4846875 25.7793289,48.5210625 C25.6909289,48.5773125 25.6044178,48.6380625 25.5186622,48.6999375 C25.4967511,48.7149375 25.4755956,48.7306875 25.45444,48.7460625 C23.5100178,50.1804375 22.0971289,52.8451875 21.9369511,55.3659375 L22.22784,55.2601875 C22.4314622,52.7240625 23.9395511,50.0458125 25.9334622,48.7558125 C26.3966178,48.4565625 26.86884,48.2983125 27.2987511,48.2983125 C27.7452844,48.2983125 28.1483733,48.4666875 28.4641956,48.7858125 C28.9322622,49.2579375 29.1804622,50.0191875 29.1649733,50.9285625 C29.16044,51.1816875 29.1438178,51.4351875 29.1166178,51.6883125 L29.4248844,51.4160625 C29.4365956,51.2548125 29.4445289,51.0935625 29.4471733,50.9334375 C29.4592622,50.2471875 29.3289289,49.6558125 29.0913067,49.1885625 L29.0743067,48.8176875 L29.7815067,48.4029375 L29.7815067,47.8025625 L54.4092178,33.5304375 L55.2444844,33.9976875 C55.4994844,34.1263125 55.7170844,34.3235625 55.8791511,34.5778125 C55.8746178,34.5706875 55.87084,34.5631875 55.8663067,34.5560625 L55.6014844,32.8479375 L56.7843067,32.1703125 L57.8560622,32.6866875 L57.8560622,31.5564375 L61.0135289,29.7481875 L61.0135289,29.1290625 L57.8560622,30.9373125 Z"
                id="Fill-66"
                fill="#263338"
              ></path>
              <g id="truck-Group-70" transform="translate(15.866667, 0.000000)">
                <mask id="truck-mask-10" fill="white">
                  <use xlinkHref="#truck-path-9"></use>
                </mask>
                <g id="Clip-69"></g>
                <polygon
                  id="Fill-68"
                  fill="#DFE6EA"
                  mask="url(#truck-mask-10)"
                  points="31.9318178 0.2777625 0.0896844444 16.9510125 0.0896844444 34.3513875 18.2029956 44.5615125 49.7614178 26.4861375 49.7614178 9.8331375"
                ></polygon>
              </g>
              <path
                d="M56.7841556,32.17035 L55.6013333,32.847975 L55.8661556,34.5561 C56.0554222,34.842225 56.1732889,35.2041 56.1944444,35.624475 L58.6178889,36.68985 L58.2367111,32.869725 L56.7841556,32.17035 Z"
                id="Fill-71"
                fill="#1E1E1E"
              ></path>
              <polygon
                id="Fill-73"
                fill="#8897A0"
                points="61.8579756 28.6452 64.3690644 30.060075 64.8990867 29.757075 64.8990867 27.937575 63.9780644 27.430575"
              ></polygon>
              <polygon
                id="Fill-75"
                fill="#8897A0"
                points="36.5171711 43.1596125 37.40646 45.1193625 37.40646 45.7891125 38.1521933 45.3619875 38.1521933 45.0042375 37.1907489 42.7741125"
              ></polygon>
              <polygon
                id="Fill-77"
                fill="#8897A0"
                points="50.2597444 39.658275 50.2597444 39.13665 49.6405667 38.781525 30.9613444 49.481025 30.9613444 51.0909 31.4471667 51.383775 50.1263889 40.68465 50.1263889 40.2609 50.0183444 40.19265 50.0183444 39.79665"
              ></polygon>
              <polygon
                id="Fill-79"
                fill="#8897A0"
                points="30.9611556 49.4811375 30.9611556 51.0910125 31.4469778 51.3835125 31.4469778 50.9597625 31.3389333 50.8918875 31.3389333 50.2416375 31.5799556 50.3578875 31.5799556 49.8355125"
              ></polygon>
              <polygon
                id="Fill-81"
                fill="#8897A0"
                points="31.5800311 50.35785 31.3386311 50.2416 31.3386311 50.89185 50.0182311 40.192725 50.0182311 39.796725"
              ></polygon>
              <polygon
                id="Fill-83"
                fill="#FFFFFF"
                points="49.64068 38.7815625 30.96108 49.4810625 31.57988 49.8358125 50.2598578 39.1366875"
              ></polygon>
              <polygon
                id="Fill-85"
                fill="#FFFFFF"
                points="50.1265778 40.26105 31.4469778 50.9598 31.3389333 50.891925 50.0181556 40.192425"
              ></polygon>
              <polygon
                id="Fill-87"
                fill="#8897A0"
                points="61.8579756 28.6452 64.3690644 30.060075 64.3690644 28.241325 63.45862 27.728325"
              ></polygon>
              <polygon
                id="Fill-89"
                fill="#8897A0"
                points="46.57898 37.3965375 47.4682689 39.3574125 47.4682689 40.0260375 48.2140022 39.5989125 48.2140022 39.2426625 47.2525578 37.0114125"
              ></polygon>
              <polygon
                id="Fill-91"
                fill="#8897A0"
                points="46.57898 37.3965375 47.4682689 39.3574125 47.4682689 40.0260375 47.8415133 39.8122875 47.8415133 39.3574125 46.9136911 37.2045375"
              ></polygon>
              <polygon
                id="Fill-93"
                fill="#8897A0"
                points="36.5171711 43.1596125 37.40646 45.1193625 37.40646 45.7891125 37.7793267 45.5753625 37.7793267 45.1193625 36.85226 42.9676125"
              ></polygon>
              <g id="truck-Group-97" transform="translate(15.866667, 0.000000)">
                <mask id="truck-mask-12" fill="white">
                  <use xlinkHref="#truck-path-11"></use>
                </mask>
                <g id="Clip-96"></g>
                <polygon
                  id="Fill-95"
                  fill="#FFFFFF"
                  mask="url(#truck-mask-12)"
                  points="31.9318178 0.2777625 0.0896844444 16.9510125 18.2029956 27.1607625 49.7614178 9.8331375"
                ></polygon>
              </g>
              <polygon
                id="Fill-98"
                fill="#FFFFFF"
                points="64.8992 27.9376875 64.3691778 28.2414375 63.4587333 27.7280625 63.9781778 27.4303125"
              ></polygon>
              <polygon
                id="Fill-100"
                fill="#DFE6EA"
                points="15.9565022 16.9510125 15.9565022 34.3513875 34.0694356 44.5615125 34.0694356 27.1611375"
              ></polygon>
              <path
                d="M25.7792533,48.52095 C26.1260533,48.296325 26.4720978,48.152325 26.8071867,48.0777 L26.8071867,48.076575 L28.9744978,46.89795 C28.9744978,46.89795 29.3983644,45.75195 29.4459644,44.67645 C29.4931867,43.60095 29.2336533,38.502825 28.9038533,36.936075 C28.5740533,35.369325 27.8672311,34.5972 25.7471422,33.26445 C23.6270533,31.9317 16.7734089,27.919575 16.7734089,27.919575 C16.7734089,27.919575 15.2078978,26.80695 13.30352,27.7647 C11.3991422,28.722825 7.82498667,30.820575 7.12080889,31.649325 C7.12080889,31.649325 4.69472,35.43045 3.83338667,39.4962 C3.83338667,39.4962 3.55383111,39.70245 3.61276444,41.83395 C3.67132,43.9662 3.73025333,45.45495 3.73025333,45.45495 C3.73025333,45.45495 3.37740889,45.4407 3.52436444,46.477575 C3.67132,47.513325 3.62712,48.054075 4.33318667,48.754575 C5.03963111,49.45545 8.06903111,51.7332 10.6133644,53.23695 C13.1580756,54.7407 17.6139644,56.755575 18.1436089,56.9157 C18.6728756,57.0762 19.6728533,56.8137 20.51152,56.302575 C21.0464533,55.976325 21.6032978,55.5972 21.9368756,55.365825 C22.1057422,52.7067 23.6682311,49.886325 25.7792533,48.52095"
                id="Fill-102"
                fill="#1B75BB"
              ></path>
              <path
                d="M25.7792533,48.52095 C26.1260533,48.296325 26.4720978,48.152325 26.8071867,48.0777 L26.8071867,48.076575 L28.9744978,46.89795 C28.9744978,46.89795 29.3983644,45.75195 29.4459644,44.67645 C29.4931867,43.60095 29.2336533,38.502825 28.9038533,36.936075 C28.7927867,36.40845 28.6386533,35.971575 28.4025422,35.572575 C27.2533422,36.252075 24.27192,38.006325 23.1166756,38.60445 C22.2579867,39.048825 21.6153867,39.57045 21.0902756,40.4307 C21.1155867,40.5972 21.0959422,40.815825 20.9980978,41.102325 C20.6120089,42.231825 19.10732,47.287575 19.10732,47.287575 C19.10732,47.287575 19.0068311,47.845575 18.0506756,47.94345 C17.9675644,48.4482 17.8772756,49.05345 17.8451644,49.491075 C17.9724756,49.499325 18.08392,49.5042 18.1919644,49.5147 C18.84892,49.5777 19.5039867,48.92595 19.5039867,48.92595 L19.5039867,49.55595 C19.5039867,49.55595 18.84892,50.208075 18.1919644,50.145075 C17.8496978,50.112075 17.4722978,50.1357 16.6884089,49.919325 C15.9683644,49.7202 14.9071867,49.31895 13.2136089,48.48345 C9.67534222,46.737825 6.75134222,44.6562 5.96783111,44.02545 C5.51487556,43.66095 4.81560889,43.037325 4.21569778,42.453825 C3.99545333,42.240075 3.78918667,42.031575 3.61314222,41.843325 C3.61276444,41.8407 3.61276444,41.837325 3.61276444,41.83395 C3.67132,43.9662 3.73025333,45.45495 3.73025333,45.45495 C3.73025333,45.45495 3.37740889,45.4407 3.52436444,46.477575 C3.67132,47.513325 3.62712,48.054075 4.33318667,48.754575 C5.03963111,49.45545 8.06903111,51.7332 10.6133644,53.23695 C13.1580756,54.7407 17.6139644,56.755575 18.1436089,56.9157 C18.6728756,57.0762 19.6728533,56.8137 20.51152,56.302575 C21.0464533,55.976325 21.6032978,55.5972 21.9368756,55.365825 C22.1057422,52.7067 23.6682311,49.886325 25.7792533,48.52095"
                id="Fill-104"
                fill="#DFE6EA"
              ></path>
              <path
                d="M3.6431,45.4909125 L3.6431,45.4909125 C3.67823333,45.4627875 3.70996667,45.4567875 3.72318889,45.4552875 C3.71034444,45.4564125 3.67861111,45.4627875 3.6431,45.4909125"
                id="Fill-106"
                fill="#8897A0"
              ></path>
              <path
                d="M5.86753111,47.6556 C5.88528667,47.667975 5.90493111,47.6811 5.92268667,47.6946 L5.57588667,46.10085 C5.56002,46.041975 5.41382,45.5241 5.18979778,45.338475 C5.02282,45.200475 4.19964222,44.31735 3.80410889,43.89285 L3.67037556,43.749225 C3.70475333,44.804475 3.73006444,45.4551 3.73006444,45.4551 C4.09802,46.200225 5.24306444,47.208975 5.86753111,47.6556"
                id="Fill-108"
                fill="#8897A0"
              ></path>
              <path
                d="M3.72352889,45.4554 C3.72768444,45.45465 3.73032889,45.455025 3.73032889,45.455025 C3.73032889,45.455025 3.72768444,45.455025 3.72352889,45.4554"
                id="Fill-110"
                fill="#8897A0"
              ></path>
              <path
                d="M14.9068844,53.0092125 C14.9068844,53.0092125 17.3443067,54.0794625 18.1425511,54.1225875 C18.9404178,54.1649625 19.8251733,53.5870875 19.8251733,53.5870875 C19.8251733,53.5870875 19.8323511,53.6249625 19.8425511,53.6868375 C19.8603067,53.6755875 19.8735289,53.6677125 19.8773067,53.6650875 L19.92264,53.6354625 L19.8040178,51.7060875 L19.6699067,51.7889625 C19.6631067,51.7930875 18.9967067,52.1988375 18.3000844,52.2400875 C17.8308844,52.2670875 17.1251956,52.0184625 16.56344,51.8208375 C16.2748178,51.7192125 16.0258622,51.6314625 15.8520844,51.5958375 C15.7429067,51.5737125 15.6529956,51.5628375 15.5763067,51.5628375 C15.31564,51.5628375 15.2491511,51.6989625 15.20004,51.7983375 C15.1909733,51.8174625 15.1819067,51.8365875 15.1709511,51.8557125 C15.1040844,51.9682125 14.9491956,52.5164625 14.8286844,52.9660875 C14.8781733,52.9934625 14.9068844,53.0092125 14.9068844,53.0092125"
                id="Fill-112"
                fill="#8897A0"
              ></path>
              <path
                d="M18.14274,54.1225125 C17.3441178,54.0797625 14.9070733,53.0091375 14.9070733,53.0091375 C14.9070733,53.0091375 11.3038289,51.0175125 9.98802889,50.2037625 C8.67222889,49.3900125 6.85247333,48.3598875 5.86760667,47.6556375 C5.24314,47.2086375 4.09809556,46.2002625 3.73014,45.4551273 C3.73014,45.4551273 3.37767333,45.4408875 3.52425111,46.4773875 C3.67158444,47.5135125 3.62738444,48.0538875 4.33345111,48.7547625 C5.03951778,49.4556375 8.06891778,51.7333875 10.6136289,53.2371375 C13.1579622,54.7405125 17.6142289,56.7553875 18.1434956,56.9155125 C18.4846289,57.0197625 19.0210733,56.9462625 19.5839622,56.7448875 C19.7124067,56.2600125 19.9538067,55.3120125 19.9696733,54.9643875 C19.9915844,54.4888875 19.8253622,53.5870125 19.8253622,53.5870125 C19.8253622,53.5870125 18.9406067,54.1648875 18.14274,54.1225125"
                id="Fill-114"
                fill="#404B4D"
              ></path>
              <path
                d="M27.2485067,37.8469875 L22.9852844,40.2409875 C22.9852844,40.2409875 22.2138622,40.5274875 21.78924,41.6194875 C21.3649956,42.7107375 20.5546622,45.7362375 20.3619956,46.9043625 C20.1689511,48.0724875 19.9947956,48.7234875 20.3619956,48.7234875 C20.7280622,48.7234875 22.6569956,46.9043625 23.5833067,45.9278625 C24.5088622,44.9513625 27.57604,41.6764875 27.57604,41.6764875 C27.57604,41.6764875 28.0203067,41.1788625 28.00104,40.5087375 C27.9813956,39.8386125 27.8465289,38.0194875 27.8465289,38.0194875 C27.8465289,38.0194875 27.8661733,37.6748625 27.2485067,37.8469875"
                id="Fill-116"
                fill="#141C1E"
              ></path>
              <path
                d="M20.5545489,39.89595 C20.5545489,39.89595 17.0627489,38.4792 12.9729267,35.970075 C8.88348222,33.462075 7.05239333,31.75845 7.05239333,31.75845 C6.65346,32.403825 4.60930444,35.834325 3.83334889,39.4962 C3.83334889,39.4962 3.82239333,39.504075 3.80577111,39.533325 C4.01203778,39.800325 4.27383778,40.135575 4.60099333,40.54695 C5.60399333,41.8107 7.45623778,43.380825 10.1955044,44.8557 C12.9347711,46.329825 15.4420822,47.689575 17.1976156,47.91945 C18.9531489,48.149325 19.1072822,47.287575 19.1072822,47.287575 C19.1072822,47.287575 20.6119711,42.231825 20.99806,41.102325 C21.3837711,39.97245 20.5545489,39.89595 20.5545489,39.89595"
                id="Fill-118"
                fill="#141C1E"
              ></path>
              <path
                d="M26.7176156,45.805125 L28.3299711,44.785125 L28.3299711,44.320875 C28.3299711,44.320875 28.3428156,44.04975 27.8743711,44.320875 C27.4066822,44.592 26.7263044,45.146625 26.71346,45.4305 C26.7006156,45.714375 26.7176156,45.805125 26.7176156,45.805125"
                id="Fill-120"
                fill="#404B4D"
              ></path>
              <path
                d="M28.32982,44.320875 C28.32982,44.320875 28.3377533,44.13375 28.0695311,44.227875 L28.0695311,44.66925 L26.7099089,45.539625 C26.7053756,45.73725 26.7174644,45.805125 26.7174644,45.805125 L28.32982,44.785125 L28.32982,44.320875 Z"
                id="Fill-122"
                fill="#000000"
              ></path>
              <path
                d="M6.42294,47.6248125 C6.96505111,47.9953125 8.63558444,49.1315625 9.82180667,49.7960625 C10.9740289,50.4421875 13.3476067,51.7591875 14.2338733,52.2504375 C14.2338733,52.2504375 14.5882289,52.3456875 14.7068511,52.1469375 L14.9663844,51.5566875 C14.9663844,51.5566875 15.0986067,51.2521875 14.7518067,51.0703125 C13.8054733,50.5745625 11.1840733,49.1990625 10.07454,48.5739375 C9.00051778,47.9683125 6.97789556,46.6280625 6.16265111,46.0828125 C6.16265111,46.0828125 5.83814,45.8668125 5.91142889,46.2339375 L6.19022889,47.2888125 C6.19022889,47.2888125 6.26842889,47.5183125 6.42294,47.6248125"
                id="Fill-124"
                fill="#525F61"
              ></path>
              <path
                d="M9.86408,49.3072125 C8.84408,48.7345875 7.33863556,47.7543375 6.21210222,47.0032125 L6.23288,47.0827125 C7.76816889,48.1169625 9.05790222,48.9352125 9.89505778,49.4050875 C10.75488,49.8877125 12.3955689,50.7918375 14.6565689,52.0285875 L14.6924578,51.9457125 C12.3502356,50.6793375 10.7257911,49.7917125 9.86408,49.3072125"
                id="Fill-126"
                fill="#141C1E"
              ></path>
              <path
                d="M9.84896889,49.48665 C9.02579111,49.025025 7.76703556,48.2274 6.26952444,47.220525 L6.28048,47.261025 C6.28388,47.2719 6.28992444,47.286525 6.29672444,47.302275 C7.21472444,47.9274 8.75530222,48.95415 9.88032444,49.584525 C11.1451244,50.293275 13.6943689,51.70815 14.5217022,52.166775 C14.56288,52.1559 14.5938578,52.1379 14.6146356,52.1124 C12.3521244,50.8749 10.7091689,49.96965 9.84896889,49.48665"
                id="Fill-128"
                fill="#141C1E"
              ></path>
              <path
                d="M9.87885111,49.1281125 C8.87358444,48.5622375 7.35529556,47.5801125 6.15434,46.7843625 L6.17587333,46.8664875 C7.30996222,47.6239875 8.86640667,48.6391125 9.91020667,49.2259875 C10.7711622,49.7097375 12.3922067,50.5958625 14.7306511,51.8596125 L14.7657844,51.7793625 C12.3854067,50.5054875 10.7439622,49.6144875 9.87885111,49.1281125"
                id="Fill-130"
                fill="#141C1E"
              ></path>
              <path
                d="M9.90907333,48.7696125 C8.90871778,48.2052375 7.31751778,47.1833625 6.03798444,46.3433625 L6.06027333,46.4288625 C7.34094,47.2744875 8.92080667,48.2929875 9.94042889,48.8678625 C10.8134733,49.3591125 12.47154,50.2531125 14.8757178,51.5284875 L14.8802511,51.5194875 C14.8828956,51.5119875 14.8938511,51.4834875 14.8968733,51.4444875 C11.9135622,49.8739875 10.5399622,49.1247375 9.90907333,48.7696125"
                id="Fill-132"
                fill="#141C1E"
              ></path>
              <path
                d="M9.89396222,48.948675 C8.89511778,48.386175 7.36247333,47.399175 6.09578444,46.565175 L6.11845111,46.64805 C7.33111778,47.4528 8.89776222,48.4683 9.92531778,49.046925 C10.7889178,49.53255 12.4280956,50.42205 14.8035622,51.6933 L14.8383178,51.61455 C12.4292289,50.33655 10.7685178,49.44105 9.89396222,48.948675"
                id="Fill-134"
                fill="#141C1E"
              ></path>
              <path
                d="M9.83382,49.6657125 C8.77793111,49.0735875 7.35786444,48.1349625 6.42626444,47.5034625 C6.44213111,47.5195875 6.45875333,47.5353375 6.47650889,47.5477125 C7.27890889,48.0963375 8.78624222,49.1084625 9.86782,49.7148375 C11.1065533,50.4085875 13.7245533,51.8609625 14.2685533,52.1628375 C14.2821533,52.1654625 14.3097311,52.1710875 14.3425978,52.1748375 C13.4026867,51.6535875 11.0370422,50.3407125 9.83382,49.6657125"
                id="Fill-136"
                fill="#141C1E"
              ></path>
              <path
                d="M9.95538889,48.6883125 C10.5817444,49.0408125 11.9406111,49.7818125 14.8808556,51.3305625 C14.8585667,51.2705625 14.8090778,51.2060625 14.7078333,51.1531875 C12.3671222,49.9273125 10.7925444,49.0865625 10.0283,48.6553125 C8.9505,48.0478125 6.92938889,46.7083125 6.10998889,46.1600625 C6.08278889,46.1428125 6.02952222,46.1158125 5.99665556,46.1135625 C5.99438889,46.1255625 5.99212222,46.1540625 6.0027,46.2084375 C7.29507778,47.0570625 8.93463333,48.1126875 9.95538889,48.6883125"
                id="Fill-138"
                fill="#141C1E"
              ></path>
              <path
                d="M16.9490756,49.3566375 C16.2312978,49.1867625 15.1244089,48.7963875 13.2136089,47.8528875 C9.67534222,46.1076375 6.75134222,44.0256375 5.96783111,43.3952625 C5.51487556,43.0311375 4.81560889,42.4071375 4.21569778,41.8236375 L4.21569778,42.4540125 C4.81560889,43.0371375 5.51487556,43.6611375 5.96783111,44.0252625 C6.75134222,44.6563875 9.67534222,46.7380125 13.2136089,48.4836375 C14.9071867,49.3187625 15.9683644,49.7203875 16.6884089,49.9195125 L16.9490756,49.3566375 Z"
                id="Fill-140"
                fill="#525F61"
              ></path>
              <path
                d="M18.1919644,49.51485 C17.89012,49.4856 17.5606978,49.5006 16.9490756,49.3566 L16.6884089,49.919475 C17.4722978,50.135475 17.8496978,50.112225 18.1919644,50.145225 C18.84892,50.208225 19.5039867,49.555725 19.5039867,49.555725 L19.5039867,48.9261 C19.5039867,48.9261 18.84892,49.57785 18.1919644,49.51485"
                id="Fill-142"
                fill="#F6921E"
              ></path>
              <path
                d="M3.60475556,41.204775 C3.604,41.393775 3.60588889,41.602275 3.61268889,41.834025 C3.61268889,41.8374 3.61268889,41.8404 3.61306667,41.8434 C3.78911111,42.03165 3.99537778,42.24015 4.21562222,42.4539 L4.21562222,41.823525 C3.99197778,41.606775 3.78306667,41.39565 3.60475556,41.204775"
                id="Fill-144"
                fill="#F6921E"
              ></path>
              <path
                d="M18.3057889,52.333575 C17.5770556,52.376325 16.3364333,51.79095 15.8332333,51.687825 C15.3304111,51.5847 15.3387222,51.75645 15.2522111,51.903075 C15.1838333,52.017825 14.9926778,52.717575 14.9137222,53.01195 C15.0587889,53.0757 17.3692778,54.081075 18.1425889,54.1227 C18.9404556,54.165075 19.8252111,53.5872 19.8252111,53.5872 L19.7198111,51.868575 C19.7198111,51.868575 19.0341444,52.29045 18.3057889,52.333575"
                id="Fill-146"
                fill="#F0EFF0"
              ></path>
              <path
                d="M3.73017778,45.455025 C4.08717778,46.1784 5.17366667,47.147025 5.80871111,47.612025 L5.48457778,46.124775 C5.48457778,46.124775 5.33686667,45.582525 5.12908889,45.410775 C4.94888889,45.26115 4.06602222,44.311275 3.67502222,43.892025 C3.70713333,44.863275 3.73017778,45.455025 3.73017778,45.455025"
                id="Fill-148"
                fill="#F0EFF0"
              ></path>
              <path
                d="M14.6276311,53.9416125 L12.9502978,53.1199875 C12.9348089,53.1124875 12.9196978,53.1083625 12.9049644,53.1034875 L12.9049644,53.3074875 C12.9049644,53.5261125 13.0640089,53.7897375 13.2581867,53.8932375 L14.8746978,54.7549875 C14.89812,54.7666125 14.9204089,54.7759875 14.94232,54.7827375 L14.9600756,54.5146125 C14.9748089,54.2959875 14.8255867,54.0387375 14.6276311,53.9416125"
                id="Fill-150"
                fill="#141C1E"
              ></path>
              <path
                d="M7.99789556,50.6281125 C7.97258444,50.4102375 7.80031778,50.1364875 7.61482889,50.0187375 L6.11354,49.0666125 L6.14225111,49.2349875 C6.17965111,49.4506125 6.36060667,49.7251125 6.54496222,49.8447375 L8.01791778,50.7991125 L7.99789556,50.6281125 Z"
                id="Fill-152"
                fill="#141C1E"
              ></path>
              <path
                d="M14.8748111,54.75495 L13.2583,53.8932 C13.0641222,53.7897 12.9050778,53.526075 12.9050778,53.30745 L12.9050778,53.10345 C12.7282778,53.041575 12.5907667,53.140575 12.5907667,53.34195 L12.5907667,53.572575 C12.5907667,53.791575 12.7494333,54.054825 12.9436111,54.15795 L14.5612556,55.0197 C14.7550556,55.1232 14.9258111,55.02945 14.9405444,54.8112 L14.9424333,54.7827 C14.9205222,54.77595 14.8982333,54.766575 14.8748111,54.75495"
                id="Fill-154"
                fill="#FFFFFF"
              ></path>
              <path
                d="M8.01799333,50.79915 L6.54503778,49.844775 C6.36030444,49.72515 6.17972667,49.45065 6.14232667,49.235025 L6.11361556,49.06665 L6.01048222,49.0014 C5.82499333,48.884025 5.70372667,48.9639 5.73999333,49.180275 L5.77966,49.409775 C5.81668222,49.625775 5.99801556,49.8999 6.18237111,50.019525 L7.73428222,51.025275 C7.91863778,51.1449 8.04897111,51.06465 8.02403778,50.8479 L8.01799333,50.79915 Z"
                id="Fill-156"
                fill="#FFFFFF"
              ></path>
              <path
                d="M18.8115956,54.273375 C19.0881289,54.142125 19.8251733,53.587125 19.8251733,53.587125 C19.8251733,53.587125 18.9404178,54.165 18.1425511,54.122625 C17.3443067,54.0795 14.9068844,53.00925 14.9068844,53.00925 C14.9068844,53.00925 11.30364,51.01725 9.98784,50.2035 C8.67204,49.390125 6.85228444,48.359625 5.86741778,47.65575 C5.24295111,47.20875 4.09790667,46.200375 3.73032889,45.4548667 C3.73032889,45.4548667 3.61095111,45.450375 3.54030667,45.650625 C3.89390667,46.437375 5.17532889,47.447625 5.79412889,47.911125 C6.76275111,48.63675 8.59875111,49.6455 9.91455111,50.459625 C11.2303511,51.273 14.8332178,53.265 14.8332178,53.265 C14.8332178,53.265 17.27064,54.33525 18.0688844,54.378 C18.3182178,54.391125 18.5890844,54.379125 18.8115956,54.273375"
                id="Fill-158"
                fill="#FFFFFF"
              ></path>
              <path
                d="M11.9466556,53.4798 C11.9481667,53.4693 11.9496778,53.45805 11.9496778,53.445675 L11.9496778,52.183425 C11.9496778,52.037175 11.8461667,51.857925 11.7188556,51.784425 L8.72572222,50.0523 C8.70267778,50.03955 8.68114444,50.03205 8.65998889,50.027175 C8.65847778,50.0388 8.65696667,50.050425 8.65696667,50.06355 L8.65696667,51.2703 C8.65696667,51.416175 8.7601,51.596925 8.8859,51.671925 L11.8828111,53.453925 C11.9051,53.46705 11.9266333,53.47455 11.9466556,53.4798"
                id="Fill-160"
                fill="#000000"
              ></path>
              <path
                d="M11.8828489,53.4538125 L8.88593778,51.6718125 C8.76013778,51.5968125 8.65700444,51.4160625 8.65700444,51.2705625 L8.65700444,50.0634375 C8.65700444,50.0506875 8.65851556,50.0390625 8.66002667,50.0270625 C8.56482667,50.0049375 8.49418222,50.0645625 8.49418222,50.1841875 L8.49418222,51.3909375 C8.49418222,51.5368125 8.59807111,51.7171875 8.72387111,51.7921875 L11.7207822,53.5745625 C11.8360044,53.6435625 11.9312044,53.5996875 11.9466933,53.4796875 C11.9266711,53.4748125 11.9051378,53.4669375 11.8828489,53.4538125"
                id="Fill-162"
                fill="#FFFFFF"
              ></path>
              <path
                d="M19.8252111,53.5870875 C19.8252111,53.5870875 18.9404556,54.1649625 18.1425889,54.1225875 C17.3443444,54.0794625 14.9069222,53.0092125 14.9069222,53.0092125 C14.9069222,53.0092125 11.3036778,51.0172125 9.98787778,50.2038375 C8.67207778,49.3900875 6.85232222,48.3599625 5.86745556,47.6557125 C5.24298889,47.2087125 4.09794444,46.2003375 3.73036667,45.4548273 C3.73036667,45.4548273 3.37752222,45.4405875 3.52447778,46.4774625 C3.56452222,46.7575875 3.58983333,47.0013375 3.62081111,47.2218375 C4.27398889,47.8300875 5.03256667,48.4015875 5.83345556,48.9498375 C5.8803,48.9412125 5.94112222,48.9573375 6.01063333,49.0012125 L6.11376667,49.0664625 L7.61467778,50.0185875 C7.69552222,50.0699625 7.77334444,50.1520875 7.8387,50.2458375 C8.05818889,50.3823375 8.2773,50.5184625 8.49414444,50.6538375 L8.49414444,50.1843375 C8.49414444,50.0643375 8.56478889,50.0047125 8.65998889,50.0272125 C8.68114444,50.0320875 8.70267778,50.0395875 8.72572222,50.0523375 L11.7188556,51.7840875 C11.8461667,51.8579625 11.9496778,52.0372125 11.9496778,52.1834625 L11.9496778,52.8134625 C12.1744556,52.9409625 12.4007444,53.0665875 12.6274111,53.1892125 C12.6795444,53.0965875 12.7819222,53.0609625 12.9050778,53.1033375 C12.9198111,53.1085875 12.9345444,53.1123375 12.9500333,53.1198375 L14.6277444,53.9414625 C14.7762111,54.0145875 14.8978556,54.1777125 14.9424333,54.3468375 C16.1475444,54.9003375 17.2102333,55.3094625 17.8690778,55.4785875 C18.6133,55.6694625 19.3631889,55.4969625 19.9283444,55.2760875 C19.9510111,55.1504625 19.9661222,55.0420875 19.9695222,54.9644625 C19.9914333,54.4885875 19.8252111,53.5870875 19.8252111,53.5870875"
                id="Fill-164"
                fill="#FFFFFF"
              ></path>
              <path
                d="M15.4837133,52.3445625 C15.4402689,52.7116875 15.6280244,53.1174375 15.9362911,53.3113125 C16.21018,53.4841875 16.5275133,53.3210625 16.5660467,52.9955625 C16.6094911,52.6273125 16.4213578,52.2223125 16.1134689,52.0284375 C15.83958,51.8559375 15.5222467,52.0186875 15.4837133,52.3445625"
                id="Fill-166"
                fill="#FFFFFF"
              ></path>
              <path
                d="M4.48796222,45.7875375 C4.45622889,46.1554125 4.59449556,46.5607875 4.82116222,46.7546625 C5.02327333,46.9271625 5.25636222,46.7644125 5.28507333,46.4389125 C5.31680667,46.0710375 5.17854,45.6664125 4.95187333,45.4721625 C4.74976222,45.2992875 4.51629556,45.4620375 4.48796222,45.7875375"
                id="Fill-168"
                fill="#FFFFFF"
              ></path>
              <path
                d="M3.80932222,44.889225 C3.78287778,45.195225 3.8981,45.53235 4.08623333,45.693975 C4.25434444,45.837225 4.4489,45.702225 4.47232222,45.4311 C4.49876667,45.124725 4.38392222,44.787975 4.19541111,44.626725 C4.0273,44.483475 3.83274444,44.6181 3.80932222,44.889225"
                id="Fill-170"
                fill="#FFFFFF"
              ></path>
              <path
                d="M16.8278844,52.8565125 C16.7874622,53.1958875 16.96124,53.5705125 17.2460844,53.7497625 C17.4995733,53.9095125 17.7923511,53.7587625 17.8278622,53.4580125 C17.8682844,53.1175125 17.6941289,52.7440125 17.41004,52.5647625 C17.1565511,52.4050125 16.8633956,52.5550125 16.8278844,52.8565125"
                id="Fill-172"
                fill="#FFFFFF"
              ></path>
              <path
                d="M18.2226022,52.97445 C18.1870911,53.272575 18.3400911,53.6007 18.5890467,53.757825 C18.8115578,53.8977 19.0684467,53.7657 19.0994244,53.5017 C19.1349356,53.2032 18.9823133,52.87545 18.7326022,52.718325 C18.5104689,52.578825 18.25358,52.71045 18.2226022,52.97445"
                id="Fill-174"
                fill="#FFFFFF"
              ></path>
              <path
                d="M20.0686889,53.03265 C20.0875778,53.040525 20.1091111,53.051025 20.1174222,53.05215 C20.1310222,53.0559 20.1385778,53.056275 20.1438667,53.055525 C20.1525556,53.05215 20.1536889,53.04315 20.1589778,53.0379 L20.1846667,52.99965 L20.2364222,52.9239 L20.3418222,52.773525 L20.5552667,52.474275 C20.6980667,52.274775 20.8412444,52.076025 20.9874444,51.879525 C21.2768222,51.483525 21.5733778,51.09315 21.879,50.70915 C22.4876,49.939275 23.1366222,49.1979 23.8396667,48.509025 C24.5408222,47.81865 25.2835333,47.16615 26.0870667,46.592775 C26.8879556,46.020525 27.7542,45.521025 28.6899556,45.2094 C27.7810222,45.588525 26.9540667,46.123275 26.1773556,46.713525 C25.4017778,47.307525 24.6760667,47.9634 23.9847333,48.65265 C23.2956667,49.344525 22.6281333,50.0589 21.9821333,50.793525 C21.6602667,51.16215 21.3448222,51.5364 21.0384444,51.91815 C20.8869556,52.11015 20.7362222,52.3029 20.5892667,52.499025 L20.3728,52.794525 L20.2655111,52.94415 L20.213,53.018775 L20.1865556,53.05665 C20.1831556,53.0634 20.1759778,53.0679 20.1759778,53.0769 C20.1767333,53.08215 20.1801333,53.0889 20.1892,53.0994 C20.1929778,53.106525 20.2107333,53.12265 20.2254667,53.137275 L20.0686889,53.03265 Z"
                id="Fill-176"
                fill="#000000"
              ></path>
              <path
                d="M19.6056844,48.813525 C19.70164,48.18165 19.8179956,47.553525 19.9324622,46.92465 C20.05184,46.2969 20.1806622,45.670275 20.3147733,45.04515 C20.4477511,44.420025 20.5954622,43.79715 20.7560178,43.177275 C20.9173289,42.55665 21.0941289,41.940525 21.3155067,41.333025 C21.4277067,41.013525 21.6237733,40.733025 21.8375956,40.48515 C22.0533067,40.23615 22.2939511,40.0134 22.5447956,39.8064 C22.7960178,39.599775 23.0600844,39.410775 23.3305733,39.232275 C23.6021956,39.05565 23.8783511,38.8839 24.1699956,38.740275 L25.8839733,37.859775 C26.4589511,37.5729 27.0309067,37.2804 27.6092844,37.000275 L27.6240178,37.0269 C27.0686844,37.351275 26.5080622,37.663275 25.9497067,37.9809 L24.26444,38.914275 C23.7094844,39.22065 23.1790844,39.580275 22.6943956,39.98415 C22.4514844,40.186275 22.2214178,40.402275 22.0185511,40.638525 C21.8168178,40.8759 21.6422844,41.1309 21.5391511,41.41515 C21.3177733,42.007275 21.1439956,42.62115 20.9724844,43.2339 C20.80324,43.848525 20.6483511,44.4669 20.4930844,45.086025 C20.3389511,45.70515 20.1953956,46.3269 20.0525956,46.949025 C19.9048844,47.570775 19.7681289,48.194775 19.62684,48.81765 L19.6056844,48.813525 Z"
                id="Fill-178"
                fill="#000000"
              ></path>
              <path
                d="M19.9456467,55.170975 C19.8795356,55.600725 19.6921578,56.3361 19.5837356,56.74485 L20.5115578,56.302725 C21.0464911,55.9761 21.6033356,55.59735 21.9369133,55.365975 C21.9675133,54.889725 22.0430689,54.408975 22.1575356,53.931225 L19.9456467,55.170975 Z"
                id="Fill-180"
                fill="#141C1E"
              ></path>
              <path
                d="M2.97666222,39.3276 C2.81950667,39.648975 2.55657333,39.8031 2.48404,39.840225 C2.34577333,39.830475 1.64575111,39.7746 0.776484444,39.618225 C0.694128889,39.603225 0.622728889,39.5841 0.558884444,39.560475 L0.558884444,37.054725 C0.558884444,36.909225 0.485217778,36.6951 0.395306667,36.579975 L0.163351111,36.282225 C0.0738177778,36.166725 0.000151111111,36.192225 0.000151111111,36.337725 L0.000151111111,39.219975 C0.000151111111,39.36585 0.0915733333,39.56235 0.203773333,39.65685 L0.354884444,39.7836 C0.44744,39.8616 0.52564,39.8451 0.550195556,39.754725 C0.609506667,39.7731 0.672973333,39.789225 0.742862222,39.802725 C1.72017333,39.9786 2.48895111,40.028475 2.49650667,40.02885 L2.51955111,40.029975 L2.54070667,40.02135 C2.55657333,40.0146 2.93246222,39.846975 3.14628444,39.408975 C3.28606222,39.12285 3.58186222,39.045225 4.10470667,39.15885 L4.14475111,38.976225 C3.75752889,38.892225 3.21541778,38.83785 2.97666222,39.3276"
                id="Fill-182"
                fill="#263338"
              ></path>
              <path
                d="M27.5674267,38.0799 C27.56176,38.079525 27.55496,38.07915 27.5477822,38.07915 C27.5118933,38.07915 27.4503156,38.084775 27.3566267,38.1084 L23.1243822,40.484775 L23.1054933,40.49565 L23.0843378,40.503525 C23.0597822,40.5129 22.4213378,40.772775 22.0530044,41.7204 C21.6427378,42.775275 20.8376933,45.756525 20.6404933,46.9494 C20.6129156,47.1159 20.5860933,47.272275 20.5607822,47.417025 C20.4958044,47.790525 20.4240267,48.20415 20.4281822,48.4029 C20.9056933,48.1344 22.4043378,46.761525 23.3774933,45.735525 C24.2784933,44.785275 27.2470711,41.616525 27.3660711,41.4894 C27.3894933,41.46165 27.7336489,41.045775 27.71816,40.51665 C27.70116,39.91515 27.5874489,38.350275 27.5674267,38.0799"
                id="Fill-184"
                fill="#263338"
              ></path>
              <path
                d="M20.7589644,40.3218375 C20.6796311,40.1958375 20.5300311,40.1755875 20.5236089,40.1748375 L20.4767644,40.1718375 L20.4476756,40.1557125 C20.4121644,40.1414625 16.88712,38.7007125 12.8248756,36.2088375 C9.57296444,34.2145875 7.75623111,32.7400875 7.11929778,32.1929625 C6.49256444,33.2508375 4.81938667,36.2737125 4.12503111,39.4855875 C4.33923111,39.7604625 4.57345333,40.0585875 4.82278667,40.3732125 C5.48087556,41.2023375 7.07132,42.8545875 10.3300311,44.6088375 L10.4403422,44.6680875 C13.0704311,46.0837125 15.5546978,47.4213375 17.2350533,47.6414625 C17.4201644,47.6650875 17.59432,47.6774625 17.7529867,47.6774625 C18.6660756,47.6774625 18.8145422,47.2840875 18.8300311,47.2345875 L18.83192,47.2135875 L18.8368311,47.2079625 C18.8980311,47.0013375 20.3471867,42.1342125 20.7306311,41.0122125 C20.8352756,40.7047125 20.8454756,40.4598375 20.7589644,40.3218375"
                id="Fill-186"
                fill="#263338"
              ></path>
              <path
                d="M16.2923844,44.116875 C14.09334,42.828 12.4129844,42.85875 11.3593622,42.221625 C10.3053622,41.58525 5.24994,38.79525 5.24994,38.79525 L4.12642889,39.478125 C4.12605111,39.48075 4.12529556,39.483 4.12491778,39.48525 C4.33911778,39.7605 4.57334,40.058625 4.82267333,40.37325 C5.48076222,41.202375 7.07120667,42.854625 10.3299178,44.608875 L10.4402289,44.668125 C13.0706956,46.08375 15.5545844,47.421375 17.23494,47.641125 C17.4200511,47.665125 17.5942067,47.6775 17.7532511,47.6775 C18.66634,47.6775 18.8144289,47.284125 18.8299178,47.23425 L18.8321844,47.213625 L18.8367178,47.208 C18.8510733,47.1585 18.94514,46.8435 19.08454,46.377 C18.6576511,45.879 17.7672289,44.981625 16.2923844,44.116875"
                id="Fill-188"
                fill="#45555B"
              ></path>
              <path
                d="M18.5426556,42.9446625 C18.3737889,43.4505375 17.4066778,43.5874125 16.3836556,43.2502875 C15.3610111,42.9127875 14.6689222,42.2295375 14.8381667,41.7240375 C15.0074111,41.2181625 15.9737667,41.0812875 16.9964111,41.4184125 C18.0194333,41.7551625 18.7115222,42.4387875 18.5426556,42.9446625"
                id="Fill-190"
                fill="#45555B"
              ></path>
              <path
                d="M18.1488222,42.81465 C18.0309556,43.1664 17.2992,43.2429 16.5145556,42.983775 C15.7295333,42.7254 15.1889333,42.230025 15.3068,41.878275 C15.4242889,41.52615 16.1560444,41.4504 16.9406889,41.709525 C17.7257111,41.967525 18.2663111,42.462525 18.1488222,42.81465"
                id="Fill-192"
                fill="#36474C"
              ></path>
              <path
                d="M17.2082311,42.4627125 C17.1685644,42.5815875 16.9207422,42.6078375 16.6551644,42.5204625 C16.3899644,42.4330875 16.2067422,42.2650875 16.2467867,42.1458375 C16.2864533,42.0269625 16.5338978,42.0014625 16.7994756,42.0888375 C17.0654311,42.1758375 17.2482756,42.3438375 17.2082311,42.4627125"
                id="Fill-194"
                fill="#45555B"
              ></path>
              <path
                d="M7.22832444,32.286075 C6.88379111,32.90145 4.94881333,36.4647 4.82905778,39.05145 L5.24990222,38.79495 L6.12068,38.79495 L10.3619911,34.62195 C8.74888,33.52695 7.72925778,32.709825 7.22832444,32.286075"
                id="Fill-196"
                fill="#C4D0D3"
              ></path>
              <path
                d="M16.2923844,44.116875 C17.7672289,44.981625 18.6576511,45.879 19.08454,46.377 C19.1385622,46.197375 19.1982511,45.996375 19.2639844,45.7785 C18.82954,45.241875 17.9462956,44.313 16.52094,43.510875 C14.3679844,42.29775 12.4707844,42.16125 12.4707844,42.16125 L6.12071778,38.79525 L5.24994,38.79525 C5.24994,38.79525 10.3053622,41.58525 11.3593622,42.221625 C12.4129844,42.85875 14.09334,42.828 16.2923844,44.116875"
                id="Fill-198"
                fill="#36474C"
              ></path>
              <path
                d="M7.11929778,32.193075 C6.49256444,33.250575 4.81938667,36.273825 4.12503111,39.485325 C4.33923111,39.760575 4.57345333,40.0587 4.82278667,40.37295 C5.10385333,40.72695 5.55454222,41.230575 6.23152,41.8272 C7.79854222,39.2772 10.5551867,37.24545 12.52152,36.021825 C9.45963111,34.122825 7.73583111,32.72295 7.11929778,32.193075"
                id="Fill-200"
                fill="#FFFFFF"
              ></path>
              <path
                d="M17.3182022,46.326975 L17.4398467,49.199475 C17.4398467,49.199475 17.9124467,49.7136 18.8561356,49.199475 L18.8561356,46.478475 C18.8561356,46.478475 18.33858,46.614225 17.3182022,46.326975"
                id="Fill-202"
                fill="#263338"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationTruck.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationTruck.defaultProps = {
  size: 70,
};

export { IllustrationTruck as default } from "./Truck";
