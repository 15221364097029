import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { IconAlert } from "../../../ui/Icon/Line/Alert";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconLocationHistory } from "../../../ui/Icon/Line/LocationHistory";
import { IconSuccess } from "../../../ui/Icon/Solid/Success";
import { Pending } from "../../../ui/Indicators/Pending";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../../ui/Tags/Tag";
import { approx, getReportRouteNameTranslation } from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { FleetView } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { ReportArchive } from "./reportArchiveSlice";
import "./ReportsArchiveView.css";

export class ReportArchiveTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      driver?: Driver;
      fleetView?: FleetView;
      reportArchive?: ReportArchive;
    },
    navigate: NavigateFunction,
    preferencesContext: Preferences
  ) => {
    let row: Row = { render: {} as Row };

    const downloadFile = (tmpUrl: string, name: string, isPdf: boolean) => {
      const url = tmpUrl;
      const link = document.createElement("a");
      link.href = url;
      !isPdf && link.setAttribute("download", name);
      isPdf && link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const cronParser = require("cron-parser");

    function getNextSchedule(
      cronExpression: string,
      lastScheduleDate: string | number | Date
    ) {
      const options = {
        currentDate: new Date(lastScheduleDate),
        tz: "Europe/Rome",
      };

      try {
        const interval = cronParser.parseExpression(cronExpression, options);
        const nextDate = interval.next().toString();
        return nextDate;
      } catch (err) {}
    }

    let reportArchive: ReportArchive =
      entities.reportArchive ?? ({} as ReportArchive);

    const cronstrue = require("cronstrue/i18n");

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "reportGenerated":
            row.render.reportGenerated = (
              <div className="table-report-archive-row-element">
                {reportArchive?.lastScheduledReportExecution?.executionTime ? (
                  <>
                    <span
                      data-for="tooltip"
                      data-tip={
                        t(
                          "table.columns.reportStatus." +
                            reportArchive?.lastScheduledReportExecution
                              ?.reportExecutionStatusEnum
                        ) +
                        (reportArchive?.lastScheduledReportExecution
                          ?.progress >= 0
                          ? " " +
                            approx(
                              reportArchive?.lastScheduledReportExecution
                                ?.progress
                            ) +
                            "%"
                          : "")
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <p className="reportMarked">
                          {reportArchive?.lastScheduledReportExecution
                            .reportExecutionStatusEnum === "EXECUTED" ? (
                            <IconSuccess
                              size={16}
                              color="--global-colors-feedback-success"
                            />
                          ) : reportArchive?.lastScheduledReportExecution
                              .reportExecutionStatusEnum === "FAILED" ? (
                            <IconAlert
                              size={16}
                              color="--global-colors-feedback-danger"
                            />
                          ) : (
                            <Pending />
                          )}
                        </p>
                        <p className="reportMarked">
                          {reportArchive?.lastScheduledReportExecution
                            ?.reportExecutionStatusEnum === "RUNNING" &&
                          reportArchive?.lastScheduledReportExecution
                            ?.progress >= 0 ? (
                            <progress
                              style={{ width: "80px" }}
                              value={
                                reportArchive.lastScheduledReportExecution
                                  .progress / 100
                              }
                            />
                          ) : (
                            new Date(
                              reportArchive?.lastScheduledReportExecution?.executionTime
                            ).getDate() +
                            "/" +
                            (new Date(
                              reportArchive?.lastScheduledReportExecution?.executionTime
                            ).getMonth() +
                              1) +
                            "/" +
                            new Date(
                              reportArchive?.lastScheduledReportExecution?.executionTime
                            ).getFullYear()
                          )}
                        </p>
                      </div>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "schedulationName":
            row.render.schedulationName = (
              <div className="table-report-archive-row-element">
                {reportArchive.name ? (
                  <>
                    <p
                      className="reportMarked"
                      style={{
                        overflow: "hidden",
                        maxWidth: "350px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                      }}
                    >
                      {reportArchive?.name}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "sentTo":
            row.render.sentTo = (
              <div className="table-report-archive-row-element">
                {reportArchive?.lastScheduledReportExecution?.user ? (
                  <>
                    <p className="reportMarked">
                      {reportArchive?.lastScheduledReportExecution?.user}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );

            break;
          case "reportType":
            row.render.reportType = (
              <div className="table-report-archive-row-element">
                {reportArchive.type ? (
                  <>
                    <p className="reportMarked">
                      {getReportRouteNameTranslation(reportArchive?.type)}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "schedulationPeriod":
            row.render.schedulationPeriod = (
              <div className="table-report-archive-row-element">
                {reportArchive?.cronExpression ? (
                  <>
                    <p
                      className="reportMarked"
                      style={{ whiteSpace: "normal", maxWidth: " 200px" }}
                    >
                      {cronstrue.toString(reportArchive?.cronExpression, {
                        locale: preferencesContext.language,
                      })}
                    </p>
                  </>
                ) : (
                  <p className="reportMarked">
                    {new Date(
                      reportArchive?.lastScheduledReportExecution?.executionTime
                    ).getDate() +
                      "/" +
                      (new Date(
                        reportArchive?.lastScheduledReportExecution?.executionTime
                      ).getMonth() +
                        1) +
                      "/" +
                      new Date(
                        reportArchive?.lastScheduledReportExecution?.executionTime
                      ).getFullYear()}
                  </p>
                )}
              </div>
            );
            break;
          case "reportSchedulationType":
            row.render.reportSchedulationType = (
              <Tag
                text={
                  reportArchive?.cronExpression
                    ? t("report.archive.periodic")
                    : t("report.archive.oneShot")
                }
                size="small"
                mode={"outline"}
                type={"neutro"}
              />
            );
            break;
          case "nextSchedulationDate":
            const cronExpression = reportArchive?.cronExpression;
            const lastScheduleDate =
              reportArchive?.lastScheduledReportExecution?.executionTime;
            const nextDate = getNextSchedule(
              cronExpression,
              lastScheduleDate
            ) as Date;

            row.render.nextSchedulationDate = (
              <div className="table-report-archive-row-element">
                {cronExpression && nextDate !== undefined ? (
                  <>
                    <p className="reportMarked">
                      {new Date(nextDate)?.getDate() +
                        "/" +
                        (new Date(nextDate)?.getMonth() + 1) +
                        "/" +
                        new Date(nextDate)?.getFullYear()}
                    </p>
                  </>
                ) : (
                  <p className="reportMarked">--:--:----</p>
                )}
              </div>
            );
            break;
          case "download":
            row.render.download = (
              <div
                className="table-report-archive-row-element"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  cursor: reportArchive?.lastScheduledReportExecution?.pdfUrl
                    ? "pointer"
                    : "var(--global-cursor-not-allowed) !important",
                  opacity:
                    reportArchive?.lastScheduledReportExecution?.pdfUrl ||
                    reportArchive?.lastScheduledReportExecution?.excelUrl
                      ? "inherit"
                      : "50%",
                }}
                onClick={() => {
                  reportArchive?.lastScheduledReportExecution?.pdfUrl &&
                  !reportArchive?.lastScheduledReportExecution?.pdfUrl?.includes(
                    "/null"
                  )
                    ? downloadFile(
                        reportArchive?.lastScheduledReportExecution?.pdfUrl,
                        reportArchive?.lastScheduledReportExecution
                          ?.pdfReportName,
                        true
                      )
                    : reportArchive?.lastScheduledReportExecution?.excelUrl &&
                      !reportArchive?.lastScheduledReportExecution?.excelUrl?.includes(
                        "/null"
                      ) &&
                      downloadFile(
                        reportArchive?.lastScheduledReportExecution?.excelUrl,
                        reportArchive?.lastScheduledReportExecution
                          ?.excelReportName,
                        false
                      );
                }}
              >
                <span style={{ marginRight: "40%" }}>
                  <IconDownload size={14} color={"#35465B"} />
                </span>
              </div>
            );
            break;
          case "history":
            row.render.history = (
              <div
                className="table-report-archive-row-element"
                style={{
                  justifyContent: "center",
                  cursor: "pointer",
                  display: "flex",
                  minWidth: "100px",
                  width: "100%",
                }}
                onClick={() =>
                  navigate(`/reports/schedulation/archive/${reportArchive.id}`)
                }
              >
                <span style={{ marginRight: "40%" }}>
                  <IconLocationHistory size={14} color="#35465B" />
                </span>
              </div>
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
