import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import {
  getFilteredNotificationsRuleAggregationsAsync,
  getNotificationRule,
} from "../notification/rule/notificationRuleAggregationSlice";
import {
  addressBookSchema,
  addressBooksSchema,
} from "./addressBookNormalization";
import AddressBookRepository from "./addressBookRepository";

//#region Type
export type AddressBook = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  telephone: string;
  telephonePrefix: string;
  username: string;
  tenant: number;
};
//#endregion Type

//#region API
export const getAddressBooksAsync = createAsyncThunk(
  "addressBooks/getAddressBooks",
  async (data: {}, { rejectWithValue }) => {
    try {
      const addressBooksRepository = new AddressBookRepository();
      const response = await addressBooksRepository.getAddressesBook();
      const addressBooks = _.get(response, Config.ADDRESSBOOK_RESPONSE_PATH);
      const normalizedData = normalize(addressBooks, addressBooksSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(
        error?.response?.data?.message ?? error?.response?.data?.status
      );
    }
  }
);

export const getFilteredAddressBookAsync = createAsyncThunk(
  "addressBooks/getFilteredAddressBook",
  async (data: { queryParams?: string }, { rejectWithValue }) => {
    try {
      const addressBooksRepository = new AddressBookRepository();
      const response = await addressBooksRepository.getAddressesBook(
        data.queryParams
      );
      const addressBook = _.get(response, Config.ADDRESSBOOK_RESPONSE_PATH);
      const normalizedData = normalize(addressBook, addressBooksSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(
        error?.response?.data?.message ?? error?.response?.data?.status
      );
    }
  }
);

export const getAddressBookAsync = createAsyncThunk(
  "addressBook/getAddressBook",
  async (addressBookId: number) => {
    const addressBookRepository = new AddressBookRepository();
    const response = await addressBookRepository.getAddressBook(addressBookId);
    const addressBook = _.get(response, Config.ADDRESSBOOK_RESPONSE_PATH);
    const normalizedData = normalize(addressBook, addressBookSchema);
    return normalizedData.entities;
  }
);

export const createAddressBookAsync = createAsyncThunk(
  "addressBook/createAddressBook",
  async (data: { addressBook: AddressBook }, { rejectWithValue }) => {
    try {
      const addressBookRepository = new AddressBookRepository();
      const response = await addressBookRepository.createAddressBook(
        data.addressBook
      );
      const addressBook = _.get(response, Config.ADDRESSBOOK_RESPONSE_PATH);
      const normalizedData = normalize(addressBook, addressBookSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const editAddressBookAsync = createAsyncThunk(
  "addressBook/editAddressBook",
  async (data: { addressBook: AddressBook }, { rejectWithValue }) => {
    try {
      const addressBookRepository = new AddressBookRepository();
      const response = await addressBookRepository.editAddressBook(
        data.addressBook
      );
      const addressBook = _.get(response, Config.ADDRESSBOOK_RESPONSE_PATH);
      const normalizedData = normalize(addressBook, addressBookSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const deleteAddressBookAsync = createAsyncThunk(
  "addressBook/deleteAddressBook",
  async (addressBookId: number, { rejectWithValue }) => {
    try {
      const addressBookRepository = new AddressBookRepository();
      await addressBookRepository.deleteAddressBook(addressBookId);
      return addressBookId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const validateAddressBookAsync = createAsyncThunk(
  "addressBook/validateAddressBook",
  async (addressBookId: number, { rejectWithValue }) => {
    try {
      const addressBookRepository = new AddressBookRepository();
      const response = await addressBookRepository.validateAddressBook(
        addressBookId
      );
      return addressBookId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

//#endregion API

//#region Custom Functions
function handleFailedData(state: any, action: PayloadAction<any>) {
  let castAction: any = action;
  if (castAction?.payload?.data?.message) {
    state.reasonCode = getErrorCodes(castAction?.payload?.data?.message);
  }
  if (castAction?.payload?.status && castAction?.payload?.status === 500) {
    state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
  }
}

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.addressBook
    ? addressBooksAdapter.setAll(state, action.payload.addressBook)
    : addressBooksAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const addressBooksAdapter = createEntityAdapter<AddressBook>({
  selectId: (addressBook) => addressBook.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const addressBookSlice = createSlice({
  name: "addressBooks",
  initialState: addressBooksAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    addressBooksEmptyState: (state) => {
      addressBooksAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getAddressBooksAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getAddressBooksAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addressBooksAdapter.upsertMany(
            state,
            action.payload.addressBook ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getAddressBooksAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getFilteredNotificationsRuleAggregationsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.addressBook &&
            addressBooksAdapter.upsertMany(state, action.payload?.addressBook);
        }
      )
      .addCase(
        getNotificationRule.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.addressBook &&
            addressBooksAdapter.upsertMany(state, action.payload?.addressBook);
        }
      )
      .addCase(
        getFilteredAddressBookAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getFilteredAddressBookAsync.rejected, (state: any) => {
        state.status = "failed";
      })
      .addCase(getFilteredAddressBookAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAddressBookAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getAddressBookAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addressBooksAdapter.upsertMany(
            state,
            action.payload.addressBook ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getAddressBookAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(createAddressBookAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        createAddressBookAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addressBooksAdapter.upsertMany(
            state,
            action.payload.addressBook ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        createAddressBookAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(deleteAddressBookAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteAddressBookAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addressBooksAdapter.upsertMany(
            state,
            action.payload.addressBook ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(
        deleteAddressBookAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(editAddressBookAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        editAddressBookAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addressBooksAdapter.upsertMany(
            state,
            action.payload.addressBook ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PUT;
        }
      )
      .addCase(
        editAddressBookAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
          handleFailedData(state, action);
        }
      );
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const addressBookSelectors = addressBooksAdapter.getSelectors<RootState>(
  (state) => state.addressBook
);
export const selectaddressBookSliceStatus = (state: any) =>
  state.addressBook.status;
export const selectaddressBookSliceReasonCode = (state: any) =>
  state.addressBook.reasonCode;

export const { addressBooksEmptyState } = addressBookSlice.actions;
//#endregion Status

export default addressBookSlice.reducer;
