export class Config {
  //#region VEHICLES
  static VEHICLES_STATUS_VIEWS__RESPONSE_PATH = "data.content";
  static VEHICLES_STATUS_RESPONSE_PATH = "data.content";
  static VEHICLES_STATUS_VIEW_RESPONSE_PATH = "data.content";
  static VEHICLE_STATUS_RESPONSE_PATH = "data";
  static VEHICLE_STATUS_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static VEHICLE_STATUS_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  static VEHICLE_DETAILS_RESPONSE_PATH = "data.content";
  static VEHICLE_DETAILS_VIEWS_RESPONSE_PATH = "data.content";
  static VEHICLE_LAST_TRACKS_RESPONSE_PATH = "data";
  static VEHICLE_LAST_STOPS_RESPONSE_PATH = "data";
  static VEHICLE_TRAVELS_SUMMARY_RESPONSE_PATH = "data.content";
  static VEHICLE_TRAVELS_MACRO_SUMMARY_RESPONSE_PATH = "data";
  static VEHICLE_TRAVEL_SUMMARY_RESPONSE_PATH = "data";
  static VEHICLE_TRAVELS_SUMMARY_PAGING_STATE_RESPONSE_PATH =
    "data.pageable.pagingState";
  static VEHICLE_FUEL_COSTS_RESPONSE_PATH = "data";
  static VEHICLES_FUEL_COSTS_RESPONSE_PATH = "data";
  static VEHICLE_FUELS_RESPONSE_PATH = "data";
  static VEHICLES_FUELS_RESPONSE_PATH = "data";
  static VEHICLES_ADMIN_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static VEHICLES_ADMIN_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  //#endregion VEHICLES

  //#region DRIVERS
  static DRIVERS_DETAILS_RESPONSE_PATH = "data.content";
  static DRIVER_DETAILS_RESPONSE_PATH = "data";
  static DRIVERS_DETAILS_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static DRIVER_STATUS_RESPONSE_PATH = "data";
  static DRIVER_LAST_TRACKS_RESPONSE_PATH = "data";
  static DRIVERS_STATUSES_RESPONSE_PATH = "data";
  static DRIVERS_STATUS_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static DRIVERS_STATUS_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  static DRIVERS_STATUSES_AND_DETAILS_RESPONSE_PATH = "data.content";
  static DRIVER_TRAVELS_RESPONSE_PATH = "data.content";
  static DRIVER_TRAVELS_SUMMARY_PATH = "data";
  static DRIVER_TRAVELS_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static DRIVER_ADMIN_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static DRIVER_TRAVELS_PAGING_STATE_RESPONSE_PATH =
    "data.pageable.pagingState";
  //#endregion DRIVERS

  //#region MESSAGES
  static EXISTENTCHAT_DETAILS_RESPONSE_PATH = "data";
  static SEND_MESSAGE_RESPONSE_PATH = "data";
  static GET_MESSAGES_RESPONSE_PATH = "data.content";
  static GET_MESSAGES_STATUS_PATH = "data";
  static GET_MESSAGES_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static CHATALIASES_DETAILS_RESPONSE_PATH = "data._embedded.chatAliases";
  //#endregion MESSAGES

  //#region GEOFENCES
  static GEOFENCES_REPORTS_RESPONSE_PATH = "data";
  static GEOFENCES_RESPONSE_PATH = "data.content";
  static GEOFENCE_RESPONSE_PATH = "data";
  static GEOFENCE_CATEGORY_RESPONSE_PATH = "data";
  static GEOFENCE_CATEGORIES_RESPONSE_PATH = "data.content";
  static GEOFENCES_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";

  static GEOFENCES_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  //#endregion GEOFENCES

  //#region FLEETS
  static FLEETS_VIEW_RESPONSE_PATH = "data.content";
  static FLEETS_VIEW_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static FLEETS_VIEW_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  static FLEET_VIEWS_RESPONSE_PATH = "data";
  static FLEET_VIEWS_FILTERED_RESPONSE_PATH = "data.content";
  static FLEET_VIEW_RESPONSE_PATH = "data";
  static FLEET_RESPONSE_PATH = "data";
  //#endregion FLEETS

  //#region TENANTS
  static TENANT_RESPONSE_PATH = "data";
  //#endregion TENANTS

  //#region DEVICES
  static DEVICE_RESPONSE_PATH = "data";
  //#endregion DEVICES

  //#region PREFERENCES
  static PREFERENCES_RESPONSE_PATH = "data";
  //#endregion PREFERENCES

  //#region USERS
  static USER_INFO_RESPONSE_PATH = "data";
  static USERS_INFO_RESPONSE_PATH = "data.content";
  static USERS_INFO_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static USERS_INFO_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  //#endregion USERS

  //#region CONTRACTS
  static CONTRACT_RESPONSE_PATH = "data";
  //#endregion CONTRACTS

  //#region PRESETS
  static PRESETS_RESPONSE_PATH = "data";
  static PRESET_RESPONSE_PATH = "data";
  //#endregion PRESETS

  //#region ROUTES
  static ROUTES_HISTORY_VIEW_PATH = "data";
  static ROUTES_HISTORY_PATH = "data.content";
  static ROUTES_HISTORY_SUMMARY_PATH = "data";
  static ROUTE_HISTORY_PATH = "data";
  static SINGLE_ROUTE_PATH_RESPONSE = "data";
  //#endregion ROUTES

  //#region DEADLINES
  static DEADLINES_RESPONSE_PATH = "data.content";
  static DEADLINE_RESPONSE_PATH = "data";
  static DEADLINE_POST_RESPONSE_PATH = "data";
  static DEADLINES_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static DEADLINES_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  //#endregion DEADLINES

  //#region REPORTS
  static REPORTS_METADATA_PATH = "data";
  static REPORTS_IO_RESPONSE_PATH = "data";
  static REPORTS_IO_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static REPORTS_IO_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  static REPORTS_IO_SUMMARY_PATH = "data";
  static REPORTS_UTILIZATION_RESPONSE_PATH = "data";
  static REPORTS_UTILIZATION_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static REPORTS_UTILIZATION_TOTAL_ELEMENTS_RESPONSE_PATH =
    "data.totalElements";
  static REPORTS_UTILIZATION_SUMMARY_PATH = "data";
  static REPORTS_GEOFENCE_SUMMARY_PATH = "data";
  static GPS_DATA_REPORTS_RESPONSE_PATH = "data.content";
  static GPS_DATA_RESPONSE_PATH = "data";
  static GPS_DATA_REPORTS_SUMMARY_RESPONSE_PATH = "data";
  static GPS_DATA_TRAVEL_RESPONSE_PATH = "data";
  static REPORTS_GPS_DATA_PAGING_STATE_RESPONSE_PATH =
    "data.pageable.pagingState";
  static SPEED_DATA_RESPONSE_PATH = "data";
  //#endregion REPORTS

  //#region SECURITYPROFILES
  static SECURITYPROFILES_RESPONSE_PATH = "data._embedded.securityprofiles";
  //#endregion SECURITYPROFILES

  //#region PRIVILEGES
  static PRIVILEGES_RESPONSE_PATH = "data";
  static PRIVILEGES_DEFAULT_RESPONSE_PATH = "data._embedded.privilegedefaults";
  //#endregion PRIVILEGES

  //#region EVENTS
  static LOG_EVENTS_RESPONSE_PATH = "data.content";
  static EVENTS_RESPONSE_PATH = "data._embedded.event_types";
  static EVENTSIO_RESPONSE_PATH = "data._embedded.events_io";
  static LOG_EVENTS_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static LOG_EVENTS_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";

  static GPS_DATA_EVENT_RESPONSE_PATH = "data";
  //#endregion EVENTS

  //#region NOTIFICATION
  static NOTIFICATION_EVENTS_RESPONSE_PATH = "data.notificationPage.content";
  static NOTIFICATION_EVENTS_TOTAL_PAGES_RESPONSE_PATH =
    "data.notificationPage.totalPages";
  static NOTIFICATION_EVENTS_TOTAL_ELEMENTS_RESPONSE_PATH =
    "data.notificationPage.totalElements";
  static NOTIFICATION_EVENTS_UNREADNOTIFICATION_RESPONSE_PATH =
    "data.unReadNotifications";
  static NOTIFICATION_CATEGORY_RESPONSE_PATH =
    "data._embedded.notificationCategories";
  static NOTIFICATION_RESPONSE_PATH = "data";
  static NOTIFICATION_UPDATE_RESPONSE_PATH = "data";

  //#endregion NOTIFICATION

  //#region NOTIFICATION-RULE
  static NOTIFICATION_RULE_PATH = "data.content";
  static NOTIFICATION_RULE_PATCH_PATH = "data";
  static NOTIFICATION_RULE_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static NOTIFICATION_RULE_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  static NOTIFICATION_RULE_AGGREGATION_PATH = "data";
  //#endregion NOTIFICATION-RULE

  //#region COMMANDS
  static COMMAND_REQUEST_RESPONSE_PATH = "data";
  //#endregion

  //#region STATUS
  static STATUS_AMOUNT_RESPONSE_PATH = "data";
  //#endregion

  //#region ADDRESS_BOOK
  static ADDRESSBOOK_RESPONSE_PATH = "data";
  //#endregion

  //#region TACHOGRAPH_FILE
  static TACHOGRAPH_FILES_STATUS_SUMMARY_RESPONSE_PATH = "data";
  static TACHOGRAPH_FILES_STATUS_RESPONSE_PATH = "data.content";
  static TACHOGRAPH_FILES_STATUS_PAGING_STATE_RESPONSE_PATH =
    "data.pageable.pagingState";
  static TACHOGRAPH_FILES_RESPONSE_PATH = "data.content";
  static TACHOGRAPH_FILES_PAGING_STATE_RESPONSE_PATH =
    "data.pageable.pagingState";
  static TACHOGRAPH_FILES_REPORT_RESPONSE_PATH = "data.content";
  static TACHOGRAPH_FILES_REPORT_PAGING_STATE_RESPONSE_PATH =
    "data.pageable.pagingState";
  //#endregion

  //#region REFUELLING_REPORT
  static REFUELLING_REPORT_RESPONSE_PATH = "data";
  static REFUELLING_REPORT_PAGINATED_PATH = "data.content";
  static REFUELLING_REPORT_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static REFUELLING_REPORT_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  //#endregion

  //#region PUBLIC_ROUTE_PLANNING
  static PUBLIC_ROUTE_PLANNING_SUMMARY = "data";
  static PUBLIC_ROUTE_PLANNING_VIEW = "data";
  //#endregion

  //#region PUBLIC_ROUTE_SHIFT
  static PUBLIC_ROUTE_SHIFT = "data";
  //#endregion

  //#region PUBLIC_TRANSPORT
  static PUBLIC_ROUTE_VIEW_RESPONSE_PATH = "data.content";
  static PUBLIC_ROUTE_VIEW_TOTAL_ELEMENTS_RESPONSE_PATH = "data.totalElements";
  static PUBLIC_ROUTE_VIEW_TOTAL_PAGES_RESPONSE_PATH = "data.totalPages";
  static PUBLIC_ROUTE_LIST_VIEW = "data";
  static PUBLIC_TRANSPORT_STATUS_RESPONSE_PATH = "data";
  //#endregion

  //#region ARCHIVE_REPORT
  static ARCHIVE_REPORT_RESPONSE_PATH = "data.content";
  static ARCHIVE_REPORT_TOTAL_ELEMENS = "data.totalElements";
  static ARCHIVE_REPORT_TOTAL_PAGES = "data.totalPages";
  //#endregion

  //#region ACTIVITIES
  static ACTIVITIES_RESPONSE_PATH = "data.content";
  static ACTIVITIES_TOTAL_ELEMENTS = "data.totalElements";
  static ACTIVITIES_TOTAL_PAGES = "data.totalPages";
  static ACTIVITY_RESPONSE_PATH = "data";
  //#endregion
}
