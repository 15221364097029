import { t } from "i18next";
import { useState } from "react";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconAttach } from "../../ui/Icon/Line/Attach";
import { IconDownload } from "../../ui/Icon/Line/Download";
import { IconLocatorOff } from "../../ui/Icon/Line/LocatorOff";
import { LabelText } from "../../ui/LabelText/LabelText";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { Tag } from "../../ui/Tags/Tag";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import { Driver, driversSelectors } from "../driver/driversSlice";
import ReportsRepository from "../report/reportRepository";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import { Activity } from "./activitiesSlice";

interface DashboardActivityDetailModalProps {
  open: boolean;
  onClose: (e: any) => any;
  activity: Activity | null;
  preferencesContext: Preferences;
  queryString: string;
  onAttachmentClick: (e: any) => any;
}

export const DashboardActivityDetailModal: React.FC<DashboardActivityDetailModalProps> =
  ({
    open,
    onClose,
    activity,
    preferencesContext,
    queryString,
    onAttachmentClick,
  }) => {
    const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
    const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
    let vehicle: Vehicle = {} as Vehicle;
    let driver: Driver = {} as Driver;
    if (activity && activity.vehicle && activity.driver) {
      vehicle =
        vehiclesSelectors.selectById(store.getState(), activity.vehicle) ??
        ({} as Vehicle);
      driver =
        driversSelectors.selectById(store.getState(), activity.driver) ??
        ({} as Driver);
    }
    const reportsRepository = new ReportsRepository();
    const downloadResult = (pdf: boolean) => {
      const params =
        queryString +
        (pdf ? "&isPdf=true" : "&isPdf=false" + "&name=" + activity?.name);
      pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
      reportsRepository
        .getDriverActivityDownload(params)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          const fileName = data.split("/").pop();
          const attachmentDownloadLink = document.createElement("a");
          attachmentDownloadLink.href = process.env.REACT_APP_BUCKET_URL + data;
          attachmentDownloadLink.download = fileName;
          attachmentDownloadLink.target = "_blank";
          attachmentDownloadLink.style.display = "none";
          document.body.appendChild(attachmentDownloadLink);
          attachmentDownloadLink.click();
          document.body.removeChild(attachmentDownloadLink);
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
        })
        .catch((error) => {
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          if (
            error.response &&
            error.response.data.message ==
              GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
          ) {
            console.log(error?.message || "Report Timeout Exception");
            ToastNotification({
              toastId: "reportTimeoutError",
              status: "default",
              title: t("common.reportTimeoutErrorTitle"),
              description: t("common.reportTimeoutErrorDesc"),
            });
          } else {
            console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          }
        });
    };

    const sendReportEmail = () => {
      reportsRepository
        .getDriverActivityReportEmail(queryString)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          ToastNotification({
            toastId: "requestSendingMailSuccess",
            status: "success",
            description: t(
              "report.toastNotification.requestSendingMailSuccess"
            ),
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error?.message || "Unknown Error");
          else console.log(error.response?.data?.error || "Unknown Error");
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        });
    };

    const exportButtonList = [
      {
        id: 0,
        title: t("report.filterBar.downloadExcel"),
        icon: isExcelGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner" />
        ),
        onClick: () => {
          downloadResult(false);
        },
      },
      {
        id: 1,
        title: t("report.filterBar.downloadPDF"),
        icon: isPdfGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner" />
        ),
        onClick: () => {
          downloadResult(true);
        },
      },
      {
        id: 2,
        title: t("report.filterBar.sendReportEmail"),
        icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          sendReportEmail();
        },
      },
    ];

    return (
      activity && (
        <ModalWrapper open={open} closeAction={onClose}>
          <ModalBody
            title={t("navigation.userMenu.activities")}
            desc={
              t("activity.type." + activity.type) +
              (vehicle.alias ? " - " + vehicle.alias : "") +
              (vehicle.plate ? " - " + vehicle.plate : "")
            }
          >
            <div className="driver-activity-detail-label">
              <LabelText text={t("common.name")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.name}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("table.columns.driver")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={driver.firstName + " " + driver.lastName ?? ""}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("common.vehicle")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={vehicle.alias + " - " + vehicle.plate}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("table.columns.dateTime")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={ConvertTimeZone(
                      activity.date,
                      preferencesContext.timeZone ?? undefined,
                      preferencesContext.localeFormat ?? undefined
                    )}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("activity.position")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.dynamicFields.address}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("table.columns.type")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.name}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            {activity?.dynamicFields?.attached && (
              <div className="driver-activity-detail-attachments">
                <LabelText text={t("table.columns.attachment")} />
                <div
                  className="driver-activity-detail-attachments-label"
                  onClick={onAttachmentClick}
                  style={{ cursor: "default" }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.cursor = "pointer")
                  }
                  onMouseOut={(e) => (e.currentTarget.style.cursor = "default")}
                >
                  {activity.dynamicFields.attached
                    .split(",")
                    .map((attachment) => (
                      <div className="row">
                        <div className="attach">
                          <IconAttach
                            size={14}
                            color="--global-colors-ink-ink"
                          />
                        </div>
                        <div className="tag">
                          <Tag text={attachment} type="neutro2" mode="fill" />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="driver-activity-detail-label">
              <LabelText text={t("activity.quantity")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.dynamicFields.refuelQuantity}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("table.columns.cost")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.dynamicFields.cost}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("activity.payment")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.dynamicFields.selectedPayment}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("common.status")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.name}
                    disabled={true}
                  />
                </Form>
              </div>
            </div>
            <div className="driver-activity-detail-label">
              <LabelText text={t("activity.notes")} />
              <div className="text-field">
                <Form>
                  <TextField
                    id="name"
                    validate="alphabetic|isNotEmpty"
                    placeholder={""}
                    value={activity.note}
                    disabled={true}
                    type="textarea"
                  />
                </Form>
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            secondaryLabel={t("common.sendMessageToDriver")}
            downloadButtonList={exportButtonList}
            // TODO add secondary action for redirect to messages
          />
        </ModalWrapper>
      )
    );
  };
