import HttpClient from "../../utils/HttpClient";

export default class ReportsMetadataRepository extends HttpClient {
  constructor() {
    super(true);
  }
  getReportsMetadata(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    let path = "/tenants/" + tenantId + "/users/" + userId + "/reportMetadata";
    return this.instance.get(path + (queryParams ?? ""));
  }
}
