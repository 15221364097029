import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { RefreshTokenManager } from "./RefreshToken";

export default class HttpClient {
  protected instance: AxiosInstance;
  /*
   * API client utility: useful to handle all API calls.
   * Tokens storage/update/delete is performed directly by the client.
   * should be true to handle automatically token refresh mechanism for every API call.
   * @param automaticallyHandleTokenRefresh
   */
  constructor(
    automaticallyHandleTokenRefresh: boolean,
    midPath: string = "/api/v1"
  ) {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_SERVER_URL + midPath,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (automaticallyHandleTokenRefresh) {
      this.instance.interceptors.request.use(
        (config: AxiosRequestConfig) => {
          const token = localStorage.getItem("jwtToken");
          if (token && config && config.headers) {
            config.headers["Authorization"] = "Bearer " + token;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      this.instance.interceptors.response.use(
        (res) => {
          return res;
        },
        (err) => {
          return this.retryAPI(err);
        }
      );
    }
  }

  protected async retryAPI(err: any) {
    const refreshTokenManager = RefreshTokenManager.getInstance();
    if (refreshTokenManager.isRefreshTokenManagerBusy()) {
      let response: any = await new Promise((resolve) =>
        setTimeout(() => {
          resolve(this.retryAPI(err));
        }, 1500)
      ).then((item) => item);
      return response;
    }
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;
        if (!refreshTokenManager.getNewAccessToken()) {
          refreshTokenManager.setRefreshTokenManagerBusy(true);
          try {
            const instanceWithoutAuth = new HttpClient(false);
            const rs = await instanceWithoutAuth.refreshToken();
            const { accessToken, refreshToken } = rs.data;
            localStorage.setItem("jwtToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            refreshTokenManager.setInstant(new Date());
            refreshTokenManager.setRefreshTokenManagerBusy(false);
          } catch (_error: any) {
            refreshTokenManager.setInstant(undefined);
            console.log("hello");
            refreshTokenManager.setRefreshTokenManagerBusy(false);
            await this.logout().then(() => window.location.reload());
            return Promise.resolve("logout");
          }
        }
        this.instance.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("jwtToken");
        return this.instance(originalConfig);
      }
    }
    if (err.response.status === 403 && err.response.data) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }

  protected refreshToken() {
    return this.instance.post("/auth/refreshtoken", {
      refreshToken: localStorage.getItem("refreshToken"),
    });
  }

  protected logout() {
    const currentToken = localStorage.getItem("refreshToken");
    localStorage.removeItem("firebaseToken");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("customerId");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("tenantEmail");
    localStorage.removeItem("chatToken");
    localStorage.removeItem("fullName");
    localStorage.removeItem("preferencesId");
    localStorage.removeItem("profilePic");
    return this.instance.post("/auth/logout", {
      refreshToken: currentToken,
    });
  }
}
