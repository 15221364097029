import { t } from "i18next";
import { useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconFileReport } from "../../../ui/Icon/Line/FileReport";
import { IconTitle } from "../../../ui/IconTitle/IconTitle";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { getQueryString } from "../../../utils/Utils";
import { ArchiveModalTableBuilder } from "./ArchiveModalTableBuilder";
import {
  getReportArchiveExecutionsAsync,
  ReportArchiveExecutions,
  reportArchiveExecutionsSelectors,
  selectReportArchiveExecutionsStatusSlicePage,
  selectReportArchiveExecutionsStatusSliceStatus,
  selectReportArchiveExecutionsStatusSlicTotalElements,
} from "./reportArchiveExecutionsSlice";
import { ReportsArchiveModalFilterBar } from "./ReportsArchiveModalFilterBar";

interface ReportsArchiveModalProps {
  open: boolean;
  onClose: () => any;
  name: string;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

interface CheckedProps {
  id: number;
  color: string;
  number: number;
  vehicleId: number;
  queryParams: string;
}

export const ReportsArchiveModal: React.FC<ReportsArchiveModalProps> = ({
  open,
  onClose,
  name,
}) => {
  const archiveRoute = useMatch("reports/schedulation/archive/:executionsId");
  const reportArchiveExecutions: ReportArchiveExecutions[] = useAppSelector(
    reportArchiveExecutionsSelectors.selectAll
  ).filter(
    (e) =>
      e.scheduledReportId ===
      parseInt(archiveRoute?.params?.executionsId ?? "0")
  );

  const [resetPage, setResetPage] = useState<boolean>(false);

  const tableBuilderRef = useRef<ArchiveModalTableBuilder>(
    new ArchiveModalTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const tableData: TableData = {} as TableData;

  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);

  const archiveSlicePages = useAppSelector(
    selectReportArchiveExecutionsStatusSlicePage
  );

  const archiveSliceTotalElements = useAppSelector(
    selectReportArchiveExecutionsStatusSlicTotalElements
  );

  const archiveSliceStatus = useAppSelector(
    selectReportArchiveExecutionsStatusSliceStatus
  );

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  tableData.columns = tableBuilder.setColumns([
    { name: "reportGenerated" },
    { name: "resend" },
    { name: "downloadPDF" },
    { name: "downloadEXCEL" },
    { name: "delete" },
  ]);

  reportArchiveExecutions.length > 0 &&
    (tableData.rows =
      reportArchiveExecutions
        ?.reverse()
        .map((reportExecution: ReportArchiveExecutions) => {
          return tableBuilder.rowsBuilder({
            reportExecution: reportExecution,
          });
        }) ?? tableData.rows);

  return (
    <>
      <ModalWrapper open={open} closeAction={onClose}>
        <ModalBody
          title={t("report.archive.scheduleArchive")}
          desc={name}
        ></ModalBody>
        <div
          style={{
            borderBottom: "1px solid var(--global-colors-sky-dark)",
            borderTop: "1px solid var(--global-colors-sky-dark)",
            marginTop: "30px",
          }}
        >
          <ReportsArchiveModalFilterBar
            filter={(params) => {
              if (params !== "") {
                setQueryParamsFromFilterBar(params);
                store.dispatch(
                  getReportArchiveExecutionsAsync({
                    queryParams: params,
                    scheduledReportId: archiveRoute?.params?.executionsId ?? "",
                  })
                );
              }
            }}
          />
        </div>

        {tableData.rows?.length > 0 ? (
          <>
            <Table data={tableData}>
              <Table.Head />
              <Tooltip />
              <Table.Body id="ior-table-body" />
            </Table>
            <PageCounter
              totalElements={archiveSliceTotalElements}
              numOfPages={archiveSlicePages}
              numSelected={checkedList.length}
              resetPage={resetPage}
              onClick={(id, currentPage) => {
                if (id !== currentPage) {
                  const pageAndSize = getQueryString({
                    page: id - 1,
                    size: "10",
                  });
                  const finalQueryParams = queryParamsFromFilterBar
                    ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                    : pageAndSize;
                  // Restore dispatch once Backend Pagination is completed
                  store.dispatch(
                    getReportArchiveExecutionsAsync({
                      queryParams: finalQueryParams,
                      scheduledReportId:
                        archiveRoute?.params?.executionsId ?? "",
                    })
                  );
                }
              }}
            />
          </>
        ) : (
          archiveSliceStatus !== "loading" && (
            <div
              className="file-not-found"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                height: "fit-content",
                paddingTop: "50px",
                paddingBottom: "50px",
                backgroundColor: "var(--global-colors-sky-lighter)",
              }}
            >
              <IconTitle
                icon={<IconFileReport size={48} color="#031832" />}
                title={t("report.archive.reportNotFound")}
                text={""}
              />
            </div>
          )
        )}
      </ModalWrapper>
    </>
  );
};
