import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import { GeofencesDropdown } from "../../../ui/Forms/GeofencesDropdown";
import { TimeDropdown } from "../../../ui/Forms/TimeDropdown";
import { VehiclesDropdown } from "../../../ui/Forms/VehiclesDropdown";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconGeofence } from "../../../ui/Icon/Line/Geofence";
import { IconList } from "../../../ui/Icon/Line/List";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconVehicle } from "../../../ui/Icon/Line/Vehicle";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../../utils/Utils";
import { getAddressBooksAsync } from "../../address/addressBookSlice";
import {
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../../fleet/fleetViewsSlice";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceStatus,
} from "../../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  selectGeofenceCategoriesSliceStatus,
} from "../../geofenceCategory/geofenceCategoriesSlice";
import { schedulesSelectors } from "../../schedules/schedulesSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  getPresetsAsync,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  selectVehiclesSliceStatus,
  vehiclesSelectors,
} from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import { getReportsMetadataAsync } from "../reportsMetadataSlice";
import { SchedulationManageModal } from "../schedulation/SchedulationManageModal";
import { SchedulationModal } from "../schedulation/SchedulationModal";
import { Option } from "./../../../ui/Forms/ChildDropdown";
import "./GeofenceReportFilterBar.css";
import { reportsGeofenceSummaryEmptyState } from "./GeofenceReportsSummarySlice";
import {
  geofencesReportsEmptyState,
  getGeofencesReportsAsync,
  selectGeofencesReportsSliceReasonCode,
  selectGeofencesReportsSliceStatus,
} from "./GeofencesReportsSlice";
interface QueryParams {
  [paramName: string]: any;
}

interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
}
interface GeofenceReportFilterBarProps {
  callback: (buildQueryParam: string) => any;
  presets: any[];
  disableButton: boolean;
}

interface GeofenceRouteType {
  key: "" | "STOP" | "TRACK";
  label: string;
}

const geofenceRouteTypeValues: GeofenceRouteType[] = [
  {
    key: "",
    label: t("report.filterBar.onPass/onlyStops"),
  },
  {
    key: "TRACK",
    label: t("report.filterBar.onPass"),
  },
  {
    key: "STOP",
    label: t("report.filterBar.onlyStops"),
  },
];
let context: string = "reportGeofence";

export const GeofenceReportFilterBar: React.FC<GeofenceReportFilterBarProps> =
  ({ callback, presets, disableButton }) => {
    const navigate = useNavigate();
    const pathMatchAddingSchedulation = useMatch(
      "/reports/geofence-report/addSchedulation"
    );
    const pathMatchEditingSchedulation = useMatch(
      "/reports/geofence-report/editSchedulation/:schedulationId"
    );
    const [manageModalOpened, setManageModalOpened] = useState(false);
    const schedules = useAppSelector(schedulesSelectors.selectAll);
    const filterSchedulesByType = (
      type: string,
      type2: string,
      type3: string
    ) => {
      return schedules.filter(
        (schedule: any) =>
          schedule.type === type ||
          schedule.type === type2 ||
          schedule.type === type3
      );
    };

    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const parentRef = useRef<HTMLHeadingElement>(null);

    const reportsRepository = new ReportsRepository();

    const [initialRouteTypeValue, setInitialRouteTypeValue] = useState(
      geofenceRouteTypeValues[0]
    );
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
    const [isDisabledGenerate, setIsDisabledGenerate] = useState(true);
    const [queryString, setQueryString] = useState("");
    const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);
    const [timeSelected, setTimeSelected] = useState<number>(0);
    const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
    const [geofenceList, setGeofenceList] = useState<Geofence[]>([]);
    const [vehiclesId, setVehiclesId] = useState<number[]>([]);
    const [selectedGeofences, setSelectedGeofences] = useState<Geofence[]>([]);
    const [geofenceId, setGeofenceId] = useState<number[]>([]);
    const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
    const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);

    const [schedulationModal, setSchedulationModal] = useState(
      !!pathMatchAddingSchedulation || !!pathMatchEditingSchedulation
    );

    const vehicles: Vehicle[] = vehiclesSelectors.selectAll(store.getState());
    const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);
    let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
    const geofenceSliceStatus = useAppSelector(selectGeofencesSliceStatus);
    const geofencesReportsSliceStatus = useAppSelector(
      selectGeofencesReportsSliceStatus
    );
    const geofencesReportsSliceReasonCode = useAppSelector(
      selectGeofencesReportsSliceReasonCode
    );
    const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
    const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const isGeofencesReportsIdle = geofencesReportsSliceStatus === "loading";

    let defaultColumns: Preset = {} as Preset;
    let customColumns: TableColumnEntry[] = [];
    const geofencesCategoriesFromRedux = geofenceCategoriesSelectors.selectAll(
      store.getState()
    );
    const geofenceCategorySliceStatus = useAppSelector(
      selectGeofenceCategoriesSliceStatus
    );
    const [geofenceOptions, setGeofenceOptions] = useState<Option[]>([]);

    const areGeofencesLoading =
      geofenceSliceStatus === "loading" ||
      geofenceCategorySliceStatus === "loading";

    /**
     * This is the initial useEffect that is in charge on component mount:
     * - of retrieving all the presets of the current tenant
     * - of retrieving all the fleets of the current tenant
     * - of retrieving all the vehicles of the current tenant
     * - of retrieving all the geofences of the current tenant
     * - initialize the google map script
     * On component unmount will delete the google map script
     */

    useEffect(() => {
      store.dispatch(getPresetsAsync(getQueryString({ context: context })));
      store.dispatch(getFleetViewsAsync());
      store.dispatch(getFilteredVehiclesDetailsAsync(""));
      store.dispatch(
        getGeofencesAsync({
          queryParams: getQueryString({ status: ["ACTIVE", "INACTIVE"] }),
        })
      );
      store.dispatch(getAddressBooksAsync(""));

      return () => {
        store.dispatch(reportsGeofenceSummaryEmptyState());
        store.dispatch(geofencesReportsEmptyState());
      };
    }, []);

    if (!!presets && presets.length > 0) {
      defaultColumns = presets.filter((x) => x.name === "Default")[0];
      if (!!defaultColumns) {
        defaultColumns?.columns.forEach((column, index) =>
          customColumns.push({
            id: index,
            name: t("table.columns." + column),
            value: column,
          })
        );
      }
    }

    let vehiclesGroupByFleet = vehicles.reduce(
      (group: any, vehicle: Vehicle) => {
        const { fleet } = vehicle;
        const fleetName = fleetViewsSelectors.selectById(
          store.getState(),
          fleet
        )?.name;
        if (fleetName) {
          group[fleetName] = group[fleetName] ?? [];
          group[fleetName].push(vehicle);
        }
        return group;
      },
      {}
    );
    let fleetNames = Object.keys(vehiclesGroupByFleet);

    useEffect(() => {
      if (geofencesCategoriesFromRedux.length > 0 && geofences.length > 0) {
        const geofenceOptions: Option[] = geofencesCategoriesFromRedux?.map(
          (category: GeofenceCategory) => {
            let color = category?.color ?? "";
            let geofencesByCategory = geofences.filter(
              (geofence) => geofence.geofenceCategory === category.id
            );
            const option = {
              label: category.name,
              hasCheckbox: true,
              hasDropdown: true,
              hasCount: true,
              color: color,
              geofences: geofencesByCategory,
            } as Option;
            return option;
          }
        );
        setGeofenceOptions(geofenceOptions);
      }
    }, [geofencesCategoriesFromRedux, geofences]);

    //#region Toast notifications
    useEffect(() => {
      if (
        geofencesReportsSliceStatus === "failed" &&
        geofencesReportsSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "reportsGeofenceNetworkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [geofencesReportsSliceStatus, geofencesReportsSliceReasonCode]);

    useEffect(() => {
      let presetNotification: {
        status: string | undefined;
        description: string | undefined;
      } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
      if (
        presetNotification.status !== undefined &&
        presetNotification.description !== undefined
      ) {
        ToastNotification({
          toastId: t(presetNotification.description),
          status: presetNotification.status as TypeOptions,
          description: t(presetNotification.description),
        });
        store.dispatch(restoreState());
      }

      if (presetsSliceStatus === "failed" && presetsSliceReasonCode === "") {
        console.error(t("common.networkError"));
        ToastNotification({
          toastId: "presetsNetworkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [presetsSliceStatus, presetsSliceReasonCode]);
    //#endregion Toast notifications

    /**
     * This useEffect takes the vehicles identifiers retrieved from the URL and based
     * on the downloaded vehicles details builds the vehicle list (vehicleList hook).
     */
    useEffect(() => {
      if (
        vehiclesId &&
        vehiclesId.length > 0 &&
        vehicleSliceStatus === "idle" &&
        vehicles.length > 0 &&
        vehicleList.length === 0
      ) {
        const selectedVehicles: Vehicle[] = [];
        vehiclesId.forEach((x) => {
          const selectedVehicle =
            vehiclesSelectors.selectById(store.getState(), x ?? -1) ??
            ({} as Vehicle);
          selectedVehicle &&
            !_.isEmpty(selectedVehicle) &&
            selectedVehicles.push(selectedVehicle);
        });
        setVehicleList(selectedVehicles);
      }
    }, [vehiclesId, vehicles]);

    /**
     * Handle each filter bar parameter change.
     * Be aware to provide a map where the value of the key is a string.
     * If you want to handle values that are 0 provide them in form of string.
     * @param params
     * @param generateImmediatly
     */
    const handleChanges = (
      params: Map<string, string[] | string>,
      generateImmediatly?: boolean
    ): void => {
      if (!!params) {
        params.forEach((value, key) => {
          if (value != undefined && value != null && value.length > 0) {
            queryParams[key] = value;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
            }
          }
        });
        const stringifiedParams = getQueryString(queryParams);
        setQueryString(stringifiedParams);
        pathMatchAddingSchedulation === null &&
          pathMatchEditingSchedulation === null &&
          navigate(`/reports/geofence-report${stringifiedParams}`);
        pathMatchAddingSchedulation &&
          navigate(
            `/reports/geofence-report/addSchedulation${stringifiedParams}`
          );
        pathMatchEditingSchedulation &&
          navigate(
            `/reports/geofence-report/editSchedulation/${pathMatchEditingSchedulation.params.schedulationId}${stringifiedParams}`
          );

        generateImmediatly && generateResultWithParams(stringifiedParams);
      }
      if (
        queryParams &&
        queryParams["vehicleIds"] &&
        queryParams["endPeriod"] &&
        queryParams["startPeriod"] &&
        queryParams["id"]
      ) {
        setIsDisabledGenerate(false);
      } else {
        setIsDisabledGenerate(true);
      }
    };

    const generateResult = () => {
      if (
        (queryString !== "" && vehicleList.length > 0 && isSelectedPeriod) ||
        initialDateValue
      ) {
        generateResultWithParams(queryString);
      }
    };

    const generateResultWithParams = (queryString: string) => {
      if (queryString !== "") {
        store.dispatch(getGeofencesReportsAsync({ queryParams: queryString }));
        callback(queryString);
      }
    };

    const options = fleetNames?.map((element: string) => ({
      label: element,
      hasCheckbox: true,
      hasDropdown: true,
      hasCount: true,
      vehicles: vehiclesGroupByFleet[element],
    }));

    useEffect(() => {
      if (
        geofenceId &&
        geofenceId.length > 0 &&
        geofences.length > 0 &&
        geofenceSliceStatus === "idle" &&
        selectedGeofences.length === 0 &&
        geofenceOptions !== undefined &&
        geofenceOptions.length > 0
      ) {
        const selectGeofences: Geofence[] = [];
        geofenceId.forEach((x) => {
          const selectGeofence =
            geofencesSelectors.selectById(store.getState(), x ?? -1) ??
            ({} as Geofence);
          selectGeofences && selectGeofences.push(selectGeofence);
        });
        setSelectedGeofences(selectGeofences);
      }
    }, [geofenceId, geofences, geofenceOptions]);

    useEffect(() => {
      schedulationModal && setManageModalOpened(false);
    }, [schedulationModal]);
    useEffect(() => {
      manageModalOpened && setSchedulationModal(false);
    }, [manageModalOpened]);

    //#region " checking parameters in the url "
    const [searchParams] = useSearchParams();
    const location: Location = useLocation();

    useEffect(() => {
      pathMatchEditingSchedulation && setSchedulationModal(true);
    }, [pathMatchEditingSchedulation]);

    useEffect(() => {
      let generateImmediatly = false;
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        generateImmediatly = true;
        const vehiclesId = currentSearchParams.getAll("vehicleIds");
        generateImmediatly =
          generateImmediatly && vehiclesId && vehiclesId.length > 0;
        if (!!vehiclesId) {
          if (vehiclesId?.length > 0) {
            setVehiclesId(vehiclesId.map((el) => parseInt(el)));
            map.set(
              "vehicleIds",
              vehiclesId.map((el) => el)
            );
          }
        }

        const startPeriod = currentSearchParams.get("startPeriod");
        const endPeriod = currentSearchParams.get("endPeriod");
        generateImmediatly = generateImmediatly && !!startPeriod;
        if (startPeriod) {
          map.set("startPeriod", startPeriod);
          setIsSelectedPeriod(true);
          setInitialDateValue((prev) => {
            let prevValue = prev;

            prevValue[0] = new Date(startPeriod);

            return prevValue;
          });
        }
        if (endPeriod) {
          map.set("endPeriod", endPeriod);
          setInitialDateValue((prev) => {
            let prevValue = prev;

            prevValue[1] = new Date(endPeriod);

            return prevValue;
          });
        }

        const geofencesIds = currentSearchParams.getAll("id");
        generateImmediatly =
          generateImmediatly && geofencesIds && geofencesIds.length > 0;
        if (!!geofencesIds) {
          if (geofencesIds?.length > 0) {
            setGeofenceId(geofencesIds.map((el) => parseInt(el)));
            map.set(
              "id",
              geofencesIds.map((el) => el)
            );
          }
        }

        // Retrieving the route state type
        const routeStateType = currentSearchParams?.get("onPassOnlyStop");
        let routeTypeValue: GeofenceRouteType = {
          key: "",
          label: t("report.filterBar.onPass/onlyStops"),
        };
        if (!!routeStateType) {
          routeTypeValue =
            geofenceRouteTypeValues.find(
              (geofenceRouteTypeValue) =>
                geofenceRouteTypeValue.key === routeStateType
            ) ?? routeTypeValue;
        }
        if (routeTypeValue) {
          map.set("onPassOnlyStop", routeTypeValue.key);
          setInitialRouteTypeValue(routeTypeValue);
        }

        const duration = currentSearchParams.get("duration");
        if (duration) {
          map.set("duration", duration);
          setTimeSelected(Number(duration));
        }
      }

      // If there are no query params, default values are set
      if (map.size === 0 && _.isEmpty(queryParams)) {
        map.set("duration", "0");
        setTimeSelected(0);
      }

      if (map.size > 0) {
        handleChanges(map, generateImmediatly);
      }
    }, [location]);
    //#endregion

    const downloadResult = (pdf: boolean) => {
      if (queryString !== "" || isSelectedPeriod) {
        const params = queryString + (pdf ? "&isPdf=true" : "&isPdf=false");
        pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
        reportsRepository
          .getGeofencesReportDownload(params)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const fileName = data.split("/").pop();
            const attachmentDownloadLink = document.createElement("a");
            attachmentDownloadLink.href =
              process.env.REACT_APP_BUCKET_URL + data;
            attachmentDownloadLink.download = fileName;
            attachmentDownloadLink.target = "_blank";
            attachmentDownloadLink.style.display = "none";
            document.body.appendChild(attachmentDownloadLink);
            attachmentDownloadLink.click();
            document.body.removeChild(attachmentDownloadLink);
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          })
          .catch((error) => {
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
            if (
              error.response &&
              error.response.data.message ==
                GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
            ) {
              console.log(error?.message || "Report Timeout Exception");
              ToastNotification({
                toastId: "reportTimeoutError",
                status: "default",
                title: t("common.reportTimeoutErrorTitle"),
                description: t("common.reportTimeoutErrorDesc"),
              });
            } else {
              console.log(error.response?.data?.error || "Unknown Error");
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t("common.networkError"),
              });
            }
          });
      }
    };

    const sendReportEmail = () => {
      if (queryString !== "" || isSelectedPeriod) {
        reportsRepository
          .getGeofencesReportEmail(queryString)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            ToastNotification({
              toastId: "requestSendingMailSuccess",
              status: "success",
              description: t(
                "report.toastNotification.requestSendingMailSuccess"
              ),
            });
          })
          .catch((error) => {
            if (!error.response) console.log(error?.message || "Unknown Error");
            else console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          });
      }
      return null;
    };

    const list = [
      {
        id: 0,
        title: t("report.filterBar.downloadExcel"),
        icon: isExcelGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(false);
        },
      },
      {
        id: 1,
        title: t("report.filterBar.downloadPDF"),
        icon: isPdfGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(true);
        },
      },
      {
        id: 2,
        title: t("report.filterBar.sendReportEmail"),
        icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          sendReportEmail();
        },
      },
    ];

    return (
      <div className="gr-filterbar-row">
        <div className="gr-filterbar-vehicles-dropdown">
          <div ref={parentRef}>
            <VehiclesDropdown
              options={options}
              loading={vehicleSliceStatus === "loading"}
              vehiclesLimit={5}
              getValueSelected={(values: Vehicle[]) => {
                if (!_.isEqual(values, vehicleList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    map.set(
                      "vehicleIds",
                      values.map((x) => x.id)
                    );
                    setVehicleList(values);
                  } else {
                    map.set("vehicleIds", []);
                    setVehicleList([]);
                  }
                  handleChanges(map);
                }
              }}
              valueSelected={vehicleList}
            />
          </div>
        </div>
        <div className="gr-filterbar-datepicker">
          <Form>
            <DatePickerDropdown
              setDate={(val) => {
                const map = new Map();
                const startPeriod =
                  typeof val[0] === "string"
                    ? val[0]
                    : val[0].format("YYYY-MM-DDTHH:mm");
                map.set("startPeriod", startPeriod);
                map.set("endPeriod", val[1]?.format("YYYY-MM-DDTHH:mm") ?? "");
                setIsSelectedPeriod(true);
                handleChanges(map);
              }}
              limitDaysRange={31}
              hasTime={true}
              localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
              language={preferencesContext.language ?? "it"}
              icon={
                <IconCalendar size={12} color="--global-colors-ink-light" />
              }
              initialValue={initialDateValue.length ? initialDateValue : []}
              defaultValue={initialDateValue[1]}
              dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
              clearCallback={() => {
                const map = new Map();
                const startPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
                map.set("startPeriod", startPeriod);
                setIsSelectedPeriod(true);
                handleChanges(map);
              }}
            />
          </Form>
        </div>
        <div className="gr-geolocator-drop">
          <Form>
            <GeofencesDropdown
              options={geofenceOptions}
              loading={areGeofencesLoading}
              getValueSelected={(values: Geofence[]) => {
                if (!_.isEqual(values, geofenceList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    map.set(
                      "id",
                      values.map((x) => x.id)
                    );
                    setGeofenceList(values);
                  } else {
                    map.set("id", []);
                    setGeofenceList([]);
                  }
                  handleChanges(map);
                }
              }}
              valueSelected={selectedGeofences}
            />
          </Form>
        </div>
        <div className="gr-eventtype-drop">
          <Dropdown
            itemAttribute="label"
            size={"small"}
            onChange={(values: GeofenceRouteType[]) => {
              const map = new Map();
              const geofenceRouteTypeValue = geofenceRouteTypeValues.find(
                (geofenceRouteTypeValue) =>
                  geofenceRouteTypeValue.key === values[0].key
              )?.key;
              map.set("onPassOnlyStop", geofenceRouteTypeValue);
              handleChanges(map);
            }}
            value={initialRouteTypeValue}
            options={geofenceRouteTypeValues}
          />
        </div>
        <div className="gr-time-dropdown">
          <TimeDropdown
            setTime={(milliseconds) => {
              const map = new Map();
              map.set("duration", milliseconds);
              handleChanges(map);
              setTimeSelected(milliseconds);
            }}
            defaultTime={timeSelected}
          />
        </div>
        <div
          className="gr-filterbar-btn-generate"
          style={{ marginRight: "15px" }}
        >
          <Button
            aspect="primary"
            size="small"
            disabled={isDisabledGenerate}
            label={t("report.filterBar.buttonGenerate")}
            onClick={() => generateResult()}
            isLoading={isGeofencesReportsIdle}
          />
        </div>
        <div className="gr-filterbar-btn">
          <div className="ior-filterbar-btn-schedule">
            <DropdownButton
              aspect="secondary"
              size="small"
              label={t("report.filterBar.schedule")}
              onClick={() =>
                store.dispatch(
                  getReportsMetadataAsync(
                    "?lang=" + preferencesContext.language
                  )
                )
              }
              disabled={false}
              list={
                [
                  {
                    id: 0,
                    title: t("common.schedulate"),
                    icon: <IconAdd size={14} color={"#687484"} />,
                    onClick: () => {
                      setSchedulationModal(!schedulationModal);
                    },
                  },
                  {
                    id: 0,
                    title: t("common.schedulationList"),
                    icon: <IconList size={14} color={"#687484"} />,
                    onClick: () => {
                      setManageModalOpened(true);
                    },
                    disabled:
                      filterSchedulesByType(
                        "GEOFENCE_ROUTE_AND_STOP_REPORT",
                        "GEOFENCE_ROUTE_REPORT",
                        "GEOFENCE_STOP_REPORT"
                      ).length === 0,
                  },
                ] as any
              }
            >
              <IconCalendar size={14} color="--global-colors-ink-ink" />
            </DropdownButton>
          </div>
          <DropdownButton
            aspect="secondary"
            size="small"
            disabled={disableButton}
            label={t("common.export")}
            list={list}
          >
            <IconDown size={14} color="--global-colors-ink-ink" />
          </DropdownButton>
        </div>
        {schedulationModal && vehicles.length > 0 && !!geofences && (
          <SchedulationModal
            reportType="GEOFENCE_ROUTE_AND_STOP_REPORT"
            onClose={() => {
              setSchedulationModal(false);
            }}
            isOpen={schedulationModal}
            customEntities={geofences.map((geofence) => {
              return {
                title: geofence.name,
                id: geofence.id,
              };
            })}
            customEntitiesParamName="id"
            customEntitiesTwoParamName="vehicleIds"
            customTextLabel={t("report.filterBar.geofencesDropdownLabel")}
            customIcon={<IconGeofence size={14} />}
            customEntitiesTwo={vehicles.map((vehicle) => {
              return {
                title: vehicle.alias,
                id: vehicle.id,
              };
            })}
            customIconTwo={<IconVehicle size={14} />}
            customTextLabelTwo={t("common.selectVehicles")}
            paramsComponents={[
              <div className="gr-eventtype-drop">
                <Dropdown
                  itemAttribute="label"
                  size={"small"}
                  onChange={(values: GeofenceRouteType[]) => {
                    const map = new Map();
                    const geofenceRouteTypeValue = geofenceRouteTypeValues.find(
                      (geofenceRouteTypeValue) =>
                        geofenceRouteTypeValue.key === values[0].key
                    )?.key;
                    map.set("onPassOnlyStop", geofenceRouteTypeValue);
                    handleChanges(map);
                  }}
                  value={initialRouteTypeValue}
                  options={geofenceRouteTypeValues}
                />
              </div>,
              <div className="gr-time-dropdown">
                <TimeDropdown
                  setTime={(milliseconds) => {
                    const map = new Map();
                    map.set("duration", milliseconds);
                    handleChanges(map);
                    setTimeSelected(milliseconds);
                  }}
                  defaultTime={timeSelected}
                />
              </div>,
            ]}
            params={queryParams}
            schedulationId={
              pathMatchEditingSchedulation?.params?.schedulationId
            }
            paramsToSchedulate={["onPassOnlyStop", "duration"]}
          />
        )}
        {schedules.length > 0 && (
          <SchedulationManageModal
            schedulationAddModal={(e) => setSchedulationModal(e)}
            reportType={"GEOFENCE_ROUTE_AND_STOP_REPORT"}
            isOpen={manageModalOpened}
            onClose={() => {
              setManageModalOpened(false);
            }}
            schedulations={filterSchedulesByType(
              "GEOFENCE_ROUTE_AND_STOP_REPORT",
              "GEOFENCE_ROUTE_REPORT",
              "GEOFENCE_STOP_REPORT"
            )}
          />
        )}
      </div>
    );
  };
