import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { IconActivity } from "../../ui/Icon/Line/Activity";
import { IconTitle } from "../../ui/IconTitle/IconTitle";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { driversSelectors } from "../driver/driversSlice";
import { AttachmentsModal } from "../report/refuelling/attachments/AttachmentsModal";
import { Preferences } from "../users/preference/preferencesSlice";
import {
  getPresetsAsync,
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../users/preset/presetsSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  activitiesEmptyState,
  activitiesSelectors,
  Activity,
  deleteActivityAsync,
  getActivitiesPaginationAsync,
  selectActivitiesSlicePage,
  selectActivitiesSliceReasonCode,
  selectActivitiesSliceStatus,
  selectActivitiesSliceTotalElements,
} from "./activitiesSlice";
import "./DashboardActivities.css";
import { DashboardActivitiesFilterBar } from "./DashboardActivitiesFilterBar";
import { DashboardActivitiesTableBuilder } from "./DashboardActivitiesTableBuilder";
import { DashboardActivityDetailModal } from "./DashboardActivityDetailModal";
import DeleteActivityModal from "./DeleteActivityModal";

interface DashboardActivitiesProps {
  permissions: UserPermissions;
}

const context = "driverActivityDashboard";

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

export const DashboardActivities: React.FC<DashboardActivitiesProps> = ({
  permissions,
}) => {
  //#region states
  const [searchParams] = useSearchParams();
  const noQueryParamsFromFilterBar =
    (searchParams.toString().split("&").length === 1 &&
      !!searchParams.get("size")) ||
    searchParams.toString() === "";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openDeleteActivityModal, setOpenDeleteActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] =
    useState<Activity | null>(null);
  //#endregion

  const navigate = useNavigate();
  const tableBuilderRef = useRef<DashboardActivitiesTableBuilder>(
    new DashboardActivitiesTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  let activities: Activity[] = useAppSelector(activitiesSelectors.selectAll);
  const activitySliceStatus = useAppSelector(selectActivitiesSliceStatus);
  const activitySliceReasonCode = useAppSelector(
    selectActivitiesSliceReasonCode
  );
  const activitiesSlicePages = useAppSelector(selectActivitiesSlicePage);
  const activitiesSliceTotalElements = useAppSelector(
    selectActivitiesSliceTotalElements
  );

  //#region use effect

  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [queryParamsChanged]);

  useEffect(() => {
    document.title = t("navigation.userMenu.activities");
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    return () => {
      store.dispatch(activitiesEmptyState());
    };
  }, []);

  useEffect(() => {
    if (activitySliceStatus === "failed" && activitySliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (activitySliceStatus === "idle") {
      if (activitySliceReasonCode === GTFleetSuccessCodes.PATCH) {
        ToastNotification({
          toastId: "activityUpdateSuccess",
          status: "success",
          description: t("pages.dashboardActivities.toast.update"),
        });
        store.dispatch(
          getActivitiesPaginationAsync({
            queryParams: queryParamsFromFilterBar,
          })
        );
      } else if (activitySliceReasonCode === GTFleetSuccessCodes.DELETE) {
        ToastNotification({
          toastId: "notificationsReadSuccess",
          status: "success",
          description: t("pages.dashboardActivities.toast.delete"),
        });
        store.dispatch(
          getActivitiesPaginationAsync({
            queryParams: queryParamsFromFilterBar,
          })
        );
      }
    }
  }, [activitySliceStatus, activitySliceReasonCode]);

  //#endregion

  //#region functions
  const isLoading = activitySliceStatus === "loading";
  const isActivityIdle = activitySliceStatus === "idle";

  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    const unusedColumns = ["latitude", "longitude", "address"];

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? [
            ...preset.columns
              .filter((element) => !unusedColumns.includes(element))
              .map((element) => ({
                name: element,
              })),
            { name: "attachment" },
          ]
        : []
    );

    tableData.rows =
      activitySliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? activities.map((activity: Activity) => {
            let vehicle =
              vehiclesSelectors.selectById(
                store.getState(),
                activity.vehicle
              ) ?? ({} as Vehicle);
            let driver = driversSelectors.selectById(
              store.getState(),
              activity.driver
            );

            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                activity,
                vehicle,
                driver,
                openAttachmentModal: setOpenAttachmentModal,
                openDetailsModal: setOpenDetailsModal,
                openDeleteActivityModal: setOpenDeleteActivityModal,
                selectedActivity: setSelectedActivity,
              },
              preferencesContext,
              navigate
            );
          })
        : tableData.rows;
  }

  function closeDeleteActivityModal(id?: number) {
    if (id) {
      store.dispatch(deleteActivityAsync(id));
    }
    setOpenDeleteActivityModal(false);
    navigate({
      pathname: "/dashboard/activities",
      search: queryParamsFromFilterBar,
    });
  }

  function closeDetailsModal() {
    setOpenDetailsModal(false);
  }

  function redirectToMessages() {
    navigate("/dashboard/messages", {
      state: { driverId: selectedActivity?.driver },
    });
  }

  //#endregion functions

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <DashboardActivitiesFilterBar
            presets={presets ?? []}
            callback={setQueryParamsFromFilterBar}
          />
        </div>
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <>
          <PageContent>
            <div className="driver-activity-table">
              <Table data={tableData}>
                <Table.Head hasTableSpace={true} />
                <Table.Body />
              </Table>
            </div>
            <div className="drivers-activity-pagination">
              <PageCounter
                isActionPerforming={isLoading}
                totalElements={activitiesSliceTotalElements}
                resetPage={resetPage}
                disabled={!isActivityIdle}
                numOfPages={activitiesSlicePages}
                onClick={(id, currentPage) => {
                  if (id !== currentPage) {
                    const pageAndSize = getQueryString({
                      page: id - 1,
                      size: "10",
                    });
                    const finalQueryParams = queryParamsFromFilterBar
                      ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                      : pageAndSize;
                    store.dispatch(
                      getActivitiesPaginationAsync({
                        queryParams: finalQueryParams,
                      })
                    );
                  }
                }}
              />
            </div>
          </PageContent>
        </>
      )}
      {!isLoading && isActivityIdle && activities.length === 0 && (
        <PageContent>
          <div className="col">
            <div className="driver-activity-page-withoutTable">
              <div className="driver-activity-page-withoutTable-item">
                <IconTitle
                  icon={<IconActivity color="currentColor" size={48} />}
                  title={t(
                    "pages.dashboardActivities.withoutTableComponent.title"
                  )}
                  text={t(
                    "pages.dashboardActivities.withoutTableComponent.desc"
                  )}
                />
              </div>
            </div>
          </div>
        </PageContent>
      )}
      {openAttachmentModal && (
        <>
          <div className="modal-refuelling-attachments">
            <AttachmentsModal
              attachments={selectedActivity?.dynamicFields.attached ?? ""}
              open={openAttachmentModal}
              onClose={() => setOpenAttachmentModal(false)}
            />
          </div>
        </>
      )}
      {openDetailsModal && (
        <>
          <div className="driver-activity-attachments">
            <DashboardActivityDetailModal
              open={openDetailsModal}
              onClose={closeDetailsModal}
              activity={selectedActivity}
              preferencesContext={preferencesContext}
              queryString={queryParamsFromFilterBar}
              onAttachmentClick={() => {
                setOpenDetailsModal(false);
                setOpenAttachmentModal(true);
              }}
            />
          </div>
        </>
      )}
      {openDeleteActivityModal && (
        <>
          <DeleteActivityModal
            closeDeleteActivityModal={closeDeleteActivityModal}
            openDeleteActivityModal={openDeleteActivityModal}
            selectedActivity={selectedActivity}
          />
        </>
      )}
    </>
  );
};
