import HttpClient from "../../../utils/HttpClient";

export default class NotificationDefaultTemplateRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getNotificationDefaultTemplate(queryParams?: string) {
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/notifications/templates?type=TEXT" +
        queryParams
    );
  }
}
