import { t } from "i18next";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui/Button/Button";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { IconTrash } from "../../../ui/Icon/Line/Trash";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { getReportRoute } from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { SchedulationDeleteModal } from "./SchedulationDeleteModal";
import "./SchedulationManageModal.css";

interface SchedulationManageModalProps {
  isOpen: boolean;
  onClose: () => any;
  schedulationAddModal: (e: boolean) => any;
  reportType: string;
  schedulations: any[];
}

export const SchedulationManageModal: React.FC<SchedulationManageModalProps> =
  ({ isOpen, onClose, schedulations, reportType, schedulationAddModal }) => {
    const cronstrue = require("cronstrue/i18n");
    const navigate = useNavigate();
    const [preferencesContext]: [Preferences] = useContext(UserContext);
    const [isDeleting, setIsDeleting] = useState(undefined);

    return (
      <>
        {isDeleting === undefined ? (
          <div className="schedulation-modal">
            <ModalWrapper open={isOpen} closeAction={onClose}>
              <ModalBody
                title={t("reportSchedulation.modalEditSchedule.title")}
                desc={t("reportSchedulation.modalEditSchedule.desc")}
                stylePadding={"0px"}
              >
                <div
                  style={{
                    marginBottom: "20px",
                    marginTop: "20px",
                    maxHeight: "450px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {schedulations.map((schedulation) => {
                    return (
                      <div className="schedulation-manage-modal-card">
                        <span className="schedulation-modal-card-circle-icon">
                          <IconCalendar size={14} />
                        </span>
                        <div className="schedulation-manage-modal-card-body">
                          <span className="schedulation-manage-modal-card-body-text-one">
                            {schedulation?.cronExpression &&
                              cronstrue.toString(schedulation?.cronExpression, {
                                locale: preferencesContext.language,
                              })}
                          </span>
                          <span className="schedulation-manage-modal-card-body-text-two">
                            {schedulation?.name}
                          </span>
                        </div>
                        <div className="schedulation-manage-modal-card-buttons">
                          <Button
                            size={"small"}
                            label={<IconEdit size={14} color={"#35465B"} />}
                            aspect="secondary"
                            onClick={() => {
                              navigate(
                                "/reports/" +
                                  getReportRoute(reportType) +
                                  "/editSchedulation/" +
                                  schedulation?.id
                              );
                              onClose();
                            }}
                          />
                          <Button
                            size={"small"}
                            label={<IconTrash size={14} color={"#FFFFF"} />}
                            aspect="danger"
                            onClick={() => {
                              setIsDeleting(schedulation.id);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ModalBody>
              <ModalFooter
                primaryAction={() => schedulationAddModal(true)}
                secondaryAction={onClose}
                primaryLabel={t("reportSchedulation.modalEditSchedule.addNew")}
                secondaryLabel={t("reportSchedulation.modalSchedule.cancel")}
                padding={"20px"}
              />
            </ModalWrapper>
          </div>
        ) : (
          <SchedulationDeleteModal
            reportType={reportType}
            isOpen={isDeleting !== undefined}
            schedulationId={isDeleting ?? 0}
            onClose={() => {
              setIsDeleting(undefined);
            }}
          />
        )}
      </>
    );
  };
