import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { excludePaths, routes } from "../../utils/breadcrumbsRoutes";
import { IconHelp } from "../Icon/Line/Help";
import { IconNotification } from "../Icon/Line/Notification";
import { IconPhone } from "../Icon/Solid/Phone";
import { ThumbProfile } from "../ThumbProfile/ThumbProfile";
import { MenuBalloon } from "./MenuBalloon";
import { NotificationsCounter } from "./NotificationsCounter";
import { NotificationsMenu } from "./NotificationsMenu";
import "./TopBar.css";
import { TopBarBreadcrumb } from "./TopBarBreadcrumb";

export const TopBar = ({
  menuItems,
  userInfo,
  userMenuItems,
  notificationsItems,
  userHelpItems,
  tenant,
}) => {
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const [userNotificationIsOpen, setUserNotificationIsOpen] = useState(false);
  const [userHelpIsOpen, setUserHelpIsOpen] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState(-1);
  const notificationRef = useRef(null);
  const helpRef = useRef(null);
  const profileRef = useRef(null);

  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: excludePaths });

  const handleBellClick = () => {
    setUserNotificationIsOpen((prevState) => !prevState);
  };

  const handleHelpClick = () => {
    setUserHelpIsOpen((prevState) => !prevState);
  };

  const handleThumbProfileClick = () => {
    setUserMenuIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setNotificationNumber(
      notificationsItems?.notificationsList?.reduce(
        (total, category) => total + category.notifications.length ?? 0,
        0
      )
    );
  }, [notificationsItems]);

  //#region UseEffect for click outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideNotif);
    document.addEventListener("mousedown", handleClickOutsideHelp);
    document.addEventListener("mousedown", handleClickOutsideProfile);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNotif);
      document.removeEventListener("mousedown", handleClickOutsideHelp);
      document.removeEventListener("mousedown", handleClickOutsideProfile);
    };
  }, []);

  function handleClickOutsideNotif(event) {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setUserNotificationIsOpen(false);
    }
  }
  function handleClickOutsideHelp(event) {
    if (helpRef.current && !helpRef.current.contains(event.target)) {
      setUserHelpIsOpen(false);
    }
  }

  function handleClickOutsideProfile(event) {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setUserMenuIsOpen(false);
    }
  }
  //#endregion

  return (
    <div className="mn-top-bar">
      <TopBarBreadcrumb breadcrumbs={breadcrumbs} />
      <div className="mn-top-bar__navigation">
        <nav>
          <ul>
            {menuItems
              ?.filter((menuItem) => menuItem != null)
              .map((item, currentIndex) => {
                return (
                  <li
                    key={currentIndex}
                    onClick={() => {
                      item.onClick();
                    }}
                    className={
                      item.isActive
                        ? "mn-top-bar__menu-item mn-top-bar__menu-item--active"
                        : "mn-top-bar__menu-item"
                    }
                  >
                    <span>{item.label}</span>
                  </li>
                );
              })}
          </ul>
        </nav>
        <div className="mn-top-bar__splitter" />
        {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
          process.env.REACT_APP_DEPLOY_TARGET === "stage" ||
          process.env.REACT_APP_DEPLOY_TARGET === "pre-prod" ||
          process.env.REACT_APP_DEPLOY_TARGET === "prod") && (
          <div
            className="user-notification"
            onClick={handleBellClick}
            ref={notificationRef}
          >
            {notificationNumber > 0 ? (
              <div className="counter">
                <NotificationsCounter
                  notificationsCount={
                    notificationNumber > 999 ? 999 + "+" : notificationNumber
                  }
                />
              </div>
            ) : null}
            <div className="icon-notification">
              <IconNotification size={14} color="--global-colors-ink-light" />
            </div>
            <NotificationsMenu
              open={userNotificationIsOpen}
              notifications={notificationsItems}
            />
          </div>
        )}
        <div className="user-help" onClick={handleHelpClick} ref={helpRef}>
          <IconHelp size={14} color="--global-colors-ink-light" />
          <MenuBalloon open={userHelpIsOpen}>
            <MenuBalloon.Header userHelp={userHelpItems} />
            <div>
              <p>{t("navigation.topBar.call")}</p>
              <ul>
                <li>
                  <a href="tel:+390802464245">
                    <IconPhone size={14} color="currentColor" /> +39 080 246 42
                    45
                  </a>
                </li>
                <li>
                  <a href="tel:+390332756011">
                    <IconPhone size={14} color="currentColor" /> +39 033 275 60
                    11
                  </a>
                </li>
              </ul>
            </div>
          </MenuBalloon>
        </div>
        {tenant.name && (
          <>
            <div className="mn-top-bar__splitter" />
            <div className="mn-top-bar-company-name-text-container">
              <div className="mn-top-bar-company-name-text">{tenant.name}</div>
            </div>
          </>
        )}
        <div
          className="user-profile"
          onClick={handleThumbProfileClick}
          ref={profileRef}
        >
          <ThumbProfile size="smaller" imgUrl={userInfo.profilePic} />
          <MenuBalloon open={userMenuIsOpen}>
            <MenuBalloon.Header userInfo={userInfo} />
            {userMenuItems && (
              <ul>
                {userMenuItems.map((item, index) => {
                  return (
                    <MenuBalloon.Item
                      key={index}
                      label={item.label}
                      onClick={() => {
                        item.onClick();
                        setUserMenuIsOpen(false);
                      }}
                      icon={item.icon}
                    />
                  );
                })}
              </ul>
            )}
          </MenuBalloon>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      isActive: PropTypes.bool,
    })
  ),
  hasNotifications: PropTypes.bool,
};

TopBar.defaultProps = {
  menuItems: [],
  hasNotifications: true,
};
