import PropTypes from "prop-types";

export const IllustrationAmbulance = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 51"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="-0.587371512%"
        y1="49.9825709%"
        x2="100%"
        y2="49.9825709%"
        id="ambulance-linearGradient-1"
      >
        <stop stopColor="#C1C0C0" offset="0%"></stop>
        <stop stopColor="#DEDDDC" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0%"
        y1="49.9868228%"
        x2="100%"
        y2="49.9868228%"
        id="ambulance-linearGradient-2"
      >
        <stop stopColor="#C1C0C0" offset="0%"></stop>
        <stop stopColor="#DEDDDC" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="39.3248192%"
        y1="59.6152822%"
        x2="59.3384677%"
        y2="38.6679311%"
        id="ambulance-linearGradient-3"
      >
        <stop stopColor="#BCC2C7" offset="0%"></stop>
        <stop stopColor="#E6ECF2" offset="76%"></stop>
        <stop stopColor="#D2D8DD" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="ambulance-Image-and-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="ambulance-Illustration"
        transform="translate(-209.000000, -319.000000)"
        fillRule="nonzero"
      >
        <g id="ambulance-Group" transform="translate(202.000000, 314.000000)">
          <g id="ambulance" transform="translate(7.000000, 5.000000)">
            <polygon
              id="ambulance-Path"
              fill="#303E42"
              points="8.58819672 26.01 8.58819672 26.4005405 9.24918033 26.9610811 9.24918033 26.3913514"
            ></polygon>
            <path
              d="M8.31278689,24.4524324 L7.67016393,24.0848649 C7.51868852,23.9975676 7.3947541,24.0848649 7.3947541,24.2456757 L7.3947541,26.3959459 C7.40544762,26.5924573 7.50734661,26.7726526 7.67016393,26.882973 L8.31278689,27.2505405 C8.4642623,27.3378378 8.58819672,27.2505405 8.58819672,27.0897297 L8.58819672,24.9394595 C8.5775032,24.7429481 8.47560421,24.5627528 8.31278689,24.4524324 L8.31278689,24.4524324 Z"
              id="ambulance-Path"
              fill="#DFE6EA"
            ></path>
            <path
              d="M8.31278689,24.4524324 L7.67016393,24.0848649 C7.52786885,24.0021622 7.41311475,24.0618919 7.3947541,24.2135135 C7.43551631,24.2157415 7.47502644,24.2283969 7.5095082,24.2502703 L8.14754098,24.6178378 C8.31264639,24.7266484 8.41650838,24.9073041 8.42754098,25.1048649 L8.42754098,27.2872973 C8.52393443,27.2872973 8.59278689,27.222973 8.59278689,27.0897297 L8.59278689,24.9394595 C8.58104003,24.742112 8.47734918,24.561754 8.31278689,24.4524324 L8.31278689,24.4524324 Z"
              id="ambulance-Path"
              fill="#D2D8DD"
            ></path>
            <path
              d="M54.6229508,28.6978378 C55.0314754,28.922973 55.0314754,29.2951351 54.6229508,29.5248649 L17.2957377,50.7702703 C16.8276202,50.9975924 16.2812323,50.9975924 15.8131148,50.7702703 L2.12065574,43.2856757 C1.70754098,43.0605405 1.70754098,42.6883784 2.12065574,42.4586486 L39.4754098,21.2132432 C39.9435274,20.9859211 40.4899153,20.9859211 40.9580328,21.2132432 L54.6229508,28.6978378 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M21.2616393,41.4248649 C19.7560656,42.2978378 18.7370492,43.8967568 18.4295082,46.0975676 L22.202623,43.9381081 L24.2911475,44.2459459 C24.2911475,41.7510811 23.0839344,40.3727027 21.2616393,41.4248649 Z"
              id="ambulance-Path"
              fill="#343433"
            ></path>
            <path
              d="M50.3495082,28.4221622 C50.3495082,26.4740541 48.972459,25.6654054 47.2970492,26.6578378 C45.6946795,27.7105655 44.6059669,29.3868684 44.295082,31.28 L49.0137705,28.5783784 L50.4918033,28.9459459 C50.3357377,28.7943243 50.3495082,28.4221622 50.3495082,28.4221622 Z"
              id="ambulance-Path"
              fill="#343433"
            ></path>
            <path
              d="M48.9081967,26.8094595 L48.9081967,26.8094595 L47.627541,26.032973 L47.627541,26.032973 C47.1685246,25.7481081 46.4937705,25.7848649 45.7639344,26.202973 C44.21918,27.2082773 43.2469933,28.891613 43.147541,30.7332432 C43.0788525,31.3759318 43.3524973,32.0074926 43.8681967,32.3964865 L43.8681967,32.3964865 L45.1993443,33.2005405 L45.1993443,33.2005405 C45.6583607,33.5083784 46.3514754,33.4854054 47.0996721,33.0535135 C48.6426775,32.0471847 49.6130602,30.3640195 49.7115254,28.5232432 C49.7160656,27.6686486 49.4039344,27.0713514 48.9081967,26.8094595 Z"
              id="ambulance-Path"
              fill="#343433"
            ></path>
            <path
              d="M49.7160656,28.5278378 C49.624842,30.3768707 48.6536048,32.0700608 47.1042623,33.0810811 C45.6629508,33.9127027 44.492459,33.2372973 44.492459,31.5694595 C44.5923074,29.7303354 45.56252,28.0491722 47.1042623,27.0437838 C48.5501639,26.1891892 49.7160656,26.86 49.7160656,28.5278378 Z"
              id="ambulance-Path"
              fill="#575656"
            ></path>
            <path
              d="M49.8078689,28.707027 C49.7144213,30.4506672 48.7952523,32.0449762 47.3337705,32.9983784 C45.9567213,33.7886486 44.8596721,33.1454054 44.8596721,31.5694595 C44.9516852,29.8241674 45.8710245,28.2278557 47.3337705,27.2735135 C48.7016393,26.4832432 49.8078689,27.1081081 49.8078689,28.707027 Z"
              id="ambulance-Path"
              fill="#3A3939"
            ></path>
            <path
              d="M49.0229508,29.1618919 C48.9575789,30.349822 48.3301021,31.4353123 47.3337705,32.0840541 C46.4157377,32.6262162 45.6491803,32.1897297 45.6491803,31.11 C45.7127822,29.9227052 46.3386334,28.837077 47.3337705,28.1878378 C48.2655738,27.6502703 49.0229508,28.0867568 49.0229508,29.1618919 Z"
              id="ambulance-Path"
              fill="#E6E5E5"
            ></path>
            <path
              d="M48.8347541,29.2262162 C48.777412,30.3313592 48.2001172,31.3438788 47.2786885,31.9554054 C46.4111475,32.4562162 45.7088525,32.0472973 45.7088525,31.0364865 C45.7699693,29.9326747 46.3530367,28.9241207 47.2786885,28.3210811 C48.1278689,27.8156757 48.8347541,28.2245946 48.8347541,29.2262162 Z"
              id="ambulance-Path"
              fill="url(#ambulance-linearGradient-1)"
            ></path>
            <path
              d="M46.0852459,30.5494595 C46.0890898,30.3369393 46.1310689,30.1268415 46.2091803,29.9291892 L46.0668852,29.8189189 C45.8916825,30.1854094 45.7947561,30.5844433 45.7822951,30.9905405 C45.7823268,31.0829092 45.7900033,31.1751164 45.8052459,31.2662162 L46.117377,30.8067568 C46.0955229,30.7227567 46.0847212,30.6362596 46.0852459,30.5494595 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M46.4478689,31.3672973 L46.1219672,31.7118919 C46.4398269,31.8370144 46.7994116,31.7920229 47.0767213,31.5924324 L47.1868852,31.5235135 L47.1455738,31.4040541 C46.9260844,31.5304738 46.6528878,31.5160811 46.4478689,31.3672973 L46.4478689,31.3672973 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M47.2786885,28.4313514 C46.9089794,28.6547723 46.5950153,28.9596115 46.3606557,29.3227027 L46.5213115,29.3594595 C46.6694203,29.1435647 46.8619164,28.9618484 47.0859016,28.8264865 L47.2557377,28.7437838 L47.4163934,28.3578378 C47.3679988,28.3773453 47.3218333,28.4019907 47.2786885,28.4313514 L47.2786885,28.4313514 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M48.2655738,29.9016216 C48.1619871,30.324436 47.9517931,30.7135911 47.655082,31.0318919 L47.655082,31.11 C47.9866296,30.7435056 48.2313836,30.3069205 48.3711475,29.8327027 L48.2655738,29.9016216 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M48.0314754,28.2383784 L47.9809836,28.2383784 L47.852459,28.8081081 C48.0540484,28.8530618 48.2114831,29.0106485 48.2563934,29.2124324 L48.4767213,29.1481081 C48.5237744,28.7829653 48.348557,28.4249598 48.0314754,28.2383784 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M48.0314754,28.2383784 C48.357233,28.4224639 48.53882,28.7859884 48.4904918,29.1572973 L48.2701639,29.2216216 C48.2897519,29.2814257 48.3035852,29.3429667 48.3114754,29.4054054 L48.7704918,29.2721622 C48.7291803,28.6564865 48.4491803,28.2935135 48.0314754,28.2383784 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M46.3606557,30.0486486 L46.2091803,29.9291892 C46.1310689,30.1268415 46.0890898,30.3369393 46.0852459,30.5494595 C46.0834889,30.640867 46.0943006,30.7320821 46.117377,30.8205405 L45.8052459,31.28 C45.82113,31.3779606 45.8488753,31.4736199 45.8878689,31.5648649 L46.2504918,31.0410811 C46.2255232,30.9482083 46.2131716,30.8523912 46.2137705,30.7562162 C46.2171104,30.513132 46.2669719,30.272942 46.3606557,30.0486486 L46.3606557,30.0486486 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M47.2327869,31.3627027 L47.1455738,31.4040541 L47.1868852,31.5235135 L47.0767213,31.5924324 C46.7994116,31.7920229 46.4398269,31.8370144 46.1219672,31.7118919 L46.0393443,31.7991892 C46.3055738,32.0978378 46.7508197,32.1391892 47.2603279,31.8451351 L47.375082,31.7716216 L47.2327869,31.3627027 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M47.4072131,28.3578378 L47.2465574,28.7437838 L47.0767213,28.8264865 C46.8527361,28.9618484 46.66024,29.1435647 46.5121311,29.3594595 L46.3514754,29.3227027 L46.2963934,29.4008108 L46.6636066,29.4881081 C46.8294618,29.2672094 47.0387985,29.0826902 47.2786885,28.9459459 C47.3346273,28.910913 47.3947795,28.8831237 47.4577049,28.8632432 L47.6963934,28.2797297 C47.5969732,28.29285 47.4997314,28.3191152 47.4072131,28.3578378 L47.4072131,28.3578378 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M48.3757377,29.8327027 C48.2359737,30.3069205 47.9912198,30.7435056 47.6596721,31.11 L47.6872131,31.5235135 C48.2200127,31.0212915 48.5772816,30.3608337 48.7062295,29.6397297 L48.3757377,29.8327027 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M47.8891803,29.772973 C47.8724644,30.2143299 47.6454612,30.6209383 47.2786885,30.8664865 C46.9298361,31.0686486 46.6498361,30.9032432 46.6498361,30.4989189 C46.6706966,30.0541408 46.9049227,29.6468445 47.2786885,29.4054054 C47.6091803,29.2078378 47.8891803,29.3686486 47.8891803,29.772973 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M47.7377049,29.8648649 C47.724114,30.2002156 47.5541899,30.5097761 47.2786885,30.7010811 C47.012459,30.8527027 46.7921311,30.7286486 46.7921311,30.4162162 C46.8113204,30.0743878 46.9920084,29.7621442 47.2786885,29.5754054 C47.5311475,29.4054054 47.7377049,29.5432432 47.7377049,29.8648649 Z"
              id="ambulance-Path"
              fill="#DADAD9"
            ></path>
            <path
              d="M22.8406557,41.8567568 L22.8406557,41.8567568 L21.5737705,41.0756757 L21.5737705,41.0756757 C21.1147541,40.7908108 20.44,40.8275676 19.7101639,41.2502703 C18.1684216,42.2556587 17.198209,43.9368219 17.0983607,45.7759459 C17.0345897,46.4201979 17.3090969,47.0514782 17.8236066,47.4437838 L17.8236066,47.4437838 L19.1409836,48.2432432 L19.1409836,48.2432432 C19.6,48.5510811 20.2931148,48.5281081 21.0413115,48.0962162 C22.5826548,47.0883124 23.5525056,45.4060699 23.6531148,43.5659459 C23.6531148,42.7297297 23.3409836,42.1186486 22.8406557,41.8567568 Z"
              id="ambulance-Path"
              fill="#343433"
            ></path>
            <path
              d="M23.6531148,43.5705405 C23.5525056,45.4106645 22.5826548,47.092907 21.0413115,48.1008108 C19.6,48.9324324 18.4295082,48.257027 18.4295082,46.5891892 C18.5293566,44.7500651 19.4995692,43.0689019 21.0413115,42.0635135 C22.4918033,41.2272973 23.6531148,41.9027027 23.6531148,43.5705405 Z"
              id="ambulance-Path"
              fill="#575656"
            ></path>
            <path
              d="M23.744918,43.7497297 C23.6514705,45.4933699 22.7323015,47.0876789 21.2708197,48.0410811 C19.8937705,48.8313514 18.7967213,48.1927027 18.7967213,46.6121622 C18.8901688,44.868522 19.8093379,43.274213 21.2708197,42.3208108 C22.6386885,41.5305405 23.744918,42.1691892 23.744918,43.7497297 Z"
              id="ambulance-Path"
              fill="#3A3939"
            ></path>
            <path
              d="M22.9508197,44.2045946 C22.8871865,45.3931069 22.2615203,46.4801207 21.2662295,47.1313514 C20.3481967,47.6689189 19.5770492,47.2324324 19.5770492,46.1572973 C19.640988,44.9677146 20.2686352,43.8802223 21.2662295,43.2305405 C22.202623,42.692973 22.9508197,43.1294595 22.9508197,44.2045946 Z"
              id="ambulance-Path"
              fill="#E6E5E5"
            ></path>
            <path
              d="M22.7718033,44.2735135 C22.7103181,45.3808295 22.1256401,46.3926332 21.197377,46.9981081 C20.3298361,47.4989189 19.6229508,47.0945946 19.6229508,46.0791892 C19.684436,44.9718732 20.269114,43.9600695 21.197377,43.3545946 C22.064918,42.862973 22.7718033,43.2672973 22.7718033,44.2735135 Z"
              id="ambulance-Path"
              fill="url(#ambulance-linearGradient-2)"
            ></path>
            <path
              d="M20.0222951,45.5921622 C20.0271759,45.3801833 20.0675161,45.1705223 20.1416393,44.9718919 L20.0039344,44.8662162 C19.8288406,45.2311166 19.7319032,45.6286307 19.7193443,46.0332432 C19.7119579,46.1265204 19.7119579,46.2202364 19.7193443,46.3135135 L20.0314754,45.8540541 C20.0166441,45.7675692 20.013556,45.6794749 20.0222951,45.5921622 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M20.384918,46.4054054 L20.0590164,46.7454054 C20.3763327,46.8701298 20.7350181,46.826977 21.0137705,46.6305405 L21.1239344,46.557027 L21.082623,46.4375676 C20.8631863,46.5618695 20.5920123,46.5493691 20.384918,46.4054054 L20.384918,46.4054054 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M21.197377,43.4740541 C20.8287387,43.6988626 20.5150568,44.0034277 20.2793443,44.3654054 L20.44,44.4021622 C20.5902551,44.1881173 20.7823243,44.0068041 21.0045902,43.8691892 C21.0588793,43.8383646 21.1156975,43.8122338 21.1744262,43.7910811 L21.335082,43.4005405 L21.197377,43.4740541 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M22.1980328,44.9443243 C22.0953694,45.3704546 21.8796964,45.7609455 21.5737705,46.0745946 L21.5737705,46.1572973 C21.9010248,45.7877287 22.1452137,45.3521516 22.2898361,44.88 L22.1980328,44.9443243 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M21.9685246,43.2810811 L21.9180328,43.2810811 L21.784918,43.8462162 C21.9860873,43.8936065 22.143828,44.0497254 22.1934426,44.2505405 L22.4137705,44.1862162 C22.4588911,43.8226368 22.2839342,43.4669692 21.9685246,43.2810811 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M21.9685246,43.2810811 C22.2942822,43.4651666 22.4758691,43.8286911 22.427541,44.2 L22.2072131,44.2643243 C22.2258138,44.3243966 22.2396275,44.3858504 22.2485246,44.4481081 L22.707541,44.3194595 C22.6662295,43.7037838 22.3862295,43.3362162 21.9685246,43.2810811 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M20.2931148,45.0913514 L20.1416393,44.9718919 C20.0675161,45.1705223 20.0271759,45.3801833 20.0222951,45.5921622 C20.0202399,45.6835843 20.0310565,45.7748406 20.0544262,45.8632432 L19.7422951,46.3227027 C19.7587919,46.4190388 19.7865224,46.5131051 19.824918,46.602973 L20.187541,46.0791892 C20.1625724,45.9863164 20.1502208,45.8904993 20.1508197,45.7943243 C20.1558142,45.5533615 20.2040113,45.3152561 20.2931148,45.0913514 L20.2931148,45.0913514 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M21.1698361,46.4054054 L21.082623,46.4467568 L21.1239344,46.5662162 L21.0137705,46.6397297 C20.7350181,46.8361662 20.3763327,46.879319 20.0590164,46.7545946 L19.9763934,46.8464865 C20.242623,47.1451351 20.6878689,47.1818919 21.197377,46.8878378 L21.3121311,46.8143243 L21.1698361,46.4054054 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M21.3442623,43.4005405 L21.1836066,43.7910811 C21.1248778,43.8122338 21.0680597,43.8383646 21.0137705,43.8691892 C20.7915046,44.0068041 20.5994355,44.1881173 20.4491803,44.4021622 L20.2885246,44.3654054 L20.2334426,44.4435135 L20.6006557,44.5354054 C20.7579723,44.3073993 20.9612407,44.1148905 21.197377,43.9702703 C21.2552426,43.9389425 21.3150347,43.9113195 21.3763934,43.8875676 L21.615082,43.3040541 C21.5210845,43.3245843 21.43008,43.357007 21.3442623,43.4005405 L21.3442623,43.4005405 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M22.3127869,44.88 C22.1681645,45.3521516 21.9239756,45.7877287 21.5967213,46.1572973 L21.6242623,46.5662162 C22.1555071,46.0627984 22.5124805,45.4028868 22.6432787,44.6824324 L22.3127869,44.88 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M21.8262295,44.8156757 C21.8053689,45.2604538 21.5711429,45.6677501 21.197377,45.9091892 C20.8485246,46.1113514 20.5685246,45.9459459 20.5685246,45.5462162 C20.5893852,45.1014381 20.8236112,44.6941418 21.197377,44.4527027 C21.5462295,44.2505405 21.8262295,44.4159459 21.8262295,44.8156757 Z"
              id="ambulance-Path"
              fill="#918D8D"
            ></path>
            <path
              d="M21.6839344,44.8983784 C21.6669203,45.2420778 21.4858814,45.5566373 21.197377,45.7437838 C20.9265574,45.9 20.7108197,45.7713514 20.7108197,45.4635135 C20.7278338,45.1198141 20.9088727,44.8052546 21.197377,44.6181081 C21.4681967,44.4618919 21.6839344,44.5905405 21.6839344,44.8983784 Z"
              id="ambulance-Path"
              fill="#DADAD9"
            ></path>
            <path
              d="M55.5134426,23.6254054 L55.5134426,17.1286486 L54.7285246,7.9027027 C54.682623,7.31459459 54.4163934,7.11243243 54.1042623,7.08486486 L40.4714754,0.137837838 L40.4714754,0.137837838 C40.012459,-0.257297297 39.0944262,0.321621622 39.0944262,0.321621622 L16.212459,12.6259459 C16.212459,12.6259459 14.5462295,13.4575676 13.1140984,15.8835135 L13.1140984,15.8835135 L10.415082,20.7905405 L5.5495082,29.4054054 C5.5495082,29.4054054 2.65770492,32.8881081 1.83606557,34.1194595 C1.32655738,34.8775676 0.918032787,35.5897297 0.918032787,36.0216216 L0.918032787,37.7445946 L0.86295082,37.7445946 C0.86295082,37.7445946 0.440655738,37.9972973 0.436065574,38.2040541 L0.436065574,39.972973 C0.463763392,40.1884905 0.572013395,40.3854986 0.739016393,40.5243243 L12.6688525,47.4805405 C13.4767213,47.94 14.9180328,48.7118919 15.7763934,48.7073177 C16.6347541,48.7027027 18.3606557,47.6872973 18.3606557,47.6872973 C18.3606557,47.6872973 18.3606557,47.5954054 18.3606557,47.5494595 C18.3606557,44.5905405 19.4714754,42.4954054 21.2891803,41.4248649 C23.1068852,40.3543243 24.3186885,41.7510811 24.3186885,44.2459459 L44.1160656,32.7916216 C44.2076824,32.5227088 44.2496771,32.2393599 44.24,31.9554054 C44.3487859,29.8024489 45.477765,27.8308031 47.2786885,26.6486486 C48.9632787,25.6745946 50.3311475,26.4648649 50.3311475,28.412973 C50.3311475,28.412973 50.2990164,29.1021622 50.762623,28.9275676 C50.762623,28.9275676 52.7731148,27.7789189 53.7921311,27.177027 C54.3613115,26.8416216 55.9311475,25.6837838 55.9311475,25.0267568 L55.9311475,23.7264865 L55.5134426,23.6254054 Z"
              id="ambulance-Path"
              fill="#DFE6EA"
            ></path>
            <path
              d="M55.5134426,23.6254054 L55.5134426,17.1286486 L54.7285246,7.9027027 C54.6137705,6.41405405 53.0622951,7.40189189 53.0622951,7.40189189 L30.1940984,20.6205405 C30.1940984,20.6205405 28.5278689,21.4475676 27.0819672,23.8918919 L24.1763934,28.6748649 L19.7377049,37.1151351 C19.7377049,37.1151351 16.6393443,40.8918919 15.8177049,42.1094595 C15.7167213,42.2610811 15.6157377,42.4127027 15.5239344,42.5689189 C14.6951934,42.473813 13.8958791,42.2045082 13.1783607,41.7786486 C10.2681967,40.0878378 1.3357377,35.042973 1.3357377,35.042973 L1.29442623,35.02 C1.10279479,35.3246678 0.982005415,35.6685432 0.940983607,36.0262162 L0.940983607,37.7491892 L0.885901639,37.7491892 C0.885901639,37.7491892 0.463606557,38.0018919 0.459016393,38.2086486 L0.459016393,39.972973 C0.486714212,40.1884905 0.594964214,40.3854986 0.761967213,40.5243243 L12.6688525,47.4805405 C13.3057951,47.8593475 13.9719587,48.1866051 14.6609836,48.4591892 C14.6189965,48.384901 14.5968628,48.3010281 14.5967213,48.2156757 L14.5967213,47.5356757 C14.5967213,48.1972973 15.8314754,48.8589189 16.4052459,48.5281081 C16.9009836,48.2432432 18.3377049,47.6872973 18.3377049,47.6872973 C18.3377049,47.6872973 18.3377049,47.5954054 18.3377049,47.5494595 C18.3377049,44.5905405 19.4485246,42.4954054 21.2662295,41.4248649 C23.0839344,40.3543243 24.2957377,41.7510811 24.2957377,44.2459459 L44.1160656,32.7916216 C44.2076824,32.5227088 44.2496771,32.2393599 44.24,31.9554054 C44.3487859,29.8024489 45.477765,27.8308031 47.2786885,26.6486486 C48.9632787,25.6745946 50.3311475,26.4648649 50.3311475,28.412973 C50.3311475,28.412973 50.2990164,29.1021622 50.762623,28.9275676 C50.762623,28.9275676 52.7731148,27.7789189 53.7921311,27.177027 C54.3613115,26.8416216 55.9311475,25.6837838 55.9311475,25.0267568 L55.9311475,23.7264865 L55.5134426,23.6254054 Z"
              id="ambulance-Path"
              fill="#D2D8DD"
            ></path>
            <path
              d="M19.1547541,43.7037838 L16.0977049,45.3716216 C14.9272131,46.047027 13.3436066,45.0913514 13.3436066,45.0913514 L0.918032787,37.9835135 C0.850371688,37.9297118 0.82934711,37.8359664 0.867540984,37.7583784 C0.867540984,37.7583784 0.445245902,38.0110811 0.440655738,38.2178378 L0.440655738,39.972973 C0.468353556,40.1884905 0.576603559,40.3854986 0.743606557,40.5243243 L12.6688525,47.4805405 C13.4767213,47.94 14.9180328,48.7118919 15.7763934,48.7073177 C16.6347541,48.7027027 18.3606557,47.6872973 18.3606557,47.6872973 C18.3606557,47.6872973 18.3606557,47.5954054 18.3606557,47.5494595 C18.3201266,46.2229473 18.5921645,44.9055166 19.1547541,43.7037838 L19.1547541,43.7037838 Z"
              id="ambulance-Path"
              fill="#404B4D"
            ></path>
            <path
              d="M18.3606557,46.727027 C17.3967213,47.2554054 15.8268852,48.0594595 15.2301639,48.0640541 C14.3718033,48.0640541 12.935082,47.3105405 12.1180328,46.8372973 L0.459016393,40.0143243 C0.501515874,40.2092564 0.606244046,40.385047 0.757377049,40.5151351 L12.6688525,47.4805405 C13.4767213,47.94 14.9180328,48.7118919 15.7763934,48.7073177 C16.6347541,48.7027027 18.3606557,47.6872973 18.3606557,47.6872973 C18.3606557,47.6872973 18.3606557,47.5954054 18.3606557,47.5494595 C18.3331148,47.2691892 18.3606557,46.9935135 18.3606557,46.727027 Z"
              id="ambulance-Path"
              fill="#333E40"
            ></path>
            <path
              d="M13.3390164,45.5967568 C13.3390164,45.5967568 14.9272131,46.5524324 16.0931148,45.877027 L18.8472131,44.3654054 C18.9311937,44.1389228 19.0293106,43.9179468 19.1409836,43.7037838 L16.0839344,45.3716216 C14.9134426,46.047027 13.3298361,45.0913514 13.3298361,45.0913514 L0.918032787,37.9835135 C0.850371688,37.9297118 0.82934711,37.8359664 0.867540984,37.7583784 C0.867540984,37.7583784 0.445245902,38.0064865 0.440655738,38.2178378 L13.3390164,45.5967568 Z"
              id="ambulance-Path"
              fill="#5A6567"
            ></path>
            <path
              d="M9.60721311,44.7008108 C9.60721311,44.8432432 9.50622951,44.8983784 9.38229508,44.8294595 L5.25114754,42.4264865 C5.11943411,42.3428116 5.03579093,42.2009935 5.02622951,42.0451351 L5.02622951,41.2778378 C5.02622951,41.1354054 5.12721311,41.0802703 5.25114754,41.1491892 L9.4052459,43.5521622 C9.53822955,43.6367664 9.62204813,43.780594 9.63016393,43.9381081 L9.60721311,44.7008108 Z"
              id="ambulance-Path"
              fill="#263133"
            ></path>
            <path
              d="M5.07672131,42.1416216 L5.07672131,41.3743243 C5.07672131,41.2318919 5.17770492,41.1767568 5.29704918,41.2456757 L9.4557377,43.6486486 C9.48636008,43.6676451 9.51417156,43.6908437 9.53836066,43.7175676 C9.50101476,43.650208 9.44734761,43.5933291 9.38229508,43.5521622 L5.25114754,41.1491892 C5.12721311,41.0802703 5.02622951,41.1491892 5.02622951,41.2778378 L5.02622951,42.0451351 C5.03375759,42.1623069 5.08255042,42.2730107 5.16393443,42.3575676 C5.11565446,42.2949388 5.08548622,42.22024 5.07672131,42.1416216 L5.07672131,42.1416216 Z"
              id="ambulance-Path"
              fill="#0D181A"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#FFFFFF"
              points="9.37311475 44.6089189 5.23278689 42.2197297 5.23278689 41.3421622 9.37311475 43.7359459"
            ></polygon>
            <path
              d="M13.0039344,46.6259459 C12.9936946,46.658931 12.9686053,46.6852076 12.9361491,46.6969393 C12.9036928,46.7086709 12.86762,46.7045019 12.8386885,46.6856757 L10.5986885,45.537027 C10.5294389,45.4987674 10.4896961,45.4228215 10.4977049,45.3440541 L10.4977049,44.5675676 C10.5044192,44.5342821 10.5281194,44.50698 10.5601098,44.4956783 C10.5921003,44.4843767 10.6276708,44.4907396 10.6537705,44.5124324 L13.0406557,45.8908108 C13.1125721,45.9307242 13.1512798,46.0119043 13.1370492,46.092973 L13.0039344,46.6259459 Z"
              id="ambulance-Path"
              fill="#273234"
            ></path>
            <path
              d="M13.0406557,45.9459459 L10.9154098,44.6962162 L10.9154098,45.2245946 C10.9047339,45.3038544 10.9452208,45.3812222 11.0163934,45.4175676 L13.0544262,46.4605405 L13.1370492,46.1481081 C13.1512798,46.0670394 13.1125721,45.9858593 13.0406557,45.9459459 L13.0406557,45.9459459 Z"
              id="ambulance-Path"
              fill="#0E191B"
            ></path>
            <path
              d="M1.88655738,40.1705405 C1.91002903,40.2618887 1.9633491,40.3427547 2.03803279,40.4002703 L4.13114754,41.7556757 C4.19540984,41.797027 4.23672131,41.7556757 4.22295082,41.6775676 L4.09901639,40.8918919 C4.07843211,40.8017492 4.0242539,40.7228684 3.94754098,40.6713514 L1.83606557,39.4721622 C1.76721311,39.4308108 1.7304918,39.4721622 1.7442623,39.5548649 L1.88655738,40.1705405 Z"
              id="ambulance-Path"
              fill="#273234"
            ></path>
            <path
              d="M4.09901639,40.8918919 C4.07843211,40.8017492 4.0242539,40.7228684 3.94754098,40.6713514 L2.24459016,39.7018919 L2.32721311,40.0878378 C2.35220941,40.1797782 2.4051442,40.2616655 2.47868852,40.3221622 L4.18622951,41.4202703 L4.09901639,40.8918919 Z"
              id="ambulance-Path"
              fill="#0E191B"
            ></path>
            <path
              d="M24.9613115,28.4221622 C24.7735457,28.5393185 24.5670329,28.6232467 24.3508197,28.6702703 C23.8408596,28.6417807 23.3444071,28.4955318 22.9003279,28.242973 L10.557377,21.1351351 C10.4643755,21.0529637 10.4137124,20.9329453 10.4196721,20.8089189 L5.68262295,29.1664865 C5.2557377,29.6581081 5.9442623,30.0348649 5.9442623,30.0348649 L16.9055738,36.3616216 C16.9055738,36.3616216 19.1363934,37.4735135 19.9672131,36.9910811 L20.4262295,36.72 C20.5791147,36.615102 20.7062382,36.4768591 20.7980328,36.3156757 L25.0255738,28.5691892 C25.117377,28.4175676 25.0852459,28.3486486 24.9613115,28.4221622 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M24.5390164,28.9091892 C24.4868805,28.9260666 24.4329712,28.9368589 24.3783607,28.9413514 L24.2452459,28.9413514 C23.7281835,28.889156 23.2269984,28.7328729 22.7718033,28.4818919 L22.7718033,28.4818919 L10.1855738,21.1948649 L5.68721311,29.1527027 C5.68907753,29.2891081 5.72371414,29.4230606 5.78819672,29.5432432 C5.85884368,29.6502339 5.95721312,29.7359932 6.07278689,29.7913514 L6.07278689,29.7913514 L17.0295082,36.1181081 C17.7750588,36.4968915 18.5815102,36.7410824 19.4118033,36.8394595 C19.5557956,36.8478478 19.6995496,36.8193854 19.8295082,36.7567568 L20.2885246,36.4856757 C20.3975925,36.4049795 20.4896329,36.3034813 20.5593443,36.187027 L24.5390164,28.9091892 Z"
              id="ambulance-Path"
              fill="#263338"
            ></path>
            <path
              d="M24.5390164,28.9091892 C24.4868805,28.9260666 24.4329712,28.9368589 24.3783607,28.9413514 L24.2452459,28.9413514 C23.7281835,28.889156 23.2269984,28.7328729 22.7718033,28.4818919 L22.7718033,28.4818919 L10.1855738,21.1948649 L5.68721311,29.1527027 C5.68907753,29.2891081 5.72371414,29.4230606 5.78819672,29.5432432 C5.85884368,29.6502339 5.95721312,29.7359932 6.07278689,29.7913514 L6.07278689,29.7913514 L17.0295082,36.1181081 C17.7750588,36.4968915 18.5815102,36.7410824 19.4118033,36.8394595 C19.5557956,36.8478478 19.6995496,36.8193854 19.8295082,36.7567568 L20.2885246,36.4856757 C20.3975925,36.4049795 20.4896329,36.3034813 20.5593443,36.187027 L24.5390164,28.9091892 Z"
              id="ambulance-Path"
              fill="#263338"
            ></path>
            <path
              d="M10.1855738,21.1948649 L5.73311475,29.0791892 L5.69180328,29.1986486 L5.69180328,29.1986486 L7.39934426,28.027027 C7.51831877,27.9482928 7.61732966,27.8428939 7.68852459,27.7191892 L10.9245902,21.6221622 L10.1855738,21.1948649 Z"
              id="ambulance-Path"
              fill="#C5D0D3"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#C5D0D3"
              points="12.6504918 22.6191892 12.4898361 24.3513514 13.9586885 23.3681081"
            ></polygon>
            <path
              d="M11.535082,21.9759459 C11.0760656,22.8213514 10.5527869,23.7724324 10.0754098,24.6454054 L10.0203279,26.0237838 L12.3154098,24.502973 L12.4760656,22.5272973 L11.535082,21.9759459 Z"
              id="ambulance-Path"
              fill="#C5D0D3"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#303E42"
              points="21.6013115 34.2894595 16.0701639 31.0916216 14.9914754 31.4178378 21.2111475 35.0108108"
            ></polygon>
            <polygon
              id="ambulance-Path"
              fill="#303E42"
              points="18.1770492 25.8216216 13.7062295 23.2302703 12.237377 28.8678378 16.6118033 31.3948649"
            ></polygon>
            <path
              d="M24.3783607,28.9459459 L24.2452459,28.9459459 C23.7281835,28.8937506 23.2269984,28.7374675 22.7718033,28.4864865 L22.7718033,28.4864865 L19.4072131,26.5383784 L17.837377,32.1024324 L21.6059016,34.2802703 L24.5390164,28.9091892 C24.4870677,28.9276291 24.4331542,28.9399641 24.3783607,28.9459459 L24.3783607,28.9459459 Z"
              id="ambulance-Path"
              fill="#303E42"
            ></path>
            <path
              d="M14.9777049,31.4132432 L13.4583607,31.6062162 L7.21114754,27.9994595 L6.42622951,28.6932432 C6.42622951,28.8402703 6.47213115,28.8127027 6.53180328,28.9321622 C6.59147541,29.0516216 6.75672131,29.1481081 6.81639344,29.1848649 L6.81639344,29.1848649 L17.7777049,35.5116216 C17.8878689,35.5621622 19.2695082,36.232973 20.16,36.232973 C20.3044047,36.2401407 20.4482299,36.2100823 20.5777049,36.1456757 L20.5777049,36.1456757 L21.1927869,35.02 L14.9777049,31.4132432 Z"
              id="ambulance-Path"
              fill="#445459"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#303E42"
              points="13.4583607 31.6062162 14.812459 31.4362162 8.30819672 27.6778378 7.21114754 27.9994595"
            ></polygon>
            <path
              d="M17.584918,32.6216216 C17.6721311,32.0427027 18.1403279,31.8359459 18.704918,32.1621622 C19.309944,32.5618244 19.71704,33.1997785 19.824918,33.9172973 L20.4216393,34.2618919 C20.2812202,33.1353522 19.653541,32.1274082 18.704918,31.5051351 C17.8144262,30.9905405 17.08,31.3489189 16.9881967,32.3 L17.584918,32.6216216 Z"
              id="ambulance-Path"
              fill="#445459"
            ></path>
            <path
              d="M15.7672131,31.8681081 C15.7672131,31.8681081 16.8734426,31.6521622 18.5534426,32.5343243 C19.6095146,33.1195258 20.5026176,33.9597898 21.1514754,34.9786486 L20.5088525,35.5851351 C19.0095001,35.1874895 17.5694184,34.59267 16.2262295,33.8162162 C14.4452459,32.6905405 14.6472131,32.3183784 14.6472131,32.3183784 L15.7672131,31.8681081 Z"
              id="ambulance-Path"
              fill="#3D4B4F"
            ></path>
            <path
              d="M17.8511475,42.4356757 C17.7960656,42.5918919 17.9521311,42.6332432 18.0714754,42.5643243 L53.0714754,22.3481081 C53.2028038,22.2619203 53.2860857,22.1190135 53.2963934,21.9621622 L53.2963934,21.7232432 C53.2963934,21.5854054 53.1954098,21.5256757 53.0714754,21.5991892 L18.957377,41.3054054 C18.6304547,41.4749641 18.3415083,41.7094712 18.1081967,41.9945946 C17.9997194,42.1270688 17.9129551,42.2759512 17.8511475,42.4356757 L17.8511475,42.4356757 Z"
              id="ambulance-Path"
              fill="#D2D8DD"
            ></path>
            <path
              d="M50.317377,27.9213514 C50.3400891,28.0872993 50.3508273,28.2546695 50.3495082,28.4221622 C50.3495082,28.4221622 50.317377,29.1113514 50.7809836,28.9367568 C50.7809836,28.9367568 52.7914754,27.7881081 53.8104918,27.1862162 C54.3796721,26.8508108 55.9495082,25.692973 55.9495082,25.0359459 L55.9495082,24.6637838 L50.317377,27.9213514 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.07"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M53.7921311,27.2 L53.7921311,27.2 C54.3613115,26.8645946 55.9311475,25.7067568 55.9311475,25.0497297 L55.9311475,23.7264865 L55.4721311,23.6254054 L55.4721311,22.7845946 L53.7508197,23.7816216 L53.7921311,27.2 Z"
              id="ambulance-Path"
              fill="#404B4D"
            ></path>
            <path
              d="M54.4760656,24.1537838 L55.5134426,23.6254054 L55.972459,23.7264865 C55.5052059,23.9625145 54.9974013,24.1075188 54.4760656,24.1537838 L54.4760656,24.1537838 Z"
              id="ambulance-Path"
              fill="#5A6567"
            ></path>
            <path
              d="M55.9495082,25.0359459 L55.8898361,25.0956757 C55.2669825,25.7094322 54.5607991,26.2322339 53.7921311,26.6486486 L53.7921311,27.2 L53.7921311,27.2 C54.3796721,26.8508108 55.944918,25.6975676 55.9495082,25.0359459 Z"
              id="ambulance-Path"
              fill="#333E40"
            ></path>
            <path
              d="M53.0622951,7.40189189 L30.1940984,20.6205405 C30.1940984,20.6205405 28.5278689,21.4475676 27.0819672,23.8918919 L54.7147541,7.92108108 C54.6229508,6.43243243 53.0622951,7.40189189 53.0622951,7.40189189 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              opacity="0.7"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M40.4714754,0.137837838 L40.4714754,0.137837838 C40.012459,-0.257297297 39.0944262,0.321621622 39.0944262,0.321621622 L16.212459,12.6259459 C16.212459,12.6259459 14.5462295,13.4575676 13.1140984,15.8835135 L27.0819672,23.8918919 C28.5140984,21.4659459 30.1803279,20.6389189 30.1803279,20.6389189 L53.0622951,7.40189189 C53.3750319,7.20482037 53.7348612,7.09533937 54.1042623,7.08486486 L40.4714754,0.137837838 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.037377,26.5797297 L28.9685246,26.6210811 L27.2196721,27.6410811 L26.5954098,27.9994595 L26.5954098,27.9994595 C26.031692,28.3316735 25.5794948,28.8242925 25.2963934,29.4145946 C24.2544262,31.3535135 21.2662295,36.7981081 21.2662295,36.7981081 C21.2662295,36.7981081 26.347541,33.4210811 28.1514754,32.2035135 C28.7938084,31.8474859 29.2271898,31.2053257 29.317377,30.4759459 C29.3816393,29.6627027 29.4688525,28.4681081 29.5790164,27.2275676 C29.6386885,26.3132432 29.202623,26.4832432 29.037377,26.5797297 Z"
              id="ambulance-Path"
              fill="#141C1E"
            ></path>
            <path
              d="M29.2852459,26.7267568 L29.2852459,26.7267568 C29.2383288,26.7333337 29.1931264,26.7489358 29.1521311,26.7727027 L29.1108197,26.8002703 L29.1108197,26.8002703 L29.1108197,26.8002703 C29.1108197,26.8002703 28.1422951,27.37 27.3527869,27.8248649 L26.7285246,28.1832432 L26.7285246,28.1832432 C26.4222274,28.3560945 26.151259,28.585237 25.9298361,28.8586486 L25.4708197,33.7151351 C26.4531148,33.0810811 27.4078689,32.4286486 28,32.0243243 C28.5797192,31.701427 28.9741161,31.1245753 29.064918,30.4667568 C29.064918,30.2186486 29.1108197,29.9383784 29.1337705,29.6305405 C29.1888525,28.9413514 29.2531148,28.0821622 29.3311475,27.2183784 C29.3770492,26.837027 29.2944262,26.7405405 29.2852459,26.7267568 Z"
              id="ambulance-Path"
              fill="#303E42"
            ></path>
            <path
              d="M25.4754098,29.5340541 C24.947541,30.5127027 23.9193443,32.4010811 23.0288525,34.022973 L22.9737705,35.4013514 L25.2688525,33.8759459 L25.6452459,29.2813514 C25.5718033,29.3548649 25.5167213,29.4513514 25.4754098,29.5340541 Z"
              id="ambulance-Path"
              fill="#303E42"
            ></path>
            <path
              d="M24.1763934,43.0283784 C24.2548903,43.4294205 24.293331,43.8372851 24.2911475,44.2459459 L44.1160656,32.7916216 C44.2076824,32.5227088 44.2496771,32.2393599 44.24,31.9554054 C44.2409552,31.7740958 44.2532195,31.5930228 44.2767213,31.4132432 L24.1763934,43.0283784 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.07"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M10.1855738,21.1948649 L5.68721311,29.1527027 C5.68907753,29.2891081 5.72371414,29.4230606 5.78819672,29.5432432 C5.85884368,29.6502339 5.95721312,29.7359932 6.07278689,29.7913514 L6.07278689,29.7913514 L8.18885246,31.0135135 C9.63934426,29.3043243 12.4531148,26.8048649 17.1809836,25.2702703 L10.1855738,21.1948649 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              opacity="0.2"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#1A64A3"
              points="47.7744262 17.4548649 47.2557377 16.7656757 46.3881967 17.8683784 46.3881967 16.6967568 45.3921311 17.2756757 45.3921311 18.4472973 44.5245902 18.3416216 44.0059016 19.6327027 44.9193443 19.7383784 44.0059016 20.9008108 44.5245902 21.5945946 45.3921311 20.4872973 45.3921311 21.6589189 46.3881967 21.08 46.3881967 19.912973 47.2557377 20.0140541 47.7744262 18.722973 46.8609836 18.6172973"
            ></polygon>
            <path
              d="M54.8983607,9.91513514 L54.7331148,7.95783784 L27.7613115,23.5427027 C27.1186885,23.9056757 26.2281967,23.4737838 26.2281967,23.4737838 L13.1140984,15.8927027 L11.3285246,19.1089189 L24.0891803,26.4602703 C24.801782,26.7905868 25.6292296,26.7598478 26.3154098,26.3775676 L26.3154098,26.3775676 L54.8983607,9.91513514 Z"
              id="ambulance-Path"
              fill="#D43D3D"
            ></path>
            <path
              d="M54.8983607,9.91513514 L54.7331148,7.95783784 L27.7613115,23.5427027 C27.5911232,23.6362686 27.400071,23.6852631 27.2059016,23.6851351 L27.0819672,23.8918919 L25.3468852,26.7451351 C25.6832091,26.7139039 26.0088559,26.6104739 26.3016393,26.4418919 L26.3016393,26.4418919 L54.8983607,9.91513514 Z"
              id="ambulance-Path"
              fill="#D43D3D"
              opacity="0.3"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#D43D3D"
              points="55.5134426 17.4594595 28.1744262 33.2602703 17.4196721 41.9945946 17.5114754 42.137027 55.5134426 20.1656757"
            ></polygon>
            <polygon
              id="ambulance-Path"
              fill="#D43D3D"
              opacity="0.3"
              style={{ mixBlendMode: "multiply" }}
              points="55.5134426 19.1135135 55.5134426 17.4594595 28.1744262 33.2602703 17.4196721 41.9945946 17.5114754 42.137027 55.5134426 20.1656757"
            ></polygon>
            <path
              d="M42.1921187,26.86 L42.1100214,27.2367568 L41.8347541,27.3883784 L42.1921187,25.8951351 L42.5446541,25.7067568 L42.9116772,26.7956757 L42.6219221,26.9564865 L42.5301663,26.6716216 L42.1921187,26.86 Z M42.4915323,26.4786486 L42.4190935,26.2397297 C42.4190935,26.1754054 42.3756303,26.0927027 42.3563133,26.0237838 L42.3563133,26.0237838 C42.3563133,26.1064865 42.3176792,26.2167568 42.2983622,26.2994595 L42.2307527,26.6164865 L42.4915323,26.4786486 Z"
              id="ambulance-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M43.9508197,25.67 C43.9508197,25.5183784 43.9508197,25.33 43.9508197,25.1416216 L43.9508197,25.1416216 C43.9140984,25.3254054 43.8681967,25.5367568 43.8268852,25.7159459 L43.6891803,26.2994595 L43.4918033,26.4143243 L43.372459,25.9824324 C43.3357377,25.8491892 43.2990164,25.6883784 43.2714754,25.522973 L43.2714754,25.522973 C43.2714754,25.7021622 43.2714754,25.9043243 43.2714754,26.0697297 L43.2714754,26.5751351 L43.037377,26.7083784 L43.1108197,25.3713514 L43.4459016,25.1783784 L43.5560656,25.5459459 C43.5927869,25.6745946 43.624918,25.817027 43.652459,25.9548649 L43.652459,25.9548649 C43.6845902,25.7894595 43.7213115,25.5964865 43.762623,25.4310811 L43.877377,24.9302703 L44.212459,24.7372973 L44.2721311,25.9962162 L44.0242623,26.1386486 L43.9508197,25.67 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M44.4147602,24.6224324 C44.5475297,24.5446604 44.685497,24.4725745 44.8280658,24.4064865 C44.9518807,24.3347449 45.0996175,24.2932138 45.2530139,24.287027 C45.3519744,24.287027 45.4218288,24.3467568 45.4218288,24.4662162 C45.4079915,24.6119765 45.324809,24.7482101 45.1889806,24.8475676 L45.1889806,24.8475676 C45.3461532,24.8108108 45.4683985,24.8475676 45.4683985,25.0359459 C45.462839,25.1594606 45.4123142,25.279096 45.3228683,25.3805405 C45.1656781,25.5355516 44.9668569,25.6610929 44.7407478,25.7481081 C44.6284808,25.8016838 44.5117958,25.8492676 44.3914754,25.8905405 L44.4147602,24.6224324 Z M44.7349266,24.9716216 L44.8397083,24.9256757 C44.9794027,24.8746 45.0761116,24.7705124 45.0958413,24.65 C45.0958413,24.5489189 45.0143444,24.5351351 44.8688143,24.5994595 C44.8219763,24.6213577 44.777228,24.6459276 44.7349266,24.672973 L44.7349266,24.9716216 Z M44.7349266,25.5459459 L44.8571719,25.4954054 C45.0107089,25.435632 45.11419,25.3174622 45.1307685,25.182973 C45.1307685,25.0543243 45.002702,25.0589189 44.8455295,25.1324324 L44.7349266,25.182973 L44.7349266,25.5459459 Z"
              id="ambulance-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M45.667541,23.8918919 L45.667541,24.6362162 C45.667541,24.8613514 45.7409836,24.9302703 45.8695082,24.8567568 C46.0235933,24.7584146 46.1047228,24.5779528 46.0760656,24.3972973 L46.0760656,23.6437838 L46.3285246,23.4967568 L46.3285246,24.2227027 C46.3522831,24.5734033 46.1740909,24.9069443 45.8695082,25.0818919 C45.5895082,25.2472973 45.4104918,25.16 45.4104918,24.7464865 L45.4104918,24.0251351 L45.667541,23.8918919 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#FFFFFF"
              points="46.5488525 23.3681081 46.8013115 23.2256757 46.8013115 24.2732432 47.2603279 24.0159459 47.2603279 24.2594595 46.562623 24.6637838"
            ></polygon>
            <path
              d="M47.686784,23.6851351 L47.5998575,24.0618919 L47.3245902,24.2135135 L47.686784,22.7248649 L48.0344901,22.5318919 L48.4015132,23.6254054 L48.1117582,23.7816216 L48.0200024,23.5013514 L47.686784,23.6851351 Z M47.9861976,23.3037838 L47.9089296,23.0694595 C47.9089296,23.0005405 47.8702956,22.9178378 47.8509786,22.8489189 L47.8509786,22.8489189 C47.8509786,22.9362162 47.8171738,23.0418919 47.7978568,23.1291892 L47.7302473,23.4416216 L47.9861976,23.3037838 Z"
              id="ambulance-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M48.495082,23.5381081 L48.495082,22.2424324 L48.7934426,22.0724324 L49.0229508,22.4124324 C49.0959213,22.5189913 49.1574638,22.6329547 49.2065574,22.7524324 L49.2065574,22.7524324 C49.2065574,22.5916216 49.2065574,22.4216216 49.2065574,22.2240541 L49.2065574,21.8472973 L49.4360656,21.7140541 L49.4360656,23.0051351 L49.1698361,23.1613514 L48.9311475,22.7983784 C48.855902,22.6900252 48.7913372,22.5746196 48.7383607,22.4537838 L48.7383607,22.4537838 C48.7383607,22.6237838 48.7383607,22.802973 48.7383607,23.0143243 L48.7383607,23.4048649 L48.495082,23.5381081 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M50.4642623,22.3664865 C50.3798486,22.4525997 50.2841042,22.5267959 50.1796721,22.587027 C49.7895082,22.8121622 49.5921311,22.6467568 49.5921311,22.2791892 C49.6020767,21.8493429 49.8328724,21.4550482 50.202623,21.2362162 C50.2902112,21.1812156 50.3890502,21.1466671 50.4918033,21.1351351 L50.4413115,21.397027 C50.3651543,21.406598 50.2917465,21.431612 50.2255738,21.4705405 C50.0051103,21.6054981 49.871089,21.845924 49.8721311,22.1045946 C49.8721311,22.3664865 50.0052459,22.4537838 50.2301639,22.3205405 C50.3086844,22.276691 50.3799175,22.2208896 50.4413115,22.1551351 L50.4642623,22.3664865 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#FFFFFF"
              points="51.2996721 21.3740541 50.8865574 21.6083784 50.8865574 21.9162162 51.3455738 21.6497297 51.3455738 21.8932432 50.6340984 22.3021622 50.6340984 21.0064865 51.322623 20.6113514 51.322623 20.8502703 50.8636066 21.102973 50.8636066 21.3740541 51.2767213 21.1351351"
            ></polygon>
            <path
              d="M23.8045902,24.8108108 L23.5429508,25.0589189 L23.1986885,24.8567568 L24.2406557,23.9010811 L24.6583607,24.1445946 L24.4701639,25.6056757 L24.1488525,25.4172973 L24.2039344,25.0451351 L23.8045902,24.8108108 Z M24.2636066,24.8108108 L24.3095082,24.502973 C24.3095082,24.4202703 24.3462295,24.3145946 24.36,24.2318919 L24.36,24.2318919 C24.3008092,24.2995837 24.2379841,24.3640032 24.1718033,24.4248649 L23.9560656,24.6316216 L24.2636066,24.8108108 Z"
              id="ambulance-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M21.8170492,24.0618919 L21.5095082,23.8918919 L22.1934426,22.7202703 L22.6065574,22.9637838 L22.5514754,23.4324324 C22.5514754,23.5932432 22.4963934,23.7816216 22.4642623,23.947027 L22.4642623,23.947027 C22.5652459,23.822973 22.6845902,23.6943243 22.7993443,23.5794595 L23.1390164,23.2578378 L23.5567213,23.5013514 L23.0472131,24.8108108 L22.7580328,24.6408108 L22.96,24.1813514 L23.1895082,23.6667568 L23.1895082,23.6667568 C23.0747541,23.8045946 22.9416393,23.9516216 22.8268852,24.0664865 L22.4413115,24.4478378 L22.1980328,24.3054054 L22.2668852,23.7310811 C22.2668852,23.5564865 22.3127869,23.3497297 22.344918,23.1705405 L22.344918,23.1705405 L22.0832787,23.6621622 L21.8170492,24.0618919 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M21.2708197,23.7356757 C21.1511633,23.6810541 21.0346928,23.6196947 20.9219672,23.5518919 C20.7216826,23.4558849 20.5517345,23.3064406 20.4308197,23.12 C20.3736964,23.0122508 20.3736964,22.8831546 20.4308197,22.7754054 C20.5169431,22.626751 20.7024341,22.5688539 20.8577049,22.6421622 L20.8577049,22.6421622 C20.7421906,22.5481923 20.7012571,22.3898875 20.7567213,22.2516216 C20.8096893,22.1463829 20.9254209,22.0884612 21.0413115,22.1091892 C21.2063378,22.1286979 21.3640042,22.1886689 21.5003279,22.2837838 C21.6377931,22.3618574 21.7696931,22.449364 21.895082,22.5456757 L21.2708197,23.7356757 Z M21.4681967,22.5410811 L21.335082,22.4537838 C21.1927869,22.3710811 21.0872131,22.3710811 21.0413115,22.4537838 C20.9954098,22.5364865 21.0413115,22.6559459 21.2203279,22.7524324 L21.3259016,22.8167568 L21.4681967,22.5410811 Z M21.224918,23.0005405 L21.1147541,22.972973 C20.9586885,22.8764865 20.8072131,22.8535135 20.747541,22.972973 C20.6878689,23.0924324 20.7842623,23.2256757 20.9265574,23.3083784 L21.0504918,23.3772973 L21.224918,23.0005405 Z"
              id="ambulance-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M20.6281967,21.7921622 L20.2885246,22.4721622 C20.231649,22.6358895 20.0975322,22.7608467 19.9303111,22.8059112 C19.76309,22.8509757 19.5844255,22.8103102 19.4531148,22.6972973 C19.2852904,22.629976 19.1552091,22.4926465 19.0969776,22.321316 C19.0387462,22.1499856 19.0581728,21.9617435 19.1501639,21.8059459 L19.4944262,21.1351351 L19.8065574,21.3189189 L19.4577049,22.0172973 C19.347541,22.2286486 19.3888525,22.3848649 19.5540984,22.4767568 C19.7193443,22.5686486 19.8616393,22.5181081 19.9672131,22.3113514 L20.3160656,21.5945946 L20.6281967,21.7921622 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#FFFFFF"
              points="18.6085246 22.1781081 17.7409836 21.6635135 17.8557377 21.4337838 18.4111475 21.76 18.9022951 20.7767568 19.2190164 20.9605405"
            ></polygon>
            <path
              d="M16.9836066,20.8135135 L16.7219672,21.0616216 L16.3822951,20.8594595 L17.4242623,19.8991892 L17.837377,20.1427027 L17.6445902,21.5945946 L17.3186885,21.4016216 L17.3737705,21.0340541 L16.9836066,20.8135135 Z M17.442623,20.8135135 L17.4885246,20.5102703 C17.4885246,20.422973 17.5252459,20.3218919 17.5436066,20.2345946 L17.5436066,20.2345946 L17.3554098,20.4275676 L17.1396721,20.6343243 L17.442623,20.8135135 Z"
              id="ambulance-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M15.9003279,20.5791892 L16.0655738,20.2162162 C16.1619672,20.0186486 16.2537705,19.8486486 16.3455738,19.6924324 L16.3455738,19.6924324 C16.2009252,19.8002591 16.0473648,19.8955584 15.8865574,19.9772973 L15.3586885,20.2713514 L15.0236066,20.0783784 L15.6340984,18.8654054 L15.9232787,19.0354054 L15.7442623,19.3891892 C15.6611333,19.5577829 15.5692031,19.7218834 15.4688525,19.8808108 L15.4688525,19.8808108 C15.6055722,19.7768594 15.7497914,19.6831801 15.9003279,19.6005405 L16.4144262,19.3248649 L16.7816393,19.5408108 L16.1757377,20.7583784 L15.9003279,20.5791892 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M13.9127869,19.0905405 C13.9833583,19.1671396 14.0654385,19.2322471 14.1560656,19.2835135 C14.4360656,19.4489189 14.6747541,19.3937838 14.7986885,19.1502703 C14.8590158,19.0315106 14.8675924,18.893066 14.8223852,18.7677559 C14.777178,18.6424458 14.6822121,18.5414277 14.56,18.4886486 C14.4701339,18.4366156 14.3724944,18.3993837 14.2708197,18.3783784 L14.3121311,18.1256757 C14.4445481,18.1496317 14.5709138,18.1996022 14.6839344,18.2727027 C14.8906068,18.3542661 15.0557575,18.5157011 15.1421211,18.7205802 C15.2284846,18.9254594 15.2287837,19.1565191 15.1429508,19.3616216 C14.9731148,19.7062162 14.5921311,19.8210811 14.1101639,19.5408108 C13.9852253,19.4774052 13.8706831,19.3952891 13.7704918,19.2972973 L13.9127869,19.0905405 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#FFFFFF"
              points="13.0727869 17.9648649 13.5868852 18.2681081 13.7108197 18.0154054 13.1691803 17.6937838 13.2793443 17.4686486 14.1377049 17.9740541 13.5318033 19.187027 12.6459016 18.6678378 12.7560656 18.4427027 13.3298361 18.7781081 13.4721311 18.4886486 12.962623 18.19"
            ></polygon>
            <path
              d="M23.5245902,37.06 C22.5744262,37.6297297 18.3560656,41.1951351 16.1803279,41.7464865 C15.2724924,41.8920977 14.3420715,41.7304104 13.5363934,41.287027 C10.6216393,39.5962162 1.60196721,34.6018919 1.60196721,34.6018919 L1.56065574,34.5743243 C1.4504918,34.7810811 1.10163934,35.4243243 1.03737705,35.5943243 C1.19173347,35.8005176 1.39112686,35.9686726 1.62032787,36.0859459 C2.40983607,36.5086486 12.9304918,42.477027 12.9304918,42.477027 C12.9304918,42.477027 14.4360656,43.3086486 15.482623,43.2351351 C16.5291803,43.1616216 17.5160656,42.1508108 17.5160656,42.1508108 L23.5245902,37.06 Z"
              id="ambulance-Path"
              fill="#D43D3D"
            ></path>
            <path
              d="M55.5134426,17.9924324 L55.4721311,18.0475676 L55.4721311,18.0475676 L54.6229508,19.2972973 C54.5173872,19.4580956 54.4542217,19.6430328 54.4393443,19.8348649 L54.4393443,21.8105405 C54.4393443,21.9345946 54.5081967,22.0127027 54.6091803,22.0127027 L54.6091803,22.0127027 C54.6510367,22.0122408 54.6920944,22.0011761 54.7285246,21.9805405 L55.522623,21.5210811 L55.5134426,17.9924324 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.4"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M54.6963934,21.952973 L55.5134426,21.4935135 L55.5134426,18.0659459 L55.5134426,18.0659459 L54.6688525,19.3110811 C54.5689112,19.4654478 54.508964,19.6423048 54.4944262,19.8256757 L54.4944262,21.8013514 C54.4760656,21.9667568 54.5770492,22.0218919 54.6963934,21.952973 Z"
              id="ambulance-Path"
              fill="#BD1E2D"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#F0F0F1"
              points="54.4760656 21.2867568 55.5134426 20.6848649 55.5134426 19.9818919 54.4760656 20.5837838"
            ></polygon>
            <path
              d="M0.940983607,37.4781081 C1.09873969,37.6246637 1.27277393,37.7526176 1.45967213,37.8594595 L2.78622951,38.6221622 C2.86440045,38.6625422 2.95074353,38.6845416 3.03868852,38.6864865 L3.03868852,38.6864865 C3.115904,38.6855521 3.18918063,38.6522124 3.24065574,38.5945946 C3.30468582,38.5074214 3.33113895,38.398197 3.31409836,38.2913514 L3.23606557,37.3172973 C3.1839222,37.038462 3.00604198,36.7993637 2.75409836,36.6694595 L1.94163934,36.21 C1.62765541,36.0482021 1.34480996,35.8319743 1.10622951,35.5713514 L1.07409836,35.5254054 C1.0065618,35.6826281 0.967697095,35.8506857 0.959344262,36.0216216 L0.940983607,37.4781081 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M3.21311475,38.3097297 L3.13967213,37.3402703 C3.08417321,37.0833455 2.91539538,36.8654119 2.68065574,36.7475676 L1.87737705,36.2972973 C1.55359385,36.130601 1.26275293,35.9064218 1.01901639,35.6356757 C0.971832561,35.7622978 0.945479442,35.8957422 0.940983607,36.0308108 L0.940983607,37.3586486 C1.10756535,37.528912 1.297701,37.6743591 1.50557377,37.7905405 L2.82754098,38.5532432 C2.82754098,38.5532432 3.25901639,38.7645946 3.21311475,38.3097297 Z"
              id="ambulance-Path"
              fill="#F0F0F1"
            ></path>
            <path
              d="M3.06622951,37.9421622 C3.06622951,38.2408108 2.85508197,38.3602703 2.60721311,38.2132432 C2.33525572,38.0292221 2.16531514,37.7281369 2.14819672,37.4 C2.14819672,37.1013514 2.35934426,36.9818919 2.60721311,37.1289189 C2.87917051,37.3129401 3.04911109,37.6140252 3.06622951,37.9421622 L3.06622951,37.9421622 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M1.98295082,37.1840541 C1.95526114,36.7642878 1.73048801,36.3824865 1.37704918,36.1548649 C1.23890836,36.0593921 1.05617361,36.0593921 0.918032787,36.1548649 L0.918032787,37.0462162 C1.02257135,37.2507036 1.18139207,37.4223955 1.37704918,37.5424324 C1.71672131,37.7124324 1.98295082,37.5608108 1.98295082,37.1840541 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M4.06688525,37.0967568 C3.82360656,36.9543243 3.66754098,37.0691892 3.72721311,37.3448649 L4.0852459,39.0540541 C4.16195122,39.3742517 4.35716348,39.6533956 4.63147541,39.8351351 L10.2498361,43.0513514 C10.5077391,43.1855398 10.8237846,43.126816 11.0163934,42.9089189 L11.7003279,42.0589189 C11.7870433,41.9618866 11.8236544,41.829959 11.7993591,41.7020608 C11.7750639,41.5741625 11.6926353,41.4648911 11.5763934,41.4064865 L4.06688525,37.0967568 Z"
              id="ambulance-Path"
              fill="#404B4D"
            ></path>
            <path
              d="M11.7416393,41.967027 L3.84655738,37.4045946 L3.84655738,37.6986486 L11.6039344,42.182973 L11.704918,42.0589189 C11.7197016,42.0440791 11.73209,42.0270286 11.7416393,42.0083784 L11.7416393,41.967027 Z"
              id="ambulance-Path"
              fill="#273234"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#273234"
              points="3.93836066 38.3878378 11.255082 42.6148649 11.4203279 42.4127027 3.8695082 38.0524324"
            ></polygon>
            <path
              d="M4.02098361,38.782973 L4.0852459,39.0540541 C4.08311048,39.0692948 4.08311048,39.0847593 4.0852459,39.1 L10.864918,43.0145946 C10.920876,42.9823381 10.9706207,42.9403255 11.0118033,42.8905405 L11.0577049,42.8354054 L4.02098361,38.782973 Z"
              id="ambulance-Path"
              fill="#273234"
            ></path>
            <path
              d="M11.8059016,41.7372973 C11.7963505,41.8229058 11.7629488,41.9041026 11.7095082,41.9716216 L11.0485246,42.7894595 C10.8632388,43.0036065 10.554527,43.0608306 10.304918,42.9272973 L4.88852459,39.8213514 C4.62584624,39.6532357 4.43581619,39.392512 4.35606557,39.0908108 L4.01180328,37.422973 C3.97967213,37.252973 4.01180328,37.1472973 4.12196721,37.1289189 L4.06688525,37.0967568 C3.82360656,36.9543243 3.66754098,37.0691892 3.72721311,37.3448649 L4.0852459,39.0540541 C4.16195122,39.3742517 4.35716348,39.6533956 4.63147541,39.8351351 L10.2498361,43.0513514 C10.5077391,43.1855398 10.8237846,43.126816 11.0163934,42.9089189 L11.7003279,42.0589189 C11.7751326,41.9690697 11.8128893,41.8540471 11.8059016,41.7372973 Z"
              id="ambulance-Path"
              fill="#5A6567"
            ></path>
            <path
              d="M17.1029508,42.692973 C17.0570492,42.6240541 16.9606557,42.6148649 16.8596721,42.692973 L16.2308197,43.0513514 C15.9483239,43.1605073 15.6477174,43.215074 15.344918,43.2121622 C14.4917316,43.1662749 13.6620749,42.9157194 12.9259016,42.4816216 C12.8305089,42.4281019 12.7231235,42.3996489 12.6137705,42.3989189 C12.4660715,42.4024968 12.3232046,42.4523075 12.2052459,42.5413514 L11.5672131,43.1891892 C11.488366,43.2602796 11.4461327,43.3634282 11.452459,43.4694595 C11.4823726,43.6203642 11.5857555,43.7462681 11.7278689,43.8048649 L13.0819672,44.5675676 C13.6909578,44.9629923 14.3910602,45.1955304 15.1154098,45.242973 L15.1154098,45.242973 C15.3575102,45.2456451 15.5977481,45.2003619 15.8222951,45.1097297 L16.1940984,44.8937838 C16.360287,44.7815229 16.4883747,44.6212587 16.5613115,44.4343243 L17.107541,42.9456757 C17.1477351,42.8658374 17.1460178,42.7712959 17.1029508,42.692973 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M11.7691803,43.7037838 L13.1462295,44.4894595 C13.7429508,44.8340541 14.8904918,45.4083784 15.8085246,45.0224324 L16.1665574,44.8156757 C16.3155798,44.7120608 16.4305852,44.5665665 16.4970492,44.3975676 L17.0386885,42.9089189 C17.0937705,42.7527027 17.0386885,42.6791892 16.9193443,42.7527027 L16.2904918,43.1156757 C15.1016393,43.5751351 13.6557377,42.9456757 12.8983607,42.5413514 C12.703261,42.4364878 12.4651098,42.4544112 12.2878689,42.5872973 L11.6452459,43.2305405 C11.6452459,43.2305405 11.3652459,43.4694595 11.7691803,43.7037838 Z"
              id="ambulance-Path"
              fill="#F0F0F1"
            ></path>
            <path
              d="M13.2334426,43.6486486 C13.2334426,43.9472973 13.0268852,44.0667568 12.7744262,43.9197297 C12.5013756,43.7367207 12.331098,43.4350384 12.3154098,43.1064865 C12.3154098,42.8078378 12.5265574,42.6883784 12.7744262,42.84 C13.0484451,43.0199778 13.2192566,43.3208961 13.2334426,43.6486486 L13.2334426,43.6486486 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M14.6885246,44.3791892 C14.6885246,44.7559459 14.4268852,44.9121622 14.1009836,44.7237838 C13.7482826,44.4967551 13.5262213,44.1137571 13.5042623,43.6945946 C13.5042623,43.3178378 13.7704918,43.1662162 14.1009836,43.3545946 C14.4489171,43.5827436 14.6671949,43.9633916 14.6885246,44.3791892 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M15.1934426,43.2810811 C15.147541,43.7727027 15.0786885,44.6043243 15.0740984,45.1510811 C15.3188226,45.1602365 15.5627491,45.1179777 15.7901639,45.027027 L16.1481967,44.8202703 C16.2972191,44.7166554 16.4122245,44.571161 16.4786885,44.4021622 L17.0203279,42.9135135 C17.0754098,42.7572973 17.0203279,42.6837838 16.9009836,42.7572973 L16.2721311,43.1202703 C15.9276402,43.2483269 15.560263,43.3030955 15.1934426,43.2810811 L15.1934426,43.2810811 Z"
              id="ambulance-Path"
              fill="#F5911E"
            ></path>
            <path
              d="M16.2767213,44.2872973 C16.2767213,44.517027 16.1160656,44.6089189 15.9186885,44.4940541 C15.7083006,44.3553822 15.5756005,44.1254873 15.5606557,43.8737838 C15.5606557,43.6440541 15.7213115,43.5521622 15.9186885,43.6624324 C16.1303296,43.8020528 16.2631965,44.0339417 16.2767213,44.2872973 L16.2767213,44.2872973 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              opacity="0.17"
            ></path>
            <path
              d="M21.4590164,39.5778378 C21.4533754,39.6555391 21.4128667,39.7264979 21.3488525,39.7708108 L21.0091803,39.9683784 C20.944918,40.0005405 20.8944262,39.9683784 20.8944262,39.9040541 L20.8944262,39.7754054 C20.8973871,39.695728 20.9406208,39.6230253 21.0091803,39.5824324 L21.3488525,39.3802703 C21.4085246,39.3481081 21.4590164,39.3802703 21.4590164,39.4445946 L21.4590164,39.5778378 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M21.4177049,39.5135135 C21.4134611,39.5904085 21.3724475,39.660541 21.307541,39.7018919 L20.9632787,39.9040541 C20.9036066,39.9362162 20.8531148,39.9040541 20.8531148,39.8397297 L20.8531148,39.7064865 C20.8587557,39.6287852 20.8992644,39.5578265 20.9632787,39.5135135 L21.307541,39.3159459 C21.3672131,39.2837838 21.4177049,39.3159459 21.4177049,39.3802703 L21.4177049,39.5135135 Z"
              id="ambulance-Path"
              fill="#F5911E"
            ></path>
            <path
              d="M8.04655738,32.492973 C7.99877276,32.6329203 8.05400794,32.7873467 8.17967213,32.8651351 L14.7206557,36.6418919 C14.8572119,36.7118663 15.0210998,36.7012827 15.147541,36.6143243 L15.3770492,36.4443243 C15.4299679,36.4165612 15.4631236,36.3616965 15.4631236,36.3018919 C15.4631236,36.2420873 15.4299679,36.1872226 15.3770492,36.1594595 L8.44131148,32.1621622 C8.38716536,32.1258685 8.31908408,32.1172789 8.257634,32.1389881 C8.19618393,32.1606973 8.14857172,32.2101593 8.12918033,32.2724324 L8.04655738,32.492973 Z"
              id="ambulance-Path"
              fill="#404B4D"
            ></path>
            <path
              d="M8.04655738,32.6491892 L8.12918033,32.4332432 C8.1490972,32.3713786 8.19634424,32.3221277 8.2572907,32.2996993 C8.31823715,32.2772708 8.38610393,32.2841597 8.44131148,32.3183784 L15.3586885,36.2972973 C15.3870483,36.3138478 15.4119783,36.3356827 15.4321311,36.3616216 C15.4556277,36.3234152 15.4610134,36.2767544 15.4468395,36.2341916 C15.4326655,36.1916287 15.4003875,36.1575347 15.3586885,36.1410811 L8.44131148,32.1621622 C8.38716536,32.1258685 8.31908408,32.1172789 8.257634,32.1389881 C8.19618393,32.1606973 8.14857172,32.2101593 8.12918033,32.2724324 L8.04655738,32.4883784 C8.02823374,32.5467001 8.02823374,32.6092459 8.04655738,32.6675676 C8.04655738,32.6675676 8.04196721,32.6537838 8.04655738,32.6491892 Z"
              id="ambulance-Path"
              fill="#5A6567"
            ></path>
            <path
              d="M29.0511475,34.5513514 C29.0455066,34.6290527 29.0049979,34.7000114 28.9409836,34.7443243 L28.1790164,35.1808108 C28.1193443,35.2175676 28.0688525,35.1808108 28.0688525,35.1164865 L28.0688525,34.3951351 C28.0744934,34.3174338 28.1150021,34.2464751 28.1790164,34.2021622 L28.9409836,33.7427027 C29.0006557,33.7059459 29.0511475,33.7427027 29.0511475,33.807027 L29.0511475,34.5513514 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M29.7121311,33.8437838 C29.7064902,33.9214851 29.6659815,33.9924438 29.6019672,34.0367568 L27.6603279,35.1578378 C27.6006557,35.19 27.5501639,35.1578378 27.5501639,35.0935135 L27.5501639,34.997027 C27.5544389,34.9189156 27.5952672,34.8473972 27.6603279,34.8040541 L29.6019672,33.682973 C29.6616393,33.6462162 29.7121311,33.682973 29.7121311,33.7472973 L29.7121311,33.8437838 Z"
              id="ambulance-Path"
              fill="#4B5459"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#FFFFFF"
              style={{ mixBlendMode: "overlay" }}
              points="12.8019672 41.5535135 17.6354098 36.9543243 12.572459 41.4202703 10.837377 43.3867568"
            ></polygon>
            <polygon
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
              points="6.7704918 30.7194595 3.31409836 36.1686486 3.84655738 39.3802703 3.47934426 36.2559459"
            ></polygon>
            <path
              d="M17.3554098,41.9991892 L20.1095082,39.7018919 L22.8636066,37.4045946 C23.7816393,36.6418919 24.6996721,35.8883784 25.6177049,35.1348649 L28.4590164,32.8927027 L28.4590164,32.8927027 C28.5730444,32.8196695 28.6791875,32.7349811 28.7757377,32.64 C28.874796,32.5441298 28.9668367,32.4412517 29.0511475,32.3321622 C29.2203785,32.1095711 29.3593,31.8654513 29.4642623,31.6062162 C29.5676773,31.3416487 29.6385798,31.0654791 29.6754098,30.7837838 C29.6977865,30.6452841 29.7131069,30.5057342 29.7213115,30.3656757 L29.7213115,29.9337838 L29.8360656,28.2154054 C29.877377,27.6410811 29.9186885,27.0667568 29.9645902,26.497027 L29.9645902,26.497027 C29.9645902,27.0713514 29.9645902,27.6456757 29.932459,28.22 C29.9003279,28.7943243 29.8957377,29.3686486 29.877377,29.942973 L29.877377,30.3748649 C29.877377,30.5172973 29.877377,30.6643243 29.8406557,30.8113514 C29.8019845,31.1025922 29.7279965,31.3880298 29.6203279,31.6613514 C29.5052667,31.9394372 29.3506637,32.199421 29.1613115,32.4332432 C29.073261,32.5519167 28.9749592,32.662613 28.867541,32.7640541 C28.7582119,32.8696322 28.638224,32.9635595 28.5095082,33.0443243 L28.5095082,33.0443243 L25.7186885,35.2956757 C24.8006557,36.0491892 23.882623,36.7981081 22.9232787,37.5424324 L20.1186885,39.7845946 L17.304918,42.0083784 L17.3554098,41.9991892 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M53.0209836,12.3594595 C53.0261937,12.3867804 53.0261937,12.4148412 53.0209836,12.4421622 L53.0209836,17.2710811 C53.007139,17.5050432 52.8826729,17.7183756 52.6859016,17.8454054 L40.04,25.16 C39.9923676,25.1913246 39.9363197,25.2073537 39.8793443,25.2059459 C39.9160656,25.352973 40.0491803,25.3989189 40.2052459,25.3116216 L52.8603279,17.997027 C53.057257,17.8680084 53.1815392,17.6532868 53.1954098,17.4181081 L53.1954098,12.5937838 C53.1954098,12.4467568 53.1219672,12.3640541 53.0209836,12.3594595 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              opacity="0.7"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M17.9016393,42.5505405 L17.864918,42.5505405 C17.926539,42.5897619 18.0052643,42.5897619 18.0668852,42.5505405 L53.0668852,22.3343243 C53.1430615,22.2869397 53.2037007,22.2182554 53.2413115,22.1367568 L17.9016393,42.5505405 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M17.9016393,42.5091892 C17.961632,42.3420344 18.0501594,42.1865732 18.1632787,42.0497297 C18.3942003,41.7622131 18.6836914,41.527264 19.012459,41.3605405 L53.1265574,21.6451351 C53.1739393,21.6140326 53.235241,21.6140326 53.282623,21.6451351 C53.255082,21.5532432 53.172459,21.5256757 53.0714754,21.5854054 L18.957377,41.3054054 C18.6304547,41.4749641 18.3415083,41.7094712 18.1081967,41.9945946 C17.9980122,42.1326627 17.9111784,42.2878728 17.8511475,42.4540541 C17.8236066,42.5321622 17.8511475,42.5827027 17.8970492,42.6056757 C17.8883572,42.5738358 17.8899641,42.5400583 17.9016393,42.5091892 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <g
              id="ambulance-Group"
              opacity="0.1"
              transform="translate(24.786885, 25.729730)"
              fill="#000000"
            >
              <polygon
                id="ambulance-Path"
                points="0.413114754 17.3262162 0.367213115 17.2481081 5.02163934 14.5556757 5.28327869 9.95648649 5.40262295 0.179189189 5.49442623 0.179189189 5.3704918 9.96108108 5.10885246 14.6108108 5.08590164 14.6245946"
              ></polygon>
            </g>
            <polygon
              id="ambulance-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
              points="55.5134426 22.292973 53.8380328 23.267027 53.8380328 10.4756757 53.7462295 10.4756757 53.7462295 27.222973 53.8104918 27.1862162 53.8380328 27.1862162 53.8380328 23.3681081 55.5134426 22.3986486"
            ></polygon>
            <path
              d="M31.0983607,30.2921622 L31.0983607,25.4678378 C31.1122312,25.2326591 31.2365135,25.0179375 31.4334426,24.8889189 L38.7042623,20.6756757 C38.7616814,20.6355572 38.8329664,20.6206226 38.9016393,20.6343243 C38.8210255,20.5607207 38.697663,20.5607207 38.6170492,20.6343243 L31.3462295,24.8337838 C31.1481944,24.9617507 31.0235566,25.1770867 31.0111475,25.4127027 L31.0111475,30.237027 C30.9983108,30.3362944 31.0553073,30.4313801 31.1488525,30.4667568 C31.1131124,30.415789 31.0953471,30.3543589 31.0983607,30.2921622 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.15"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M39.957377,25.1737838 L39.957377,20.3494595 C39.9651386,20.1099745 40.088306,19.8890865 40.2878689,19.7567568 L52.947541,12.4421622 C53.0046045,12.4053357 53.0737352,12.3921552 53.1403279,12.4054054 C53.0612335,12.3324249 52.9394222,12.3324249 52.8603279,12.4054054 L40.2006557,19.7154054 C40.0049255,19.8449216 39.8822687,20.0597781 39.8701639,20.2943243 L39.8701639,25.1186486 C39.8555233,25.2182849 39.9131496,25.3144214 40.0078689,25.3483784 C39.9714665,25.2977307 39.9536329,25.2360641 39.957377,25.1737838 L39.957377,25.1737838 Z"
              id="ambulance-Path"
              fill="#000000"
              opacity="0.15"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M38.9521311,20.8227027 C38.9521311,20.6802703 38.8832787,20.592973 38.7822951,20.5883784 C38.7847877,20.617424 38.7847877,20.6466301 38.7822951,20.6756757 L38.7822951,25.5 C38.7684245,25.7351787 38.6441423,25.9499003 38.4472131,26.0789189 L31.1855738,30.2783784 C31.1374864,30.3086967 31.0817487,30.3246371 31.024918,30.3243243 C31.0616393,30.4667568 31.1901639,30.5172973 31.3462295,30.43 L38.6170492,26.2259459 C38.8139783,26.0969273 38.9382606,25.8822057 38.9521311,25.647027 L38.9521311,20.8227027 Z"
              id="ambulance-Path"
              fill="#FFFFFF"
              opacity="0.7"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <g
              id="ambulance-Group"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
              transform="translate(39.016393, 18.837838)"
              fill="#000000"
            >
              <rect
                id="Rectangle"
                x="0.486557377"
                y="0.179189189"
                width="1"
                height="16.467027"
              ></rect>
            </g>
            <g
              id="ambulance-Group"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
              transform="translate(30.295082, 23.891892)"
              fill="#000000"
            >
              <polygon
                id="ambulance-Path"
                points="0.376923077 16.5083784 0.376923077 0.133243243 0.479230769 0.0735135135 0.479230769 16.5083784"
              ></polygon>
            </g>
            <g
              id="ambulance-Group"
              opacity="0.07"
              style={{ mixBlendMode: "multiply" }}
              transform="translate(24.786885, 9.648649)"
              fill="#000000"
            >
              <path
                d="M0.491147541,18.4013514 L0.417704918,18.3462162 C1.43143346,17.1327107 2.65509413,16.1117499 4.03016393,15.3321622 C6.50885246,13.8435135 29.8360656,0.142432432 30.0609836,0 L30.1068852,0.0781081081 C29.8727869,0.215945946 6.5547541,13.917027 4.08065574,15.4056757 C2.71421458,16.1804765 1.49831328,17.1952245 0.491147541,18.4013514 Z"
                id="ambulance-Path"
              ></path>
            </g>
            <polygon
              id="ambulance-Path"
              fill="#000000"
              opacity="0.3"
              style={{ mixBlendMode: "multiply" }}
              points="29.1659016 19.2697297 18.9665574 13.4943243 20.7337705 12.4743243 30.9331148 18.2451351"
            ></polygon>
            <path
              d="M23.5521311,11.8127027 C24.9613115,12.6259459 24.9613115,13.9445946 23.5521311,14.7578378 C21.952635,15.5714202 20.0611355,15.5714202 18.4616393,14.7578378 C17.0570492,13.9445946 17.0570492,12.6259459 18.4616393,11.8127027 C20.0611355,10.9991203 21.952635,10.9991203 23.5521311,11.8127027 L23.5521311,11.8127027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M31.3003279,16.4578378 C32.704918,17.2664865 32.704918,18.5851351 31.3003279,19.3983784 C29.699052,20.2112831 27.8065217,20.2112831 26.2052459,19.3983784 C24.8006557,18.5851351 24.8006557,17.2664865 26.2052459,16.4578378 C27.8065217,15.6449331 29.699052,15.6449331 31.3003279,16.4578378 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M30.4695082,16.9356757 C31.415082,17.4824324 31.415082,18.3691892 30.4695082,18.9205405 C29.3902455,19.4674779 28.1153283,19.4674779 27.0360656,18.9205405 C26.0904918,18.3691892 26.0904918,17.4824324 27.0360656,16.9356757 C28.1153283,16.3887383 29.3902455,16.3887383 30.4695082,16.9356757 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M31.2131148,16.83 L28.5508197,15.2862162 C28.3855738,15.1437838 27.9311475,15.2356757 27.3895082,15.5481081 C26.6963934,15.9386486 26.1639344,16.5405405 26.1639344,16.8575676 C26.1612323,16.9033794 26.1741823,16.9487483 26.2006557,16.9862162 L29.0603279,18.6402703 L29.0603279,18.6402703 C29.2531148,18.7459459 29.68,18.6402703 30.184918,18.3554054 C30.8596721,17.9648649 31.4059016,17.3767568 31.4059016,17.0413514 C31.4086059,16.9305715 31.3235767,16.8373545 31.2131148,16.83 Z"
              id="ambulance-Path"
              fill="#0F53B9"
            ></path>
            <path
              d="M28.532459,15.2862162 C28.3672131,15.1437838 27.9127869,15.2356757 27.3711475,15.5481081 C26.6963934,15.9386486 26.1639344,16.5405405 26.1639344,16.8575676 C26.1612323,16.9033794 26.1741823,16.9487483 26.2006557,16.9862162 L29.0603279,18.6402703 L31.3554098,16.9172973 L28.532459,15.2862162 Z"
              id="ambulance-Path"
              fill="#0F53B9"
            ></path>
            <path
              d="M26.1868852,17 C26.1604118,16.9625321 26.1474618,16.9171632 26.1501639,16.8713514 C26.1501639,16.5359459 26.6963934,15.9524324 27.3711475,15.5618919 C27.6668327,15.3570311 28.0168189,15.2450554 28.3763934,15.2402703 L24.2452459,12.8694595 C24.08,12.727027 23.6255738,12.8189189 23.0885246,13.1313514 C22.4091803,13.5218919 21.8629508,14.11 21.8629508,14.4408108 C21.8612759,14.4865036 21.8741337,14.5315494 21.8996721,14.5694595 L26.1914754,17 L26.1868852,17 Z"
              id="ambulance-Path"
              fill="url(#ambulance-linearGradient-3)"
            ></path>
            <path
              d="M31.3921311,17.0413514 C31.3921311,17.3767568 30.8459016,17.9602703 30.1711475,18.3554054 C29.4963934,18.7505405 28.9455738,18.7918919 28.9455738,18.4610811 C28.9455738,18.1302703 29.4918033,17.5421622 30.1711475,17.147027 C30.8504918,16.7518919 31.3921311,16.7105405 31.3921311,17.0413514 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.904918,16.3797297 C29.9023875,16.7115355 29.632061,16.9787047 29.3005698,16.9770272 C28.9690786,16.9753338 28.7014775,16.7054297 28.7023126,16.3736153 C28.7031516,16.0418009 28.9721111,15.7732432 29.3036066,15.7732432 C29.4638828,15.7732432 29.6175285,15.8372823 29.7304291,15.9511546 C29.8433298,16.0650269 29.9061415,16.2193035 29.904918,16.3797297 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.8360656,16.3797297 C29.8360656,16.6690075 29.6017857,16.9035135 29.3127869,16.9035135 C29.023788,16.9035135 28.7895082,16.6690075 28.7895082,16.3797297 C28.7895082,16.0904519 29.023788,15.8559459 29.3127869,15.8559459 C29.6017857,15.8559459 29.8360656,16.0904519 29.8360656,16.3797297 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.7488525,16.3797297 C29.6976722,16.5841384 29.5141339,16.7274923 29.3036066,16.7274923 C29.0930792,16.7274923 28.9095409,16.5841384 28.8583607,16.3797297 C28.811891,16.1941345 28.8851915,15.9991408 29.0423641,15.8902452 C29.1995366,15.7813495 29.4076765,15.7813495 29.564849,15.8902452 C29.7220216,15.9991408 29.7953221,16.1941345 29.7488525,16.3797297 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.6295082,16.3797297 C29.6295082,16.559894 29.4835971,16.7059459 29.3036066,16.7059459 C29.1236161,16.7059459 28.9777049,16.559894 28.9777049,16.3797297 C28.9777049,16.1995655 29.1236161,16.0535135 29.3036066,16.0535135 C29.4835971,16.0535135 29.6295082,16.1995655 29.6295082,16.3797297 L29.6295082,16.3797297 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.5331148,16.3797297 C29.5331148,16.506606 29.4303604,16.6094595 29.3036066,16.6094595 C29.2419458,16.6106941 29.1823833,16.5870367 29.1383443,16.5438194 C29.0943052,16.5006022 29.0694959,16.4414623 29.0695082,16.3797297 C29.0695082,16.250316 29.1743176,16.1454054 29.3036066,16.1454054 C29.3652796,16.1453931 29.4243625,16.1702263 29.467538,16.2143079 C29.5107136,16.2583895 29.5343482,16.3180095 29.5331148,16.3797297 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.597377,17.4824324 C29.597377,17.7361849 29.3918684,17.9418919 29.1383607,17.9418919 C28.8848529,17.9418919 28.6793443,17.7361849 28.6793443,17.4824324 C28.6793443,17.22868 28.8848529,17.022973 29.1383607,17.022973 C29.3918684,17.022973 29.597377,17.22868 29.597377,17.4824324 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.4780328,17.4824324 C29.4780328,17.6625967 29.3321217,17.8086486 29.1521311,17.8086486 C28.9721406,17.8086486 28.8262295,17.6625967 28.8262295,17.4824324 C28.8262295,17.3022682 28.9721406,17.1562162 29.1521311,17.1562162 C29.2389426,17.1549657 29.3225648,17.1889331 29.3839561,17.2503837 C29.4453474,17.3118343 29.4792821,17.3955372 29.4780328,17.4824324 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.3770492,17.4824324 C29.3745488,17.6100484 29.2704682,17.7121867 29.1429508,17.7121622 C29.0336314,17.690168 28.954983,17.5940459 28.954983,17.4824324 C28.954983,17.3708189 29.0336314,17.2746969 29.1429508,17.2527027 C29.2704682,17.2526782 29.3745488,17.3548165 29.3770492,17.4824324 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.7213115,12.2951351 C23.6714754,12.8418919 23.6714754,13.7286486 22.7213115,14.2754054 C21.6436496,14.8222736 20.3701209,14.8222736 19.292459,14.2754054 C18.3606557,13.7286486 18.3606557,12.8648649 19.2786885,12.2951351 C20.3600862,11.7435965 21.6399138,11.7435965 22.7213115,12.2951351 L22.7213115,12.2951351 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M21.5095082,12.6167568 C21.5044479,13.2346912 21.0007882,13.7320019 20.3834381,13.728649 C19.766088,13.7252621 19.267877,13.2224825 19.2695457,12.6045296 C19.2712229,11.9865766 19.7721489,11.4865072 20.3895082,11.4864767 C20.6885297,11.4852319 20.9755328,11.6042087 21.18611,11.8167169 C21.3966872,12.029225 21.5132025,12.3174669 21.5095082,12.6167568 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M21.4222951,11.242973 C21.2570492,11.1005405 20.802623,11.197027 20.2609836,11.5048649 C19.5862295,11.8954054 19.04,12.4835135 19.04,12.8143243 C19.0383251,12.8600171 19.0511829,12.9050629 19.0767213,12.942973 L21.9363934,14.6016216 L24.2314754,12.8740541 L21.4222951,11.242973 Z"
              id="ambulance-Path"
              fill="#0F53B9"
            ></path>
            <path
              d="M24.2819672,13.0027027 C24.2819672,13.3381081 23.7357377,13.9216216 23.0609836,14.3121622 C22.3862295,14.7027027 21.8354098,14.7716216 21.8354098,14.4178378 C21.8354098,14.0640541 22.3816393,13.4989189 23.0609836,13.1083784 C23.7403279,12.7178378 24.2819672,12.6718919 24.2819672,13.0027027 Z"
              id="ambulance-Path"
              fill="#0F53B9"
            ></path>
            <path
              d="M21.2432912,12.6167568 C21.2432912,13.0887363 20.8610326,13.4713514 20.3895082,13.4713514 C19.9179838,13.4713514 19.5357377,13.0887363 19.5357377,12.6167568 C19.5357377,12.1447772 19.9179838,11.7621497 20.3895082,11.7621497 C20.6163168,11.7609316 20.8341917,11.8505738 20.994572,12.0111089 C21.1549523,12.171644 21.244508,12.3897292 21.2432912,12.6167568 L21.2432912,12.6167568 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M21.1147541,12.6167568 C21.1147541,13.0278357 20.7818301,13.3610811 20.3711475,13.3610811 C19.960465,13.3610811 19.627541,13.0278357 19.627541,12.6167568 C19.627541,12.2056778 19.960465,11.8724324 20.3711475,11.8724324 C20.7818301,11.8724324 21.1147541,12.2056778 21.1147541,12.6167568 L21.1147541,12.6167568 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M21.0229508,12.6167568 C21.0229508,12.8732077 20.8686175,13.1044068 20.6319162,13.2025463 C20.3952149,13.3006858 20.1227599,13.2464389 19.9415966,13.0651007 C19.7604333,12.8837625 19.7062386,12.6110445 19.8042835,12.3741148 C19.9023284,12.137185 20.1333046,11.9827027 20.3895082,11.9827027 C20.7393489,11.9827027 21.0229508,12.2665784 21.0229508,12.6167568 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M20.8485246,12.6167568 C20.8485246,12.8705092 20.643016,13.0762162 20.3895082,13.0762162 C20.1360004,13.0762162 19.9304918,12.8705092 19.9304918,12.6167568 C19.9304918,12.3630043 20.1360004,12.1572973 20.3895082,12.1572973 C20.643016,12.1572973 20.8485246,12.3630043 20.8485246,12.6167568 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M20.72,12.6167568 C20.72,12.7994585 20.5720338,12.9475676 20.3895082,12.9475676 C20.2069826,12.9475676 20.0590164,12.7994585 20.0590164,12.6167568 C20.0590164,12.434055 20.2069826,12.2859459 20.3895082,12.2859459 C20.477537,12.2846959 20.5623264,12.3191475 20.6245784,12.3814596 C20.6868304,12.4437717 20.7212488,12.528643 20.72,12.6167568 L20.72,12.6167568 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.6019672,12.2537838 C22.6056901,12.5686527 22.4190664,12.8545959 22.1295078,12.9776819 C21.8399492,13.100768 21.5047961,13.0366225 21.2810292,12.8152903 C21.0572622,12.593958 20.9891934,12.2592688 21.108705,11.967986 C21.2282166,11.6767031 21.5116422,11.4865085 21.8262295,11.4864865 C22.2510979,11.4864567 22.5969392,11.828535 22.6019672,12.2537838 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.427541,12.2537838 C22.4250105,12.5855896 22.154684,12.8527587 21.8231927,12.8510812 C21.4917015,12.8493879 21.2241004,12.5794838 21.2249355,12.2476693 C21.2257745,11.9158549 21.494734,11.6472973 21.8262295,11.6472973 C21.9865057,11.6472973 22.1401514,11.7113364 22.2530521,11.8252087 C22.3659527,11.939081 22.4287645,12.0933575 22.427541,12.2537838 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.3495082,12.2537838 C22.3495082,12.5430616 22.1152283,12.7775676 21.8262295,12.7775676 C21.5372307,12.7775676 21.3029508,12.5430616 21.3029508,12.2537838 C21.3029508,11.964506 21.5372307,11.73 21.8262295,11.73 C22.1152283,11.73 22.3495082,11.964506 22.3495082,12.2537838 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.2714754,12.2537838 C22.2714754,12.5075362 22.0659668,12.7132432 21.812459,12.7132432 C21.5589513,12.7132432 21.3534426,12.5075362 21.3534426,12.2537838 C21.3534426,12.0000313 21.5589513,11.7943243 21.812459,11.7943243 C22.0659668,11.7943243 22.2714754,12.0000313 22.2714754,12.2537838 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.1521311,12.2537838 C22.1521311,12.433948 22.00622,12.58 21.8262295,12.58 C21.646239,12.58 21.5003279,12.433948 21.5003279,12.2537838 C21.5003279,12.0736195 21.646239,11.9275676 21.8262295,11.9275676 C22.00622,11.9275676 22.1521311,12.0736195 22.1521311,12.2537838 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.0557377,12.2537838 C22.0557377,12.38066 21.9529834,12.4835135 21.8262295,12.4835135 C21.7645688,12.4847482 21.7050063,12.4610907 21.6609672,12.4178735 C21.6169281,12.3746563 21.5921188,12.3155164 21.5921311,12.2537838 C21.5921311,12.12437 21.6969406,12.0194595 21.8262295,12.0194595 C21.8879026,12.0194471 21.9469854,12.0442804 21.990161,12.088362 C22.0333365,12.1324435 22.0569712,12.1920635 22.0557377,12.2537838 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.5147667,13.3518919 C22.5166129,13.6923801 22.3131362,14.0003775 21.9994015,14.1319679 C21.6856667,14.2635584 21.3236175,14.1927607 21.0824265,13.9526562 C20.8412355,13.7125516 20.7685233,13.3505463 20.8982658,13.0357923 C21.0280083,12.7210384 21.3345892,12.5156757 21.6747541,12.5156757 C22.1368866,12.5156757 22.5122288,12.8893202 22.5147667,13.3518919 L22.5147667,13.3518919 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.2806557,13.3518919 C22.2825154,13.5976023 22.1360772,13.8201555 21.9098209,13.9154775 C21.6835645,14.0107995 21.4222061,13.9600513 21.2479665,13.7869647 C21.0737268,13.6138781 21.0210415,13.3526609 21.1145483,13.1254679 C21.208055,12.8982748 21.4292736,12.75 21.6747541,12.75 C22.0075999,12.75 22.2781342,13.0187343 22.2806557,13.3518919 L22.2806557,13.3518919 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.1980328,13.3518919 C22.1980328,13.6411697 21.9637529,13.8756757 21.6747541,13.8756757 C21.3857553,13.8756757 21.1514754,13.6411697 21.1514754,13.3518919 C21.1514754,13.0626141 21.3857553,12.8281081 21.6747541,12.8281081 C21.9637529,12.8281081 22.1980328,13.0626141 22.1980328,13.3518919 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.12,13.3518919 C22.12,13.6056443 21.9144914,13.8113514 21.6609836,13.8113514 C21.4074759,13.8113514 21.2019672,13.6056443 21.2019672,13.3518919 C21.2019672,13.0981394 21.4074759,12.8924324 21.6609836,12.8924324 C21.9144914,12.8924324 22.12,13.0981394 22.12,13.3518919 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M22.0006557,13.3518919 C22.0025181,13.4842362 21.9243064,13.6045893 21.8026847,13.6565323 C21.6810629,13.7084752 21.5401406,13.681711 21.4459793,13.5887863 C21.351818,13.4958616 21.3230835,13.3551971 21.3732461,13.2327334 C21.4234087,13.1102698 21.5425243,13.0302834 21.6747541,13.0302703 C21.8529672,13.0302526 21.9981457,13.1735245 22.0006557,13.3518919 L22.0006557,13.3518919 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M21.9088525,13.3518919 C21.9088525,13.4813056 21.8040431,13.5862162 21.6747541,13.5862162 C21.5654346,13.5642221 21.4867863,13.4681 21.4867863,13.3564865 C21.4867863,13.244873 21.5654346,13.1487509 21.6747541,13.1267567 C21.8005411,13.1266599 21.9039197,13.2260802 21.9088525,13.3518919 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M28.9868947,16.7427027 C28.9868947,17.1961377 28.714006,17.6049245 28.2954907,17.7784466 C27.8769754,17.9519686 27.3952434,17.8560537 27.0749257,17.5354267 C26.7546079,17.2147998 26.6587854,16.7326028 26.8321402,16.3136835 C27.0054949,15.8947643 27.4138875,15.6216121 27.8668852,15.6216121 C28.164302,15.620394 28.4498916,15.7381133 28.6601988,15.9486234 C28.870506,16.1591336 28.9881117,16.4449989 28.9868947,16.7427027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M28.7206682,16.7427027 C28.7206682,17.2146823 28.3384097,17.5972973 27.8668852,17.5972973 C27.3953608,17.5972973 27.0131148,17.2146823 27.0131148,16.7427027 C27.0131148,16.2707231 27.3953608,15.8880956 27.8668852,15.8880956 C28.0936939,15.8868776 28.3115688,15.9765198 28.4719491,16.1370549 C28.6323293,16.29759 28.7218851,16.5156751 28.7206682,16.7427027 L28.7206682,16.7427027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M28.6104918,16.7427027 C28.6104918,17.1537817 28.2775678,17.487027 27.8668852,17.487027 C27.4562027,17.487027 27.1232787,17.1537817 27.1232787,16.7427027 C27.1232787,16.3316237 27.4562027,15.9983784 27.8668852,15.9983784 C28.2775678,15.9983784 28.6104918,16.3316237 28.6104918,16.7427027 L28.6104918,16.7427027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M28.5003279,16.7427027 C28.5003279,17.0928811 28.2167259,17.3767568 27.8668852,17.3767568 C27.5170445,17.3767568 27.2334426,17.0928811 27.2334426,16.7427027 C27.2334426,16.3925243 27.5170445,16.1086486 27.8668852,16.1086486 C28.2167259,16.1086486 28.5003279,16.3925243 28.5003279,16.7427027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M28.3259016,16.7427027 C28.3259016,16.9964552 28.120393,17.2021622 27.8668852,17.2021622 C27.6133775,17.2021622 27.4078689,16.9964552 27.4078689,16.7427027 C27.4078689,16.4889503 27.6133775,16.2832432 27.8668852,16.2832432 C28.120393,16.2832432 28.3259016,16.4889503 28.3259016,16.7427027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M28.197377,16.7427027 C28.1948667,16.9236077 28.0476332,17.0689364 27.8668852,17.0689189 C27.7005627,17.0491958 27.5752746,16.9080527 27.5752746,16.7404054 C27.5752746,16.5727581 27.7005627,16.431615 27.8668852,16.4118919 C28.0494108,16.4118919 28.197377,16.5600009 28.197377,16.7427027 Z"
              id="ambulance-Path"
              fill="#5099DA"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M30.0793443,16.3797297 C30.0868095,16.6953543 29.9026137,16.9840809 29.6134117,17.1100783 C29.3242096,17.2360756 28.9875849,17.1742561 28.7619026,16.9537027 C28.5362202,16.7331492 28.4664163,16.3977768 28.58533,16.1053611 C28.7042438,15.8129454 28.988198,15.6217101 29.3036066,15.6216216 C29.7249656,15.6215035 30.0693713,15.9580821 30.0793443,16.3797297 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.9921311,17.4824324 C29.9921311,17.9467994 29.6160503,18.3232432 29.1521311,18.3232432 C28.688212,18.3232432 28.3121311,17.9467994 28.3121311,17.4824324 C28.3121311,17.0180654 28.688212,16.6416216 29.1521311,16.6416216 C29.6160503,16.6416216 29.9921311,17.0180654 29.9921311,17.4824324 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.7580328,17.4824324 C29.7580328,17.8173857 29.4867614,18.0889189 29.1521311,18.0889189 C28.8175009,18.0889189 28.5462295,17.8173857 28.5462295,17.4824324 C28.5462295,17.1474792 28.8175009,16.8759459 29.1521311,16.8759459 C29.4867614,16.8759459 29.7580328,17.1474792 29.7580328,17.4824324 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M29.6754098,17.4824324 C29.67288,17.7710986 29.4374918,18.0032972 29.1490979,18.0016218 C28.8607039,17.9999282 28.6280389,17.7649958 28.6288725,17.4763197 C28.6297107,17.1876437 28.8637323,16.9540652 29.1521311,16.9540541 C29.4418709,16.9565761 29.6754208,17.192402 29.6754098,17.4824324 Z"
              id="ambulance-Path"
              fill="#5099DA"
              opacity="0.3"
              style={{ mixBlendMode: "overlay" }}
            ></path>
            <path
              d="M24.1259016,34.5697297 L23.4878689,34.1975676 C23.3318033,34.1102703 23.2078689,34.1975676 23.2078689,34.362973 L23.2078689,36.5132432 C23.2244861,36.7017322 23.3280403,36.871657 23.4878689,36.9727027 L24.1259016,37.3448649 C24.2819672,37.4321622 24.4059016,37.3448649 24.4059016,37.1840541 L24.4059016,35.0521622 C24.3948964,34.8558196 24.2908508,34.6765519 24.1259016,34.5697297 Z"
              id="ambulance-Path"
              fill="#DFE6EA"
            ></path>
            <path
              d="M24.1259016,34.5697297 L23.4878689,34.1975676 C23.3318033,34.1102703 23.2078689,34.1975676 23.2078689,34.362973 L23.2078689,36.5132432 C23.2244861,36.7017322 23.3280403,36.871657 23.4878689,36.9727027 L24.1259016,37.3448649 C24.2819672,37.4321622 24.4059016,37.3448649 24.4059016,37.1840541 L24.4059016,35.0521622 C24.3948964,34.8558196 24.2908508,34.6765519 24.1259016,34.5697297 Z"
              id="ambulance-Path"
              fill="#DFE6EA"
            ></path>
            <path
              d="M24.1259016,34.5697297 L23.4878689,34.1975676 C23.3731148,34.1332432 23.2767213,34.1562162 23.2308197,34.2435135 C23.2676973,34.2502304 23.3033116,34.26263 23.3363934,34.2802703 L23.9744262,34.6524324 C24.1327356,34.7549671 24.2357911,34.9240737 24.2544262,35.1118919 L24.2544262,37.2667568 C24.2548568,37.3047447 24.2470254,37.3423716 24.2314754,37.377027 C24.332459,37.377027 24.4059016,37.3172973 24.4059016,37.1840541 L24.4059016,35.0521622 C24.3948964,34.8558196 24.2908508,34.6765519 24.1259016,34.5697297 Z"
              id="ambulance-Path"
              fill="#D2D8DD"
            ></path>
            <polygon
              id="ambulance-Path"
              fill="#303E42"
              points="22.5468852 35.0521622 22.5468852 35.6218919 23.2078689 35.8240541 23.2078689 35.4335135"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationAmbulance.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationAmbulance.defaultProps = {
  size: 70,
};

export { IllustrationAmbulance as default } from "./Ambulance";
