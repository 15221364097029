export const Direction = ({ color }) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
      fill={color}
      fillRule="evenodd"
    >
      <defs>
        <style></style>
      </defs>
      <g id="b">
        <g id="c">
          <path
            id="d"
            class="e"
            d="M12.09,0c-1.53,2.22-2.3,4.89-2.3,8s.77,5.78,2.3,8L.48,8.85c-.47-.29-.62-.91-.33-1.38,.08-.13,.19-.25,.33-.33L12.09,0h0Z"
          />
        </g>
      </g>
    </svg>
  );
};
