import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../../utils/Utils";
import { publicTransportSchema } from "./publicTransportNormalization";
import PublicTransportsStatusRepository from "./publicTransportsStatusRepository";

//#region Type
export const statusStopType = {
  STOPPED: "STOPPED",
  NOT_STOPPED: "NOT_STOPPED",
  STOP_INFO_NOT_AVAILABLE: "STOP_INFO_NOT_AVAILABLE",
  IN_PROCESSING: "IN_PROCESSING",
};
export type StatusStopType = keyof typeof statusStopType;

export const publicStopETAStatusType = {
  LATE: "LATE",
  ON_TIME: "ON_TIME",
  IN_ADVANCE: "IN_ADVANCE",
};
export type PublicStopETAStatusType = keyof typeof publicStopETAStatusType;

export interface PublicTimeEstimate {
  time: number;
  delay: number;
}

export interface PublicStopETA {
  stopSequence: number;
  stopId: number;
  tripIndex: number;
  publicStopCode: string;
  publicStopName: string;
  staticTime: string;
  estimatedTime: string;
  publicStopETAStatus?: PublicStopETAStatusType;
  arrival: PublicTimeEstimate;
  departure?: PublicTimeEstimate;
}

export interface PublicStopStatus {
  publicStopId: number;
  publicStopIndex: number;
  numberOfTrips: number;
  tripId: number;
  publicRouteId: number;
  latitude: number;
  longitude: number;
  name: string;
  code: string;
  speed: number;
  status: boolean;
  currentlyInStop: StatusStopType;
  lowSpeedFirstUpdateWhenInStop: string;
  staticTime: string;
  realTime: string;
  publicStopETAStatus?: string;
}

export interface PublicTransportStatus {
  id: number;
  lastUpdate: Date;
  startDate: Date;
  delay: number;
  localTimeWhenInGeofenceAndSpeedBelowThreshold: string;
  publicStopETAs: PublicStopETA[];
  prevPublicStops: PublicStopStatus[];
  currPublicStop: PublicStopStatus;
  nextPublicStop?: PublicStopStatus;
  routeName: string;
  stopCode: string;
  tripCode: string;
  dynamicFields?: {
    customerId: number;
  };
}
//#endregion Type

//#region API
export const getPublicTransportAsync = createAsyncThunk(
  "publicTransports/getPublicTransport",
  async (data: { id: number; fleetId: number }, { rejectWithValue }) => {
    try {
      const publicTransportsStatusRepository =
        new PublicTransportsStatusRepository();
      const response =
        await publicTransportsStatusRepository.getPublicTransport(
          data.fleetId,
          data.id
        );
      const publicTransport = _.get(
        response,
        Config.PUBLIC_TRANSPORT_STATUS_RESPONSE_PATH
      );
      const normalizedData = normalize(publicTransport, publicTransportSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Slice
const publicTransportStatusAdapter = createEntityAdapter<PublicTransportStatus>(
  {
    selectId: (publicTransport) => publicTransport.id,
  }
);

export const publicTransportStatusSlice = createSlice({
  name: "publicTransportStatus",
  initialState: publicTransportStatusAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    publicTransportStatusEmptyState: (state: any) => {
      publicTransportStatusAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    upsertPublicTransportStatus: (
      state,
      action: PayloadAction<PublicTransportStatus>
    ) => {
      action.payload.id &&
        publicTransportStatusAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getPublicTransportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicTransportStatusAdapter.upsertMany(
            state,
            action.payload.publicTransport
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getPublicTransportAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getPublicTransportAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});
//#endregion Slice

//#region Status
export const publicTransportStatusSelectors =
  publicTransportStatusAdapter.getSelectors<RootState>(
    (state) => state.publicTransportStatus
  );

export const { upsertPublicTransportStatus } =
  publicTransportStatusSlice.actions;

//#endregion Status

export default publicTransportStatusSlice.reducer;
