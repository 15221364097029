import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { Partner, partnersSelectors } from "../features/tenants/partnerSlice";
import { Tenant, tenantsSelectors } from "../features/tenants/tenantsSlice";
import { IconActivity } from "../ui/Icon/Line/Activity";
import { IconAddView } from "../ui/Icon/Line/AddView";
import { IconAlerts } from "../ui/Icon/Line/Alerts";
import { IconAutomations } from "../ui/Icon/Line/Automations";
import { IconBuild } from "../ui/Icon/Line/Build";
import { IconDashboard } from "../ui/Icon/Line/Dashboard";
import { IconDevices } from "../ui/Icon/Line/Devices";
import { IconDriverBehaviour } from "../ui/Icon/Line/DriverBehaviour";
import { IconExchange } from "../ui/Icon/Line/Exchange";
import { IconGeofence } from "../ui/Icon/Line/Geofence";
import { IconGroup } from "../ui/Icon/Line/Group";
import { IconLicense } from "../ui/Icon/Line/License";
import { IconLocationHistory } from "../ui/Icon/Line/LocationHistory";
import { IconLogs } from "../ui/Icon/Line/Logs";
import { IconMaintenanceDeadlines } from "../ui/Icon/Line/MaintenanceDeadlines";
import { IconMap } from "../ui/Icon/Line/Map";
import { IconMenuRoutes } from "../ui/Icon/Line/MenuRoutes";
import { IconMessages } from "../ui/Icon/Line/Messages";
import { IconMissions } from "../ui/Icon/Line/Missions";
import { IconNotification } from "../ui/Icon/Line/Notification";
import { IconPermissions } from "../ui/Icon/Line/Permissions";
import { IconTimer } from "../ui/Icon/Line/Timer";
import { IconVehicle } from "../ui/Icon/Line/Vehicle";
import { IconSettings } from "../ui/Icon/Solid/Settings";
import { EmptySpace } from "../ui/Navigation/EmptySpace";
import { Menu } from "../ui/Navigation/Menu";
import { MenuItem } from "../ui/Navigation/MenuItem";
import "./Sidebar.css";

interface MenuItemProps {
  loading: boolean;
  active: boolean;
  enabled: boolean;
}
interface SidebarProps {
  dashboardFleetControl: MenuItemProps;
  dashboardOverview: MenuItemProps;
  dashboardVehicle: MenuItemProps;
  dashboardMessages: MenuItemProps;
  dashboardLocationHistoryVehicle: MenuItemProps;
  dashboardLocationHistoryDriver: MenuItemProps;
  dashboardDriver: MenuItemProps;
  dashboardNotification: MenuItemProps;
  dashboardLogs: MenuItemProps;
  dashboardTachograph: MenuItemProps;
  dashboardMaintenance: MenuItemProps;
  dashboardActivities: MenuItemProps;
  adminVehicle: MenuItemProps;
  adminDriver: MenuItemProps;
  adminUser: MenuItemProps;
  adminGeofences: MenuItemProps;
  adminFleets: MenuItemProps;
  adminNotification: MenuItemProps;
  adminPublicRoutePlanning: MenuItemProps;
  adminPublicRoutes: MenuItemProps;
  adminPublicRoutesStop: MenuItemProps;
  expandedUpdated: (e: any) => any;
}

function Sidebar({
  dashboardFleetControl,
  dashboardOverview,
  dashboardVehicle,
  dashboardMessages,
  dashboardLocationHistoryVehicle,
  dashboardLocationHistoryDriver,
  dashboardDriver,
  dashboardNotification,
  dashboardLogs,
  dashboardTachograph,
  dashboardMaintenance,
  dashboardActivities,
  adminVehicle,
  adminDriver,
  adminUser,
  adminGeofences,
  adminFleets,
  adminNotification,
  adminPublicRoutePlanning,
  adminPublicRoutes,
  adminPublicRoutesStop,
  expandedUpdated,
}: Readonly<SidebarProps>) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);

  let tenant: Tenant =
    useAppSelector((state) =>
      tenantsSelectors.selectById(state, localStorage.getItem("tenantId") ?? -1)
    ) ?? ({} as Tenant);

  let partner: Partner =
    useAppSelector((state) =>
      partnersSelectors.selectById(state, tenant?.partner ?? -1)
    ) ?? ({} as Partner);

  useEffect(() => {
    if (expandedUpdated) {
      expandedUpdated(expanded);
    }
  }, [expanded]);

  return (
    <Routes>
      <Route
        path="/dashboard/*"
        element={
          <div className="sidebar">
            <Menu
              expanded={expanded}
              expandedUpdated={setExpanded}
              partnerLogoPositive={!!partner ? partner.partnerLogoPositive : ""}
              partnerLogoNegative={!!partner ? partner.partnerLogoNegative : ""}
            >
              {dashboardOverview.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.overview")}
                  icon={
                    <IconDashboard size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/overview");
                  }}
                  isActive={dashboardOverview.active}
                  isLoading={dashboardOverview.loading}
                />
              ) : null}
              {dashboardFleetControl.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.fleetControl")}
                  icon={<IconMap size={14} color="--global-colors-ui-white" />}
                  onClick={() => {
                    navigate("/dashboard/fleet-control");
                  }}
                  isActive={dashboardFleetControl.active}
                  isLoading={dashboardFleetControl.loading}
                />
              ) : null}
              <EmptySpace />
              {dashboardVehicle.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.vehicles")}
                  icon={
                    <IconVehicle size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/vehicles");
                  }}
                  isActive={dashboardVehicle.active}
                  isLoading={dashboardVehicle.loading}
                />
              ) : null}
              {process.env.REACT_APP_DEPLOY_TARGET === "local" ||
              process.env.REACT_APP_DEPLOY_TARGET === "stage" ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.diagnosisReport")}
                  icon={
                    <IconBuild size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/diagnosis-report");
                  }}
                  isActive={false} //todo
                />
              ) : null}
              {process.env.REACT_APP_DEPLOY_TARGET === "local" ||
              process.env.REACT_APP_DEPLOY_TARGET === "stage" ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.utilization")}
                  icon={
                    <IconTimer size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/utilization");
                  }}
                  isActive={false} //todo
                />
              ) : null}
              {dashboardMaintenance.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.maintenance")}
                  icon={
                    <IconMaintenanceDeadlines
                      size={14}
                      color="--global-colors-ui-white"
                    />
                  }
                  onClick={() => {
                    navigate("/dashboard/maintenance");
                  }}
                  isActive={dashboardMaintenance.active}
                  isLoading={dashboardMaintenance.loading}
                />
              ) : null}
              {dashboardLocationHistoryVehicle.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.locationHistoryVehicle")}
                  icon={
                    <IconLocationHistory
                      size={14}
                      color="--global-colors-ui-white"
                    />
                  }
                  onClick={() => {
                    navigate("/dashboard/vehicles/location-history");
                  }}
                  isActive={dashboardLocationHistoryVehicle.active}
                  isLoading={dashboardLocationHistoryVehicle.loading}
                />
              ) : null}
              <EmptySpace />
              {dashboardDriver.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.drivers")}
                  icon={
                    <IconDriverBehaviour
                      size={14}
                      color="--global-colors-ui-white"
                    />
                  }
                  onClick={() => {
                    navigate("/dashboard/drivers");
                  }}
                  isActive={dashboardDriver.active}
                  isLoading={dashboardDriver.loading}
                />
              ) : null}
              {dashboardMessages.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.messages")}
                  icon={
                    <IconMessages size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/messages");
                  }}
                  isActive={dashboardMessages.active}
                  isLoading={dashboardMessages.loading}
                />
              ) : null}
              {dashboardActivities.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.activities")}
                  icon={
                    <IconActivity size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/activities");
                  }}
                  isActive={dashboardActivities.active}
                  isLoading={dashboardActivities.loading}
                />
              ) : null}
              {process.env.REACT_APP_DEPLOY_TARGET === "local" ||
              process.env.REACT_APP_DEPLOY_TARGET === "stage" ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.driverBehaviour")}
                  icon={
                    <IconDriverBehaviour
                      size={14}
                      color="--global-colors-ui-white"
                    />
                  }
                  onClick={() => {
                    navigate("/dashboard/driver-behaviour");
                  }}
                  isActive={false}
                />
              ) : null}
              {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                process.env.REACT_APP_DEPLOY_TARGET === "stage") &&
              dashboardLocationHistoryDriver.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.locationHistoryDriver")}
                  icon={
                    <IconLocationHistory
                      size={14}
                      color="--global-colors-ui-white"
                    />
                  }
                  onClick={() => {
                    navigate("/dashboard/drivers/location-history");
                  }}
                  isActive={dashboardLocationHistoryDriver.active}
                  isLoading={dashboardLocationHistoryDriver.loading}
                />
              ) : null}
              {process.env.REACT_APP_DEPLOY_TARGET === "local" ||
              process.env.REACT_APP_DEPLOY_TARGET === "stage" ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.missions")}
                  icon={
                    <IconMissions size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/missions");
                  }}
                  isActive={false} //todo
                />
              ) : null}
              <EmptySpace />
              {dashboardNotification.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.notifications")}
                  icon={
                    <IconNotification
                      size={14}
                      color="--global-colors-ui-white"
                    />
                  }
                  onClick={() => {
                    navigate("/dashboard/notifications");
                  }}
                  isActive={dashboardNotification.active}
                  isLoading={dashboardNotification.loading}
                />
              ) : null}
              {dashboardLogs.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.logs")}
                  icon={<IconLogs size={14} color="--global-colors-ui-white" />}
                  onClick={() => {
                    navigate("/dashboard/logs");
                  }}
                  isActive={dashboardLogs.active}
                  isLoading={dashboardLogs.loading}
                />
              ) : null}
              {dashboardTachograph.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.userMenu.tachographFiles")}
                  icon={
                    <IconLicense size={14} color="--global-colors-ui-white" />
                  }
                  onClick={() => {
                    navigate("/dashboard/tachograph-files");
                  }}
                  isActive={dashboardTachograph.active}
                  isLoading={dashboardTachograph.loading}
                />
              ) : null}
            </Menu>
          </div>
        }
      />
      <Route
        path="/admin/*"
        element={
          <div className="sidebar">
            <Menu
              partnerLogoPositive={!!partner ? partner.partnerLogoPositive : ""}
              partnerLogoNegative={!!partner ? partner.partnerLogoNegative : ""}
              isAdmin={true}
              expanded={expanded}
              expandedUpdated={setExpanded}
            >
              {adminVehicle.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.vehicles")}
                  icon={
                    <IconVehicle size={14} color="--global-colors-ink-dark" />
                  }
                  onClick={() => {
                    navigate("/admin/vehicles");
                  }}
                  isActive={adminVehicle.active}
                  isLoading={adminVehicle.loading}
                />
              ) : null}
              {adminDriver.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.drivers")}
                  icon={
                    <IconDriverBehaviour
                      size={14}
                      color="--global-colors-ink-dark"
                    />
                  }
                  onClick={() => {
                    navigate("/admin/drivers");
                  }}
                  isActive={adminDriver.active}
                  isLoading={adminDriver.loading}
                />
              ) : null}
              {adminUser.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.permissions")}
                  onClick={() => {
                    navigate("/admin/users");
                  }}
                  icon={
                    <IconPermissions
                      size={14}
                      color="--global-colors-ink-dark"
                    />
                  }
                  isActive={adminUser.active}
                  isLoading={adminUser.loading}
                />
              ) : null}
              {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.devices")}
                  icon={
                    <IconDevices size={14} color="--global-colors-ink-dark" />
                  }
                />
              )}
              {adminFleets.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.groups")}
                  onClick={() => {
                    navigate("/admin/fleets");
                  }}
                  icon={
                    <IconGroup size={14} color="--global-colors-ink-dark" />
                  }
                  isActive={adminFleets.active}
                  isLoading={adminFleets.loading}
                />
              ) : null}
              {adminGeofences.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.geofences")}
                  onClick={() => {
                    navigate("/admin/geofences");
                  }}
                  icon={
                    <IconGeofence size={14} color="--global-colors-ink-dark" />
                  }
                  isActive={adminGeofences.active}
                  isLoading={adminGeofences.loading}
                />
              ) : null}
              {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.alerts")}
                  icon={
                    <IconAlerts size={14} color="--global-colors-ink-dark" />
                  }
                />
              )}
              {adminNotification.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.notifications")}
                  onClick={() => {
                    navigate("/admin/notifications");
                  }}
                  icon={
                    <IconNotification
                      size={14}
                      color="--global-colors-ink-dark"
                    />
                  }
                  isActive={adminNotification.active}
                  isLoading={adminNotification.loading}
                />
              ) : null}
              <EmptySpace />
              {adminPublicRoutePlanning.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.routePlanning")}
                  onClick={() => {
                    navigate("/admin/publicRoutePlanning");
                    setExpanded(false);
                  }}
                  icon={
                    <IconAddView size={14} color="--global-colors-ink-dark" />
                  }
                  isActive={adminPublicRoutePlanning.active}
                  isLoading={adminPublicRoutePlanning.loading}
                />
              ) : null}
              {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.automations")}
                  icon={
                    <IconAutomations
                      size={14}
                      color="--global-colors-ink-dark"
                    />
                  }
                />
              )}
              {adminPublicRoutes && adminPublicRoutes.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.lines")}
                  onClick={() => {
                    navigate("/admin/publicRoutes");
                    setExpanded(false);
                  }}
                  icon={
                    <IconMenuRoutes
                      size={14}
                      color="--global-colors-ink-dark"
                    />
                  }
                  isActive={adminPublicRoutes.active}
                  isLoading={adminPublicRoutes.loading}
                />
              ) : null}
              {adminPublicRoutesStop && adminPublicRoutesStop.enabled ? (
                <MenuItem
                  isCollapsed={!expanded}
                  label={t("navigation.adminMenu.trips")}
                  onClick={() => {
                    navigate("/admin/publicRouteStops");
                    setExpanded(false);
                  }}
                  icon={
                    <IconExchange size={14} color="--global-colors-ink-dark" />
                  }
                  isActive={adminPublicRoutesStop.active}
                  isLoading={adminPublicRoutesStop.loading}
                />
              ) : null}
            </Menu>
          </div>
        }
      />
      <Route
        path="/account-settings/*"
        element={
          <div className="sidebar">
            <Menu
              isAdmin={true}
              expanded={expanded}
              expandedUpdated={setExpanded}
              partnerLogoPositive={!!partner ? partner.partnerLogoPositive : ""}
              partnerLogoNegative={!!partner ? partner.partnerLogoNegative : ""}
            >
              <MenuItem
                isCollapsed={!expanded}
                label={t("navigation.menuBalloon.userMenu.accountSettings")}
                icon={
                  <IconSettings size={14} color="--global-colors-ink-dark" />
                }
                isActive={true}
                onClick={() => {
                  navigate("/account-settings/edit-account/view");
                }}
              />
            </Menu>
          </div>
        }
      />
      <Route path="/reports/*" element={<></>} />
    </Routes>
  );
}

export default Sidebar;
