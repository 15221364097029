import { t } from "i18next";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { Activity } from "./activitiesSlice";

interface DeleteActivityModalProps {
  openDeleteActivityModal: boolean;
  selectedActivity: Activity | null;
  closeDeleteActivityModal: (e: any) => any;
}

const DeleteActivityModal: React.FC<DeleteActivityModalProps> = ({
  openDeleteActivityModal,
  selectedActivity,
  closeDeleteActivityModal,
}) => {
  return (
    <FeedbackModal
      open={openDeleteActivityModal}
      title={`${t("pages.dashboardActivities.deleteGroup.title")}`}
      desc={
        t("pages.dashboardActivities.deleteGroup.desc") +
        " " +
        t("pages.dashboardActivities.deleteGroup.actionPermanent")
      }
      primaryLabel={`${t("common.confirm")}`}
      isDanger={true}
      primaryAction={() => {
        closeDeleteActivityModal(selectedActivity?.id);
      }}
      secondaryLabel={`${t("common.cancel")}`}
      secondaryAction={closeDeleteActivityModal}
      closeAction={closeDeleteActivityModal}
    />
  );
};
export default DeleteActivityModal;
