import { t } from "i18next";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Tenant, tenantsSelectors } from "../../features/tenants/tenantsSlice";
import { IconWhatsapp } from "../Icon/Line/Whatsapp";
import "./MenuBalloon.css";

type MenuBalloonProps = {
  open: boolean;
  children?: React.ReactNode;
  [key: string]: any;
};

export const MenuBalloon: React.FC<MenuBalloonProps> = ({
  open,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <>
      {isOpen && (
        <div className="mn-menu-balloon" {...props}>
          <div className="mn-menu-balloon-inner">{children}</div>
        </div>
      )}
    </>
  );
};

MenuBalloon.propTypes = {
  /**
   * The state of the balloon.
   */
  open: PropTypes.any,
};
MenuBalloon.defaultProps = {
  open: false,
};

type UserInfo = {
  fullName: string;
  email: string;
};

type Notification = {
  categoryName: string;
  notifications: {
    message: string;
    time: string;
  }[];
};

type UserHelp = {
  sectionTitle: string;
  label: string;
  onClick?: () => void;
};

type HeaderProps = {
  userInfo?: UserInfo;
  notifications?: Notification[];
  userHelp?: UserHelp;
  [key: string]: any;
};

const Header: React.FC<HeaderProps> = ({
  userInfo,
  notifications,
  userHelp,
  ...props
}) => {
  const w = window;

  const [mouseHover, setIsHovered] = useState(false);
  const tenant: Tenant = useAppSelector(tenantsSelectors.selectAll)[0];

  useEffect(() => {
    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const element = document.getElementById("whatsapp-icon-help-section");
    if (element) {
      element.addEventListener("mouseover", handleMouseOver);
      element.addEventListener("mouseout", handleMouseOut);
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      }
    };
  }, []);

  return (
    <>
      {userInfo && (
        <header {...props}>
          <div>{userInfo.fullName}</div>
          <p>{userInfo.email}</p>
        </header>
      )}
      {userHelp && (
        <header className="help-header" {...props}>
          <div>{userHelp.sectionTitle}</div>
          <div className="link" onClick={userHelp.onClick}>
            {userHelp.label}
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
            className="get-help-text"
            onClick={() => {
              const fullName = localStorage.getItem("fullName") || "";
              const companyName = tenant.name;
              const message = encodeURIComponent(
                t("common.whatsapp")
                  .replace("*fullName", fullName)
                  .replace("*companyName", companyName)
              );
              w.open("https://wa.me/+390802464245?text=" + message, "_blank");
            }}
          >
            <span id="whatsapp-icon-help-section">
              <IconWhatsapp size={14} color={mouseHover ? "blue" : "#687484"} />
            </span>
            {t("common.getHelp")}
          </div>
        </header>
      )}
    </>
  );
};

Header.propTypes = {
  userInfo: PropTypes.any,
  notifications: PropTypes.any,
  userHelp: PropTypes.any,
};

type ItemProps = {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
};

const Item: React.FC<ItemProps> = ({ label, onClick, icon }) => {
  return (
    <li onClick={() => onClick()}>
      {icon !== undefined && icon}
      {label}
    </li>
  );
};

Item.propTypes = {
  label: PropTypes.any,
  onClick: PropTypes.any,
  icon: PropTypes.object,
};

(MenuBalloon as any).Header = Header;
(MenuBalloon as any).Item = Item;
