import { schema } from "normalizr";
import { driverSchema } from "../driver/driverNormalization";
import { tenantSchema } from "../tenants/tenantNormalization";
import { userInfoSchema } from "../users/userInfoNormalization";
import { vehicleDetailsSchema } from "../vehicle/vehicleNormalization";

export const activitySchema: schema.Entity<any> = new schema.Entity(
  "activity",
  {
    tenant: tenantSchema,
    driver: driverSchema,
    vehicle: vehicleDetailsSchema,
    user: userInfoSchema,
  }
);

export const activitiesSchema = new schema.Array(activitySchema);
